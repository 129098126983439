import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { addDays, addHours, dateDiff, dateDiff2, dateDiff3 } from 'src/app/core/Funciones/fFecha';
import { NomiExpressApiService } from 'src/app/core/services/NomiExpress.api.service';
import { AccesoDatosService } from 'src/app/core/services/acceso-datos.service';
import { IEmpleados } from 'src/app/models/empleados';
import { IHorarios } from 'src/app/models/horarios';

@Component({
  selector: 'app-horarios-dia',
  templateUrl: './horarios-dia.component.html',
  styleUrls: ['./horarios-dia.component.scss']
})
export class HorariosDiaComponent implements OnInit {
  public empleados: IEmpleados[] = [];
  public ownerId: string = '';
  public companyId: number = 0;
  public camposAdd: string[] = ["nombre"];
  public cargando: boolean = true;

  public fechaReferencia: Date = new Date();
  public semanaActualTitulo: string = '';
  public diasSemana: DiaInfo[] = [];
  public numeroSemana: number[] = [];
  public campoDeEntrada: string = '';
  public empleadoActual: number = -1;

  public valorInput: string = '';
  public habilitar: boolean = false;
  public textoBoton: string = "Editar";
  public textoFiltro: string = "Semana";
  public entradas: number[] = Array(7).fill(0);
  public filaDatos: DiaInfo[] = [];
  public cantidadDias: number = 7;
  public horaIngresada: string = "";
  public horaInvalida: boolean = true;
  public formatoHoras = false;
  public formatoHoraBtn: string = "24H"
  public modalRef!: BsModalRef;
  public opcionSeleccionadaValue: string = '';
  public inputDescanso: string = "Descanso";

  public opciones: string[] = ["Opción 1", "Opción 2", "Opción 3"];
  public datosGuardados: any[] = [];
  public filtro: "semana" | "quincena" | "mes" = "semana";

  public esDebugger: boolean = false;

  constructor(
    private modalService: BsModalService,
    private readonly _api: NomiExpressApiService,
    private readonly _activatedRoute: ActivatedRoute,
    private accesoDatosService: AccesoDatosService,
  ) {

   }

   public cargarDatos() {
    this.esDebugger = this.accesoDatosService.getModoDebug();
    this.accesoDatosService.empleadosListado().subscribe(
      (empleados: IEmpleados[]) => {
        this.empleados = empleados;
      },
      (error) => {
        this.accesoDatosService.logAgrega2("Error al cargar los datos de los empleados");
        console.error(error);
        this.empleados = [];
      }
    );

    // this.accesoDatosService.horarios().subscribe(
    //   (horarios: IHorarios[]) => {
    //     this.horarios = horarios;
    //   }, (error) => {
    //     console.error(error);
    //     let err: string = error.error;
    //     this.accesoDatosService.logAgrega2("Error al cargar los horarios. " + err);
    //     mostrarSwalError('Error', 'Error al cargar los horarios');
    //   }
    // );
  }

   public verificarDescanso(event: KeyboardEvent) {
    let inputValor: string = (event.target as HTMLInputElement).value;
    if (inputValor.toLowerCase() === "d") {
      this.inputDescanso = "Descanso";
    }
  }

  public enviarOpcion(opcion: string) {
    switch (opcion) {
      case "Opción 1":
        console.log("soy opcion 1");
        this.modalRef.hide();
      break;
      case "Opción 2":
        console.log("soy opcion 2");
        this.modalRef.hide();
      break;
      case "Opción 3":
        console.log("soy opcion 3");
        this.modalRef.hide();
      break;
      default:
        console.log('No se seleccionó una opción válida');
    }
  }

  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  public cambiarFormatoHoras() {
    this.formatoHoras = !this.formatoHoras;
    this.formatoHoraBtn = this.formatoHoras ? "12H" : "24H";
  }

  public actualizarCampoDeEntrada(empleadoIndex: number, valor: string) {
    this.campoDeEntrada = valor;
    this.empleadoActual = empleadoIndex;
  }

  public habilitarEdicion() {
    this.habilitar = !this.habilitar;
    this.textoBoton = this.habilitar ? "Guardar" : "Editar";

    if (!this.habilitar) {
      for (let empleadoIndex = 0; empleadoIndex < this.empleados.length; empleadoIndex++) {
        for (let entradaIndex = 0; entradaIndex < this.filaDatos.length; entradaIndex++) {
          const valor = this.filaDatos[entradaIndex].valor;
          this.guardarValor(empleadoIndex, entradaIndex, valor);
      }
    }
  }
}

public guardarValor(empleadoIndex: number, entradaIndex: number, valor: string) {
  while (this.datosGuardados.length <= empleadoIndex) {
    this.datosGuardados.push([]);
  }

  // console.log(`empleadoIndex: ${empleadoIndex}, entradaIndex: ${entradaIndex}, valor: ${valor}` );
  this.datosGuardados[empleadoIndex][entradaIndex] = valor;
  }

  // public validarInput(event: KeyboardEvent) {
  //   const inputValue = event.key;
  //   const pattern = /[0-9]/;

  //   if (!pattern.test(inputValue) && inputValue.toLowerCase() !== "d" && inputValue.toUpperCase() !== "D") {
  //     event.preventDefault();
  //   }
  // }

  public periodoAnterior() {
    this.fechaReferencia = addDays(this.fechaReferencia, -1);
    this.actualizarCalendario();
  }

  public periodoSiguiente() {
    this.fechaReferencia = addDays(this.fechaReferencia, this.cantidadDias);
    this.actualizarCalendario();
  }

  public periodoActualBtn() {
    this.fechaReferencia = new Date();
    this.actualizarCalendario();
  }

  public filtrarPorSemana() {
    this.filtro = "semana";
    this.textoFiltro = "Semana";
    this.actualizarCalendario();
  }

  public filtrarPorQuincena() {
    this.filtro = "quincena";
    this.textoFiltro = "Quincena";
    this.actualizarCalendario();
  }

  public filtrarPorMes() {
    this.filtro = "mes";
    this.textoFiltro = "Mes";
    this.actualizarCalendario();
  }

  public actualizarCalendario() {
    const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    const diasSemanaCompleto = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];

    let inicioPeriodo: Date = new Date();
    let finalPeriodo: Date = new Date();

    switch (this.filtro) {
      case "semana":
        const diaSemanaActual = this.fechaReferencia.getDay();

        inicioPeriodo = addDays(this.fechaReferencia, (diaSemanaActual == 0 ? -6 : 1 - diaSemanaActual));
        finalPeriodo = addDays(inicioPeriodo, 6);
        break;
      case "quincena":
        inicioPeriodo = new Date(this.fechaReferencia.getFullYear(), this.fechaReferencia.getMonth(), this.fechaReferencia.getDate() <= 15 ? 1 : 16);

        if (inicioPeriodo.getDate() == 1) {
          finalPeriodo = new Date(this.fechaReferencia.getFullYear(), this.fechaReferencia.getMonth(), 15);
        } else {
          finalPeriodo = addDays(new Date(inicioPeriodo.getFullYear(), inicioPeriodo.getMonth() + 1, 1), -1);
        }
        break;
      case "mes":
        inicioPeriodo = new Date(this.fechaReferencia.getFullYear(), this.fechaReferencia.getMonth(), 1);
        finalPeriodo = addDays(new Date(inicioPeriodo.getFullYear(), inicioPeriodo.getMonth() + 1, 1), -1);
        break;
      default:
        this.semanaActualTitulo = `Período desconocido`;
        this.diasSemana = [];
        this.cantidadDias = 0;
        break;
    }

    this.fechaReferencia = inicioPeriodo;
    this.cantidadDias = dateDiff3(finalPeriodo, inicioPeriodo) + 1;

    const nombreMesInicio = meses[inicioPeriodo.getMonth()];
    const año = finalPeriodo.getFullYear();
    if (inicioPeriodo.getMonth() == finalPeriodo.getMonth()) {
      this.semanaActualTitulo = `${this.textoFiltro} del ${inicioPeriodo.getDate()} al ${finalPeriodo.getDate()} de ${nombreMesInicio} ${año}`;
    } else {
      const nombreMesFinal = meses[finalPeriodo.getMonth()];
      this.semanaActualTitulo = `${this.textoFiltro} del ${inicioPeriodo.getDate()} de ${nombreMesInicio} al ${finalPeriodo.getDate()} de ${nombreMesFinal} ${año}`;
    }

    const diasSemanaSemana = [];
    this.filaDatos = [];
    for (let i = 0; i < this.cantidadDias; i++) {
      let diaActual: Date = addDays(inicioPeriodo, i);
      const diaInfo = {
        numero: diaActual.getDate(),
        nombre: diasSemanaCompleto[diaActual.getDay()]
      };
      diasSemanaSemana.push(diaInfo);
      let fecha: Date = addHours(diaActual, i);
      let minutos: number = 0;

    //   for (let empleadoIndex = 0; empleadoIndex < this.empleados.length; empleadoIndex++) {
    //     let filaDatoEmpleados: DiaInfo[] = [];
    //     if (this.filaDatos.hasOwnProperty(this.empleados[empleadoIndex].id)) {
    //       filaDatoEmpleados = this.filaDatos.push[empleadoIndex];
    //     }
    //     filaDatoEmpleados.push({ valor: `${i}:00`, fecha: diaActual, hora: addHours(fecha, minutos / 60), entrada: true, esDescanso: false, modificado: false});
    //     filaDatoEmpleados.push({ valor: `${i + 4}:00`, fecha: diaActual, hora: addHours(fecha, 4 + (minutos / 60)), entrada: false, esDescanso: false, modificado: false});
    //     this.filaDatos.push[empleadoIndex] = filaDatoEmpleados;
    //     minutos++;
    //   }
    // }

    // this.diasSemana = diasSemanaSemana;
    this.filaDatos = Array(this.cantidadDias * 2).fill({ valor: '', esDescanso: false, modificado: false});
    this.entradas = Array(this.cantidadDias).fill(0);
  }
}

  // public diaActual(dayNumber: number): boolean {
  //   const diaActual = new Date();
  //   return (diaActual.getDate() === dayNumber && diaActual.getMonth() === this.hoy.getMonth());
  // }

  public getIndexFromFilter() {
    switch (this.filtro) {
      case "semana":
        return 0;
      case "quincena":
        return 1;
      case "mes":
        return 2;
      default:
        return 0;
    }
  }

  ngOnInit(): void {
    var parent = this._activatedRoute.parent;
    if (!parent) {
      throw new Error("An error has ocurred!");
    }

    this.ownerId = parent.snapshot.params["owner"];
    this.companyId = parent.snapshot.params["company"];

    this.fechaReferencia = new Date();
    this.actualizarCalendario();
  }
}

interface DiaInfo {
  valor: string;
  fecha: Date;
  hora: Date;
  entrada: boolean;
  esDescanso: boolean;
  modificado: boolean;
  numero?: number;
  nombre?: string;
  info?: string;
}
