import { CVFechaT } from "../core/Funciones/fFecha";

export interface IPuntoDeAcceso {
    id: string;
    name: string;
    updatedDate: Date;
    isActive: boolean;
    isDebugging: boolean;
}

export interface IMinifiedPuntoDeAcceso {
    a: string;
    b: string;
    c: string;
    d: boolean;
    e: boolean;
}

export function puntosDeAccesoExpandir(puntosDeAcceso: IMinifiedPuntoDeAcceso): IPuntoDeAcceso {
    return {
        id: puntosDeAcceso.a,
        name: puntosDeAcceso.b,
        updatedDate: CVFechaT(puntosDeAcceso.c),
        isActive: puntosDeAcceso.d,
        isDebugging: puntosDeAcceso.e
    }
}

export function newPuntoDeAcceso(): IPuntoDeAcceso {
    return {
        id: '',
        name:  '',
        updatedDate: new Date(),
        isActive: false,
        isDebugging: false
    }
}