import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Output } from '@angular/core';
import { fFecha, getFechaT } from 'src/app/core/Funciones/fFecha';
import { NomiExpressApiService } from 'src/app/core/services/NomiExpress.api.service';
import { AccesoDatosService } from 'src/app/core/services/acceso-datos.service';
import { IEmpresa } from 'src/app/models/empresa';

@Component({
  selector: 'app-config-empresa',
  templateUrl: './config-empresa.component.html',
  styleUrls: ['./config-empresa.component.scss']
})
export class ConfigEmpresaComponent {
  constructor() {}

}
