import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { mostrarSwalError, mostrarSwalToast } from 'src/app/core/Funciones/funciones';
import { AccesoDatosService } from 'src/app/core/services/acceso-datos.service';
import { PDFService } from 'src/app/core/services/pdfmake.service';
import { EmpleadosContratos, EmpleadosContratosDatos, IRespuestaContratos, TipoContratos } from 'src/app/models/documentos';
import { Contrato, IEmpleados, nombreEmpleado } from 'src/app/models/empleados';
import { Estados, IEmpresa } from 'src/app/models/empresa';
import { IRespuesta, IRespuestaChecker } from 'src/app/models/resultadoActualiza';
const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-empleados-contrato',
  templateUrl: './empleados-contrato.component.html',
  styleUrls: ['./empleados-contrato.component.scss']
})
export class EmpleadosContratoComponent implements OnInit {
  public empleadoSeleccionado?: IEmpleados;
  public empleados: IEmpleados[] = [];
  public empresa!: IEmpresa;

  public estaCargando: boolean = true;
  public esDebugger: boolean = false;
  public cuerpoContrato: Contrato = new Contrato();
  public enEdicion: boolean = false;
  public estados = Estados;
  public leyenda: string = '';
  public guardando: boolean = false;
  public nombresUpper: boolean = false;

  constructor(
    private accesoDatosService: AccesoDatosService,
    public dialogRef: MatDialogRef<EmpleadosContratoComponent>,
    private pdfService: PDFService,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {
    this.cargarDatos();
  }

  private cargarDatos() {
    this.estaCargando = true;
    this.esDebugger = this.accesoDatosService.getModoDebug();
    let datosContrato: EmpleadosContratos = new EmpleadosContratos();
    datosContrato.tipoContrato = TipoContratos.ContratoDatosBiometricos;
    this.leyenda = '';

    this.guardando = false;
    this.accesoDatosService.contratosObtener(datosContrato).subscribe(
      (respuesta: IRespuestaContratos) => {
        if (!respuesta.empresa) {
          mostrarSwalError(`Contratos`, `Error al cargar los datos de los contratos desde el servidor. Código: ${respuesta.code}, ${respuesta.mensaje}`);
          return;
        }
        this.cargarEmpleados();
        this.empresa = respuesta.empresa;
        if (!!respuesta.data) {
          this.leyenda = respuesta.data;
          this.cuerpoContrato.leyenda = respuesta.data;
        }
        if (!this.cuerpoContrato.leyenda) {
          this.accesoDatosService.contratosLeyendaPredeterminada().subscribe(
            (leyenda: string) => {
              this.leyenda = leyenda;
              this.cuerpoContrato.leyenda = leyenda;
            }
          );
        }
        this.estaCargando = false;
      },
      (error: HttpErrorResponse) => {
        console.log(`Conectando con el servidor. Contrato obtener. Error.`);
        let err: string = JSON.stringify(error);
        console.log(err);
        mostrarSwalError(`Contratos`, `Error al cargar los datos de los contratos desde el servidor. Código: ${err}`);
      },
    );
  }

  public cargarEmpleados() {
    this.accesoDatosService.empleadosListado().subscribe(
      (empleados: IEmpleados[]) => {
        this.empleados = empleados;
        this.empleados.forEach(empleado => {
          (empleado as any)['nombreCompleto'] = `${empleado.nombre} ${empleado.paterno} ${empleado.materno}`;
        });
        this.aplicarFiltros();
      },
      (error) => {
        this.accesoDatosService.logAgrega2("Error al cargar los datos de los empleados");
        console.error(error);
        this.empleados = [];
      }
    );
  }

  public aplicarFiltros() {
    this.empleados = this.empleados.filter(x => !x.baja || x.baja.getTime() <= new Date(1900, 1, 1).getTime()).sort(
      (a, b) => +a.numero - +b.numero || a.nombre?.toLowerCase().localeCompare(b.nombre?.toLowerCase())
    );
  }

  public nombreCompleto(empleado: IEmpleados): string {
    if (this.nombresUpper) {
      return nombreEmpleado(empleado).toUpperCase();
    } else {
      return nombreEmpleado(empleado);
    }
  }

  private async exportarPDF() {
    try {
      const imgDefault = 'assets/images/ACE_Acceso-Express.png';
      const imgDefaultBase64 = await this.pdfService.getBase64ImageFromAssets(imgDefault);

      let empresaImageBase64: string;
      try {
        empresaImageBase64 = await this.pdfService.getBase64ImageFromAssets(this.empresa.imageUrl);
      } catch (error) {
        console.error('Error al cargar la imagen de la empresa, usando imagen predeterminada.', error);
        empresaImageBase64 = imgDefaultBase64;
      }

      const docDefinition: any = {
        pageOrientation: 'portrait',
        pageSize: 'letter',
        footer: (currentPage: number, pageCount: number) => {
          return {
            text: `Página ${currentPage} de ${pageCount}`,
            alignment: 'center',
            style: 'footer',
            margin: [0, 20, 0, 0]
          };
        },
        content: [],
        styles: {
          footer: { fontSize: 10, italics: true, margin: [0, 0, 0, 10] },
          firmaEmpleado: { color: '#343a40', fontSize: 10 },
          titulo: { bold: true, fontSize: 15 },
        }
      }

      if (this.empleadoSeleccionado) {
        const empleadoSection = [
          {
            columns: [
              { image: empresaImageBase64, width: 50, margin: [0, 0, 20, 0] },
              { text: 'USO Y TRATAMIENTO DE DATOS BIOMÉTRICOS', style: 'titulo', alignment: 'center', margin: [0, 10, 0, 0] },
            ]
          },
          {
            margin: [0, 20, 0, 0],
            text: this.cuerpoContrato.leyenda.replace(/{empresa}/g, `${this.empresa?.nombre}`).replace(/{domicilioCompleto}/g, () => {
                const domicilio = this.empresa?.domicilio || '';
                const cp = this.empresa?.cp || '';
                const estadoKey = this.empresa?.estado as keyof typeof Estados;
                const estado = this.estados[estadoKey] || '-';
                return `${domicilio}, ${cp}, ${estado}`;
              }).replace(/{departamento}/g, 'DEPARTAMENTO DE RECURSOS HUMANOS').replace(/{correo}/g, `${this.empresa?.correo}`)
          },
          {
            stack: [
              {
                canvas: [{ type: 'line', x1: 0, y1: 10, x2: 300, y2: 10, lineWidth: 1.5 }],
                width: 100,
                margin: [0, 10, 0, 2]
              },
              { text: `${this.nombreCompleto(this.empleadoSeleccionado)}`, bold: true, style: 'firmaEmpleado' },
              { text: 'Firma', bold: true, style: 'firmaEmpleado' }
            ],
            alignment: 'center',
          },
        ]

        docDefinition.content.push(empleadoSection);
      } else {
        for (const empleado of this.empleados) {
          const empleadoSection = [
            {
              columns: [
                { image: empresaImageBase64, width: 50, margin: [0, 0, 20, 0] },
                { text: 'USO Y TRATAMIENTO DE DATOS BIOMÉTRICOS', style: 'titulo', alignment: 'center', margin: [0, 10, 0, 0] },
              ]
            },
            {
              margin: [0, 20, 0, 0],
              text: this.cuerpoContrato.leyenda.replace(/{empresa}/g, `${this.empresa?.nombre}`).replace(/{domicilioCompleto}/g, () => {
                const domicilio = this.empresa?.domicilio || '';
                const cp = this.empresa?.cp || '';
                const estadoKey = this.empresa?.estado as keyof typeof Estados;
                const estado = this.estados[estadoKey] || '-';
                return `${domicilio}, ${cp}, ${estado}`;
              }).replace(/{departamento}/g, 'DEPARTAMENTO DE RECURSOS HUMANOS').replace(/{correo}/g, `${this.empresa?.correo}`)
            },
            {
              stack: [
                {
                  canvas: [{ type: 'line', x1: 0, y1: 10, x2: 300, y2: 10, lineWidth: 1.5 }],
                  width: 100,
                  margin: [0, 10, 0, 2]
                },
                { text: `${this.nombreCompleto(empleado)}`, bold: true, style: 'firmaEmpleado' },
                { text: 'Firma', bold: true, style: 'firmaEmpleado' }
              ],
              alignment: 'center',
              margin: [0, 20, 0, 0]
            },
          ]

          docDefinition.content.push(empleadoSection);
          docDefinition.content.push({ text: '', pageBreak: 'after' });
        }
      }

      pdfMake.createPdf(docDefinition).download(`Contrato_biométrico.pdf`);
    } catch (error) {
      console.error('Error al generar el PDF', error);
    }
  }

  public guardar(abrirPDF: boolean) {
    this.guardando = true;
    if (this.cuerpoContrato.leyenda != this.leyenda && !!this.cuerpoContrato.leyenda) {
      let datosContrato: EmpleadosContratosDatos = new EmpleadosContratosDatos();
      datosContrato.idEmpresa = this.empresa.id;
      datosContrato.tipoContrato = TipoContratos.ContratoDatosBiometricos;
      datosContrato.datos = this.cuerpoContrato.leyenda;

      this.accesoDatosService.contratosGuardar(datosContrato).subscribe(
        (respuesta: IRespuestaChecker) => {
          if (respuesta.code == 100) {
            mostrarSwalToast(respuesta.mensaje, 'success');
          } else {
            mostrarSwalToast(`Respuesta del servidor: ${respuesta.code}, ${respuesta.mensaje}`, 'error');
          }
          this.guardando = false;
        },
        (error: HttpErrorResponse) => {
          console.log(`Conectando con el servidor. Contrato obtener. Error.`);
          let err: string = JSON.stringify(error);
          console.log(err);
          mostrarSwalToast(err, 'error');
          this.guardando = false;
        },
      );
      return;
    }
    if (abrirPDF) this.exportarPDF();
  }

  ngOnInit(): void {}
}
