<div class="py-3 m-3">
  <div class="d-flex justify-content-between">
    <div class="d-flex">
      <button mat-raised-button class="me-2 text-primary bg-white" (click)="crearPuntoAcceso(puntosAccesos[0])">Crear nuevo punto de acceso</button>
      <button mat-raised-button class="me-2 text-primary bg-white">Asignar contraseña para puntos de acceso</button>
      <button mat-raised-button class="me-2 text-primary bg-white" (click)="mostraSecreto = !mostraSecreto">Mostrar secretos</button>
    </div>
    <div class="d-flex justify-content-end">
      <button mat-raised-button class="me-2 text-white bg-primary" (click)="abrirModalQR(template)">Generar código QR</button>
    </div>
  </div>

  <div class="mat-elevation-z8 mt-3 col-12">
    <table mat-table [dataSource]="dataSource" matSort>

      <!-- ID Column -->
      <ng-container matColumnDef="nombreAcceso">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre de acceso</th>
        <td mat-cell *matCellDef="let acceso"> {{acceso.name}} </td>
      </ng-container>

      <!-- Progress Column -->
      <ng-container matColumnDef="identificacion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Identificación</th>
        <td mat-cell *matCellDef="let acceso"> {{acceso.id}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="secreto">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Secreto</th>
        <td mat-cell *matCellDef="let acceso"> {{ mostraSecreto ? acceso.id : '*****'}} </td>
      </ng-container>

      <!-- Fruit Column -->
      <ng-container matColumnDef="deviceId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Device ID</th>
        <td mat-cell *matCellDef="let acceso"> {{acceso.id}} </td>
      </ng-container>

      <ng-container matColumnDef="ultimoAcceso">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ultimo acceso</th>
        <td mat-cell *matCellDef="let acceso"> {{acceso.updatedDate}} </td>
      </ng-container>

      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</th>
        <td mat-cell *matCellDef="let acceso">
          <button mat-raised-button color="primary">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center h5 text-danger p-3" colspan="6">Aún no tienes puntos de acceso registrados, usa el botón de nuevo para registrar el uso de un nuevo reloj checador.</td>
      </tr>
    </table>
  </div>
</div>

<!-- ======================================== Modal generar QR ======================================== -->
<ng-template #template>
  <div class="modal-header p-2">
    <h4 class="modal-title pull-left mb-0">Generar código QR</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRefQR?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-12">
      <label class="form-label mb-0">Selecciona un punto de acceso</label>
      <select class="form-select form-select-sm" aria-label="Default select example" [(ngModel)]="opcionPuntoAcceso" [disabled]="mostrarCodigo">
        <option *ngFor="let checker of puntosAccesos | keyvalue" [value]="checker.key">
          {{ checker.value }}
        </option>
      </select>
    </div>
    <!-- <div class="input-group mb-3 scale-in-center">
      <div class="input-group-prepend">
        <label class="input-group-text" for="opcion">Selecciona un punto de acceso:</label>
      </div>
      <select class="custom-select" name="opcion" id="opcion" [(ngModel)]="opcionPuntoAcceso" [disabled]="mostrarCodigo">
        <option selected="true" disabled value="">Seleccione un punto de acceso</option>
        <option *ngFor="let checker of puntosAccesos" [value]="checker.id">{{ checker.name }}</option>
      </select>
    </div> -->
    <div *ngIf="mostrarCodigo">
      <!-- ======================================== código QR ======================================== -->
      <div class="scale-in-center">
        <qrcode class="text-center" [qrdata]="codigo" [width]="350" [errorCorrectionLevel]="'M'"></qrcode>
      </div>
      <div class="text-center scale-in-center">
        <!-- ======================================== URL ======================================== -->
        <a [href]="urltxt" target="_blank">{{ urltxt }}</a>
        <!-- ======================================== enviar WhatsApp ======================================== -->
        <div class="input-group mt-4">
          <input type="text" class="border form-control" [(ngModel)]="numeroWhats" placeholder="Ingresa el numero"  (keyup.enter)="enviarWhatsapp()">
          <div class="input-group-append">
            <button class="btn btn-info" (click)="enviarWhatsapp()">Enviar por WhatsApp</button>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2 text-center">
      <button class="btn btn-success" type="submit" (click)="obtenerCodigoQR()">{{ txtQrBtn }}</button>
    </div>
  </div>
</ng-template>
