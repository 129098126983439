<div *ngIf="!empresa">
  <div class="text-center py-5">
    <mat-spinner class="img-center"></mat-spinner>
  </div>
  <div class="text-center py-5">
    <span>Cargando datos...</span>
  </div>
</div>
<div *ngIf="!!empresa">
  <div class="container my-3 text-start shadow p-3 bg-body-tertiary rounded">
    <div class="text-center mb-2 border-bottom pb-3" *ngIf="!!empresa.imageUrl">
      <img class="img-fluid" [src]="empresa.imageUrl" alt="">
    </div>
    <div class="row g-3">
      <div class="col-12 d-flex justify-content-end" *ngIf="enEdicion || !empresa.imageUrl">
        <input type="file" (change)="onFileSelected($event)" multiple #fileInput class="d-none" accept="image/*" />
        <button (click)="fileInput.click()" mat-raised-button class="me-2 text-primary">
          Agregar logo
        </button>
        <button mat-raised-button class="me-2 text-danger bg-white" (click)="deshacerCambios()"><mat-icon>undo</mat-icon>Deshacer</button>
        <button mat-raised-button class="text-success bg-white" (click)="guardarCambios()"><mat-icon>save</mat-icon>Guardar</button>
      </div>
      <div class="col-12 d-flex justify-content-end" *ngIf="!enEdicion">
        <button mat-raised-button class="text-primary bg-white" (click)="modificarDatos()"><mat-icon>edit</mat-icon>Modificar</button>
      </div>
      <div class="col-6" *ngIf="esDebugger">
        <label for="idUnidad" class="form-label mb-0">ID unidad</label>
        <input type="text" class="form-control bg-light slide-in-left" id="idUnidad" [(ngModel)]="empresa.ownerUserId" onClick="this.select();" readonly>
      </div>
      <div class="col-6" *ngIf="esDebugger">
        <label for="idEmpresa" class="form-label mb-0">ID empresa</label>
        <input type="text" class="form-control bg-light slide-in-left" id="idEmpresa" [(ngModel)]="empresa.id" onClick="this.select();" readonly>
      </div>
      <div class="col-10">
        <label for="nombre" class="form-label mb-0">Nombre / Razón social</label>
        <input type="text" class="form-control bg-light slide-in-left" [class.bg-white]="enEdicion" id="nombre" [(ngModel)]="empresa.nombre" onClick="this.select();" [readonly]="!enEdicion || !esDebugger">
      </div>
      <div class="col-2">
        <label for="rfc" class="form-label mb-0">R.F.C.</label>
        <input type="text" class="form-control bg-light slide-in-left" [class.bg-white]="enEdicion" id="rfc" [(ngModel)]="empresa.rfc" onClick="this.select();" [readonly]="!enEdicion">
      </div>
      <div class="col-4">
        <label for="email" class="form-label mb-0">Correo</label>
        <input type="email" class="form-control bg-light slide-in-left" [class.bg-white]="enEdicion" id="email" [(ngModel)]="empresa.correo" onClick="this.select();" [readonly]="!enEdicion">
      </div>
      <div class="col-4">
        <label for="domicilio" class="form-label mb-0">Domicilio</label>
        <input type="text" class="form-control bg-light slide-in-left" [class.bg-white]="enEdicion" id="domicilio" [(ngModel)]="empresa.domicilio" onClick="this.select();" [readonly]="!enEdicion">
      </div>
      <div class="col-4">
        <label for="telefono" class="form-label mb-0">Teléfono</label>
        <input type="tel" class="form-control bg-light slide-in-left" [class.bg-white]="enEdicion" id="telefono" [(ngModel)]="empresa.telefono" onClick="this.select();" [readonly]="!enEdicion">
      </div>
      <div class="col-4">
        <label for="ciudad" class="form-label mb-0">Ciudad</label>
        <input type="text" class="form-control bg-light slide-in-left" [class.bg-white]="enEdicion" id="ciudad" [(ngModel)]="empresa.ciudad" onClick="this.select();" [readonly]="!enEdicion">
      </div>
      <div class="col-4">
        <label class="form-label mb-0">Estado</label>
        <select class="form-select bg-light" aria-label="Default select example" [(ngModel)]="empresa.estado" [disabled]="!enEdicion" [class.bg-white]="enEdicion">
          <option *ngFor="let estado of estados | keyvalue" [value]="estado.key">
            {{ estado.value }}
          </option>
        </select>
      </div>
      <div class="col-4">
        <label for="cp" class="form-label mb-0">Codigo Postal</label>
        <input (change)="validarCP($event)" type="number" class="form-control bg-light slide-in-left" [class.bg-white]="enEdicion" id="cp" [(ngModel)]="empresa.cp" onClick="this.select();" [readonly]="!enEdicion">
      </div>
      <div class="col-6">
        <label for="vigenteHasta" class="form-label mb-0">Vigente hasta</label>
        <input type="text" class="form-control slide-in-left" id="vigenteHasta" [value]="vigenteHastaTxt" onClick="this.select();" readonly  [ngClass]="{'bg-danger text-white': vigenciaValida, 'bg-light': !vigenciaValida}">
      </div>
      <div class="col-6">
        <label for="apiKeyCF" class="form-label mb-0">Etiqueta de agrupación</label>
        <input type="text" class="form-control bg-light slide-in-left" id="apiKeyCF" [class.bg-white]="enEdicion" [(ngModel)]="empresa.etiquetaGrupo" onClick="this.select();" [readonly]="!enEdicion">
      </div>
      <div class="col-6">
        <label for="idReferencia" class="form-label mb-0">ID Referencia</label>
        <input type="text" class="form-control bg-light slide-in-left" [class.bg-white]="enEdicion" id="idReferencia" [(ngModel)]="empresa.idReferencia" onClick="this.select();" [readonly]="!enEdicion">
        <small>Este campo es para el ID de su programa de nómina. <small class="text-danger">Si usted usa NomiExpress Web, no es necesario llenarlo.</small></small>
      </div>
      <div class="col-6" *ngIf="esDebugger">
        <label for="apiKeyCF" class="form-label mb-0">Api Key CF</label>
        <input type="text" class="form-control bg-light slide-in-left" id="apiKeyCF" [(ngModel)]="empresa.apiKeyCF" onClick="this.select();" readonly>
      </div>
      <div class="col-6 align-self-end mt-0" [ngClass]="{'align-self-center d-flex justify-content-end': !esDebugger}">
        <p class="mb-0 text-primary">{{fFecha(inicioApp)}}</p>
      </div>
      <div class="col-6 d-flex justify-content-end align-items-end mt-0" *ngIf="esDebugger">
        <button mat-raised-button class="text-primary bg-white mx-2" (click)="visualizarSucursales()">Visualizar puntos de acceso</button>
      </div>
      <div class="col-12 d-flex justify-content-end" *ngIf="enEdicion">
        <small class="opacity-25" (click)="modoDebug()">{{esDebugger ? 'Deshabilitar modo debugger' : 'Habilitar modo debugger'}}</small>
      </div>
    </div>
  </div>
</div>
