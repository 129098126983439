import { OpcionesFecha } from "src/app/models/accesoExpress";
import { $tipoPeriodoOrden } from "src/app/models/periodos";

const díasSemana = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];

export function CVFechaT(fecha: string | undefined): Date {
    // console.log(fecha);
    if (!fecha || fecha.length < 10) return new Date(1900, 0, 1);
    let año: number = parseInt(fecha.substring(0, 4));
    let mes: number = parseInt(fecha.substring(5, 7));
    let dia: number = parseInt(fecha.substring(8, 10));
    if (fecha.length < 12) return new Date(año, mes - 1, dia);

    let hora: number = parseInt(fecha.substring(11, 13));
    let minutos: number = parseInt(fecha.substring(14, 16));
    let segundos: number = parseInt(fecha.substring(17, 18));
    return new Date(año, mes - 1, dia, hora, minutos, segundos);
}

export function fechaCV(fecha: string | undefined): Date{
  return CVFechaT(fecha);
}

export function fechaDeTxt(fecha: string | undefined): Date{
  return CVFechaT(fecha);
}

export function fFechaCV(fecha: string): Date {
  let fechaDatos: string[] = fecha.split('-');
  if (fechaDatos.length < 2) {
    fechaDatos = fecha.split('/');
  }
  if (fechaDatos.length < 2) {
    fechaDatos = fecha.split(' de ');
  }
  if (fechaDatos.length < 2) {
    return new Date(1900, 0, 1);
  }

  let año: number = +fechaDatos[2];
  let dia: number = +fechaDatos[0];
  let mes: number = 0
  let mesTxt: string = fechaDatos[1].toLowerCase();
  if (mesTxt.length > 3) mesTxt = mesTxt.substring(0, 3);
  if (mesTxt.substring(0, 1) == '0') mesTxt = mesTxt.substring(1);
  
  switch (mesTxt) {
    case '1':
    case 'ene':
    case 'jan':
      mes = 0;
      break;

    case '2':
    case 'feb':
      mes = 1;
      break;

    case '3':
    case 'mar':
      mes = 2;
      break;

    case '4':
    case 'abr':
    case 'apr':
      mes = 3;
      break;

    case '5':
    case 'may':
      mes = 4;
      break;

    case '6':
    case 'jun':
      mes = 5;
      break;

    case '7':
    case 'jul':
      mes = 6;
      break;

    case '8':
    case 'ago':
    case 'aug':
      mes = 7;
      break;

    case '9':
    case 'sep':
      mes = 8;
      break;

    case '10':
    case 'oct':
      mes = 9;
      break;

    case '11':
    case 'nov':
      mes = 10;
      break;

    case '12':
    case 'dic':
    case 'dec':
      mes = 11;
      break;
  }

  return new Date(año, mes, dia);

}

export function getFechaT(fecha: string): Date {
  if (fecha.length < 10) return new Date(1900, 0, 0);
    let año: number = parseInt(fecha.substring(0, 4));
    let mes: number = parseInt(fecha.substring(5, 7));
    let dia: number = parseInt(fecha.substring(8, 10));
    if (fecha.length < 12) return new Date(año, mes - 1, dia);

    let hora: number = parseInt(fecha.substring(11, 13));
    let minutos: number = parseInt(fecha.substring(14, 16));
    let segundos: number = parseInt(fecha.substring(17, 18));
    return new Date(año, mes - 1, dia, hora, minutos, segundos);
  }

  export function getFechaTu(fecha: string | undefined): Date | undefined {
    if (!fecha) return undefined;
    let año: number = parseInt(fecha.substring(0, 4));
    let mes: number = parseInt(fecha.substring(5, 7));
    let dia: number = parseInt(fecha.substring(8, 10));
    if (fecha.length < 12) return new Date(año, mes - 1, dia);

    let hora: number = parseInt(fecha.substring(11, 13));
    let minutos: number = parseInt(fecha.substring(14, 16));
    let segundos: number = parseInt(fecha.substring(17, 18));
    return new Date(año, mes - 1, dia, hora, minutos, segundos);
  }

  export function addDays(source: Date, days: number) {
    const date = new Date(source.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

export function addHours(source: Date, hours: number) {
  let newDate = new Date(source);
  newDate.setTime(source.getTime() + (hours * 60 * 60 * 1000));
  return newDate;
}

export function addSeconds(source: Date, seconds: number) {
  let newDate = new Date(source);
  newDate.setTime(source.getTime() + (seconds * 1000));
  return newDate;
}

export function fFecha(fecha: Date | undefined, formato: string): string {
  if (!fecha || fecha == null) {
    fecha = new Date(1900, 0, 0);
    // console.log(`fFecha (1): ${fecha}`);
  }
  if (fecha <= new Date(19, 0, 1) ) return '-';

  let fechaTxt = `${fecha}`;
  if (fechaTxt.substring(0, 4) == '0001') {
    fecha = new Date(1900, 0, 0);
    // console.log(`fFecha (2): ${fecha}`);
  }
  let diaString: string = fecha.toLocaleDateString('es-ES', { weekday: 'long' });
  let año: number = fecha.getFullYear();
  let mes: number = fecha.getMonth() + 1;
  let dia: number = fecha.getDate();
  let hora: number = fecha.getHours();
  let minutos: number = fecha.getMinutes();
  let segundos: number = fecha.getSeconds();

  diaString = diaString.charAt(0).toUpperCase() + diaString.slice(1);

  switch (formato.toLowerCase()) {
    case 'fcc':
      return `${dia.toString().padStart(2, '0')} ${mes.toString().padStart(2, '0')} ${año.toString().slice(-2)}`;

    case 'fc':
      return `${dia.toString().padStart(2, '0')} ${$Mes[mes].substring(0, 3)} ${año.toString().slice(-2)}`;

    case 'fm':
      return `${dia} ${$Mes[mes]} ${año}`;

    case 'fmh':
      return `${dia} ${$Mes[mes]} ${año} ${hora.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}`;

    case 'fmhs':
        return `${dia} ${$Mes[mes]} ${año} ${hora.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}:${segundos.toString().padStart(2, '0')}`;

    case 'hs':
      return `${hora.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}:${segundos.toString().padStart(2, '0')}`;

    case 'hm':
      return `${hora.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}`;

    case 'amd':
      return `${año.toString()}${mes.toString().padStart(2, '0')}${dia.toString().padStart(2, '0')}`; /// yyyyMMdd

    case 'anio':
      return `${año}`

    case 'fsl':
      return `${dia} de ${$Mes[mes]} de ${año}`;

    case 'fcd':
      return `${dia.toString().padStart(2, '0')}/${mes.toString().padStart(2, '0')}/${año.toString()}`;

    case 'fcds':
      return `${diaString}, ${dia} de ${$Mes[mes]} de ${año}`;

    case 'fa':
      return `${dia.toString().padStart(2, '0')}${mes.toString().padStart(2, '0')}${año.toString().slice(-2)}`;

    default:
      return `${dia} ${$Mes[mes]} ${año}`;
  }
}

export function dateDiff2(d1: Date, d2: Date): number {
  const t = (1000 * 60 * 60 * 24);

  // console.log(`dateDiff2: ${d1}, ${d2}`);

  const utc2 = Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate());
  const utc1 = Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate());

  // UNA VEZ QUE NUNCA MARQUE ESTE ERROR BORRAR
  const utc3 = Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate(), 6);
  const utc4 = Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate(), 6);

  if ((utc1 - utc2) / t != (utc4 - utc3) / t) {
      throw Error("diferencia de fechas: " + new Date(utc3) + '/' + new Date(utc2));
  }
  // FIN BORRADO

  return (utc1 - utc2) / t;
}

export function dateDiff(inicio: Date, final: Date, tipoFecha: TipoDateDiff): number {
  let diffMs = final.getTime() - inicio.getTime();


  // let diffDays = diffMs / 86400000; // Math.floor(... days
  // let diffHrs = diffMs / (1000 * 60 * 24); // Math.floor(... hours
  // let diffMins = diffMs / (1000 * 60);
  // let diffSegundos = diffMs / 1000;
  // console.log(diffMs.toFixed(2) + "ms " + (diffMs / 86400000).toFixed(4) + " days, " + (diffMs / (1000 * 60 * 60)).toFixed(2) + " hours, " + (diffMs / (1000 * 60)).toFixed(2) + " minutes " + (diffMs / 1000).toFixed(2) + " segundos");

  switch (tipoFecha) {
    case TipoDateDiff.días:
      return diffMs / 86400000;
    case TipoDateDiff.horas:
      return diffMs / (1000 * 60 * 60);
    case TipoDateDiff.minutos:
      return diffMs / (1000 * 60);
    case TipoDateDiff.segundos:
        return diffMs / 1000;
    default:
      return diffMs;
  }

}

export function dateDiffAhora(fecha: Date, tipoFecha: TipoDateDiff): number {
  let final: Date = new Date();
  let diffMs = final.getTime() - fecha.getTime();


  // let diffDays = diffMs / 86400000; // Math.floor(... days
  // let diffHrs = diffMs / (1000 * 60 * 24); // Math.floor(... hours
  // let diffMins = diffMs / (1000 * 60);
  // let diffSegundos = diffMs / 1000;
  // console.log(diffMs.toFixed(2) + "ms " + diffDays.toFixed(8) + " days, " + diffHrs.toFixed(8) + " hours, " + diffMins.toFixed(8) + " minutes " + diffSegundos.toFixed(8) + " segundos");

  switch (tipoFecha) {
    case TipoDateDiff.días:
      return diffMs / 86400000;
    case TipoDateDiff.horas:
      return diffMs / (1000 * 60 * 24);
    case TipoDateDiff.minutos:
      return diffMs / (1000 * 60);
    case TipoDateDiff.segundos:
        return diffMs / 1000;
    default:
      return diffMs;
  }

}



export function obtenerPeriodo(periodo: string, ejercicio: number): Periodo {
  let fechaInicial: Date = new Date(1900, 0, 1);
  let fechaFinal: Date = new Date(5000, 11, 31);
  let tipoPeriodoOrden = $tipoPeriodoOrden;
  if (tipoPeriodoOrden[periodo] >= 1 && tipoPeriodoOrden[periodo] <= 12) {
    fechaInicial = new Date(ejercicio, tipoPeriodoOrden[periodo] - 1, 1);
    fechaFinal = addDays(new Date(ejercicio, tipoPeriodoOrden[periodo], 1), -1);
  } else if (tipoPeriodoOrden[periodo] == 20 || tipoPeriodoOrden[periodo] == 30) {
    let mesActual: number = new Date().getMonth() + 1;
    let mesesMenos: number = tipoPeriodoOrden[periodo] / 10;
    fechaFinal = addDays(new Date(ejercicio, mesActual, 1), -1);
    if (mesActual >= mesesMenos){
      fechaInicial = new Date(ejercicio, mesActual - mesesMenos, 1);
    } else {
      fechaInicial = new Date(ejercicio - 1, 12 + mesActual - mesesMenos, 1);
    }
  } else if (tipoPeriodoOrden[periodo] == 0) {
    fechaInicial = new Date(ejercicio, 0, 1);
    fechaFinal = new Date(ejercicio, 11, 31);
  }
  return { inicio: fechaInicial, final: fechaFinal };
}

export function getTimeString(decimalTime: number) {
  const h = Math.trunc(decimalTime);
  const m = Math.round((decimalTime - h) * 60);
  return (h > 0 ? `${h} hrs ` : '') + (m > 0 ? `${m} mins` : '');
}

export function fechaAlServidor(date: Date | undefined): string {
  // console.log(`fechaAlServidor: ${date}`);
  if (date == undefined || date == null)
      date = new Date(1900, 0, 1, 0, 0, 0, 0);
  const a = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
  return new Date(a).toISOString();
}

export function Ahora(opcionesFecha: OpcionesFecha): Date {
  return ValidarHora(new Date(), opcionesFecha);
}

export function Ahora2(checkServerTime: boolean, offSetFromServer: number, opcionesFecha: OpcionesFecha): Date {
  let fecha: Date = new Date();
  if (checkServerTime && offSetFromServer > 0 && offSetFromServer < 200) {
      fecha = addSeconds(new Date(), offSetFromServer);
  }
  return ValidarHora(fecha, opcionesFecha);
}

export function ValidarHora(fecha: Date, opcionesFecha: OpcionesFecha): Date{
  // console.log(`ValidarHora: ${fFecha(fecha, 'fmh')} opc hora: ${opcionesFecha}`);
  if (fecha.getFullYear() < 2023) return fecha;
  if (opcionesFecha == OpcionesFecha.Normal) {
    let ahoraTxt: string = `Ahora: ${new Date()}`;
    if (ahoraTxt.indexOf(`GMT-0500 (hora de verano central)`) > 0) {
        // let fecha: Date = new Date();
        // let utc = fecha.getTime() - (fecha.getTimezoneOffset() * 60000);
        // return new Date(utc + (3600000*6));
        // console.log(`ValidarHora. Quitando una hora`);
        return addHours(fecha, -1);
    }
    return fecha;
  }

  if (opcionesFecha == OpcionesFecha.HorarioVerano || opcionesFecha == OpcionesFecha.PorSO) {
    // console.log(`ValidarHora. Sin cambios`);
    return fecha;
  }

  if (opcionesFecha == OpcionesFecha.Mas1) {
    // console.log(`ValidarHora. Mas una hora`);
    return addHours(fecha, 1);
  }

  if (opcionesFecha == OpcionesFecha.Mas2) {
    // console.log(`ValidarHora. Mas dos hora`);
    return addHours(fecha, 2);
  }

  if (opcionesFecha == OpcionesFecha.Menos1) {
    // console.log(`ValidarHora. Menos una hora`);
    return addHours(fecha, -1);
  }

  if (opcionesFecha == OpcionesFecha.Menos2) {
    // console.log(`ValidarHora. Menos dos hora`);
    return addHours(fecha, -2);
  }

  console.log(`ValidarHora: ${fFecha(fecha, 'fmh')} sin cambio, opción: ${opcionesFecha}`);
  return fecha;
}

export function EsHorarioVeranoAnterior(fecha: Date): boolean{
  // console.log(`EsHorarioVeranoAnterior: ${fecha}`);
  if (fecha.getFullYear() < 2023) return false;
  let ahoraTxt: string = `Ahora: ${fecha}`;
  if (ahoraTxt.indexOf(`GMT-0500 (hora de verano central)`) > 0) {
      return true;
  }
  return false;
}

export function obtenerValorFecha(date: Date): string {
  var h = date.getHours();
  var m = date.getMinutes();
  var s = date.getSeconds();

  var m1 = m > 30 ? base[30] : base[m];
  var m2 = m > 30 ? base[m - 30] : base[0];

  var s1 = s > 30 ? base[30] : base[s];
  var s2 = s > 30 ? base[s - 30] : base[0];

  return `${s2}${m1}${base[h]}${m2}${s1}`;
}

export function dateDiff3(d1: Date, d2: Date): number {
  const t = (1000 * 60 * 60 * 24);

  const utc2 = Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate());
  const utc1 = Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate());

  // UNA VEZ QUE NUNCA MARQUE ESTE ERROR BORRAR
  const utc3 = Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate(), 6);
  const utc4 = Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate(), 6);

  if ((utc1 - utc2) / t != (utc4 - utc3) / t) {
      throw Error("diferencia de fechas: " + new Date(utc3) + '/' + new Date(utc2));
  }
  // FIN BORRADO

  return (utc1 - utc2) / t;
}

export function soloHoy(): Date {
  let hoy: Date = new Date();
  return new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate());
}

export function soloDia(fecha: Date): Date {
  return new Date(fecha.getFullYear(), fecha.getMonth(), fecha.getDate());
}

export function soloHora(fecha: Date): Date {
  return new Date(2000, 0, 1, fecha.getHours(), fecha.getMinutes(), fecha.getSeconds());
}

export function soloHoraNum(hora: number): Date {  
  let horas: number = Math.floor(hora);
  if (hora == horas) return new Date(2000, 0, 1, horas, 0, 0);
  let minutosTotal: number = (hora - horas) * 60;
  let minutos: number = Math.floor(minutosTotal);
  let segundos: number = (minutosTotal - minutos) * 60;
  return new Date(2000, 0, 1, horas, minutos, segundos);
}

export function inicioMesActual(): Date {
  let hoy: Date = new Date();
  return new Date(hoy.getFullYear(), hoy.getMonth(), 1);
}

export function inicioMes(fecha: Date): Date {
  return new Date(fecha.getFullYear(), fecha.getMonth(), 1);
}

export function inicioSemanaActual(): Date {
  let hoy: Date = new Date();
  let diaSemana: number = hoy.getDay();
  if (diaSemana == 0) diaSemana = 7;
  return new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate() - diaSemana + 1);
}

export function inicioSemana(fecha: Date): Date {
  let diaSemana: number = fecha.getDay();
  if (diaSemana == 0) diaSemana = 7;
  return new Date(fecha.getFullYear(), fecha.getMonth(), fecha.getDate() - diaSemana + 1);
}

export function inicioQuincenaActual(): Date {
  let hoy: Date = new Date();
  let dia: number = hoy.getDate();
  if (dia > 15) {
    dia = 16;
  } else {
    dia = 1;
  }
  return new Date(hoy.getFullYear(), hoy.getMonth(), dia);
}

export function inicioQuincena(fecha: Date): Date {
  let dia: number = fecha.getDate();
  if (dia > 15) {
    dia = 16;
  } else {
    dia = 1;
  }
  return new Date(fecha.getFullYear(), fecha.getMonth(), dia);
}

export function calculaDiasFestivos(año: number): { [date: string]: boolean } {
  var dias: { [date: string]: boolean } = {};

  for (let x = 0; x <= 7; x++) {
      var fTemp: Date = new Date();

      switch (x) {
          case 0:
              // 1° de enero
              fTemp = new Date(año, 1 - 1, 1);
              break;
          case 1:
              // 'El primer lunes de febrero en conmemoración del 5 de febrero (5 de febrero 2018)
              fTemp = new Date(año, 2 - 1, 5);
              break;
          case 2:
              //'El tercer lunes de marzo en conmemoración del 21 de marzo (19 de marzo 2018)
              fTemp = new Date(año, 3 - 1, 21);
              break;
          case 3:
              //'5 de mayo
              fTemp = new Date(año, 5 - 1, 1);
              break;
          case 4:
              //'16 de septiembre
              fTemp = new Date(año, 9 - 1, 16)
              break;
          case 5:
              //'El tercer lunes de noviembre en conmemoración del 20 de noviembre (19 de noviembre 2018)
              fTemp = new Date(año, 11 - 1, 20);
              break;
          case 6:
              // '1° de diciembre por la transmisión del Poder Ejecutivo Federal (Presidente)
              var i = (año - 2000) % 6
              if (año >= 2024) {
                  fTemp = new Date(año + i, 10 - 1, 1);
              } else {
                  fTemp = new Date(año + i, 12 - 1, 1);
              }                    
              break;
          case 7:
              fTemp = new Date(año, 12 - 1, 25);
              break;
      }

      var i = fTemp.getDay();
      if ((i != 1) && (x == 1 || x == 2 || x == 5)) {
          if (i == 6 && x != 2) {
              fTemp = addDays(fTemp, 2);
          } else if (i == 0) {
              fTemp = addDays(fTemp, 1);
          } else {
              fTemp = addDays(fTemp, 1 - i);
          }
      }

      dias[getDateKey(fTemp)] = true;
  }
  return dias;
}

export function getDateKey(x: Date) {
  return `${x.getFullYear()}-${x.getMonth()}-${x.getDate()}`;
}

export function díaSemana(fecha: Date): string {
  return díasSemana[fecha.getDay()];
}

export const $Mes: { [key: number]: string } = {
  1: 'Enero',
  2: 'Febrero',
  3: 'Marzo',
  4: 'Abril',
  5: 'Mayo',
  6: 'Junio',
  7: 'Julio',
  8: 'Agosto',
  9: 'Septiembre',
  10: 'Octubre',
  11: 'Noviembre',
  12: 'Diciembre'
}

export enum TipoDateDiff {
  días,
  horas,
  minutos,
  segundos,
  miliSegundos
}

export interface Periodo {
  inicio: Date,
  final: Date
}

export interface PeriodoTxt {
  inicio: string,
  final: string
}

export const base = [
  'i', '7', '4', 'k', 't', 'a', 'f', 'u', '5', 'm', 'j', 'g', 'o', 'n', 'x', '8',
  'd', 's', 'e', '9', '6', '2', '1', '3', 'c', 'q', 'p', 'h', 'l', 'b', 'r'
];
