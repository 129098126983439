import { Component, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { addDays, dateDiff3 } from 'src/app/core/Funciones/fFecha';
import { mostrarSwalError } from 'src/app/core/Funciones/funciones';
import { NomiExpressApiService } from 'src/app/core/services/NomiExpress.api.service';
import { AccesoDatosService } from 'src/app/core/services/acceso-datos.service';
import { IEmpleados } from 'src/app/models/empleados';
import { IHorarios } from 'src/app/models/horarios';

@Component({
  selector: 'app-horarios-rotativos',
  templateUrl: './horarios-rotativos.component.html',
  styleUrls: ['./horarios-rotativos.component.scss']
})
export class HorariosRotativosComponent {
  public fechaReferencia: Date = new Date();
  public textoFiltro: string = "Semana";
  public semanaActualTitulo: string = '';
  public diasDelMes: string = '';
  public cantidadDias: number = 7;
  public cantidadDatos: number = 7;
  public filtro: "semana" | "mes" = "semana";
  public formatoDatoBtn: string = "Empleados"
  public habilitar: boolean = false;
  public textoBoton: string = "Editar";
  public campoDeEntrada: string = '';
  public diasSemana: DiaInfo[] = [];
  public semanasDelMes: string[] = [];
  public diasPorSemana: number[][] = [];
  public entradas: number[] = Array(7).fill(0);
  public ownerId: string = '';
  public companyId: number = 0;
  public camposAdd: string[] = ["nombre"];
  public cargando: boolean = true;

  public empleados: IEmpleados[] = [];
  public filaDatos: { [id: string]: string[] } = { };
  public datosGuardados: any[] = [];
  public cambiarDatos: "empleados" | "grupos" = "empleados";
  public horarios: IHorarios[] = [];
  public conBajas: boolean = false;
  public tituloFiltroEmpleadoGrupo: string = 'Empleados';
  public valorTemporal: string | null = null;
  public grupoSeleccionado: string | null = null;
  public indexSeleccionado: number | null = null;

  public grupos: { nombre: string, id: string }[] = [
    { nombre: 'Grupo 1', id: '1' },
    { nombre: 'Grupo 2', id: '2' },
    { nombre: 'Grupo 3', id: '3' },
  ];

  public modalRef?: BsModalRef;
  public aplicarOtrosDias: boolean = false;
  public select1Value: string[] = [];
  public select2Values: string[] = [];
  public horarioSeleccionado: string = "";
  public esDebugger: boolean = false;
  public grupoEmpleados: string[] = [];

  constructor(
    private modalService: BsModalService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _api: NomiExpressApiService,
    private accesoDatosService: AccesoDatosService,
  ) {
    this.cargarDatos();
  }

  public cargarDatos() {
    this.cargando = true;
    this.esDebugger = this.accesoDatosService.getModoDebug();
    this.accesoDatosService.empleadosListado().subscribe(
      (empleados: IEmpleados[]) => {
        this.empleados = empleados;
        this.actualizarCalendario();
      },
      (error) => {
        this.accesoDatosService.logAgrega2("Error al cargar los datos de los empleados");
        console.error(error);
        this.empleados = [];
      }
    );

    this.accesoDatosService.horarios().subscribe(
      (horarios: IHorarios[]) => {
        this.horarios = horarios;
      }, (error) => {
        console.error(error);
        let err: string = error.error;
        this.accesoDatosService.logAgrega2("Error al cargar los horarios. " + err);
        mostrarSwalError('Error', 'Error al cargar los horarios');
      }
    );


    this.accesoDatosService.empleadosGrupos().subscribe(
      (grupos: string[]) => {
        this.grupoEmpleados = grupos;
        this.cargando = false;
      },
      (error) => {
        let errorTxt: string = error.error;
        this.accesoDatosService.logAgrega(`Error al cargar los datos de los grupos de empleados. ${errorTxt}`);
        console.error(error);
        this.cargando = false;
      }
    );
  }


  public periodoAnterior() {
    this.fechaReferencia = addDays(this.fechaReferencia, -1);
    this.actualizarCalendario();
  }

  public periodoSiguiente() {
    this.fechaReferencia = addDays(this.fechaReferencia, this.cantidadDias);
    this.actualizarCalendario();
  }

  public filtrarPorSemana() {
    this.filtro = "semana";
    this.textoFiltro = "Semana";
    this.actualizarCalendario();
  }

  public filtrarPorMes() {
    this.filtro = "mes";
    this.textoFiltro = "Mes";
    this.actualizarCalendario();
  }

  public periodoActualBtn() {
    this.fechaReferencia = new Date();
    this.actualizarCalendario();
  }

  public cambiarFormatoDatosEmpleados() {
    this.cambiarDatos = "empleados";
    this.tituloFiltroEmpleadoGrupo = 'Empleados';
  }

  public cambiarFormatoDatosGrupos() {
    this.cambiarDatos = "grupos";
    this.tituloFiltroEmpleadoGrupo = 'Grupos';
  }

  public habilitarEdicion() {
    this.habilitar = !this.habilitar;
    this.textoBoton = this.habilitar ? "Aplicar cambios" : "Editar";
    if (this.textoBoton === 'Editar') this.aplicarOtrosDias = false;

    if (this.habilitar) return; // edición

    for (let empleadoIndex = 0; empleadoIndex < this.empleados.length; empleadoIndex++) {
      const empleadoId = String(this.empleados[empleadoIndex].id);
      const grupoId = String(this.grupoEmpleados[empleadoIndex]);

      if (this.filaDatos.hasOwnProperty(empleadoId)) {
        let filaDatosEmpleado = this.filaDatos[empleadoId];
        for (let entradaIndex = 0; entradaIndex < filaDatosEmpleado.length; entradaIndex++) {
          const valor = filaDatosEmpleado[entradaIndex];
          this.guardarValor(empleadoIndex, entradaIndex, valor);
        }
      }
      if (this.filaDatos.hasOwnProperty(grupoId)) {
        let filaDatosGrupos = this.filaDatos[grupoId];
        for (let entradaIndex = 0; entradaIndex < filaDatosGrupos.length; entradaIndex++) {
          const valor = filaDatosGrupos[entradaIndex];
          this.guardarValor(empleadoIndex, entradaIndex, valor);
        }
      }
    }

  }

  public guardarValor(empleadoIndex: number, entradaIndex: number, valor: string) {
  while (this.datosGuardados.length <= empleadoIndex) {
    this.datosGuardados.push([]);
    }

    // console.log(`empleadoIndex: ${empleadoIndex}, entradaIndex: ${entradaIndex}, valor: ${valor}` );
    this.datosGuardados[empleadoIndex][entradaIndex] = valor;
  }

  public actualizarCalendario() {
    const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    const diasSemanaCompleto = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
    this.semanasDelMes = [];
    this.filaDatos = {};

    let inicioPeriodo: Date = new Date();
    let finalPeriodo: Date = new Date();
    let diasPeriodo: number = 1;

    switch (this.filtro) {
      case "semana":
        var diaSemanaActual = this.fechaReferencia.getDay();

        inicioPeriodo = addDays(this.fechaReferencia, (diaSemanaActual == 0 ? -6 : 1 - diaSemanaActual));
        finalPeriodo = addDays(inicioPeriodo, 6);

        this.cantidadDias = dateDiff3(finalPeriodo, inicioPeriodo) + 1;
        this.cantidadDatos = this.cantidadDias;
        break;
      case "mes":
        let inicioMes: Date = new Date(this.fechaReferencia.getFullYear(), this.fechaReferencia.getMonth(), 1);
        var diaSemanaActual = inicioMes.getDay();

        inicioPeriodo = addDays(inicioMes, (diaSemanaActual == 0 ? -6 : 1 - diaSemanaActual));
        finalPeriodo = addDays(new Date(inicioMes.getFullYear(), inicioMes.getMonth() + 1, 1), -1);
        diasPeriodo = 7;
        var diaSemanaActual = finalPeriodo.getDay();
        finalPeriodo = addDays(finalPeriodo, (diaSemanaActual == 0 ? -6 : 1 - diaSemanaActual) - 1);
        let siguientePeriodo = addDays(finalPeriodo, 1);
        let inicioUltimoPeriodo = addDays(finalPeriodo, (diasPeriodo * -1) + 1);
        if (inicioUltimoPeriodo.getMonth() == siguientePeriodo.getMonth()) finalPeriodo = addDays(finalPeriodo, diasPeriodo);

        this.cantidadDias = dateDiff3(finalPeriodo, inicioPeriodo) + 1;
        let periodos: number = this.cantidadDias / diasPeriodo;
        for (let i = 0; i < periodos; i++) {
          let periodo: string = `Semana ${i + 1}`;
          this.semanasDelMes.push(periodo);
        }
        this.cantidadDatos = periodos;
        this.diasDelMes = `${inicioPeriodo.getDate()} - ${finalPeriodo.getDate()}`;
        break;
      default:
        this.semanaActualTitulo = `Período desconocido`;
        this.diasSemana = [];
        this.cantidadDias = 0;
        break;
    }

    for (const empleadoKey in this.empleados) {
      if (Object.prototype.hasOwnProperty.call(this.empleados, empleadoKey)) {
        const empleado = this.empleados[empleadoKey];
        this.filaDatos[empleado.id] = Array(this.cantidadDatos).fill('');
      }
      if (Object.prototype.hasOwnProperty.call(this.grupoEmpleados, empleadoKey)) {
        const grupo = this.grupoEmpleados[empleadoKey];
        this.filaDatos[grupo] = Array(this.cantidadDatos).fill('');
      }
    }

    this.fechaReferencia = inicioPeriodo;

    const nombreMesInicio = meses[inicioPeriodo.getMonth()];
    const nombresSemanaDelMes = this.semanasDelMes[inicioPeriodo.getMonth()];
    const año = finalPeriodo.getFullYear();
    if (inicioPeriodo.getMonth() == finalPeriodo.getMonth()) {
      this.semanaActualTitulo = `${this.textoFiltro} del ${inicioPeriodo.getDate()} al ${finalPeriodo.getDate()} de ${nombreMesInicio} ${año}`;
    } else {
      const nombreMesFinal = meses[finalPeriodo.getMonth()];
      this.semanaActualTitulo = `${this.textoFiltro} del ${inicioPeriodo.getDate()} de ${nombreMesInicio} al ${finalPeriodo.getDate()} de ${nombreMesFinal} ${año}`;
    }

    const diasSemanaSemana: DiaInfo[] = [];
    for (let i = 0; i < this.cantidadDias; i = i + diasPeriodo) {
      let diaActual: Date = addDays(inicioPeriodo, i);
      const diaInfo = {
        numero: diaActual.getDate(),
        nombre: this.filtro == 'mes' ? this.periodoTxt(diaActual, diasPeriodo, false) : diasSemanaCompleto[diaActual.getDay()],
        info: this.filtro == 'mes' ? this.periodoTxt(diaActual, diasPeriodo, true) : '',
      };
      diasSemanaSemana.push(diaInfo);
    }

    this.diasSemana = diasSemanaSemana;
  }

  private periodoTxt(diaActual: Date, diasPeriodo: number, completo: boolean): string {
    const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    let diaFinal: Date = addDays(diaActual, diasPeriodo - 1);
    if (diaActual.getMonth() == diaFinal.getMonth()) return `${diaActual.getDate()} al ${diaFinal.getDate()} de ${meses[diaFinal.getMonth()]}`;
    if (completo) return `${diaActual.getDate()} de ${meses[diaActual.getMonth()]} al ${diaFinal.getDate()} de ${meses[diaFinal.getMonth()]}`;
    return `${diaActual.getDate()} al ${diaFinal.getDate()} de ${meses[diaFinal.getMonth()]}`;
  }

  public abrirModal(template: TemplateRef<any>, grupo: string, index: number) {
    this.grupoSeleccionado = grupo;
    this.indexSeleccionado = index;
    this.valorTemporal = this.filaDatos[grupo][index];
    this.modalRef = this.modalService.show(template);
  }

  public aceptarModal() {
    // if (this.grupoSeleccionado !== null && this.indexSeleccionado !== null) {
    //   this.filaDatos[this.grupoSeleccionado][this.indexSeleccionado] = this.valorTemporal;
    // }
    this.modalRef?.hide();
  }

  public actualizarValorTemporal(event: Event) {
    const target = event.target as HTMLSelectElement;
    this.valorTemporal = target.value;
  }

  public aplicarCambiosOtroDia() {
    this.aplicarOtrosDias =  true;
  }

  public copiarValor(idEmpleado: string, index: number) {
    if (!this.aplicarOtrosDias) return;
    let filaDatosEmpleado: string[] = Array(this.cantidadDatos).fill('');
    if (this.filaDatos.hasOwnProperty(idEmpleado)) {
      filaDatosEmpleado = this.filaDatos[idEmpleado];
    }
    filaDatosEmpleado[index] = this.horarioSeleccionado;
    this.select2Values = this.select1Value;
  }

  public copiarValorEnAdelante(idEmpleado: string, index: number) {
    if (!this.aplicarOtrosDias) return;
    let filaDatosEmpleado: string[] = Array(this.cantidadDatos).fill('');
    if (this.filaDatos.hasOwnProperty(idEmpleado)) {
      filaDatosEmpleado = this.filaDatos[idEmpleado];
    }
    for (let index2 = index; index2 < filaDatosEmpleado.length; index2++) {
      filaDatosEmpleado[index2] = this.horarioSeleccionado;
    }
  }

  public deshacer() {
    this.aplicarOtrosDias = false;
  }

  ngOnInit(): void {
    var parent = this._activatedRoute.parent;
    if (!parent) {
      throw new Error("An error has ocurred!");
    }

    this.ownerId = parent.snapshot.params["owner"];
    this.companyId = parent.snapshot.params["company"];

    this.actualizarCalendario();
    this.filtrarPorSemana();
    // this._api.checker.getSchedules(this.ownerId, this.companyId, this.conBajas).subscribe(result => this.horarios = result);
  }


}

interface DiaInfo {
  numero: number;
  nombre: string;
  info: string;
}
