<div *ngIf="cargando">
  <div class="text-center py-5">
      <mat-spinner class="img-center"></mat-spinner>
  </div>
  <div class="text-center">Cargando datos...</div>
</div>

<div *ngIf="!cargando" class="overflow-hidden pb-3">
  <div class="row g-3 text-start px-2 mx-auto">
    <div class="col-md-3">
      <label class="form-label mb-0">Mostrar vista de tarjetas</label>
      <select class="form-select" aria-label="Default select example" [(ngModel)]="seleccionEmpleados" (change)="onChangeVistaEmpleado()">
        <option *ngFor="let opcion of opcionesVistaEmpleado | keyvalue" [value]="opcion.key">
          {{ opcion.value }}
        </option>
      </select>
    </div>

    <div class="col-md-3">
      <label class="form-label mb-0">Ordenar por</label>
      <select class="form-select" aria-label="Default select example" [(ngModel)]="selectedAcomodo" (change)="aplicarFiltros()">
        <option *ngFor="let opcion of opcionesFiltrosAcomodo | keyvalue" [value]="opcion.key">
          {{ opcion.value }}
        </option>
      </select>
    </div>

    <div class="col-6">
      <label class="mb-0 text-start">Filtrar por empleado</label>
      <ng-select [items]="empleados" [(ngModel)]="empleadoSeleccionado" bindLabel="nombreCompleto" (ngModelChange)="filtrarEmpleado()" (clear)="limpiarFiltro()" notFoundText="Empleado no encontrado" placeholder="Filtrar por empleado">
        <ng-template ng-option-tmp let-item="item">
          {{ nombreCompleto(item) }}
        </ng-template>
      </ng-select>
    </div>

    <div class="d-flex justify-content-end align-items-end my-3">
      <button mat-raised-button color="warn" (click)="toggleMostrarRegistroIncidencias()">
        <p class="mb-0">Cerrar registros</p>
      </button>
    </div>
  </div>

  <div *ngIf="datosRFFiltrados.length === 0" class="alert alert-info mx-3">No hay incidencias para el empleado seleccionado.</div>
  <div class="row mx-auto" *ngIf="datosRFFiltrados.length > 0">
    <div *ngFor="let datos of datosRFObs | async" [ngClass]="{ 'col-6': vista2Cards, 'col-4': vista3Cards, 'col-3': vista4Cards, 'col-2': vista6Cards, 'col-1': vista12Cards }">
      <mat-card class="mb-3">
        <mat-card-header>
          <img src="{{'data:image/jpg;base64,' + datos.datosRF.datos}}" class="img-fluid" height="250" />
        </mat-card-header>
        <mat-card-content class="text-start">
          <h4 class="mb-0" (click)="esDebugger = true;">{{ nombreCompleto(datos.empleado) }}</h4>
          <small class="mt-2">{{formatoFecha(datos.datosRF.fechaTxt)}}</small>
        </mat-card-content>
        <mat-card-actions class="d-flex justify-content-between">
          <div *ngIf="datos.datosRF.similarity > 0">
            <small class="alert alert-danger py-0" *ngIf="datos.datosRF.similarity < 0.98">{{ datos.datosRF.similarity | percent:'1.2-2' }}</small>
            <small class="alert alert-primary py-0" *ngIf="datos.datosRF.similarity >= 0.98 && datos.datosRF.similarity < 0.99">{{ datos.datosRF.similarity | percent:'1.2-2' }}</small>
            <small *ngIf="datos.datosRF.similarity >= 0.99">{{ datos.datosRF.similarity | percent:'1.2-2' }}</small>
          </div>
          <button mat-raised-button class="text-primary bg-secondary-subtle" (click)="verDetalles(datos)"><mat-icon class="m-0">info</mat-icon></button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>

<div [hidden]="cargando">
  <mat-paginator
    [showFirstLastButtons]="true"
    [length]="dataSource.data.length"
    [pageSizeOptions]="[200, 400, 600, 800, 1000, dataSource.data.length]">
  </mat-paginator>
</div>

<div class="bg-danger rounded d-sm-inline-block d-none justify-content-center mt-4 mt-md-1 centro col-12" *ngIf="error">
  <div class="justify-content-center">
    <p class="text-white fs-6 mt-2">{{error}}</p>
  </div>
</div>
<div id="uno" class="bg-primary rounded d-sm-inline-block d-none p-2 justify-content-center mt-4 mt-md-1 centro col-12 col-md-7" *ngIf="mensaje">
  <div class="align-items-start justify-content-center">
    <span class="text-white fs-6">{{mensaje}}</span>
  </div>
</div>

