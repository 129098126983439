import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { CS_ConDatos } from '../../Funciones/fTexto';
import { NomiExpressApiService } from '../../services/NomiExpress.api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { IRespuestaChecker } from 'src/app/models/resultadoActualiza';
import { AccesoExpress } from 'src/app/models/accesoExpress';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { CamaraModalComponent } from '../camara-modal/camara-modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public accesoExpress: AccesoExpress;
  public connected: string = '';
  public error: string = '';
  public llaveSecreta: string = '';
  public nombrePuntoAcceso: string = '';
  public enProceso: boolean = true;
  public checkboxSeleccionado: boolean = false;
  public mostrarBoton: boolean = false;

  @ViewChild("llave") llave: any;

  @Output()
  public recargarDatosNuevosEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public dialog: MatDialog,
    private nomiExpressApi: NomiExpressApiService,
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
  ) {
    this.accesoExpress = this.nomiExpressApi.accesoExpress();
   }

  public abrirModalCamara() {
    const dialogRef = this.dialog.open(CamaraModalComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;
      let datos: string[] = result.split(`/`);
      if (!datos || datos.length < 2) return;

      if (datos[0] == 'id') {
        this.accesoExpress.clientId = datos[1];        
      } 
      if (datos[2] == 'llave' && datos.length >= 3) this.llaveSecreta = datos[3];

      // console.log(`0: ${datos[0]}, 1: ${datos[1]}, 2: ${datos[2]}, 3: ${datos[3]}, ${datos.length}`);

      // console.log(`Dialog result: ${result}`);
    });
  }

  public focusLLave(){
    this.llave.nativeElement.focus();
  }

  public navigateTo(url: string) {
    window.open(url, '_blank');
  }

  public guardarDatos() {
    if (this.enProceso) return;
    this.enProceso = true;
    this.error = '';
    if (!CS_ConDatos(this.accesoExpress.clientId)) {
      this.ponerError('No se ha especificado el punto de acceso');
      return;
    }
    if (this.accesoExpress.clientId.length < 36) {
      this.ponerError('No se ha especificado el punto de acceso correcto');
      return;
    }
    if (!CS_ConDatos(this.llaveSecreta)) {
      this.ponerError('No se ha especificado el secreto');
      return;
    }
    if (this.llaveSecreta.length < 36) {
      this.ponerError('No se ha especificado un secreto correcto');
      return;
    }

    this.nomiExpressApi.registrarTerminal(this.llaveSecreta).subscribe(
      (resultado: IRespuestaChecker) => {
        if (!resultado) {
          this.ponerError('Error al registrar la terminal');
          return;
        }
        if (resultado.code != 100) {
          this.ponerError(resultado.mensaje);
          return;
        }

        console.log(`Cargando configuración`);
          this.nomiExpressApi.cargarDatosTerminalDelServidor().subscribe(
            (respuesta: IRespuestaChecker) => {
              console.log(`cargando configuración desde el servidor. Codigo: ${respuesta.code}, Mensaje: ${respuesta.mensaje}`);
              this._router.navigate(['']);
            }, (error) => {
              console.error(error);
            }
          );


        console.log(`Cargando Acceso`);
        this._router.navigate(['']);
      },
      (err: HttpErrorResponse) => {
        this.accesoExpress.isConnected = false;
        console.error(err);
        this.ponerError(err.message);
        return;
      }
    );

  }

  public ponerNombrePuntoAcceso() {
    this.nombrePuntoAcceso = '';
    if (!this.accesoExpress.clientId || !this.accesoExpress.clientId.includes('-')) return;
    this.nomiExpressApi.obtenerNombrePuntoAcceso(this.accesoExpress.clientId).subscribe(
      (respuesta: any) => {        
        if (respuesta.code == 100) {          
          this.nombrePuntoAcceso = respuesta.mensaje;
          if (!environment.production) console.log(`respuesta codigo: ${respuesta.code}, mensaje: ${respuesta.mensaje}, id: ${respuesta.id}, apk: ${respuesta.apk}`);
          if (!!respuesta.id) {            
            this.accesoExpress.clientId = respuesta.id;
          }
          if (!!respuesta.apk) {
            this.llaveSecreta = respuesta.apk;
          }
        }
      }
    );
  }

  public pegar(tipo: number) {
    navigator.clipboard.readText().then((clipboardContent: string) => {
      if (tipo == 1) {
        const inputField1 = document.getElementById('inputField1') as HTMLInputElement;
        inputField1.value = clipboardContent;
      } else {
        const inputField2 = document.getElementById('inputField2') as HTMLInputElement;
        inputField2.value = clipboardContent;
      }



    })
  }

  private ponerError(error: string) {
    this.enProceso = false;
    this.accesoExpress.deviceKey = '';
    this.error = error;
    setTimeout(() => {
      this.error = '';
    }, 5000);
  }

  ngOnInit(): void {
    this.enProceso = false;

    // console.log(`LOGIN init ==========================================================================================================`);

    let txt: string = this._activatedRoute.snapshot.params['id'];
    if (!!txt) {
      this.accesoExpress.clientId = txt;
      console.log(`id: ${this.accesoExpress.clientId}`);
    } else {
      console.log(`txt: ${txt}`);
    }

    txt = this._activatedRoute.snapshot.params['llave'];
    if (!!txt) {
      this.llaveSecreta = txt;
      console.log(`id: ${this.llaveSecreta}`);
    } else {
      console.log(`txt: ${txt}`);
    }


    this._activatedRoute.paramMap.pipe(
      switchMap(params => {
        console.log(`Carga de parámetros`);
        txt = params.get('id') || '';
        if (!!txt) {
          this.accesoExpress.clientId = txt;
          console.log(`id: ${this.accesoExpress.clientId}`);
        }

        txt = params.get('llave') || '';
        if (!!txt) {
          this.llave = txt;
          console.log(`llave: ${this.llave}`);
        }
        return '';
      })
    );
  }

}
