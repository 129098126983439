import { Pipe, PipeTransform } from '@angular/core';
import { fFecha } from '../core/Funciones/fFecha';

@Pipe({
  name: 'formatoFecha'
})
export class FormatoFechaPipe implements PipeTransform {

  transform(fecha: Date) {
    if (!fecha) {
      return '-';
    }

    return fFecha(fecha, "FSL")
  }
}
