<div class="h-100">
  <div class="container row g-5 m-auto">
    <div class="col-5 bg-body-tertiary text-start p-2" style="height: 100vh;">
      <h3 class="text-primary fw-bold ms-4">Conexión de terminales</h3>
      <div class="d-flex flex-row align-items-center">
        <div class="me-2">
          <mat-icon class="text-success">wifi</mat-icon>
        </div>
        <div class="text-start">
          <p class="mb-0">{{ empresa?.nombre }}</p>
          <p class="mb-0 text-success">Conectado</p>
        </div>
      </div>
    </div>

    <div class="col-7 text-start">
      <h3 class="opacity-75 fw-bold">{{ empresa?.nombre }}</h3>
      <p class="ms-2"><b class="text-success">0</b> / {{ empleados.length }} en turno activos</p>

      <div *ngFor="let empleado of empleados">
        <div class="d-flex flex-row align-items-center my-3">
          <div class="me-2">
            <mat-icon class="text-info">not_listed_location</mat-icon>
          </div>
          <div class="text-start">
            <div class="d-flex">
              <p class="mb-0 fw-bold">{{ nombreCompleto(empleado) }}</p>
              <!-- <small class="bg-body-secondary ms-2">Horario: {{ empleado.horario }} {{ empleado.idHorario}}</small> -->
            </div>
            <p class="mb-0">No ha marcado asistencia el día de hoy</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
