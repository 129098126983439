import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { fFecha } from 'src/app/core/Funciones/fFecha';
import { mostrarSwalConfirm, mostrarSwalError } from 'src/app/core/Funciones/funciones';
import { AccesoDatosService } from 'src/app/core/services/acceso-datos.service';
import { IEmpleados, nombreEmpleado } from 'src/app/models/empleados';
import { crearHorarioDesdeIncidencia, IHorarios, IHorariosEmpleado, IHorariosIncidencias, IHorariosTotal, nuevoHorario } from 'src/app/models/horarios';
import { DatosIncidencias } from 'src/app/models/incidencias';

@Component({
  selector: 'app-cambiar-horario',
  templateUrl: './cambiar-horario.component.html',
  styleUrls: ['./cambiar-horario.component.scss']
})
export class CambiarHorarioComponent implements OnInit {
  public empleadoSeleccionado: IEmpleados | undefined = undefined;
  public empleados: IEmpleados[] = [];
  public empleadosOrigen: IEmpleados[] = [];
  public esDebugger: boolean = false;
  public horarioSeleccionadoEmpleado: IHorarios = nuevoHorario();

  constructor(
    private accesoDatosService: AccesoDatosService,
    public dialogRef: MatDialogRef<CambiarHorarioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { incidencia: DatosIncidencias, agregarHorario: boolean, horario: IHorarios, diaIncidencia: any, incidenciasDelDia: DatosIncidencias[] },
  ) {
    this.cargarDatos();
    console.log('data cambiar-horario-component:' , data);
  }

  public cargarDatos() {
    this.esDebugger = this.accesoDatosService.getModoDebug();
    this.accesoDatosService.empleadosListado().subscribe(
      (empleados: IEmpleados[]) => {
        this.empleados = empleados;
        this.empleadosOrigen = empleados;
        this.empleados.forEach(empleado => {
          (empleado as any)['nombreCompleto'] = `${empleado.nombre} ${empleado.paterno} ${empleado.materno}`;
        });
        this.empleados = this.empleadosOrigen.filter(x => !x.baja || x.baja.getTime() <= new Date(1900, 1, 1).getTime()).sort(
          (a, b) => +a.numero - +b.numero || a.nombre?.toLowerCase().localeCompare(b.nombre?.toLowerCase())
        );
      },
      (error) => {
        this.accesoDatosService.logAgrega2("Error al cargar los datos de los empleados");
        console.error(error);
        this.empleados = [];
        this.empleadosOrigen = [];
      }
    );
  }

  public nombreCompleto(empleado: IEmpleados): string {
    return nombreEmpleado(empleado);
  }

  public aplicarCambioTurno() {
    const empleadoEncontrado = this.empleados.find((empleado) => empleado == this.empleadoSeleccionado);
    if (!this.empleadoSeleccionado) {
      mostrarSwalConfirm('Deberá de seleccionar un empleado para realizar el cambio de turno', 'error');
      return;
    }

    if (this.empleadoSeleccionado != empleadoEncontrado) {
      mostrarSwalConfirm('Verifique que el nombre este bien escrito o bien selecciónelo de la lista', 'error');
      return;
    }

    mostrarSwalConfirm(`Cambio de turno aplicado de: ${this.data.incidencia.nombre} para: ${this.nombreCompleto(this.empleadoSeleccionado)} correctamente`, 'success');
    this.dialogRef.close();
  }

  public aplicarAsignarHorario() {
    mostrarSwalConfirm(`Asignación de horario aplicado correctamente`, 'success');
    this.dialogRef.close();
  }

  public formatoFecha(fecha: Date | undefined){
    if (!fecha) {
      return '-';
    }

    return fFecha(fecha, "FSL")
  }

  public obtenerHorario() {
    let idEmpleado: number = !this.empleadoSeleccionado || !this.empleadoSeleccionado.id ? 0 : +this.empleadoSeleccionado.id;
    if (!idEmpleado) {
      mostrarSwalError('Horarios', 'No se puede localizar el empleado seleccionado');
      return;
    }

    let horarioIncidenciaEmpleado = this.data.incidenciasDelDia.find(inc => inc.idEmpleado == idEmpleado);
    console.log('horarioIncidenciaEmpleado: ', horarioIncidenciaEmpleado);
    if (!horarioIncidenciaEmpleado) {
      mostrarSwalError('Horarios', 'No se puede obtener el horario');
      return;
    }

    this.horarioSeleccionadoEmpleado = crearHorarioDesdeIncidencia(horarioIncidenciaEmpleado.detalleHorarioDia, horarioIncidenciaEmpleado.fecha, horarioIncidenciaEmpleado.horario);
  }

  public limpiarFiltro() {
    this.empleadoSeleccionado = undefined;
  }

  ngOnInit(): void {}
}
