import { CVFechaT } from '../core/Funciones/fFecha';
import { fechaT } from '../core/Funciones/fTexto';
import { convertirHorarioDiaADetalle, IDetalleHOrarioDia } from './incidencias';

export interface ISchedule {
  id: string;
  nombre: string;
  semana: string;
  desfase: number;
  semanaAlternativa: string | undefined;
  desfaseAlternativa: number;
  predeterminadoPara: number;
}

export interface IScheduleHistory {
  id: string;
  nombre: string;
  semana: string;
  desfase: number;
  semanaAlternativa: string | undefined;
  desfaseAlternativa: number;
  idHorario: string;
  validoHasta: Date | undefined;
}

export interface IScheduleEmpleados {
  id: string;
  validoHasta: Date | undefined;
  idEmpleado: number;
  idHorario: string;
  idDepartamento: number | undefined;
}

export interface IMinifiedHorarios {
  a: string;
  b: string;
  c: string;
  d: number;
  e: string | undefined;
  f: number | undefined;
  g: number | undefined;
}

export interface IMinifiedHistorialHorarios {
  a: string;
  b: string;
  c: string;
  d: number;
  e: string | undefined;
  f: number | undefined;
  g: string;
  h: string | undefined;
}

export interface IMinifiedHorariosEmpleados {
  a: string;
  b: string;
  c: number;
  d: string;
  e: number | undefined;
}

export function horariosExpandir(horariosMini: IMinifiedHorarios): ISchedule {
  return {
    id: horariosMini.a,
    nombre: horariosMini.b,
    semana: horariosMini.c,
    desfase: horariosMini.d,
    semanaAlternativa: horariosMini.e || '',
    desfaseAlternativa: horariosMini.f || 0,
    predeterminadoPara: horariosMini.g || 0,
  };
}

export function historialHorariosExpandir(
  historialHorariosMini: IMinifiedHistorialHorarios
): IScheduleHistory {
  return {
    id: historialHorariosMini.a,
    nombre: historialHorariosMini.b,
    semana: historialHorariosMini.c,
    desfase: historialHorariosMini.d,
    semanaAlternativa: historialHorariosMini.e,
    desfaseAlternativa: historialHorariosMini.f || 0,
    idHorario: historialHorariosMini.g,
    validoHasta: historialHorariosMini.h ? CVFechaT(historialHorariosMini.h) : undefined,
  };
}

export function horariosEmpleadosExpandir(
  horariosEmpleados: IMinifiedHorariosEmpleados
): IScheduleEmpleados {
  return {
    id: horariosEmpleados.a,
    validoHasta: horariosEmpleados.b ? CVFechaT(horariosEmpleados.b) : undefined,
    idEmpleado: horariosEmpleados.c,
    idHorario: horariosEmpleados.d,
    idDepartamento: horariosEmpleados.e,
  };
}

export interface RangoHora {
  horaEntrada: number;
  horaSalida: number;
  nocturno: boolean;
  horaEntradaFormateada: string | undefined,
  horaSalidaFormateada: string | undefined,
}

export interface RangoHoraFormateada extends RangoHora {
  horaEntradaFormateada: string;
  horaSalidaFormateada: string;
}

export interface MinifiedRangoHora {
  en: number;
  sa: number;
  no: boolean;
  enStr: string | undefined;
  saStr: string | undefined;
}

export interface HorariosPorDia {
  [key: string]: RangoHora[];
  domingo: RangoHora[];
  lunes: RangoHora[];
  martes: RangoHora[];
  miercoles: RangoHora[];
  jueves: RangoHora[];
  viernes: RangoHora[];
  sabado: RangoHora[];
}

export interface HorariosSemana {
  [key: string]: RangoHora[];
}

export interface HorariosSemanaFormateado {
  [key: string]: RangoHoraFormateada[];
}

export interface DataHorario {
  horariosPorDia: HorariosPorDia;
  nombreHorario: string;
}

export function nuevoHorarioPorDia(): HorariosPorDia {
  let horarioNormal: RangoHora = {
    horaEntrada: 9,
    horaSalida: 16,
    nocturno: false,
    horaEntradaFormateada: undefined,
    horaSalidaFormateada: undefined,
  };
  return {
    ['nombre']: [horarioNormal],
    domingo: [horarioNormal],
    lunes: [horarioNormal],
    martes: [horarioNormal],
    miercoles: [horarioNormal],
    jueves: [horarioNormal],
    viernes: [horarioNormal],
    sabado: [horarioNormal],
  };
}

export function nuevoRangoHora(): RangoHora {
  return {
    horaEntrada: 9,
    horaSalida: 18,
    nocturno: false,
    horaEntradaFormateada: undefined,
    horaSalidaFormateada: undefined,
  };
}


export interface IHorarios {
  id: number;
  idEmpleado: number;
  idHorario: string | undefined;
  nombreHorario: string;
  inicio: Date;
  inicioTxt: string;
  fin: Date;
  finTxt: string;
  esModificable: boolean;
  nocturno: boolean;
  detalles: IHorarioDetalle[];
  esRotativo: boolean;
}

export interface IHorariosFormateada extends IHorarios {
  detalleFormateado: IHorariosDetallesFormateado[],
}

export interface IHorarioDetalle {
  diasDescanso: string[];
  horariosSemana: HorariosSemana;
}

export interface IHorariosDetallesFormateado extends IHorarioDetalle {
  horariosSemanaFormateada: HorariosSemanaFormateado;
}

export function nuevoHorario(): IHorarios {
  return {
    id: -1,
    idEmpleado: -1,
    idHorario: undefined,
    nombreHorario: 'Nuevo horario',
    inicio: new Date(2023, 1, 1),
    inicioTxt: '',
    fin: new Date(2099, 11, 31),
    finTxt: '',
    esModificable: true,
    nocturno: false,
    detalles: [ nuevoDetalle() ],
    esRotativo: false,
  };
}

export function nuevoDetalle(): IHorarioDetalle {
  return {
    diasDescanso:[ 'Domingo' ],
    horariosSemana: {
      Domingo: [ ],
      Lunes: [ nuevoRangoHora() ],
      Martes: [ nuevoRangoHora() ],
      Miércoles: [ nuevoRangoHora() ],
      Jueves: [ nuevoRangoHora() ],
      Viernes: [ nuevoRangoHora() ],
      Sábado: [{ horaEntrada: 10, horaSalida: 14, nocturno: false, horaEntradaFormateada: undefined, horaSalidaFormateada: undefined }],
    },
  }
}

export interface IHorarioEmpleado {
  idEmpleado: number;
  idHorario: string;
  fecha: Date;
}

export interface IHorariosEmpleado{
  idEmpleado: number,
  horarios: IHorarios[]
}

export interface IHorariosTotal {
  horariosEmpleados: IHorariosEmpleado[];
  horariosIncidencias: IHorariosIncidencias[];
}

export interface IPeriodo {
  inicio: Date;
  inicioTxt: string;
  fin: Date;
  finTxt: string;
}

export function nuevoPeriodo(inicio: Date, fin: Date): IPeriodo {
  return {
    inicio: inicio,
    inicioTxt: fechaT(inicio),
    fin: fin,
    finTxt: fechaT(fin)
  };
}


export class IHorariosIncidencias {
  id: number = 0;
  idEmpresa: number = 0;
  idEmpleado: number = 0;
  inicio: Date = new Date(1900, 0, 1);
  inicioTxt: string = '';
  fin: Date = new Date(1900, 0, 1);
  finTxt: string = '';
  horario: string = '';
  esDescanso: boolean = false;
  esNocturno: boolean = false;
  horarioDia: RangoHora[] = [];
}

export function minifiedRangoHora(rangoHora: RangoHora): MinifiedRangoHora {
  return {
    en: rangoHora.horaEntrada,
    sa: rangoHora.horaSalida,
    no: rangoHora.nocturno,
    enStr: rangoHora.horaEntradaFormateada,
    saStr: rangoHora.horaSalidaFormateada
  }
}

export function minifiedRangosHora(rangosHora: RangoHora[]): MinifiedRangoHora[] {
  let miniRangosHora: MinifiedRangoHora[] = [];
  rangosHora.forEach(
    rango => {
      miniRangosHora.push({
        en: rango.horaEntrada,
        sa: rango.horaSalida,
        no: rango.nocturno,
        enStr: rango.horaEntradaFormateada,
        saStr: rango.horaSalidaFormateada
      });
    }
  )
  return miniRangosHora;
}

export function expandirRangoHora(rangoHora: MinifiedRangoHora): RangoHora {
  return {
    horaEntrada: rangoHora.en,
    horaSalida: rangoHora.sa,
    nocturno: rangoHora.no,
    horaEntradaFormateada: rangoHora.enStr,
    horaSalidaFormateada: rangoHora.saStr,
  }
}

export function expandirRangosHora(miniRangoHora: MinifiedRangoHora[]): RangoHora[] {
  let rangosHora: RangoHora[] = [];
  miniRangoHora.forEach(
    rango => {
      rangosHora.push({
        horaEntrada: rango.en,
        horaSalida: rango.sa,
        nocturno: rango.no,
        horaEntradaFormateada: rango.enStr,
        horaSalidaFormateada: rango.saStr,
      });
    }
  )
  return rangosHora;
}

export function crearHorarioDesdeIncidencia(detalle: IDetalleHOrarioDia, fecha: Date, nombreHorario: string): IHorarios {
  return {
    id: 0,
    idEmpleado: 0,
    idHorario: undefined,
    nombreHorario: nombreHorario,
    inicio: fecha,
    inicioTxt: '',
    fin: fecha,
    finTxt: '',
    esModificable: false,
    nocturno: detalle.esNocturno,
    detalles: convertirHorarioDiaADetalle(detalle, fecha),
    esRotativo: false,
  }
}

export function extenderHorarioFormateado(horario: IHorarios): IHorariosFormateada {
  let horarioFormateado: IHorariosDetallesFormateado[] = [];
  for (let indexDetalle = 0; indexDetalle < horario.detalles.length; indexDetalle++) {
    const detalle = horario.detalles[indexDetalle];
    let horaSemanaDetalle: HorariosSemanaFormateado = {};
    for (const diaSemana in detalle.horariosSemana) {
      if (Object.prototype.hasOwnProperty.call(detalle.horariosSemana, diaSemana)) {
        const horarioDia = detalle.horariosSemana[diaSemana];
        let rangosDiaFormateado: RangoHoraFormateada[] = [];
        for (let rangosDia = 0; rangosDia < horarioDia.length; rangosDia++) {
          const rangoHora = horarioDia[rangosDia];
          rangosDiaFormateado.push({
            horaEntrada: rangoHora.horaEntrada,
            horaSalida: rangoHora.horaSalida,
            horaEntradaFormateada: rangoHora.horaEntrada.toString(), // dar formato correcto
            horaSalidaFormateada: rangoHora.horaSalida.toString(), // dar formato correcto
            nocturno: rangoHora.nocturno
          });
        }
        horaSemanaDetalle[diaSemana] = rangosDiaFormateado;
      }
      horarioFormateado.push({
        diasDescanso: detalle.diasDescanso,
        horariosSemana: detalle.horariosSemana,
        horariosSemanaFormateada: horaSemanaDetalle,
      });
    }
  }
  return {
    id: horario.id,
    idEmpleado: horario.idEmpleado,
    idHorario: horario.idHorario,
    nombreHorario: horario.nombreHorario,
    inicio: horario.inicio,
    inicioTxt: horario.inicioTxt,
    fin: horario.fin,
    finTxt: horario.finTxt,
    esModificable: horario.esModificable,
    nocturno: horario.nocturno,
    detalles: horario.detalles,
    detalleFormateado: horarioFormateado,
    esRotativo: horario.esRotativo,
  }
}

export function nuevoHorarioFormateado(): IHorariosFormateada {
  return {
    id: -1,
    idEmpleado: -1,
    idHorario: undefined,
    nombreHorario: 'Nuevo horario',
    inicio: new Date(2023, 1, 1),
    inicioTxt: '',
    fin: new Date(2099, 11, 31),
    finTxt: '',
    esModificable: true,
    nocturno: false,
    detalles: [ nuevoDetalle() ],
    detalleFormateado: [],
    esRotativo: false,
  };
}
