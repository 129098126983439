import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AccesoExpress } from 'src/app/models/accesoExpress';
import { NomiExpressApiService } from '../../services/NomiExpress.api.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss'],
})
export class InicioComponent implements OnInit, AfterViewInit, OnDestroy {
  public accesoExpress: AccesoExpress;  

  constructor(
    private nomiExpressApi: NomiExpressApiService
  ) {
    this.accesoExpress = this.nomiExpressApi.accesoExpress();
  }

  ngAfterViewInit(): void {
    
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    console.log('app-inicio, ngOnDestroy');
  }

}
