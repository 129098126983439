<div class="d-flex justify-content-between">
  <div class="mx-auto mt-2">
    <h1>Puntos de Acceso</h1>
  </div>
  <button mat-dialog-close mat-icon-button><mat-icon>close</mat-icon></button>
</div>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

  <ng-container matColumnDef="nombrePuntoAcceso">
    <th mat-header-cell *matHeaderCellDef>Nombre del punto de acceso</th>
    <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
  </ng-container>

  <ng-container matColumnDef="ultimoAcceso">
    <th mat-header-cell *matHeaderCellDef>Ultimo acceso</th>
    <td mat-cell *matCellDef="let punto"> {{formatoFecha(punto.ultimoAcceso)}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
