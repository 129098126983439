<div class="d-flex justify-content-between">
  <div class="ms-5 mt-2">
    <h1>Configuración</h1>
  </div>
  <button mat-dialog-close mat-icon-button><mat-icon>close</mat-icon></button>
</div>

<div>
  <div class="d-flex">
    <!-- ================================== slide toggle ================================== -->
    <div class="col-6">
      <ul class="list-group list-group-flush ms-4">
        <li class="list-group-item">
          <mat-slide-toggle color="primary" [checked]="reconocimientoFacial" (click)="seleccionar(1);  reconocimientoChange()">Reconocimiento Facial</mat-slide-toggle>
        </li>
        <li class="list-group-item">
          <mat-slide-toggle color="primary" [checked]="habilitarSonido" (click)="seleccionar(3)">Sonido</mat-slide-toggle>
        </li>
        <li class="list-group-item" *ngIf="accesoExpress.tipoAcceso === 'BI'">
          <mat-slide-toggle color="primary" [checked]="habilitarCodigoManual" (click)="seleccionar(2)">Codigo manual</mat-slide-toggle>
        </li>        
        <li class="list-group-item" *ngIf="accesoExpress.tipoAcceso === 'BI'">
          <mat-slide-toggle color="primary" [checked]="infoReconocimiento" (click)="seleccionar(4)">Inf. de Reconocimiento Facial</mat-slide-toggle>
        </li>
        <div class="mt-3">
          <label class="form-label mb-0 opacity-75">Orientación</label>
          <select class="form-select form-select-sm" aria-label="Default select example">
            <option value="horizontal">Horizontal</option>
            <option value="vertical">Vertical</option>
          </select>
        </div>        
        <li class="list-group-item mt-3">
          <span class="text-danger fs-6">{{error}}</span>
        </li>
      </ul>
    </div>
    <!-- ================================== botones ================================== -->
    <div class="col-5 text-start mx-4">      
      <button mat-raised-button class="mt-3 ms-3 w-100 text-primary bg-secondary-subtle text-start" (click)="datosEmpresa()"><small>Datos de la empresa</small></button>      
      <button *ngIf="(mostrarConfigReconocimiento && accesoExpress.tipoAcceso === 'BI') && esDebugger" mat-raised-button class="ms-3 w-75 text-primary bg-secondary-subtle text-start" (click)="abrirConfiguraReconocimientoFacial()">
        <small>Reconocimiento Facial</small>
      </button>
      <button mat-raised-button class="mt-3 ms-3 w-100 text-primary bg-secondary-subtle text-start" (click)="abrirConfigAvanzada()"><small>Configuración Avanzada</small></button>
      <button mat-raised-button class="mt-3 ms-3 w-100 text-white bg-primary text-start" (click)="aplicarCambios()"><small>Aplicar cambios</small></button>
      <button mat-raised-button class="mt-3 ms-3 w-100 text-white bg-primary text-start" (click)="refrescarPagina()"><small>Recargar página</small></button>
      <button mat-raised-button class="mt-3 ms-3 w-100 text-info text-start" (click)="modoDebug()">{{esDebugger ? 'Deshabilitar modo debugger' : 'Habilitar modo debugger'}}</button>
    </div>    
  </div>
  <div id="uno" class="bg-danger rounded d-md-none d-inline-block p-2 justify-content-center mt-4 mt-md-1 centro col-12 col-md-7" *ngIf="error">
    <div class="align-items-start justify-content-center">
      <span class="text-white fs-6">{{error}}</span>
    </div>
  </div>
</div>


