import { Component, Input, OnInit } from '@angular/core';
import { HorariosPorDia, IHorarios, nuevoHorario } from 'src/app/models/horarios';
import { IEmpleados, nombreEmpleado, nuevoEmpleado } from 'src/app/models/empleados';
import { mostrarSwalError, mostrarSwalToast } from 'src/app/core/Funciones/funciones';
import { AccesoDatosService } from 'src/app/core/services/acceso-datos.service';
import { addDays, fFecha } from 'src/app/core/Funciones/fFecha';
import { IRespuestaChecker } from 'src/app/models/resultadoActualiza';

@Component({
  selector: 'app-empleado-horarios',
  templateUrl: './empleado-horarios.component.html',
  styleUrls: ['./empleado-horarios.component.scss']
})
export class EmpleadoHorariosComponent implements OnInit {
  public empleado: IEmpleados = nuevoEmpleado();
  public horarios: IHorarios[] = [];
  public horariosEmpleado: IHorarios[] = [];
  public cargando: boolean = true;
  public minDate: Date;

  public busqueda: string = '';
  public registrarHorario: HorariosPorDia[] = [];
  public horarioSeleccionado: IHorarios | undefined = undefined;
  public mostrarHorario: boolean = false;
  public disabledHorario: boolean = true;
  public asignacionHorario: boolean = false;

  public fechaAsignacion: Date = new Date();

  @Input()
  public asignandoHorario: boolean = false;

  constructor(
    private accesoDatosService: AccesoDatosService,
  ) {
    this.minDate = new Date();
    this.cargarDatos();
  }

  public cargarDatos() {
    this.asignandoHorario = false;
    this.accesoDatosService.empleadoObtener().subscribe(
      (empleado: IEmpleados) => {
        this.empleado = empleado;
        this.accesoDatosService.horariosEmpleado().subscribe(
          (horarios: IHorarios[]) => {
            this.horariosEmpleado = horarios;
            if (!this.horariosEmpleado || this.horariosEmpleado.length <= 0) {
              this.minDate = new Date(2000, 0, 1);              
            } else {
              let fecha: Date = addDays(horarios[0].fin, 1);
              if (fecha.getTime() > new Date().getTime()) fecha = addDays(new Date(), -30);
              this.minDate = fecha;
            }
            this.cargando = false;
          }, (error) => {
            console.error(error);
            let err: string = error.error;
            this.accesoDatosService.logAgrega2("Error al cargar los horarios del empleados. " + err);
            mostrarSwalError('Error', 'Error al cargar los horarios del empleados');
          }
        );
      },
      (error) => {
        this.accesoDatosService.logAgrega2("Error al cargar los datos de los empleados");
        console.error(error);
      }
    );

    this.accesoDatosService.horarios().subscribe(
      (horarios: IHorarios[]) => {
        this.horarios = horarios;
        this.cargando = false;
      }, (error) => {
        console.error(error);
        let err: string = error.error;
        this.accesoDatosService.logAgrega2("Error al cargar los horarios de los empleados. " + err);
        mostrarSwalError('Error', 'Error al cargar los horarios de los empleados');
      }
    );
  }

  public limpiarBusqueda() {
    this.busqueda = '';
  }

  public agregarHorarioDialog() {
    this.horariosEmpleado = [ nuevoHorario() ];
  }


  public formatoFecha(fecha: any) {
    return fFecha(fecha, "FSL");
  }

  public horarioMostrar() {
    this.mostrarHorario = true;
  }

  // public asignarHorario() {
  //   mostrarMensajeSnack(this._snackBar, 'Horario asignado correctamente', 4000);
  //   this.dialogRef.close();
  // }

  public guardarAsignacionHorario() {
    if (!this.fechaAsignacion) {
      mostrarSwalToast('Debe de seleccionar una fecha de asignación', 'warning');
      return;
    }
    if (!this.horarioSeleccionado) {
      mostrarSwalToast('Debe de seleccionar un horario a asignar', 'warning');
      return;
    }

    if (!this.horarioSeleccionado.idHorario) {
      mostrarSwalToast('El horario seleccionado no es válido', 'warning');
      return;
    }

    this.cargando = true;
    this.accesoDatosService.horarioAsignar(this.horarioSeleccionado.idHorario, this.fechaAsignacion).subscribe(
      (respuesta: IRespuestaChecker) => {        
        if (respuesta.code == 100) {
          mostrarSwalToast('La asignación del horario se guardo correctamente', 'success');
          this.cargarDatos();
          return;
        }
        
        mostrarSwalToast(`Respuesta del servidor: ${respuesta.code}, ${respuesta.mensaje}`, respuesta.code > 100 ? 'warning' : 'error');
        setInterval(() => { this.cargando = false; }, 300);          
        
      }, (error) => {
        console.error(error);
        let err: string = error.error;
        this.accesoDatosService.logAgrega2("Error al cargar los datos de la asignación del horario. " + err);
        mostrarSwalError('Error', 'Error al cargar los datos de la asignación del horario');
        setInterval(() => { this.cargando = false; }, 300);
      }
    );

    
    this.asignacionHorario =  false;
  }

  public nombreCompleto(){
    return nombreEmpleado(this.empleado);
  }

  ngOnInit() {
    // console.log(`horarios ngOnInit (1)`);
  }
}

