export interface IDepartamentos {
    id: number;
    nombre: string;
}

export interface IMinifiedDepartamento {
    a: number;
    b: string;
}

export function departamentoExpandir(departamentoMini: IMinifiedDepartamento): IDepartamentos {
    return  {
        id: departamentoMini.a,
        nombre: departamentoMini.b
    }
}