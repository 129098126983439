<div *ngIf="cargando">
  <div class="text-center py-5">
      <mat-spinner class="img-center"></mat-spinner>
  </div>
  <div class="text-center py-5">
    <span *ngIf="!guardando">Cargando datos...</span>
    <span *ngIf="guardando">Guardando datos...</span>
  </div>
</div>

<div *ngIf="!cargando && !mostrarFaceID && !mostrarRegistroIncidencias" class="overflow-hidden pb-3">
  <div class="m-3 opacity-50">
    <h4 class="mb-0">{{ nombreCompleto() }} {{ datosCFTxt }} datos de Reconocimiento Facial registrados{{ empleado.tieneDatosRF || !tieneDatosRF ? '' :  ', tiene datos biométricos guardados' }}</h4>
  </div>

  <div *ngIf="empleado.tieneDatosRF" class="row mx-auto">
    <div class="col-4" *ngFor="let imagen of datosRF.datos | keyvalue: valueDescOrder; let i = index">
      <mat-card class="mb-3">
        <mat-card-header>
          <img src="{{'data:image/jpg;base64,' + imagen.value.datos}}" class="img-fluid" height="250" />
        </mat-card-header>
        <mat-card-content class="text-start">
          <small class="mt-2">{{formatoFecha(imagen.value.fechaTxt)}}</small>
        </mat-card-content>
        <mat-card-actions class="d-flex justify-content-end">
          <button mat-raised-button class="text-primary bg-secondary-subtle mx-1" (click)="verDetalles(imagen.value)">Ver mas detalles</button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
  <div class="d-flex justify-content-center my-5" *ngIf="!empleado.tieneDatosRF">
    <img class="opacity-50" src="assets/images/face.png" alt="" height="250"/>
  </div>

  <div class="d-flex justify-content-end mt-4" *ngIf="mostrarFaceID">
    <button mat-raised-button class="text-primary bg-secondary-subtle mx-1" (click)="mostrarFaceID = true" *ngIf="empleado.tieneDatosRF">Fotos tomadas</button>
    <button mat-raised-button class="text-primary bg-secondary-subtle mx-1" (click)="mostrarFaceID = true">Agregar foto</button>
    <button mat-raised-button class="text-danger bg-secondary-subtle mx-1" *ngIf="empleado.tieneDatosRF">Eliminar foto</button>
    <button mat-raised-button class="text-text-danger-emphasis bg-secondary-subtle mx-1" *ngIf="empleado.tieneDatosRF && esAdmin">Borrar todas las fotos</button>
  </div>

  <div class="d-flex justify-content-center mt-4" *ngIf="!mostrarFaceID">
    <button mat-raised-button class="text-success mx-1" (click)="inicializarReconocimientoFacial()">Agregar foto</button>
    <input type="file" (change)="onFileSelected($event)" multiple #fileInput class="d-none" accept="image/*" />        
    <button mat-raised-button class="text-primary ml-5" (click)="fileInput.click()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <div class="d-flex justify-content-end mt-4" *ngIf="!mostrarFaceID">
    <button mat-raised-button class="text-primary ml-5" (click)="mostrarRegistroIncidencias = true">Registro de incidencias</button>
    <button mat-raised-button class="text-primary ml-5" (click)="esDebugger = !esDebugger">
      <mat-icon>info</mat-icon>
    </button>
  </div>
</div>

<!-- ================================= CAPTURA DE RECONOCIMIENTO FACIAL ================================= -->
<div *ngIf="!cargando && mostrarFaceID" class="overflow-hidden pb-3">
  <div class="mt-3 opacity-75">
    <h3 class="mb-0">{{ nombreCompleto() }} debe estar sin sombreros, sin lentes, solo una persona en la vista de la cámara, intente estar al centro de la pantalla.</h3>
    <h3 class="mb-0 text-danger">Favor de registrar el rostro de frente, viendo a la cámara</h3>
  </div>  
  <div class="mb-4">
    <small>Espere hasta que el sistema le indique que ya ha sido registrado los biométricos correspondientes</small>
  </div>

  <canvas id="face-marks" style="position: absolute; z-index: 1"></canvas>
  <div class="cam-wrapper ms-4 ms-md-0">
    <webcam [width]="camaraAncho" [height]="camaraAlto" [allowCameraSwitch]="true" [videoOptions]="videoOptions" [trigger]="invokeObservable" [switchCamera]="nextWebcamObservable"
    (imageCapture)="captureImg($event)"></webcam>
  </div>
  <div *ngIf="infoCamara">
    <span class="text-muted">{{infoCamara}}</span>
  </div>
  <div class="mt-4 d-flex justify-content-center">
    <button mat-raised-button class="text-primary bg-secondary-subtle mx-1" (click)="cambiarCamara(1.1)">+</button>
    <button mat-raised-button class="text-primary bg-secondary-subtle mx-1" (click)="cambiarCamara(0.9)">-</button>
    <button mat-raised-button class="text-danger bg-secondary-subtle mx-1 ms-5" (click)="tomarFoto(true)">Tomar Foto</button>
    <button mat-raised-button class="text-danger bg-secondary-subtle mx-1 ms-5" (click)="desactivarTomaAutomatica()">{{desactivarToma ? 'Activar toma automática' : 'Desactivar toma automática'}}</button>
    <button mat-raised-button class="text-danger bg-secondary-subtle mx-1 ms-5" (click)="desactivarReconocimientoFacial()">Cancelar</button>
    <button *ngIf="mostrarLog" mat-raised-button class="text-primary bg-secondary-subtle mx-1" (click)="abrirLog()">Log</button>
  </div>
</div>

<div *ngIf="!cargando && !mostrarFaceID && mostrarRegistroIncidencias">
  <app-registro-incidencias (cerrarRegistroIncidencias)="handleCerrarRegistroIncidencias()"></app-registro-incidencias>
</div>

<div class="bg-danger rounded d-sm-inline-block d-none justify-content-center mt-4 mt-md-1 centro col-12" *ngIf="error">
  <div class="justify-content-center">
    <p class="text-white fs-6 mt-2">{{error}}</p>
  </div>
</div>
<div id="uno" class="bg-primary rounded d-sm-inline-block d-none p-2 justify-content-center mt-4 mt-md-1 centro col-12 col-md-7" *ngIf="mensaje">
  <div class="align-items-start justify-content-center">
    <span class="text-white fs-6">{{mensaje}}</span>
  </div>
</div>
