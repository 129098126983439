<div *ngIf="estaCargando">
  <div class="text-center py-5">
    <mat-spinner class="img-center"></mat-spinner>
  </div>
  <div class="text-center py-5">
    <span>Cargando datos...</span>
  </div>
</div>

<div *ngIf="!estaCargando">
  <div class="d-flex justify-content-center">
    <h2 class="mb-0 fw-semibold">Imágenes de Registro</h2>
  </div>
  <!-- ================================== filtro empleado ================================== -->
   <div class="d-flex justify-content-between align-items-center">
    <div class="col-6 text-start">
      <label for="filtroEmpleado" class="form-label mb-0">Filtrar por empleado</label>
      <div class="input-group">
        <input type="text" class="form-control form-select-sm" id="filtroEmpleado" placeholder="Filtrar por empleado" [(ngModel)]="empleadoSeleccionado" [matAutocomplete]="auto" (ngModelChange)="cargarDatos()">
        <button *ngIf="empleadoSeleccionado" mat-raised-button type="button" class="text-primary" (click)="limpiarFiltro()"><mat-icon>cancel</mat-icon></button>
      </div>
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="nombreCompleto">
        <mat-option *ngFor="let empleado of empleados" [value]="empleado">
          {{nombreCompleto(empleado)}}
        </mat-option>
      </mat-autocomplete>
    </div>

    <div>
      <div class="d-flex align-items-end">
        <button mat-raised-button class="text-danger mx-2" (click)="cerrarRegistroImagenesRF()">
          <p class="mb-0">Volver a empleados</p>
        </button>
      </div>
    </div>
   </div>

  <div class="text-center py-5" *ngIf="empleadoSeleccionado && imagenesRegistradas.length <= 0">
    <div *ngIf="!empleadoSeleccionado || !empleadoSeleccionado.id; else sinDatosConEmpleado">
      <span>No tiene images de Reconocimiento Facial guardadas</span>
    </div>
    <ng-template #sinDatosConEmpleado>
      <span>El empleado {{nombreCompleto(empleadoSeleccionado)}} no tiene images de Reconocimiento Facial guardadas</span>
    </ng-template>
  </div>

  <div class="text-center py-3">
    <div *ngIf="!!empleadoSeleccionado && !!empleadoSeleccionado.id">
      <h3 class="py-2">Empleado: {{nombreCompleto(empleadoSeleccionado)}}</h3>
    </div>
    <div class="row row-cols-1 row-cols-md-3 row-cols-lg-4 g-3 mx-auto">
      <div *ngFor="let imagen of imagenesRegistradas">
        <mat-card class="bg-body-tertiary" [ngClass]="{'bg-danger-subtle': tipoRegistro[imagen.tipoRegistroImagen] !== tipoRegistro[2]}">
          <mat-card-header class="d-block text-start">
            <mat-card-title>
              <div class="text-center mb-2" *ngIf="!imagen.datos">
                <img src="assets/images/face.png" alt="..." class="opacity-25" style="height: 10rem;">
              </div>
              <div class="text-center mb-2" *ngIf="imagen.datos" (click)="abrirImagen(imagen)">
                <img src="{{'data:image/jpg;base64,' + imagen.datos}}" style="height: 10rem;"/>
              </div>
              <div *ngIf="!empleadoSeleccionado || !empleadoSeleccionado.id" class="input-group input-group-sm mb-3 me-2">
                <span class="input-group-text" id="inputGroup-sizing-sm" style="width: 5.5rem;">Id empleado</span>
                <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" [value]="imagen.idEmpleado" onClick="this.select();" readonly>
              </div>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="input-group input-group-sm mb-3 me-2">
              <span class="input-group-text" id="inputGroup-sizing-sm" style="width: 5.5rem;">Id registro</span>
              <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" [value]="imagen.idImagen" onClick="this.select();" readonly>
            </div>
            <div class="input-group input-group-sm mb-3">
              <span class="input-group-text" id="inputGroup-sizing-sm" style="width: 5.5rem;">Id imagen</span>
              <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" [value]="imagen.imagen.image_id" onClick="this.select();" readonly>
            </div>
            <div class="input-group input-group-sm mb-3">
              <span class="input-group-text" id="inputGroup-sizing-sm" style="width: 5.5rem;">Tipo</span>
              <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" [value]="tipoRegistro[imagen.tipoRegistroImagen]" onClick="this.select();" readonly>
            </div>
          </mat-card-content>
          <mat-card-actions class="mx-2 d-flex justify-content-between pt-2">
            <button matTooltip="Abrir imagen" class="text-primary" mat-raised-button (click)="abrirImagen(imagen)">
              <mat-icon fontIcon="image" class="text-success-emphasis m-0"></mat-icon>
            </button>
            <button matTooltip="Depurar imágenes de TODAS las empresas" class="text-primary text-end" mat-raised-button (click)="eliminarImagen(imagen)">
              <mat-icon class="text-success-emphasis">close</mat-icon>
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-end mt-3">
    <div class="form-check mx-2">
      <input class="form-check-input" type="checkbox" id="mostrarBajasCheck" [(ngModel)]="mostrarBajas" (change)="aplicarFiltros()">
      <label class="form-check-label fw-bold" for="mostrarBajasCheck">Mostrar empleados dados de baja</label>
    </div>
  </div>
</div>
