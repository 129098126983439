import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AccesoDatosService } from 'src/app/core/services/acceso-datos.service';
import { IEmpleados, nombreEmpleado } from 'src/app/models/empleados';
import { IEmpresa } from 'src/app/models/empresa';
import { EmpleadosContratoComponent } from '../empleados-contrato/empleados-contrato.component';
import { DocumentosInfo, EmpleadosDocumentoDatos, EmpleadosDocumentos, EmpleadosDocumentosDatos, IRespuestaDocumento, IRespuestaDocumentos, TipoArchivoEjecutables } from 'src/app/models/documentos';
import { mostrarSwalError, mostrarSwalToast } from 'src/app/core/Funciones/funciones';
import { IRespuestaChecker } from 'src/app/models/resultadoActualiza';
import { DomSanitizer } from "@angular/platform-browser";
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { fFecha } from 'src/app/core/Funciones/fFecha';
import { map, startWith } from 'rxjs/operators';

const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-empleados-documentos',
  templateUrl: './empleados-documentos.component.html',
  styleUrls: ['./empleados-documentos.component.scss']
})
export class EmpleadosDocumentosComponent implements OnInit {
  @Output()
  public cerrarDocumentos = new EventEmitter<void>();
  public archivosSubidos: Array<{ name: string, type: string, icon: string, size: string, fecha: string }> = [];
  public archivos: Array<{nombreArchivo: string, datos: string, tipoDocumento: string}> = [];
  public modalRef?: BsModalRef;
  public subirDocumentos: boolean = false;
  public mostrarDocumentosPorEmpleado: boolean = false;

  public esDebugger: boolean = false;
  public empleados: IEmpleados[] = [];
  public empresa!: IEmpresa;
  public documentos: EmpleadosDocumentoDatos[] = [];
  public documentosPorEmpleado: EmpleadosDocumentoDatos[] = [];
  public empleadoSeleccionado: IEmpleados | undefined = undefined;
  public estaCargando: boolean = true;
  public docInfoArray: { [key: string]: DocumentosInfo[]} = {};

  public mostrarBajas: boolean = false;
  public empleadoNoEncontrado: boolean = false;
  public mostrarDocumentos: boolean = false;
  public empleadosTotales: IEmpleados[] = [];

  constructor(
    private modalService: BsModalService,
    private accesoDatosService: AccesoDatosService,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog
  ) {
    this.cargarDatos();
  }

  public abrirDocumentosEmpleado(empleado: IEmpleados) {
    this.empleadoSeleccionado = empleado;

    if (!this.empleadoSeleccionado) {
      this.documentosPorEmpleado = this.documentos;
      return;
    }

    const idEmpleado: number = +this.empleadoSeleccionado.id;
    this.documentosPorEmpleado = this.documentos.filter(x => x.idEmpleado === idEmpleado);

    if (this.documentosPorEmpleado.length > 0) {
      this.mostrarDocumentosPorEmpleado = true;
      this.subirDocumentos = false;
    } else {
      this.mostrarDocumentosPorEmpleado = false;
      this.subirDocumentos = true;
    }
  }

  public cargarDatos() {
    this.docInfoArray = {};
    this.estaCargando = true;
    this.subirDocumentos = false;
    this.esDebugger = this.accesoDatosService.getModoDebug();
    let documento: EmpleadosDocumentos = new EmpleadosDocumentos();

    this.accesoDatosService.documentosObtener(documento).subscribe(
      (respuesta: IRespuestaDocumentos) => {
        if (respuesta.code != 100) {
          mostrarSwalError('Documentos', `Respuesta del servidor. Código: ${respuesta.code}, ${respuesta.mensaje}`);
          return;
        }

        if (!respuesta.empresa) {
          mostrarSwalError('Documentos', `No se pueden obtener los datos de la empresa`);
          return;
        }

        respuesta.empleados.forEach(empleado => {
          this.docInfoArray[empleado.id] = [new DocumentosInfo()];
        });

        this.cargarEmpleados();
        this.empresa = respuesta.empresa;
        this.documentos = [];

        respuesta.documentos.forEach(doc => {
          const empleadoId = doc.idEmpleado;
          this.documentosPorEmpleado = this.documentos.filter(x => x.idEmpleado === empleadoId);

          if (!doc.datos) {
            this.documentos.push(doc);
            return;
          }

          if (this.docInfoArray[empleadoId]) {
            this.docInfoArray[empleadoId][0].cantidad = this.documentosPorEmpleado.length;
            this.docInfoArray[empleadoId][0].ultimaMod = doc.fecha;
          } else {
            this.docInfoArray[empleadoId] = [new DocumentosInfo()];
          }

          doc.urlSegura = this.sanitizerUrl(doc.tipoDocumento + doc.datos);
          this.documentos.push(doc);
        });

        this.estaCargando = false;
      },
      (err) => {
        this.accesoDatosService.logAgrega2("Error al cargar los datos de los empleados");
        console.error(err);
        this.empleados = [];
        mostrarSwalError('Documentos', `Error al cargar los datos de los documentos desde el servidor. Código: ${err}`);
      }
    );
  }

  public limpiarFiltro() {
    this.empleadoNoEncontrado = false;
    this.cargarDatos();
  }

  public aplicarFiltros() {
    this.empleados = this.empleadosTotales;

    if (this.mostrarBajas) {
      this.empleados = this.empleadosTotales;
      return;
    }
    this.empleados = this.empleadosTotales.filter(x => !x.baja || x.baja.getTime() <= new Date(1900, 1, 1).getTime()).sort(
      (a, b) => +a.numero - +b.numero || a.nombre?.toLowerCase().localeCompare(b.nombre?.toLowerCase())
    );
  }

  public cargarEmpleados() {
    this.accesoDatosService.empleadosListado().subscribe(
      (empleados: IEmpleados[]) => {
        this.empleados = empleados;
        this.empleadosTotales = empleados;
        this.empleados.forEach(empleado => {
          (empleado as any)['nombreCompleto'] = `${empleado.nombre} ${empleado.paterno} ${empleado.materno}`;
        });
        this.aplicarFiltros();
      },
      (error) => {
        this.accesoDatosService.logAgrega2("Error al cargar los datos de los empleados");
        console.error(error);
        this.empleados = [];
        this.empleadosTotales = [];
      }
    );
  }

  public toggleMostrarDocumentos() {
    this.cerrarDocumentos.emit();
  }

  public seleccionarArchivo(event: any) {
    const files = event.target.files;
    for (let file of files) {
      this.obtenerDatosArchivo(file);
    }
  }

  public tipoArchivo(fileName: string): string {
    const extension = fileName.split('.').pop()?.toLowerCase();
    return extension ? extension : 'other';
  }

  public tipoIcono(fileType: string): string {
    switch (fileType) {
      case '.pdf':
        return 'picture_as_pdf';
      case '.xls':
      case '.xlsx':
      case '.xlsm':
      case '.csv':
      case '.xlsb':
        return 'description';
      case '.zip':
        return 'folder_zip';
      case '.doc':
      case '.docx':
      case '.dot':
      case '.dotx':
      case '.docm':
      case '.dotm':
        return 'article';
      case '.txt':
      case '.odt':
      case '.xml':
        return 'description';
      default:
        return 'attach_file';
    }
  }

  private obtenerDatosArchivo(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let data: string = reader.result as string;
      let txt64: string = ';base64,';
      let i: number = data.indexOf(txt64, 0);
      if (i < 0) {
        txt64 = `No se puede cargar el archivo seleccionado: ${file.name}`;
        console.log(txt64, data.substring(0, 150));
        mostrarSwalToast(txt64, 'error');
        return;
      }
      if (file.name.length > 90) {
        txt64 = `No se puede cargar el archivo seleccionado: ${file.name}, el nombre del archivo es demasiado largo (${file.name.length})`;
        console.log(txt64, data.substring(0, 150));
        mostrarSwalToast(txt64, 'error');
        return;
      }
      if (file.size > 10000000) {
        txt64 = `No se puede cargar el archivo seleccionado: ${file.name}, el archivo es demasiado grande`;
        console.log(txt64, `Tamaño: ${file.size}, ${data.length}`);
        mostrarSwalToast(txt64, 'error');
        return;
      }

      const fileType = this.tipoArchivo(file.name);
      if (TipoArchivoEjecutables.includes(fileType)) {
        txt64 = `No se puede cargar el archivo seleccionado: ${file.name}, el archivo es ejecutable`;
        console.log(txt64, data.substring(0, 100));
        mostrarSwalToast(txt64, 'error');
        return;
      }

      let tipoDocumento: string = data.substring(0, i + txt64.length);
      let data64: string = data.substring(tipoDocumento.length);
      if (this.esDebugger) console.log(`Cargando archivo: ${file.name}`, data.substring(0, 100), data64.substring(0, 50));
      const fileIcon = this.tipoIcono(fileType);
      const fechaModificacion = new Date(file.lastModified).toLocaleDateString();

      let fileSize = '';
      if (file.size >= 1024 * 1024) {
        const sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
        fileSize = `${sizeInMB} MB`;
      } else {
        const sizeInKB = (file.size / 1024).toFixed(2);
        fileSize = `${sizeInKB} KB`;
      }

      this.archivosSubidos.push({ name: file.name, type: fileType, icon: fileIcon, size: fileSize, fecha: fechaModificacion });
      this.archivos.push({nombreArchivo: file.name, datos: data64, tipoDocumento: tipoDocumento});
    };
  }

  public subirArchivos() {
    if (!this.empleadoSeleccionado) {
      mostrarSwalError('Seleccionar empleado', 'Debe de seleccionar un empleado a quien aplicarle los documentos')
      return;
    }
    if (!this.archivos || this.archivos.length <= 0) {
      mostrarSwalError('Seleccionar archivos', 'Debe de seleccionar un documentos para guardar')
      return;
    }

    this.estaCargando = true;
    let documentosGuardar: EmpleadosDocumentosDatos = new EmpleadosDocumentosDatos();
    documentosGuardar.idEmpresa = this.empresa.id;
    documentosGuardar.idEmpleado = +this.empleadoSeleccionado.id;
    this.archivos.forEach(file => {
      documentosGuardar.documentos.push(file);
    });

    console.log(`Documentos a guardar`, documentosGuardar);
    this.accesoDatosService.documentosGuardar(documentosGuardar).subscribe(
      (respuesta: IRespuestaChecker) => {
        if (respuesta.code == 100) {
          mostrarSwalToast('Los archivos fueron guardado correctamente', 'success');
          this.cargarDatos();
          return;
        }
        mostrarSwalError('Documentos', `No ha sido posible cargar los documentos. La respuesta del servidor: ${respuesta.code}, ${respuesta.mensaje}`);
        this.estaCargando = false;
      }, (err) => {
        this.accesoDatosService.logAgrega2("Error al cargar los datos de los empleados");
        console.error(err);
        this.empleados = [];
        mostrarSwalError('Documentos', `Error al cargar los datos de los documentos desde el servidor. Código: ${err}`);
        this.estaCargando = false;
      }
    );

    console.log('Todos los archivos han sido subidos');
  }

  eliminarImagen(index: number) {
    this.archivosSubidos.splice(index, 1);
    this.archivos.splice(index, 1);
  }

  public abrirDocumento(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  public abrirDoc(documentoDatos: EmpleadosDocumentoDatos) {
    if (!!documentoDatos.datos && !documentoDatos.extension.endsWith('pdf')) {
      let w = window.open("");
      var image = new Image();
      image.src = documentoDatos.tipoDocumento + documentoDatos.datos;
      if (!w) return;
      w.document.write(image.outerHTML);
      return;
    }

    let documento: EmpleadosDocumentos = new EmpleadosDocumentos();
    documento.id = documentoDatos.id;
    documento.idEmpresa = documentoDatos.idEmpresa;
    documento.idEmpleado = documentoDatos.idEmpleado;
    this.accesoDatosService.documentoObtener(documento).subscribe(
      (respuesta: IRespuestaDocumento) => {
        if (respuesta.code == 100) {
          if (!respuesta.documento) {
            mostrarSwalToast('No se puede obtener el tipo de archivo seleccionado', 'error');
            return;
          }
          let tipoDocumento: string = respuesta.documento.tipoDocumento;

          if (!respuesta.data) {
            mostrarSwalToast('No se puede obtener los datos de documento seleccionado', 'error');
            return;
          }

          let w = window.open("");
          if (!w) return;
          let iFrame = "<iframe src='" + tipoDocumento + encodeURI(respuesta.data) + "' width='100%' height='100%'></iframe>";
          console.log(`iFrame`, iFrame.substring(0, 100));
          w.document.write(iFrame);
          return;
        }
        mostrarSwalError('Documentos', `No ha sido posible cargar el documento. La respuesta del servidor: ${respuesta.code}, ${respuesta.mensaje}`);
      }, (err) => {
        console.error(err);
        mostrarSwalError('Documentos', `Error al cargar los datos del documento desde el servidor. Código: ${err}`);
      }
    );
  }

  public descargarDoc(documentoDatos: EmpleadosDocumentoDatos) {
    if (!!documentoDatos.datos && !documentoDatos.extension.endsWith('pdf')) {
      let w = window.open("");
      var image = new Image();
      image.src = documentoDatos.tipoDocumento + documentoDatos.datos;
      if (!w) return;
      w.document.write(image.outerHTML);
      return;
    }

    let documento: EmpleadosDocumentos = new EmpleadosDocumentos();
    documento.id = documentoDatos.id;
    documento.idEmpresa = documentoDatos.idEmpresa;
    documento.idEmpleado = documentoDatos.idEmpleado;
    this.accesoDatosService.documentoObtener(documento).subscribe(
      (respuesta: IRespuestaDocumento) => {
        if (respuesta.code == 100) {
          if (!respuesta.documento) {
            mostrarSwalToast('No se puede obtener el tipo de archivo seleccionado', 'error');
            return;
          }
          let tipoDocumento: string = respuesta.documento.tipoDocumento;

          if (!respuesta.data) {
            mostrarSwalToast('No se puede obtener los datos de documento seleccionado', 'error');
            return;
          }

          let w = window.open("");
          if (!w) return;
          let iFrame = "<iframe src='" + tipoDocumento + encodeURI(respuesta.data) + "' width='100%' height='100%'></iframe>";
          console.log(`iFrame`, iFrame.substring(0, 100));
          w.document.write(iFrame);
          return;
        }
        mostrarSwalError('Documentos', `No ha sido posible cargar el documento. La respuesta del servidor: ${respuesta.code}, ${respuesta.mensaje}`);
      }, (err) => {
        console.error(err);
        mostrarSwalError('Documentos', `Error al cargar los datos del documento desde el servidor. Código: ${err}`);
      }
    );
  }

  public eliminar(documentoDatos: EmpleadosDocumentoDatos) {
    this.estaCargando = true;
    let documento: EmpleadosDocumentos = new EmpleadosDocumentos();
    documento.id = documentoDatos.id;
    documento.idEmpresa = documentoDatos.idEmpresa;
    documento.idEmpleado = documentoDatos.idEmpleado;
    this.accesoDatosService.documentoEliminar(documento).subscribe(
      (respuesta: IRespuestaChecker) => {
        if (respuesta.code == 100) {
          mostrarSwalToast('Se ha eliminado el documento seleccionado', 'success');
          this.cargarDatos();
          return;
        }
        mostrarSwalError('Documentos', `No ha sido posible eliminar el documento. La respuesta del servidor: ${respuesta.code}, ${respuesta.mensaje}`);
      }, (err) => {
        console.error(err);
        mostrarSwalError('Documentos', `Error al cargar los datos del documento desde el servidor. Código: ${err}`);
      }
    );
  }

  public nombreCompleto(empleado: IEmpleados | undefined) {
    if (!empleado) {
      return '-';
    }
    return nombreEmpleado(empleado);
  }

  public sanitizerUrl(data: string){
    return this.sanitizer.bypassSecurityTrustResourceUrl(data);
    // return encodeURI(data);
  }

  public openContrato() {
    const contrato = this.dialog.open(EmpleadosContratoComponent, {
      width: '80vw',
      height: 'auto'
    });

    contrato.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  public buscarEmpleado(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const query = inputElement ? inputElement.value : '';
    if (!query) {
      this.empleados = this.empleadosTotales;
      this.empleadoNoEncontrado = false;
    } else {
      this.empleados = this.empleadosTotales.filter(empleado =>
      this.nombreCompleto(empleado).toLowerCase().includes(query.toLowerCase()));
      this.empleadoNoEncontrado = this.empleados.length === 0;
    }
  }

  public formatoFecha(fechaTxt: string | undefined): string {
    if (!fechaTxt) {
      return '-';
    }
    const fecha = new Date(fechaTxt);
    return fFecha(fecha, "fsl");
  }

  public displayFn(empleado: IEmpleados): string {
    return nombreEmpleado(empleado);
  }

  public cancelarSubida() {
    this.subirDocumentos = false;
    this.archivosSubidos = [];
    this.empleadoSeleccionado = undefined;
  }

  ngOnInit(): void {}
}

