import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AccesoExpress } from 'src/app/models/accesoExpress';
import { NomiExpressApiService } from '../../services/NomiExpress.api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PasswordComponent } from '../password/password.component';
import { IRespuestaChecker } from 'src/app/models/resultadoActualiza';
import { Router } from '@angular/router';
import { mostrarMensajeSnack, mostrarSwalError, mostrarSwalToast } from '../../Funciones/funciones';
import Swal from 'sweetalert2';
import { ManejoAcceso, ServidoresCF } from 'src/app/models/reconocimientoFacial';

@Component({
  selector: 'app-config-avanzada',
  templateUrl: './config-avanzada.component.html',
  styleUrls: ['./config-avanzada.component.scss']
})
export class ConfigAvanzadaComponent {
  public debug: boolean;
  public infoAdicional: boolean;
  public opcionesFecha: number;
  public validarDirectoOnline: boolean;
  public usarReconocimientoCompleto: boolean;
  public bajarFrecuenciaRF: boolean = false;
  public rangoFrecuenciaRF: number = 0;

  public servidorSelect: string = '';
  public opcionesServidor = ServidoresCF;
  public tipoAccesoSelect: string = '';
  public opcionesAcceso = ManejoAcceso;

  public accesoExpress: AccesoExpress;
  public validarReconocimiento: number = 0;
  public validarPorcentajeImagen: number = 0;
  public vecesPorVerificar: number = 0;
  public minutosParaRecargar: number = 0;

  constructor(
    private _snackBar: MatSnackBar,
    public nomiExpress: NomiExpressApiService,
    public dialogRef: MatDialogRef<ConfigAvanzadaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private dialog: MatDialog,
    private readonly _router: Router,
  ) {
    this.accesoExpress = this.nomiExpress.accesoExpress();
    this.debug = this.accesoExpress.debug;
    this.infoAdicional = this.accesoExpress.mostrarInfoAdd;
    this.opcionesFecha = this.accesoExpress.opcionesFecha;
    this.validarDirectoOnline = this.accesoExpress.validarDirectoOnline || false;
    this.usarReconocimientoCompleto = this.accesoExpress.usarReconocimientoCompleto;
    // console.log(`Datos de fecha: ${this.opcionesFecha} => ${this.accesoExpress.opcionesFecha}`);
    this.validarReconocimiento = this.accesoExpress.validarReconocimiento;
    this.validarPorcentajeImagen = this.accesoExpress.validarPorcentajeImagen;
    this.vecesPorVerificar = this.accesoExpress.vecesPorVerificar;
    this.minutosParaRecargar = this.accesoExpress.minutosParaRecargar;
    this.servidorSelect = this.accesoExpress.servidorCF;
    this.tipoAccesoSelect = this.accesoExpress.tipoAcceso;
    // console.log(`validarReconocimiento: ${this.validarReconocimiento}, accesoExpress.validarReconocimiento: ${this.accesoExpress.validarReconocimiento}`);

  }

  public log() {
    this.dialogRef.close('log');
  }

  public seleccionar(id: number) {
    switch (id) {
      case 1:
        this.debug = !this.debug;
        break;
      case 2:
        this.infoAdicional = !this.infoAdicional;
        break;
      case 3:
        this.validarDirectoOnline = !this.validarDirectoOnline;
        break;
      case 4:
        this.usarReconocimientoCompleto = !this.usarReconocimientoCompleto;
        break;
        case 5:
          this.bajarFrecuenciaRF = !this.bajarFrecuenciaRF;
    }
  }

  public aplicarCambios() {
    // this.nomiExpress.guardarAccesoExpress(this.accesoExpress);
    if (this.validarReconocimiento < .95 || this.validarReconocimiento > 1) {
      mostrarSwalToast(`No puedes guardar el porcentaje de la validez con un valor ${this.validarReconocimiento < 95 ? 'menor a 0.98' : 'mayor a 1.00'}`, 'warning');
      return;
    }
    if (this.vecesPorVerificar < 1 || this.vecesPorVerificar > 5) {
      mostrarSwalToast(`No puedes guardar las veces por validación con un valor ${this.vecesPorVerificar < 1 ? 'menor a 1' : 'mayor a 5'}`, 'warning');
      return;
    }
    if ((this.minutosParaRecargar < 10 && this.minutosParaRecargar > 0) || this.minutosParaRecargar > 14400) {
      mostrarSwalToast(`No puedes guardar los minutos para recargar la aplicación si es ${this.vecesPorVerificar < 10 ? 'menor a 10 minutos' : 'mayor a 10 días'}`, 'warning');
      return;
    }

    this.accesoExpress.debug = this.debug;
    this.accesoExpress.mostrarInfoAdd = this.infoAdicional;
    this.accesoExpress.validarDirectoOnline = this.validarDirectoOnline;
    this.accesoExpress.usarReconocimientoCompleto = this.usarReconocimientoCompleto;
    // console.log(`accesoExpress.usarReconocimientoCompleto: ${this.accesoExpress.usarReconocimientoCompleto}, usarReconocimientoCompleto: ${this.usarReconocimientoCompleto}`);
    this.accesoExpress.validarReconocimiento = this.validarReconocimiento;
    this.accesoExpress.validarPorcentajeImagen = this.validarPorcentajeImagen;
    this.accesoExpress.vecesPorVerificar = this.vecesPorVerificar;
    this.accesoExpress.minutosParaRecargar = this.minutosParaRecargar;
    this.accesoExpress.servidorCF = this.servidorSelect;
    this.accesoExpress.tipoAcceso = this.tipoAccesoSelect;
    // console.log(`validarReconocimiento: ${this.validarReconocimiento}, accesoExpress.validarReconocimiento: ${this.accesoExpress.validarReconocimiento}`);
    if (this.opcionesFecha != this.accesoExpress.opcionesFecha) {
      this.confirmarPass(true);
      return;
    }
    this.nomiExpress.guardarAccesoExpress(this.accesoExpress);

    // console.log(`=========================================  Verificación OnLine: ${this.validarDirectoOnline}, ${this.accesoExpress.validarDirectoOnline}`);
    mostrarSwalToast(`Cambios aplicados`, 'success');
    this.dialogRef.close();

  }

  public confirmarPass(cambioHorario: boolean) {
    const password = this.dialog.open(PasswordComponent, {
      data: `${cambioHorario ? 'Aplicar cambios' : 'Confirmar cerrar sesión'}`,
      width: '33rem',
      height: 'auto'
    });

    password.componentInstance.verificarPasswordEvent.subscribe((resultado: string) => {
      if (resultado !== 'verificadaPwd') return;

      if (cambioHorario) {
        console.log(`cambioHorario ${this.accesoExpress.opcionesFecha}, ${this.opcionesFecha}`);
        this.accesoExpress.opcionesFecha = this.opcionesFecha;
        this.nomiExpress.guardarAccesoExpress(this.accesoExpress);
        password.close();
        return;
      }

      if (resultado === 'verificadaPwd'){
        this.nomiExpress.cerrarSesion();
        window.location.reload();
        mostrarSwalError('Cerrar sesión', 'Cerrando sesión en el punto de acceso');
      }

      password.close();
    });
  }

  public restablecerDatos() {
    this.nomiExpress.restablecerDatosAccesoExpress();
    this.dialogRef.close('Guardado');
    mostrarMensajeSnack(this._snackBar, 'Valores de la cámara restablecidos', 2000);
  }

  public cargarDatosDelServidor() {
    this.nomiExpress.cargarDatosTerminalDelServidor().subscribe(
      (respuesta: IRespuestaChecker) => {
        console.log(`cargando configuración desde el servidor. Codigo: ${respuesta.code}, Mensaje: ${respuesta.mensaje}`);
        this._router.navigate(['']);
      }, (error) => {
        console.error(error);
      }
    );
  }

  public cargarDatos2DelServidor() {
    this.nomiExpress.cargarDatos2TerminalDelServidor().subscribe(
      (respuesta: IRespuestaChecker) => {
        console.log(`cargando configuración desde el servidor. Codigo: ${respuesta.code}, Mensaje: ${respuesta.mensaje}`);
        this._router.navigate(['']);
      }, (error) => {
        console.error(error);
      }
    );
  }

  public async sincronizarDatos() {
    Swal.fire({
      title: 'Sincronizando...',
      text: 'Por favor espere',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    try {
      const resultado = await this.simularSincronizacion();

      Swal.close();

      Swal.fire({
        title: 'Éxito',
        text: 'Datos sincronizados correctamente',
        icon: 'success'
      });
    } catch (error) {
      Swal.close();

      Swal.fire({
        title: 'Error',
        text: 'Hubo un problema al sincronizar los datos',
        icon: 'error'
      });
    }
  }

  private simularSincronizacion(): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const exito = Math.random() > 0.5;
        if (exito) {
          resolve();
        } else {
          reject();
        }
      }, 2000);
    });
  }

}
