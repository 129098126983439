import { Component, OnInit } from '@angular/core';
import { CS_ConDatos, fechaT } from './core/Funciones/fTexto';
import { ManejoDescarga, NomiExpressApiService } from './core/services/NomiExpress.api.service';
import { IResultadoActualiza } from './models/resultadoActualiza';
import { AccesoExpress, versionAccesoExpress } from './models/accesoExpress';
import { PasswordComponent } from './core/components/password/password.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TipoDateDiff, addDays, dateDiff, fFecha, getFechaT } from './core/Funciones/fFecha';
import { IEmpresa } from './models/empresa';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public accesoExpress: AccesoExpress;
  public empresa: IEmpresa = new IEmpresa();
  public title = 'AccesoExpressWeb';
  public conError: boolean = false;
  public enProceso: boolean = true;
  public activado: boolean = false;
  public error: string = '';
  public datosActualizados: Date = new Date(1900, 0, 0);

  constructor(
    private nomiExpressApi: NomiExpressApiService,
    private http: HttpClient,
    private dialog: MatDialog,
    private readonly _router: Router
  ) {
    console.log(`url: ${this._router.url}`);
    console.log(`v ${versionAccesoExpress}`);
    this.nomiExpressApi.logAgrega(`v ${versionAccesoExpress}`);
    this.accesoExpress = this.nomiExpressApi.accesoExpress();
   }

  public cerrarSesion() {
    const password = this.dialog.open(PasswordComponent, {
      data: undefined,
      width: '33rem',
      height: 'auto',
    });

    password.afterClosed().subscribe((resultado) => {
      if (!resultado) return;
      this.cerrarSesion2(resultado);
    });
  }

  private cerrarSesion2(password: string) {
    if (password != 'verificadaPwd') return;
    this.nomiExpressApi.cerrarSesion();
    this.reiniciar('Reiniciar -> cerrar sesión');
  }

  private reiniciar(logTxt: string) {
    this.nomiExpressApi.logAgrega2(logTxt);
    return;
    setTimeout(() => {
      this.nomiExpressApi.logAgrega2(logTxt);
      window.location.reload();
    }, 5000);
  }

  public recargarDatosNuevos(txt: string) {
    this.enProceso = true;
    this.conError = false;

    this.accesoExpress = this.nomiExpressApi.accesoExpress();
    this.datosActualizados = new Date(1900, 0, 0);

    if (CS_ConDatos(this.accesoExpress.clientId) && CS_ConDatos(this.accesoExpress.localSecret)) {
      setTimeout(() => {
        // this.nomiExpressApi.logAgrega2(`Verificando fecha de actualización de datos ${fFecha(this.datosActualizados, 'fmhs')}`);
        if (dateDiff(this.datosActualizados, new Date(), TipoDateDiff.días) >= 1) {
          this.nomiExpressApi.logAgrega2(`Reiniciando (app)`);
          this.reiniciar(`Reiniciando (app) 2`);
        }
      }, 20000);

      this.nomiExpressApi.logAgrega2(`Cargando datos desde el Servidor`);
      // cambiar por una función con menos datos para que sea mas rápida
      this.nomiExpressApi.getUpdate(ManejoDescarga.LoMasReciente).subscribe(
        (datosApi: IResultadoActualiza | undefined) => {
          if (datosApi)
          this.datosActualizados = new Date();
          let estaVigente: boolean = false;
          this.empresa.vigenteHastaFecha = new Date(2025, 3, 3);
          if (!!datosApi && !!datosApi.empresa) {
            this.empresa = datosApi.empresa;
            if (!!this.empresa.vigenteHasta) {
              this.empresa.vigenteHastaFecha = getFechaT(this.empresa.vigenteHasta);
              if (this.empresa.vigenteHastaFecha < new Date(2024, 8, 31)) {
                this.empresa.vigenteHastaFecha = new Date(2025, 3, 3);
              }
            } else {
              this.empresa.vigenteHastaFecha = new Date(2025, 3, 3);
            }
            if (this.empresa.vigenteHastaFecha.getTime() != this.accesoExpress.vigenteHasta.getTime()) {
              this.accesoExpress.vigenteHasta = this.empresa.vigenteHastaFecha;
              this.accesoExpress.vigenteHastaTxt = fechaT(this.empresa.vigenteHastaFecha);
            }
          }
          if (this.accesoExpress.díasVigenciaAdicional > 15) this.accesoExpress.díasVigenciaAdicional = 15;
          estaVigente = new Date().getTime() < addDays(this.empresa.vigenteHastaFecha, 1 + this.accesoExpress.díasVigenciaAdicional).getTime();

          this.nomiExpressApi.logAgrega2(`Cargando Acceso. getUpdate. ${this.accesoExpress.clientId}.  ${fFecha(this.datosActualizados, 'fmhs')}`);
          this.enProceso = false;
          this.activado = true;
          if (!estaVigente) {
            this.nomiExpressApi.logAgrega2(`No esta vigente. Vigencia: ${fFecha(this.empresa.vigenteHastaFecha, 'fsl')}`);
            this._router.navigate(['no-vigente']);
            return;
          }
        }, (err: Error) => {
          let errorTxt: string = `getUpdate. ${this.accesoExpress.clientId} Error: ${err.message}`;
          this.nomiExpressApi.logAgrega(errorTxt);
          console.log(`getUpdate. ${this.accesoExpress.clientId} -- Error -->>`);
          console.error(err);
          this.conError = true;
          this.error = err.message;
          this.enProceso = false;
        }
      );
    } else {
      this.nomiExpressApi.logAgrega(`Cargando LogIn`);
      this.enProceso = false;

      if (!environment.production && false) {
        console.log(`Desarrollo, abriendo acceso`);
        // this._router.navigate(['']);
      } else {
        console.log(`Cargando LogIn`);
        let url: string = `login//123//llave//cdcdcd`;
        this._router.navigate([`login`]);
      }
    }
  }

  public getFaceJsModels_Ssd_MobileNetModel1() {
    // this.getFaceJsModels_Ssd_MobileNet();
    // this.getFaceJsModels_Ssd_MobileNetModel1();
    // this.getFaceJsModels_Ssd_MobileNetModel2();

    if (!environment.production) {
      console.log(`getFaceJsModels_Ssd_MobileNetModel1 - local`);
      this.http.get<any>('http://localhost:4201/assets/faceJsModels/ssd_mobilenetv1_model-shard1.txt').subscribe(
          (data: any) => {
            console.log(`getFaceJsModels_Ssd_MobileNetModel1`, data);
          }, error => {
            console.log(`getFaceJsModels_Ssd_MobileNetModel1 - error`);
            console.error(error);
          }
      );
    } else {
      console.log(`getFaceJsModels_Ssd_MobileNetModel1 - ${environment.url}`);
      this.http.get<any>(`${environment.url}/assets/faceJsModels/ssd_mobilenetv1_model-shard1.txt`).subscribe(
        (data: any) => {
          console.log(`getFaceJsModels_Ssd_MobileNetModel1`, data);
        }, error => {
          console.log(`getFaceJsModels_Ssd_MobileNetModel1 - error`);
          console.error(error);
        }
      );
    }
  }

  public getFaceJsModels_Ssd_MobileNetModel2() {
    if (!environment.production) {
      this.http.get<any>('http://localhost:4201/assets/faceJsModels/ssd_mobilenetv1_model-shard2.txt').subscribe(
        (data: any) => {
          console.log(`getFaceJsModels_Ssd_MobileNetModel2`, data);
        }, error => {
          console.log(`getFaceJsModels_Ssd_MobileNetModel2 - error`);
          console.error(error);
        }
      );
    } else {
      this.http.get<any>(`${environment.url}/assets/faceJsModels/ssd_mobilenetv1_model-shard2.txt`).subscribe(
        (data: any) => {
          console.log(`getFaceJsModels_Ssd_MobileNetModel2`, data);
        }, error => {
          console.log(`getFaceJsModels_Ssd_MobileNetModel2 - error`);
          console.error(error);
        }
      );
    }
  }

  ngOnInit(): void {
    this.recargarDatosNuevos('');
  }
}
