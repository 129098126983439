<div *ngIf="cargando">
  <div class="text-center py-5">
      <mat-spinner class="img-center"></mat-spinner>
  </div>
  <div class="text-center py-5">
    <span>Cargando datos...</span>
  </div>
</div>

<div class="container-fluid" *ngIf="!cargando">
  <div class="d-flex align-items-center pt-3 border-bottom border-black mx-3 mb-3">
    <mat-icon class="me-2">groups</mat-icon>
    <h3 class="mb-0">Manejo de Grupos</h3>
  </div>

  <!-- =============================== filtros =============================== -->
  <div class="mx-4 mb-4">
    <div class="row g-3">
      <div class="col-md-6">
        <label class="form-label mb-0">Grupo a asignar</label>
        <div class="input-group input-group-sm">
          <select class="form-select form-select-sm" [ngStyle]="{ color: colorSeleccionado }" [(ngModel)]="idSchedule" (ngModelChange)="modificarHorario()">
            <option *ngFor="let horario of schedules" [value]="horario.id">
              {{ horario.name }}
            </option>
          </select>
          <button mat-raised-button type="button" class="text-primary h-auto">Agregar grupo</button>
        </div>
      </div>
      <div class="col-md-6">
        <label for="fechaAsignar" class="form-label mb-0">Fecha de asignación</label>
        <input type="date" class="form-control form-control-sm" id="fechaAsignar" placeholder="Selecciona una fecha"/>
      </div>
    </div>

    <div class="d-flex align-items-center pt-3 border-bottom border-black mb-3">
      <mat-icon>filter_alt</mat-icon>
      <p class="ms-2 fw-medium mt-2">Filtrar empleados por...</p>
    </div>

    <div class="row">
      <!-- Filtros izquierda -->
      <div class="col-lg-6 px-2">
        <div *ngIf="tieneDepartamentos">
          <label class="form-label mb-0">Departamento</label>
          <select class="form-select" [(ngModel)]="idDepartamento">
            <option *ngFor="let departamento of departments" [value]="departamento.id">
              {{ departamento.name }}
            </option>
          </select>
        </div>
        <div>
          <label class="form-label mb-0">Horario</label>
          <select class="form-select form-select-sm" [(ngModel)]="idScheduleFind">
            <option *ngFor="let horario of schedulesFind" [value]="horario.id">
              {{ horario.name }}
            </option>
          </select>
        </div>
        <div>
          <label for="nombre1" class="form-label mb-0">Nombre</label>
          <input type="search" class="form-control form-control-sm" id="nombre1" placeholder="Buscar por nombre" [(ngModel)]="nombreBusquedaTotales" (keydown)="buscarEmpleado($event, 'nombre', datos)"/>
        </div>
        <div>
          <label for="rfc1" class="form-label mb-0">R.F.C.</label>
          <input type="search" class="form-control form-control-sm" id="rfc1" placeholder="Buscar por R.F.C." [(ngModel)]="rfcBusquedaTotales" (keydown)="buscarEmpleado($event, 'rfc', datos)"/>
        </div>
      </div>

      <!-- Filtros derecha -->
      <div class="col-lg-6 px-2">
        <div *ngIf="tieneDepartamentos">
          <label class="form-label mb-0">Departamento</label>
          <select class="form-select" [(ngModel)]="idDepartamento">
            <option *ngFor="let departamento of departments" [value]="departamento.id">
              {{ departamento.name }}
            </option>
          </select>
        </div>
        <div>
          <label class="form-label mb-0">Horario</label>
          <select class="form-select form-select-sm" [(ngModel)]="idScheduleFind">
            <option *ngFor="let horario of schedulesFind" [value]="horario.id">
              {{ horario.name }}
            </option>
          </select>
        </div>
        <div>
          <label for="nombre2" class="form-label mb-0">Nombre</label>
          <input type="search" class="form-control form-control-sm" id="nombre2" placeholder="Buscar por nombre" [(ngModel)]="nombreBusquedaGrupo" (keydown)="buscarEmpleado($event, 'nombre', datos2)"/>
        </div>
        <div>
          <label for="rfc2" class="form-label mb-0">R.F.C.</label>
          <input type="search" class="form-control form-control-sm" id="rfc2" placeholder="Buscar por R.F.C." [(ngModel)]="rfcBusquedaGrupo" (keydown)="buscarEmpleado($event, 'rfc', datos2)"/>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center mt-4">
      <div class="col-lg-8">
        <!-- Tabla todos los empleados -->
        <thead class="d-flex justify-content-center">
          <tr>
            <td colspan="5" class="fw-medium">Listado completo de empleados</td>
          </tr>
        </thead>
        <table mat-table [dataSource]="datos" matSort (matSortChange)="announceSortChange($event)" class="mat-elevation-z8">
          <ng-container matColumnDef="select">
            <th class="px-0" mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-checkbox
                class="px-0"
                color="primary"
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                [aria-label]="checkboxLabel()">
              </mat-checkbox>
            </th>
            <td class="px-0" mat-cell *matCellDef="let row">
              <mat-checkbox
                class="px-0"
                color="primary"
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)"
                [aria-label]="checkboxLabel(row)">
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="nombre">
            <th class="px-1" mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
            <td class="px-1" mat-cell *matCellDef="let empleado">{{ empleado.nombre }}</td>
          </ng-container>

          <ng-container matColumnDef="rfc">
            <th class="px-1" mat-header-cell *matHeaderCellDef mat-sort-header>R.F.C.</th>
            <td class="px-1" mat-cell *matCellDef="let empleado">{{ empleado.rfc }}</td>
          </ng-container>

          <ng-container matColumnDef="departamento">
            <th class="px-1" mat-header-cell *matHeaderCellDef mat-sort-header>Departamento</th>
            <td class="px-1" mat-cell *matCellDef="let empleado">{{ empleado.departamento }}</td>
          </ng-container>

          <ng-container matColumnDef="horario">
            <th class="px-1" mat-header-cell *matHeaderCellDef mat-sort-header>Horario</th>
            <td class="px-1" mat-cell *matCellDef="let empleado">{{ empleado.horario }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="5">No existe información a mostrar</td>
          </tr>
        </table>
      </div>
    </div>

    <div class="d-flex justify-content-center mt-4">
      <div class="col-lg-8">
        <!-- Tabla grupos -->
        <thead class="d-flex justify-content-center">
          <tr>
            <td colspan="5" class="fw-medium">Listado de empleados en</td>
          </tr>
        </thead>
        <table mat-table [dataSource]="datos2" matSort (matSortChange)="announceSortChange2($event)" class="mat-elevation-z8">
          <ng-container matColumnDef="select">
            <th class="px-0" mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-checkbox
                color="primary"
                (change)="$event ? masterToggle2() : null"
                [checked]="selection2.hasValue() && isAllSelected2()"
                [indeterminate]="selection2.hasValue() && !isAllSelected2()"
                [aria-label]="checkboxLabel2()">
              </mat-checkbox>
            </th>
            <td class="px-0" mat-cell *matCellDef="let row">
              <mat-checkbox
                color="primary"
                (click)="$event.stopPropagation()"
                (change)="$event ? selection2.toggle(row) : null"
                [checked]="selection2.isSelected(row)"
                [aria-label]="checkboxLabel2(row)">
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="nombre">
            <th class="px-1" mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
            <td class="px-1" mat-cell *matCellDef="let empleado">{{ empleado.nombre }}</td>
          </ng-container>

          <ng-container matColumnDef="rfc">
            <th class="px-1" mat-header-cell *matHeaderCellDef mat-sort-header>R.F.C.</th>
            <td class="px-1" mat-cell *matCellDef="let empleado">{{ empleado.rfc }}</td>
          </ng-container>

          <ng-container matColumnDef="departamento">
            <th class="px-1" mat-header-cell *matHeaderCellDef mat-sort-header>Departamento</th>
            <td class="px-1" mat-cell *matCellDef="let empleado">{{ empleado.departamento }}</td>
          </ng-container>

          <ng-container matColumnDef="horario">
            <th class="px-1" mat-header-cell *matHeaderCellDef mat-sort-header>Horario</th>
            <td class="px-1" mat-cell *matCellDef="let empleado">{{ empleado.horario }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection2.toggle(row)"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="5">No existe información a mostrar</td>
          </tr>
        </table>
      </div>
    </div>

    <!-- Botones -->
    <div class="d-flex justify-content-center mt-4">
      <div class="col-lg-8 d-grid gap-2">
        <button mat-raised-button (click)="transferir()" type="button" class="text-success my-3">
          <mat-icon class="me-1">arrow_forward_ios</mat-icon> Transferir
        </button>
        <button mat-raised-button (click)="quitarEmpleados()" type="button" class="text-success">
          <mat-icon class="me-1">arrow_back_ios</mat-icon> Quitar Empleados
        </button>
      </div>
    </div>
  </div>
</div>
