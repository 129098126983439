<div *ngIf="cargando">
  <div class="text-center py-5">
    <mat-spinner class="img-center"></mat-spinner>
  </div>
  <div class="text-center py-5">
    <span>Cargando datos...</span>
  </div>
</div>

<!-- ====================== búsqueda de horarios ====================== -->
<div class="row mx-3 mt-3" *ngIf="!cargando">
  <!-- ====================== input búsqueda ====================== -->
  <div class="col-lg-6" *ngIf="!isModificando">
    <div class="col-12 py-2 text-start">
      <label class="form-label mb-0">Buscar horario</label>
      <div class="input-group">
        <input [(ngModel)]="inputBuscarHorario" type="text" class="form-control form-select-sm" placeholder="Buscar horario" [matAutocomplete]="auto" (ngModelChange)="buscarHorario($event)">
        <button *ngIf="inputBuscarHorario" mat-raised-button type="button" class="text-primary" (click)="limpiarBusqueda()">
          <mat-icon class="m-0" aria-hidden="false" aria-label="Example home icon" fontIcon="cancel"></mat-icon>
        </button>
      </div>
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let horario of horariosTotales" [value]="horario">
          {{ horario.nombreHorario }}
        </mat-option>
      </mat-autocomplete>
    </div>
  </div>
  <!-- ====================== botón agregar horario ====================== -->
  <div class="col-lg-6 align-content-center d-flex justify-content-end align-self-center" *ngIf="!isModificando">
    <button mat-raised-button matTooltip="Nuevo horario" class="ms-3 text-success" (click)="agregarHorario()">
      <mat-icon class="me-0">more_time</mat-icon>
      <p class="ms-2 mb-0">Nuevo horario</p>
    </button>
  </div>
  <div class="d-flex justify-content-end" *ngIf="isModificando">
    <button mat-raised-button matTooltip="Regresar" class="ms-3 text-danger" (click)="regresarHorarios()">
      <p class="mb-0">Regresar</p>
    </button>
  </div>

  <div class="row">
    <div *ngFor="let horario of horariosFiltrados" [ngClass]="{'col-6': !isModificando, 'col-12': isModificando}">
      <app-horario-detalle [horario]="horario" [soloConsulta]="false" (recargarDatosEvent)="cargarDatosEvent($event)" (isModificandoEvent)="isModificandoEvent($event)" [horarioEmpleado]="false">
      </app-horario-detalle>
    </div>
  </div>
</div>
