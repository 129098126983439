import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PasswordComponent } from 'src/app/core/components/password/password.component';
import { dateDiffAhora, fFecha, TipoDateDiff } from 'src/app/core/Funciones/fFecha';
import { mostrarSwalError } from 'src/app/core/Funciones/funciones';
import { NomiExpressApiService } from 'src/app/core/services/NomiExpress.api.service';
import { AccesoExpress } from 'src/app/models/accesoExpress';

@Component({
  selector: 'app-no-vigente',
  templateUrl: './no-vigente.component.html',
  styleUrls: ['./no-vigente.component.scss'],
})
export class NoVigenteComponent {

  private _accesoExpress: AccesoExpress;
  public vigencia: string = '';
  private clicDesActivarPuntoDeAcceso: boolean = false;
  public verDesActivarPuntoDeAcceso: boolean = false;

  constructor(
    private nomiExpressApi: NomiExpressApiService,
    private readonly _router: Router,
    private dialog: MatDialog,
    public nomiExpress: NomiExpressApiService,
  ) {
    this._accesoExpress = this.nomiExpressApi.accesoExpress();
    this.vigencia = fFecha(this._accesoExpress.vigenteHasta, 'fsl');
  }

  public desactivarPuntoDeAcceso() {
    if (this.clicDesActivarPuntoDeAcceso) {
      this.verDesActivarPuntoDeAcceso = true;
      return;
    }
    this.clicDesActivarPuntoDeAcceso = true;
  }

  public verificarVigencia() {
    let díasVigenciaAdicional: number = dateDiffAhora(this._accesoExpress.vigenteHasta, TipoDateDiff.días);
    if (díasVigenciaAdicional > 0 && díasVigenciaAdicional < 15) {
      this._accesoExpress.díasVigenciaAdicional = díasVigenciaAdicional;
      this.nomiExpressApi.guardarAccesoExpress(this._accesoExpress);
      this._router.navigate([``]);
      return;
    }
    
  }

  public confirmarPass() {
    const password = this.dialog.open(PasswordComponent, {
      data: 'Confirmar cerrar sesión',
      width: '33rem',
      height: 'auto'
    });

    password.componentInstance.verificarPasswordEvent.subscribe((resultado: string) => {
      if (resultado !== 'verificadaPwd') return;

      this.nomiExpress.cerrarSesion();      
      mostrarSwalError('Cerrar sesión', 'Cerrando sesión en el punto de acceso');
      this._router.navigate([``]);
      password.close();
    });
  }
}
