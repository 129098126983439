<!-- ===================================== menu empresa ===================================== -->
<mat-tab-group class="mx-3 mb-3" mat-stretch-tabs [(selectedIndex)]="componenteActivo" (selectedTabChange)="onTabChange($event)">
  <mat-tab *ngIf="esPruebas() || esAdmin()">
    <ng-template mat-tab-label>
      <mat-icon>description</mat-icon>
      <span class="d-none d-md-block" (click)="verificarMenuPuntosAcceso()">Datos generales</span>
    </ng-template>
    <div *ngIf="!menuPuntosAcceso">
      <app-datos-empresa (cambiarMenuPuntosAcceso)="cambiarMenuPuntosAcceso($event)"></app-datos-empresa>
    </div>
  </mat-tab>
  <mat-tab *ngIf="tipoEmpleado >= TipoEmpleado.Supervisor">
    <ng-template mat-tab-label>
      <span (click)="verificarMenuEmpleados(false)">
        <mat-icon>group</mat-icon>
      </span>
      <span class="d-none d-md-block" (click)="verificarMenuEmpleados(false)">Empleados</span>
    </ng-template>
    <div *ngIf="!menuEmpleados">
      <app-empleados (cambiarMenuEmpleados)="cambiarMenuEmpleados($event)" (empleadoNuevo)="empleadoNuevo($event)" [verificarMenuEmpleadosEvent]="verificarMenuEmpleadosEvent"></app-empleados>
    </div>
  </mat-tab>
  <mat-tab *ngIf="esPruebas() || esAdmin()">
    <ng-template mat-tab-label>
      <mat-icon>schedule</mat-icon>
      <span class="d-none d-md-block">Horarios</span>
    </ng-template>
  </mat-tab>
  <mat-tab *ngIf="esPruebas() || esAdmin()">
    <ng-template mat-tab-label>
      <mat-icon>domain_verification</mat-icon>
      <span class="d-none d-md-block" (click)="recargarIncidencias()">Incidencias</span>
    </ng-template>
    <app-incidencias [recargarEvent]="recargarIncidenciasEvent"></app-incidencias>
  </mat-tab>
  <mat-tab *ngIf="esPruebas() || esAdmin()">
    <ng-template mat-tab-label>
      <mat-icon>policy</mat-icon>
      <span class="d-none d-md-block" (click)="recargarPoliticas()">Política de incidencias</span>
    </ng-template>
    <app-empresa-politicas-incidencias (aplicarCambiosEvent)="recargarIncidencias()"></app-empresa-politicas-incidencias>
  </mat-tab>
</mat-tab-group>

<!-- ===================================== menu empleado ===================================== -->
<mat-tab-group class="mx-3" mat-stretch-tabs *ngIf="menuEmpleados" [(selectedIndex)]="componenteActivoEmpleados" (selectedTabChange)="onTabChangeEmpleados($event)">
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon>description</mat-icon>
      <span class="d-none d-md-block">Datos personales</span>
    </ng-template>
    <app-datos-empleado (mostrarRegistroIncidencias)="onMostrarRegistroIncidencias($event)" [isModificando]="modificandoEmpleado" (mostrarReconocimientoFacial)="mostrarReconocimientoFacial($event)"
      (mostrarCodgioQr)="mostrarCodigoQr($event)" (empleadoNuevo)="empleadoNuevo($event)" (verificarMenuEmpleadosEmitter)="verificarMenuEmpleados($event)"></app-datos-empleado>
  </mat-tab>
  <mat-tab *ngIf="!agregandoEmpleado">
    <ng-template mat-tab-label>
      <mat-icon>schedule</mat-icon>
      <span class="d-none d-md-block">Horario</span>
    </ng-template>
    <app-empleado-horarios></app-empleado-horarios>
  </mat-tab>
  <mat-tab *ngIf="!agregandoEmpleado">
    <ng-template mat-tab-label>
      <mat-icon>qr_code_scanner</mat-icon>
      <span class="d-none d-md-block">Código de acceso</span>
    </ng-template>
    <app-codigo-acceso-empleado></app-codigo-acceso-empleado>
  </mat-tab>
  <mat-tab *ngIf="!agregandoEmpleado">
    <ng-template mat-tab-label>
      <mat-icon>face</mat-icon>
      <span class="d-none d-md-block">Reconocimiento facial</span>
    </ng-template>
    <app-empleado-reconocimiento-facial-cf [mostrarRegistroIncidencias]="mostrarRegistroIncidencias"></app-empleado-reconocimiento-facial-cf>
  </mat-tab>
</mat-tab-group>

<mat-tab-group class="mx-3" mat-stretch-tabs *ngIf="menuPuntosAcceso" [(selectedIndex)]="componenteActivoPuntos" (selectedTabChange)="onTabChangePuntos($event)">
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon>settings_input_antenna</mat-icon>
      <span class="d-none d-md-block">Puntos de acceso</span>
    </ng-template>
    <app-puntos-acceso></app-puntos-acceso>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon>pin_drop</mat-icon>
      <span class="d-none d-md-block">Checador en vivo</span>
    </ng-template>
    <app-checador-tiempo-real></app-checador-tiempo-real>
  </mat-tab>
</mat-tab-group>

<mat-tab-group class="mx-3" mat-stretch-tabs *ngIf="menuHorarios" [(selectedIndex)]="componenteActivoHorarios" (selectedTabChange)="onTabChangeHorarios($event)">
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon>schedule</mat-icon>
      <span class="d-none d-md-block">Listado horarios</span>
    </ng-template>
    <app-horarios></app-horarios>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon>event_available</mat-icon>
      <span class="d-none d-md-block">Asignar Horarios</span>
    </ng-template>
    <app-asignar-horarios></app-asignar-horarios>
  </mat-tab>
  <mat-tab *ngIf="esDebugger">
    <ng-template mat-tab-label>
      <mat-icon>date_range</mat-icon>
      <span class="d-none d-md-block">Horarios por Dia</span>
    </ng-template>
    <app-horarios-dia></app-horarios-dia>
  </mat-tab>
  <mat-tab *ngIf="esPruebas()">
    <ng-template mat-tab-label>
      <mat-icon>event</mat-icon>
      <span class="d-none d-md-block">Horarios Rotativos</span>
    </ng-template>
    <app-horarios-rotativos></app-horarios-rotativos>
  </mat-tab>
  <mat-tab *ngIf="esPruebas()">
    <ng-template mat-tab-label>
      <mat-icon>groups</mat-icon>
      <span class="d-none d-md-block">Manejo de Grupos</span>
    </ng-template>
    <app-manejo-grupo></app-manejo-grupo>
  </mat-tab>
</mat-tab-group>

