import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IPuntoDeAcceso } from 'src/app/models/puntosDeAcceso';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-puntos-acceso',
  templateUrl: './puntos-acceso.component.html',
  styleUrls: ['./puntos-acceso.component.scss']
})
export class PuntosAccesoComponent implements OnInit, AfterViewInit {
  public displayedColumns: string[] = ['nombreAcceso', 'identificacion', 'secreto', 'deviceId', 'ultimoAcceso', 'acciones'];
  public dataSource: MatTableDataSource<IPuntoDeAcceso>;
  public puntosAccesos: IPuntoDeAcceso[] = [];

  public modalRefQR?: BsModalRef;
  public opcionPuntoAcceso: string = '';
  public mostrarCodigo: boolean = false;
  public codigo: string = '-';
  public urltxt: string = 'https://acceso.nomiexpress.com/login/';
  public numeroWhats: string = '';
  public txtQrBtn: string = "Obtener código QR";

  public mostraSecreto: boolean = false;

  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private modalService: BsModalService,
  ) {
    this.dataSource = new MatTableDataSource<IPuntoDeAcceso>(this.puntosAccesos);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  public crearPuntoAcceso(datos: IPuntoDeAcceso) {
    Swal.fire({
      html: `
        <h1 style="margin: 5px 0; font-weight: bold;">Ingrese información del punto de acceso</h1>
        <p class="text-muted">
          Estas a punto de crear una conexión de un reloj checador a NomiExpress.
        </p>
        <p>
          <b>Advertencia: </b> Se te proporcionará una clave secreta que protege la conexión entre NomiExpress y el reloj checador. Por favor,
          maneja esta clave con cuidado y no la compartas. En caso de pérdida o compromiso de la clave, crea un nuevo punto de acceso.
        </p>
        <input id="nombreAcceso" class="form-control" placeholder="Nombre del Acceso">
      `,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: 'red',
      confirmButtonColor: 'green',
      confirmButtonText: 'Agregar',
      preConfirm: () => {
        return (document.getElementById('nombreAcceso') as HTMLInputElement).value;
      }
    }).then((resultado) => {
      if (resultado.isConfirmed) {
        const nuevoNombre = resultado.value;
        if (!nuevoNombre) {
          Swal.fire({
            html: `Debes de poner un nombre al punto de acceso para poder guardarlo`,
            icon: 'warning',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            position: 'top-end',
            toast: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
          });
          return;
        }
        if (nuevoNombre) {
          if (this.puntosAccesos.length === 0) {
            this.puntosAccesos.push({
              id: '',
              name: nuevoNombre,
              updatedDate: new Date(),
              isActive: true,
              isDebugging: false,
            });
          } else {
            this.puntosAccesos[0].name = nuevoNombre;
          }
          console.log('Guardado:', this.puntosAccesos);
        }
      }
    });
  }

  public abrirModalQR(template: TemplateRef<any>) {
    this.modalRefQR = this.modalService.show(template);
  }

  public enviarWhatsapp() {
    if (this.numeroWhats.length != 10) {
      console.log();
    } else {
      const numero = encodeURIComponent(this.numeroWhats);
      const mensaje = encodeURIComponent(`Te han enviado el enlace del punto de acceso: ${this.urltxt}`);
      const url = `https://api.whatsapp.com/send?phone=${numero}&text=${mensaje}`;
      window.open(url, '_blank');
    }
  }

  public obtenerCodigoQR() {
    this.urltxt = `https://acceso.nomiexpress.com`;
    this.codigo = this.urltxt;

    if (this.mostrarCodigo) {
      this.mostrarCodigo = false;
      this.txtQrBtn = "Obtener código QR";
      return;
    }


    if (!this.opcionPuntoAcceso) {
      this.mostrarCodigo = false;
      Swal.fire({
        html: `Por favor, seleccione un punto de acceso`,
        icon: 'warning',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        position: 'top-end',
        toast: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
      });
      return;
    }

    let checker = this.puntosAccesos.find(x => x.id == this.opcionPuntoAcceso);
    if (!checker) {
      // this.claseParrafo = "shake-horizontal";
      // this.mensajeError = `No se puede obtener datos del punto de acceso: ${this.opcionPuntoAcceso}`;
      return;
    }

    if (!checker.id) { //secret
      // this.claseParrafo = "shake-horizontal";
      // this.mensajeError = `No se puede obtener datos del secreto del punto de acceso: ${checker.name}`;
      return;
    }


    this.urltxt = `https://acceso.nomiexpress.com/login/${checker.id}/llave/${checker.id}`; // checker.secretd
    this.codigo = this.urltxt;
    this.mostrarCodigo = true;
    this.txtQrBtn = "Generar nuevo código QR";

  }

  ngOnInit(): void {}
}
