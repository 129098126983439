import { Component } from '@angular/core';

@Component({
  selector: 'app-inicio-empresa',
  templateUrl: './inicio-empresa.component.html',
  styleUrls: ['./inicio-empresa.component.scss'],
})
export class InicioEmpresaComponent {

  constructor( ) {
    
  }

}
