<div class="d-flex justify-content-between align-items-center">
  <h1 class="mb-3 mx-auto">Bitácora</h1>
  <button mat-raised-button class="navy me-3" routerLink="">
    <mat-icon>keyboard_return</mat-icon>
  </button>
</div>

<span *ngFor="let log of logs" [ngStyle]="estilos">
    <p>{{ log }}</p>
</span>
