import { ModelState } from "./modelState";

export class AppEntity {
    static ignore = { '_backup': true, 'modelState': true, 'isNew': true, 'state': true };
    _backup?: any | undefined;
    _modelState?: ModelState | undefined;
    _isNew?: boolean;
    _state?: string | undefined;
    _ready?: boolean;

    id?: any;

    public static clone(source: any, target: any, ignore?: { [propertyName: string]: boolean }): void {
        Object.assign(target, source);

        for (const key in AppEntity.ignore) {
            if (Object.prototype.hasOwnProperty.call(AppEntity.ignore, key)) {
                delete target[key];
            }
        }
    }

    public static clean(entity: AppEntity) {
        entity._backup;
        entity._modelState;
        entity._isNew;
        entity._state;
        entity._ready;
    }
}
