<!-- <app-toolbar></app-toolbar>
<app-menu-inicial></app-menu-inicial> -->

<div class="fixed-top" *ngIf="enProceso">
    <div class="text-center p-5">
        <img src="../assets/images/ACE_Acceso-Express.png" class="pt-3 img-fluid" width="150" height="150"/>
    </div>
    <div class="text-center">
        <mat-spinner class="img-center"></mat-spinner>
    </div>
    <div class="text-center py-5">
        <span>Cargando datos...</span>
    </div>
</div>

<div class="text-center" *ngIf="!enProceso && conError">
    <div class="text-center py-5">
        <img src="assets/images/ACE_Acceso-Express.png" class="pt-3 img-fluid" width="150" height="150"/>
    </div>
    <div class="text-center py-5">
        <b class="text-danger">{{error}}</b>
    </div>
    <button (click)="cerrarSesion()" mat-raised-button class="text-primary w-100 bg-secondary-subtle">
        <mat-icon>settings</mat-icon>
        <span>Cerrar sesión</span>
    </button>
</div>

<div class="text-center" *ngIf="!enProceso && !conError">
    <router-outlet></router-outlet>
</div>
