import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IEmpresa } from 'src/app/models/empresa';
import { AccesoDatosService } from './acceso-datos.service';
import { NomiExpressApiService } from './NomiExpress.api.service';
import { AccesoExpress } from 'src/app/models/accesoExpress';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  public empresa: IEmpresa | undefined;
  public accesoExpress: AccesoExpress;

  private breadcrumbSubject = new BehaviorSubject<string[]>([]);
  breadcrumb$ = this.breadcrumbSubject.asObservable();

  constructor(
    private nomiExpressApi: NomiExpressApiService,
    private accesoDatos: AccesoDatosService
  ) {
    this.accesoExpress = this.nomiExpressApi.accesoExpress();
    this.cargarEmpresa();
  }

  private cargarEmpresa() {
    this.accesoDatos.empresaObtener().subscribe(
      (empresa: IEmpresa) => {
        this.empresa = empresa;
        this.actualizarBreadcrumbs([]);
      },
      (error) => {
        this.accesoDatos.logAgrega2("Error al cargar los datos de la empresa");
        console.error(error);
      }
    );
  }

  public actualizarBreadcrumbs(breadcrumbs: string[]) {
    const updatedBreadcrumbs = [this.empresa?.nombre || 'Empresa', ...breadcrumbs];
    this.breadcrumbSubject.next(updatedBreadcrumbs);
  }

  public actualizarBreadcrumbsEmpleado(breadcrumbs: string[], empleadoNombre?: string) {
    const updatedBreadcrumbs = empleadoNombre ? ['Empleados', empleadoNombre, ...breadcrumbs] : ['Empleados', ...breadcrumbs];
    this.breadcrumbSubject.next(updatedBreadcrumbs);
  }

  public actualizarBreadcrumbsHorarios(breadcrumbs: string[]) {
    const updatedBreadcrumbs = ['Horarios', ...breadcrumbs];
    this.breadcrumbSubject.next(updatedBreadcrumbs);
  }

  public actualizarBreadcrumbsPuntosAcceso(breadcrumbs: string[]) {
    const updatedBreadcrumbs = [this.empresa?.nombre || 'Empresa', ...breadcrumbs];
    this.breadcrumbSubject.next(updatedBreadcrumbs);
  }
}
