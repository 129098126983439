import { CVFechaT } from "../core/Funciones/fFecha";
import { AccesoExpress } from "./accesoExpress";

export class IEmpresa {
    id: number = 0;
    nombre: string = '';
    domicilio: string = '';
    telefono: string = '';
    correo: string = '';
    rfc: string = '';
    ownerUserId: string = '';
    imageUrl: string = '';
    ciudad: string = '';
    estado: number = 0;
    cp: string = '';
    apiKeyCF: string = '';
    vigenteHasta: string = '0001-01-01T00:00:00';
    vigenteHastaFecha: Date = new Date(1999, 0, 0);
    idReferencia: string = '';
    etiquetaGrupo: string = '';
}

export interface IEmpresaIdentidad {
    id: number;
    name: string;
    phone: string;
    mail: string;
    taxId: string;
    ownerUserId: string;
    imageUrl: string;
    checkersPassword: string;
    passwordLastChange: string;
    address: string;
    lastKnownCheckerReport: string;
}

export interface IMinifiedEmpresa {
    a: string;
    b: string;
    c: string;
    d: string;
    e: string;
    f: string;
    g: string;
    h: number;
    i: string;
    j: number;
    k: string;
    l: string;
    m: string;
    n: string;
    o: string;
    p: string;
    q: string;
}

export function empresaExpandir(empresaMini: IMinifiedEmpresa): IEmpresa {
    return {
        nombre: empresaMini.a,
        domicilio: empresaMini.b,
        telefono: empresaMini.c,
        correo: empresaMini.d,
        rfc: empresaMini.e,
        ownerUserId: empresaMini.f,
        imageUrl: empresaMini.g,
        id: empresaMini.h,
        ciudad: empresaMini.i,
        estado: empresaMini.j,
        cp: empresaMini.l,
        vigenteHasta: empresaMini.m,
        vigenteHastaFecha: CVFechaT(empresaMini.m),
        apiKeyCF: empresaMini.p,
        idReferencia: empresaMini.q,
        etiquetaGrupo: ''
    };
}

export function newEmpresaIdentidad(): IEmpresaIdentidad {
    return {
        id: 0,
        name: '',
        phone: '',
        mail: '',
        taxId: '',
        ownerUserId: '',
        imageUrl: '',
        checkersPassword: '',
        passwordLastChange: '',
        address: '',
        lastKnownCheckerReport: ''
    }
}

export function empresaDesdeIdentidad(empresa: IEmpresaIdentidad): IEmpresa {
    return {
        id: empresa.id,
        nombre:  empresa.name,
        domicilio:  empresa.address,
        telefono:  empresa.phone,
        correo:  empresa.mail,
        rfc:  empresa.taxId,
        ownerUserId:  empresa.ownerUserId,
        imageUrl:  empresa.imageUrl,
        ciudad: '',
        estado: 0,
        cp: '',
        apiKeyCF: '',
        vigenteHasta: '0001-01-01T00:00:00',
        vigenteHastaFecha: new Date(1999, 0, 0),
        idReferencia: '',
        etiquetaGrupo: ''
    }
}

export function empresaDesdeAcceso(accesoExpress: AccesoExpress): IEmpresa {
    return {
        id: accesoExpress.companyId,
        nombre: accesoExpress.companyName,
        domicilio: accesoExpress.domicilio,
        telefono: accesoExpress.telefono,
        correo: accesoExpress.correo,
        rfc: accesoExpress.rfc,
        ownerUserId: accesoExpress.ownerId,
        imageUrl: accesoExpress.imagenUrl,
        ciudad: accesoExpress.ciudad,
        estado: 0, // accesoExpress.estado,
        cp: '', // accesoExpress.cp,
        apiKeyCF: accesoExpress.apiKeyCF,
        vigenteHasta: '0001-01-01T00:00:00',
        vigenteHastaFecha: new Date(1999, 0, 0),
        idReferencia: '',
        etiquetaGrupo: ''
      };
}

export const Estados: { [key: string]: string } = {
  '0': '-',
  '1': 'Aguascalientes',
  '2': 'Baja California',
  '3': 'Baja California Sur',
  '4': 'Campeche',
  '5': 'Coahuila', // 'Coahuila de Zaragoza',
  '6': 'Colima',
  '7': 'Chiapas',
  '8': 'Chihuahua',
  '9': 'Ciudad de México',
  '10': 'Durango',
  '11': 'Guanajuato',
  '12': 'Guerrero',
  '13': 'Hidalgo',
  '14': 'Jalisco',
  '15': 'México',
  '16': 'Michoacán',//'Michoacán de Ocampo',
  '17': 'Morelos',
  '18': 'Nayarit',
  '19': 'Nuevo León',
  '20': 'Oaxaca',
  '21': 'Puebla',
  '22': 'Querétaro',
  '23': 'Quintana Roo',
  '24': 'San Luis Potosí',
  '25': 'Sinaloa',
  '26': 'Sonora',
  '27': 'Tabasco',
  '28': 'Tamaulipas',
  '29': 'Tlaxcala',
  '30': 'Veracruz', // 'Veracruz de Ignacio de la Llave',
  '31': 'Yucatán',
  '32': 'Zacatecas'
};
