import { Component } from '@angular/core';
import { CS_QuitarPunto } from 'src/app/core/Funciones/fTexto';
import { NomiExpressApiService } from 'src/app/core/services/NomiExpress.api.service';
import { AccesoDatosService } from 'src/app/core/services/acceso-datos.service';
import { AppService } from 'src/app/core/services/appService';
import { AccesoExpress } from 'src/app/models/accesoExpress';
import { EmployeeQRCredential, ICodigoQr, nuevoCodigoQr } from 'src/app/models/codigoQr';
import { IEmpleadoQr, IEmpleados, nombreEmpleado, nuevoEmpleado } from 'src/app/models/empleados';
import { IRespuestaChecker } from 'src/app/models/resultadoActualiza';

@Component({
  selector: 'app-codigo-acceso-empleado',
  templateUrl: './codigo-acceso-empleado.component.html',
  styleUrls: ['./codigo-acceso-empleado.component.scss'],
})
export class CodigoAccesoEmpleadoComponent {
  public empleado: IEmpleados = nuevoEmpleado();
  public qrCode: ICodigoQr = nuevoCodigoQr();
  public tieneCodigo: boolean = false;
  public nombrePuntoAcceso: string = '';
  public qrCodeTxt: string = '';
  public accesoExpress: AccesoExpress;
  public dataSet = this.nomiExpressApi.obtenerEmpleados();
  public mostrarDatosAppMovil: boolean = true;
  public mostrarDescargarApk: boolean = false;

  constructor(
    public readonly app: AppService,
    private nomiExpressApi: NomiExpressApiService,
    private accesoService: AccesoDatosService,
  ) {
    this.cargarEmpleado();
    this.accesoExpress = this.nomiExpressApi.accesoExpress();
  }

  private cargarEmpleado() {
    this.accesoService.empleadoObtenerQR().subscribe(
      (empleadoQr: IEmpleadoQr) => {
        this.empleado = empleadoQr.empleado;
        this.qrCode  = empleadoQr.qrCode;
        this.tieneCodigo = this.qrCode.llave > 0;
        if (this.tieneCodigo) {
          this.registrarCredencialQr();
        }
      },
      (error) => {
        this.accesoService.logAgrega2("Error al cargar los datos de los empleados Qr");
        console.error(error);
      }
    );

  }

  public asignarCodigo() {
    this.nomiExpressApi.registerQrCredential(+this.empleado.id).subscribe(
      (employeeQrCredential: EmployeeQRCredential) => {
        if (this.empleado.id != employeeQrCredential.employeeId) {
          this.cargarEmpleado();
          return;
        }
        this.qrCode.idCorto  = employeeQrCredential.shortId;
        this.qrCode.llave = employeeQrCredential.checkKey;
        this.tieneCodigo = this.qrCode.llave > 0;
        if (this.tieneCodigo) {
          this.registrarCredencialQr();
        } else {
          this.cargarEmpleado();
        }
      },
      (error) => {
        this.accesoService.logAgrega2("Error al cargar los datos de los datos qr del empleado");
        console.error(error);
      }
    );

  }

  public nombreCompleto(){
    return nombreEmpleado(this.empleado);
  }

  public ponerNombrePuntoAcceso() {
    this.nombrePuntoAcceso = '';
    this.nomiExpressApi.obtenerNombrePuntoAcceso(this.accesoExpress.clientId).subscribe(
      (respuesta: any) => {
        if (respuesta.code == 100) this.nombrePuntoAcceso = respuesta.mensaje;
      }
    );
  }

  public registrarCredencialQr() {
    this.qrCodeTxt = '';
    if (this.qrCode.llave <= 0 || this.qrCode.idCorto <= 0) return;
    const empresaNombre: string = CS_QuitarPunto(this.accesoExpress.companyName);
    const empleadoNombre: string = CS_QuitarPunto(this.nombreCompleto());
    this.qrCodeTxt = `${this.accesoExpress.companyId}.${empresaNombre}.${this.qrCode.idCorto}.${empleadoNombre}.${this.qrCode.llave}`;
  }

  public toggleMostrarDatosAppMovil(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.mostrarDatosAppMovil = inputElement.checked;
  }

  public toggleMostrarApk(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.mostrarDescargarApk = inputElement.checked;
  }

  ngOnInit(): void {
    this.ponerNombrePuntoAcceso();
  }
}
