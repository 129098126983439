<div class="mt-4">
  <div class="row g-3 mb-4 px-2 mx-auto text-start" *ngIf="!registroIncidencias && !mostrarDocumentos && !imagenesRegistradasRF">
    <!-- ==================================================== input búsqueda ====================================================-->
    <div class="col-6">
      <label class="mb-0 text-start">Búsqueda de empleado</label>
      <ng-select [disabled]="cargando" [items]="empleados" [(ngModel)]="empleadoSeleccionado" bindLabel="nombreCompleto" (ngModelChange)="buscarEmpleado($event)" (clear)="limpiarFiltro()" notFoundText="Empleado no encontrado" placeholder="Búsqueda por empleado">
        <ng-template ng-option-tmp let-item="item">
          {{ nombreCompleto(item) }}
        </ng-template>
      </ng-select>
    </div>

    <div class="col-6 d-flex align-items-end justify-content-end">
      <button mat-raised-button matTooltip="Exportar empleados" class="mx-1 text-success-emphasis" (click)="exportarEmpleados()">
        <mat-icon>upload</mat-icon>
      </button>
      <button mat-raised-button class="mx-1 text-primary" matTooltip="Importar empleados" (click)="importarArchivo()">
        <mat-icon>download</mat-icon>
      </button>
      <button mat-raised-button matTooltip="Agregar empleado" class="mx-1 text-success" (click)="abrirEmpleado(undefined)">
        <mat-icon>person_add</mat-icon>
      </button>
      <button mat-raised-button [matTooltip]="esListado ? 'Ver en cuadricula' : 'Ver en listado'" class="mx-1 text-info" (click)="verListado()">
        <mat-icon [fontIcon]="esListado ? 'apps' : 'list'"></mat-icon>
      </button>
      <button *ngIf="esDebugger" mat-raised-button matTooltip="Mostrar imágenes de registro" (click)="mostrarListadoImagenesRegistradasRF()" class="mx-1 text-primary-emphasis">
        <mat-icon>subtitles</mat-icon>
      </button>
    </div>

    <!-- ==================================================== ordenar por ====================================================-->
    <div class="col-2">
      <label class="form-label mb-0">Ordenar por</label>
      <select class="form-select" [(ngModel)]="selectedAcomodo" (ngModelChange)="aplicarFiltros()">
        <option *ngFor="let opcion of opcionesFiltrosAcomodo | keyvalue" [value]="opcion.key">
          {{ opcion.value }}
        </option>
      </select>
    </div>

    <!-- ==================================================== mostrar por ====================================================-->
    <div class="col-4">
      <label class="form-label mb-0">Mostrar empleados por</label>
      <select class="form-select" [(ngModel)]="selectedFiltro" (ngModelChange)="aplicarFiltros()">
        <option *ngFor="let opcion of opcionesFiltros | keyvalue" [value]="opcion.key">
          {{ opcion.value }}
        </option>
      </select>
    </div>

    <div class="col-6 d-flex align-items-end justify-content-end">
      <!-- ==================================================== contratos ====================================================-->
       <div class="mx-1">
        <button mat-raised-button [matMenuTriggerFor]="menu" class="dropdown-toggle btn text-primary-emphasis fw-medium" data-bs-toggle="dropdown" aria-expanded="false">
          Contratos
        </button>
        <mat-menu #menu="matMenu" matTooltip="Mostrar tipos de contratos">
          <button mat-menu-item (click)="abrirCOntratoBiometrico()">
            <span>Biométrico</span>
          </button>
        </mat-menu>
       </div>

      <button mat-raised-button class="mx-1 text-primary" (click)="abrirContratos()">
        Expedientes
      </button>
      <button mat-raised-button class="ms-1 text-primary-emphasis" (click)="validarIncidenciaDialog()">
        Validación de incidencias
      </button>
      <button mat-raised-button class="ms-1 text-primary-emphasis" (click)="controlVacacionesDialog()" *ngIf="esDebugger">
        Control de vacaciones
      </button>
    </div>
  </div>

  <!-- ==================================================== cargando ====================================================-->
  <div  *ngIf="cargando">
    <div class="text-center py-5">
      <mat-spinner class="img-center"></mat-spinner>
    </div>
    <div class="text-center">
      Cargando empleados...
    </div>
  </div>

  <!-- ==================================================== empleado no encontrado ====================================================-->
  <div class="fw-medium fs-4 alert alert-danger mt-4" *ngIf="empleadoNoEncontrado && !cargando && !esListado">Empleado no encontrado</div>

  <div class="row row-cols-1 row-cols-md-3 row-cols-lg-4 g-3 mx-auto pb-3" *ngIf="!registroIncidencias && !mostrarDocumentos && !imagenesRegistradasRF && !cargando">
    <!-- ==================================================== cards ====================================================-->
    <ng-container *ngIf="!esListado && !cardConFoto">
      <div *ngFor="let empleado of empleadosObs | async; let i = index">
        <mat-card class="bg-body-tertiary centro" *ngIf="empleadosBaja(i)">
          <mat-card-header class="d-block text-start">
            <mat-card-title>
              <div class="h6 col-12 text-truncate">{{ nombreCompleto(empleado) }}</div>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="input-group input-group-sm mb-3 me-2" *ngIf="esDebugger">
              <span class="input-group-text" id="inputGroup-sizing-sm" style="width: 5.5rem;">ID</span>
              <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" [value]="empleado.id" onClick="this.select();" readonly>
            </div>
            <div class="input-group input-group-sm mb-3">
              <span class="input-group-text" id="inputGroup-sizing-sm" style="width: 5.5rem;">Clave</span>
              <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" [value]="empleado.numero" onClick="this.select();" readonly>
            </div>
            <div class="input-group input-group-sm mb-3">
              <span class="input-group-text" id="inputGroup-sizing-sm" style="width: 5.5rem;">R.F.C.</span>
              <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" [value]="empleado.rfc" onClick="this.select();" readonly>
            </div>
            <div class="input-group input-group-sm mb-3">
              <span class="input-group-text" id="inputGroup-sizing-sm" style="width: 5.5rem;">Alta</span>
              <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" [value]="formatoFecha(empleado.alta)" onClick="this.select();" readonly>
            </div>
            <div class="input-group input-group-sm mb-3" *ngIf="mostrarBajas">
              <span class="input-group-text" id="inputGroup-sizing-sm" style="width: 5.5rem;">Baja</span>
              <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" [value]="formatoFecha(empleado.baja)" onClick="this.select();" readonly>
            </div>
            <div *ngIf="!!empleado.datosRF && !!empleado.datosRF.datos" class="d-flex align-items-center justify-content-center border border-success-subtle rounded p-1 shadow-sm bg-info-subtle">
              <mat-icon class="text-success-emphasis">check</mat-icon>
              <p class="fw-bold mb-0 ms-1">Reconocimiento Facial</p>
            </div>
          </mat-card-content>
          <mat-card-actions class="mx-2 d-flex justify-content-between pt-2">
            <div *ngIf="empleado.qrCode" class="d-flex align-items-center justify-content-center" matTooltip="Tiene código QR">
              <mat-icon>qr_code_scanner</mat-icon>
            </div>
            <button mat-raised-button matTooltip="Mostrar registro de incidencias" (click)="mostrarRegistroIncidencias(empleado)" class="me-3 text-primary-emphasis" *ngIf="esDebugger">
              <mat-icon>perm_media</mat-icon>
            </button>
            <button matTooltip="Más información" class="text-primary" mat-raised-button (click)="abrirEmpleado(empleado)">
              <mat-icon>info</mat-icon>
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </ng-container>

    <!-- ==================================================== cards con foto ====================================================-->
    <ng-container *ngIf="!esListado && cardConFoto">
      <div *ngFor="let empleado of empleadosObs | async">
        <mat-card class="centro bg-body-tertiary">
          <mat-card-header class="d-block text-start">
            <mat-card-title>
              <div class="text-center mb-2" *ngIf="!empleado.tieneDatosRF" (click)="abrirEmpleado(empleado)">
                <img src="assets/images/face.png" alt="..." class="opacity-25" style="height: 10rem;">
              </div>
              <div class="text-center mb-2" *ngIf="empleado.tieneDatosRF" (click)="abrirEmpleado(empleado)">
                <img src="{{'data:image/jpg;base64,' + empleado.datosRF.datos}}" style="height: 10rem;"/>
              </div>
              <div class="h6 col-12 text-truncate">{{ nombreCompleto(empleado) }}</div>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="input-group input-group-sm my-1" *ngIf="esDebugger">
              <span class="input-group-text" id="inputGroup-sizing-sm" style="width: 5.5rem;">ID</span>
              <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" [value]="empleado.id" onClick="this.select();" readonly>
            </div>
            <div class="input-group input-group-sm my-1">
              <span class="input-group-text" id="inputGroup-sizing-sm" style="width: 5.5rem;">Clave</span>
              <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" [value]="empleado.numero" onClick="this.select();" readonly>
            </div>
            <div class="input-group input-group-sm my-1">
              <span class="input-group-text" id="inputGroup-sizing-sm" style="width: 5.5rem;">R.F.C.</span>
              <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" [value]="empleado.rfc" onClick="this.select();" readonly>
            </div>
            <div class="input-group input-group-sm my-1">
              <span class="input-group-text" id="inputGroup-sizing-sm" style="width: 5.5rem;">Alta</span>
              <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" [value]="formatoFecha(empleado.alta)" onClick="this.select();" readonly>
            </div>
            <div class="input-group input-group-sm my-1" *ngIf="mostrarBajas">
              <span class="input-group-text" id="inputGroup-sizing-sm" style="width: 5.5rem;">Baja</span>
              <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" [value]="formatoFecha(empleado.baja)" onClick="this.select();" readonly>
            </div>
          </mat-card-content>
          <mat-card-actions class="mx-2 d-flex justify-content-between pt-2">
            <div class="d-flex">
              <div *ngIf="empleado.qrCode || true" class="d-flex align-items-center justify-content-center" matTooltip="Tiene código QR">
                <mat-icon>qr_code_scanner</mat-icon>
              </div>
              <button mat-raised-button matTooltip="Mostrar registro de incidencias" (click)="mostrarRegistroIncidencias(empleado)" class="ms-3 text-primary-emphasis" *ngIf="esDebugger">
                <mat-icon>perm_media</mat-icon>
              </button>
            </div>
            <button matTooltip="Más información" class="text-primary" mat-raised-button (click)="abrirEmpleado(empleado)">
              <mat-icon>info</mat-icon>
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </ng-container>
  </div>

  <!-- ==================================================== listado ====================================================-->
  <ng-container *ngIf="!registroIncidencias && !mostrarDocumentos && !imagenesRegistradasRF && esListado && !cargando">
    <div class="mat-elevation-z8 col-11 mx-auto my-4">
      <table mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="clave">
          <th mat-header-cell *matHeaderCellDef>Clave</th>
          <td mat-cell *matCellDef="let empleado"> {{empleado.numero}} </td>
        </ng-container>

        <ng-container matColumnDef="nombre">
          <th mat-header-cell *matHeaderCellDef>Nombre</th>
          <td mat-cell *matCellDef="let empleado"> {{nombreCompleto(empleado)}} </td>
        </ng-container>

        <ng-container matColumnDef="rfc">
          <th mat-header-cell *matHeaderCellDef>R.F.C.</th>
          <td mat-cell *matCellDef="let empleado"> {{empleado.rfc}} </td>
        </ng-container>

        <ng-container matColumnDef="alta">
          <th mat-header-cell *matHeaderCellDef>Alta</th>
          <td mat-cell *matCellDef="let empleado"> {{formatoFechaCorto(empleado.altaActualTxt)}} </td>
        </ng-container>

        <ng-container matColumnDef="detalles">
          <th mat-header-cell *matHeaderCellDef>Detalles</th>
          <td mat-cell *matCellDef="let empleado">
            <button matTooltip="Más información" class="text-primary" mat-icon-button (click)="abrirEmpleado(empleado)">
              <mat-icon fontIcon="info"></mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell text-center h5 text-danger p-3" colspan="5">Empleados no encontrados</td>
        </tr>
      </table>
    </div>
  </ng-container>

  <div [hidden]="registroIncidencias || mostrarDocumentos || imagenesRegistradasRF || cargando">
    <mat-paginator
      [showFirstLastButtons]="true"
      [length]="dataSource.data.length"
      [pageSizeOptions]="[20, 50, 80, 100, dataSource.data.length]">
    </mat-paginator>
  </div>

  <div class="d-flex justify-content-end mt-3" *ngIf="!registroIncidencias && !mostrarDocumentos && !imagenesRegistradasRF && !cargando">
    <div class="form-check mx-2">
      <input class="form-check-input" type="checkbox" id="mostrarAdminCheck" [(ngModel)]="mostrarSoloAdmin" (change)="aplicarFiltros()">
      <label class="form-check-label fw-bold" for="mostrarAdminCheck">Mostrar solo administradores</label>
    </div>
    <div class="form-check mx-2" *ngIf="esDebugger && !esListado">
      <input class="form-check-input" type="checkbox" id="mostrarCardConFoto" [(ngModel)]="cardConFoto">
      <label class="form-check-label fw-bold" for="mostrarCardConFoto">Mostrar Cards con foto</label>
    </div>
    <div class="form-check mx-2">
      <input class="form-check-input" type="checkbox" id="mostrarBajas" [(ngModel)]="mostrarBajas" (change)="aplicarFiltros()">
      <label class="form-check-label fw-bold" for="mostrarBajas">Mostrar empleados dados de baja</label>
    </div>
  </div>

  <div *ngIf="registroIncidencias && !mostrarDocumentos">
    <app-empleados-registro-incidencias [empleadoSeleccionado]="empleadoSeleccionado" (cerrarRegistroIncidencias)="handleCerrarRegistroIncidencias()"></app-empleados-registro-incidencias>
  </div>

  <div *ngIf="imagenesRegistradasRF && !registroIncidencias && !mostrarDocumentos">
    <app-empleados-imagenes-registro-rf (cerrarRegistroRF)="handleCerrarRegistroRF()"></app-empleados-imagenes-registro-rf>
  </div>

  <div *ngIf="mostrarDocumentos">
    <app-empleados-documentos (cerrarDocumentos)="handleCerrarDocumentos()"></app-empleados-documentos>
  </div>
</div>
