<div class="m-2">
  <div>
    <h1>Transferir a los empleados</h1>
  </div>
  <div>
    Los empleados seleccionados se transferirán al nuevo horario, ¿Estás seguro?
  </div>
  <div class="d-flex mt-3 float-right">
    <div class="mr-3">
      <button mat-raised-button (click)="cerrarForm()" type="button" class="text-danger ms-2">
        Cancelar
      </button>
    </div>
    <div>
      <button mat-raised-button (click)="aceptarCambios()" type="button" class="text-success">
        Guardar cambios
      </button>
    </div>
  </div>
</div>
