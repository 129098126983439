import { LiveAnnouncer } from '@angular/cdk/a11y';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { NomiExpressApiService } from 'src/app/core/services/NomiExpress.api.service';
import { IEmpleados } from 'src/app/models/empleados';
import { TransferirDialogComponent } from '../transferir-dialog/transferir-dialog.component';
import { AccesoDatosService } from 'src/app/core/services/acceso-datos.service';

@Component({
  selector: 'app-manejo-grupo',
  templateUrl: './manejo-grupo.component.html',
  styleUrls: ['./manejo-grupo.component.scss'],
})
export class ManejoGrupoComponent {
  public empleadosTotales: IEmpleados[] = [];
  public camposAdd: string[] = ['departamento', 'horario'];
  public empleados: IEmpleados[];
  public cargando: boolean = true;

  public ownerId: string = '';
  public companyId: number = 0;

  public tieneDepartamentos: boolean = false;
  public idDepartamento: number = 0;
  public departments: { id: number; name: string }[] = [];

  public idSchedule: string = '';
  public schedules: { id: string; name: string }[] = [];
  public horarios: { [id: string]: string } = {};

  public idScheduleFind: string = '';
  public schedulesFind: { id: string; name: string }[] = [];

  public seleccion: boolean = false;
  public seleccion2: boolean = false;
  public empleadosModificar: { [id: number]: number } = {};

  public nombreBusquedaTotales: string = '';
  public nombreBusquedaGrupo: string = '';
  public rfcBusquedaTotales: string = '';
  public rfcBusquedaGrupo: string = '';
  public fechaSeleccionada: Date = new Date();
  public colorSeleccionado = '';

  public datos: MatTableDataSource<IEmpleados>;
  public datos2: MatTableDataSource<IEmpleados>;
  public selection = new SelectionModel<IEmpleados>(true, []);
  public selection2 = new SelectionModel<IEmpleados>(true, []);
  public displayedColumns: string[] = [ 'select', 'nombre', 'rfc', 'departamento', 'horario', ];
  public esDebugger: boolean = false;

  constructor(
    private dialog: MatDialog,
    private readonly _api: NomiExpressApiService,
    private readonly _activatedRoute: ActivatedRoute,
    private _liveAnnouncer: LiveAnnouncer,
    private nomiExpressApi: NomiExpressApiService,
    private accesoDatosService: AccesoDatosService,
    // private readonly _homeService: HomeService
  ) {
    this.esDebugger = this.accesoDatosService.getModoDebug();
    this.empleados = this.nomiExpressApi.obtenerEmpleados();
    this.sort = new MatSort();
    this.sort2 = new MatSort();
    this.datos2 = new MatTableDataSource<IEmpleados>(this.empleados);
    this.datos = new MatTableDataSource<IEmpleados>(this.empleados);
    if (this.esDebugger) {
      this.cargando = false;
    } else {
      this.cargando = true;
    }
  }

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatSort) sort2: MatSort;

  ngAfterViewInit() {
    this.datos.sort = this.sort;
    this.datos2.sort = this.sort2;
  }

  public announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Ordenado ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Ordenación despejada');
    }
  }

  public announceSortChange2(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Ordenado ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Ordenación despejada');
    }
  }

  public masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.datos.data.forEach((row) => this.selection.select(row));
  }

  public masterToggle2() {
    this.isAllSelected()
      ? this.selection2.clear()
      : this.datos2.data.forEach((row) => this.selection2.select(row));
  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.datos.data.length;
    return numSelected === numRows;
  }

  public isAllSelected2() {
    const numSelected = this.selection.selected.length;
    const numRows = this.datos2.data.length;
    return numSelected === numRows;
  }

  public checkboxLabel(row?: IEmpleados): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  public checkboxLabel2(row?: IEmpleados): string {
    if (!row) {
      return `${this.isAllSelected2() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  public onDateChange(selectedDate: Date): void {
    this.fechaSeleccionada = selectedDate;
  }

  public buscarEmpleado(event: Event, campo: CampoEmpleado, datos: any) {
    if (event.target instanceof HTMLInputElement) {
      const filterValue = event.target.value.trim().toLowerCase();
      if (filterValue) {
        this.empleados = datos.data.filter((data: IEmpleados) => {
          const fieldValue = data[campo];
          return fieldValue && fieldValue.toLowerCase().includes(filterValue);
        });

        datos.filterPredicate = (data: IEmpleados, filter: string) => {
          const fieldValue = data[campo];
          return fieldValue && fieldValue.toLowerCase().includes(filter);
        };

        datos.filter = filterValue;
      } else {
        this.restablecerFiltro(datos);
      }
    }
  }

  private restablecerFiltro(datos: any) {
    datos.filter = '';
    this.empleados = [];
  }

  // public mostrarEmpleadosFiltrados() {
  //   this.datos = new MatTableDataSource<IEmpleados>([]);
  //   let rfcABuscar: string = '';
  //   if (this.rfcBusquedaTotales) {
  //     rfcABuscar = this.rfcBusquedaTotales.replace(/-/g, '').toLocaleLowerCase();
  //   }

  //   this.empleadosTotales.forEach((empleado) => {
  //     let agregar: boolean = true;
  //     if (this.tieneDepartamentos && this.idDepartamento != 0)
  //       agregar = this.idDepartamento != empleado.idDepartamento;
  //     if (agregar && this.idScheduleFind && this.idScheduleFind != empleado.idHorario)
  //       agregar = false;
  //     if (agregar && this.nombreBusquedaTotales)
  //       agregar = empleado.nombre.toLowerCase().indexOf(this.nombreBusquedaTotales.toLocaleLowerCase()) >= 0;
  //     if (agregar && rfcABuscar)
  //       agregar = empleado.rfc
  //         ? empleado.rfc.toLowerCase().indexOf(rfcABuscar) >= 0
  //         : false;

  //     if (agregar) {
  //       const newData = this.datos.data.slice();
  //       newData.push({
  //         id: empleado.id,
  //         nombre: empleado.nombre,
  //         paterno: empleado.paterno,
  //         materno: empleado.materno,
  //         alta: empleado.alta,
  //         baja: empleado.baja,
  //         rfc: empleado.rfc,
  //         curp: empleado.curp,
  //         correo: empleado.correo,
  //         departamento: empleado.departamento,
  //         horario: empleado.horario,
  //         idDepartamento: empleado.idDepartamento,
  //         idHorario: empleado.idHorario,
  //         incidencias: empleado.incidencias,
  //         imss: empleado.imss,
  //         puesto: empleado.puesto,
  //         area: empleado.area,
  //         selected: empleado.selected
  //       });
  //       this.datos.data = newData;
  //     }
  //   });
  // }

  // private loadSchedules() {
  //   this.schedules = [];
  //   this.schedules.push({id: '', name: 'Selecciona el horario'})
  //   this._api.checker.getSchedules(this.ownerId, this.companyId, false).subscribe(
  //     schedulesResult => {
  //       schedulesResult.forEach(schedule => {
  //         this.schedules.push({
  //           id: schedule.id,
  //           name: schedule.name
  //         });
  //       });
  //       this.modificarHorario();
  //     }
  //   );
  // }

  // public agregarGrupo() {
  //   const agregar = this.dialog.open(AgregarGrupoComponent, {
  //     data: undefined,
  //     width: '500px',
  //     maxHeight: '400px',
  //     panelClass: 'custom-dialog'
  //   })
  // }

  public transferir() {
    const transfiere = this.dialog.open(TransferirDialogComponent, {
      data: undefined,
      width: '500px',
      maxHeight: '400px',
      panelClass: 'custom-dialog'
    });
      transfiere.afterClosed().subscribe((resultado) => {
        if (!resultado) return;
        this.transferirDatos();
      });
  }

  public quitarEmpleados() {
      const transfiere = this.dialog.open(TransferirDialogComponent, {
        data: undefined,
        width: '500px',
        maxHeight: '400px',
        panelClass: 'custom-dialog',
      });
      transfiere.afterClosed().subscribe((resultado) => {
        if (!resultado) return;
        this.quitarDatos();
      });
  }

  public modificarHorario() {
    this.datos2 = new MatTableDataSource<IEmpleados>([]);
    this.empleadosModificar = {};
    this.schedulesFind = [];
    this.schedulesFind.push({ id: '', name: 'Buscar por horario' });
    let recargarEmpleados: boolean = this.idSchedule
      ? this.idSchedule == this.idScheduleFind
      : false;
    this.schedules.forEach((horario) => {
      if (horario.id != this.idSchedule && horario.id != '') {
        this.schedulesFind.push(horario);
      }
    });
    if (recargarEmpleados) {
      this.idScheduleFind = '';
      // this.mostrarEmpleadosFiltrados();
    }

    if (!this.idSchedule) return;

    this.empleadosTotales.forEach((empleado) => {
      if (empleado.idHorario == this.idSchedule) {
        const newData = this.datos2.data.slice();
        newData.push(empleado);
        this.datos2.data = newData;
      }
    });
  }

  // public seleccionarDato(dato: any, isChecked: boolean) {
  //   if (isChecked) {
  //     this.datos.push(dato);
  //   } else {
  //     const index = this.datos.indexOf(dato);
  //     if (index >= 0) {
  //       this.datos.splice(index, 1);
  //     }
  //   }
  // }

  // private loadDepartments() {
  //   this._api.companies.departments.list(this.ownerId, this.companyId, false).subscribe(
  //     (departments: Department[]) => {
  //       this.departments = [];
  //       this.departments.push({id: 0, name: 'Buscar por departamento'});
  //       for (let index = 0; index < departments.length; index++) {
  //         const departamento = departments[index];
  //         this.departments.push({id: departamento.id, name: departamento.name});
  //       }
  //     }, (error: any) => {
  //       console.error(error);
  //     }
  //   );
  // }

  public transferirDatos() {
    const datos1: IEmpleados[] = this.datos.data;
    const datos2: IEmpleados[] = this.datos2.data;

    datos1.forEach((empleado) => {
      const empleadoId: number = Number(empleado.id);
      if (empleado.selected) {
        const empleadoHorarioActual = datos2.find((x) => x.id == empleado.id);
        if (!empleadoHorarioActual) {
          datos2.push(empleado);
          let modificado = this.empleadosModificar[empleadoId];
          this.empleadosModificar[empleadoId] = 2;
          if (modificado) {
            const empleadoTotal = this.empleadosTotales.find((x) => x.id == empleado.id);
            if (empleadoTotal && empleadoTotal.idHorario == this.idSchedule) {
              this.empleadosModificar[empleadoId] = 0;
            }
          }
        }
      }
    });

    this.datos2.data = datos2;
  }

  public quitarDatos() {
    let empleadosHorario: IEmpleados[] = [];
    const datos2Array: IEmpleados[] = this.datos2.data;

    datos2Array.forEach((empleado) => {
      const empleadoId: number = Number(empleado.id);
      if (empleado.selected) {
        let modificado = this.empleadosModificar[empleadoId];
        if (modificado) {
          if (this.empleadosModificar[empleadoId] == 2) {
            this.empleadosModificar[empleadoId] = 0;
          } else {
            this.empleadosModificar[empleadoId] = 1;
          }
        } else {
          this.empleadosModificar[empleadoId] = 1;
        }
      } else {
        empleadosHorario.push(empleado);
      }
    });

    this.datos2.data = empleadosHorario;
  }

  // public seleccionaCheckbox() {
  //   for (let dato of this.datos) {
  //     dato.selected = this.seleccion;
  //   }
  // }

  // public seleccionaCheckbox2() {
  //   for (let dato of this.datos2) {
  //      dato.selected = this.seleccion2;
  //   }
  // }

  public agregarOpcion() {
    const nuevaOpcion = 'Nueva opción'; // Puedes ajustar el valor de la nueva opción según tus necesidades
    this.schedules.push();
  }

  ngOnInit(): void {
    this.ownerId = this._activatedRoute.snapshot.params['owner'] || (this._activatedRoute.parent && this._activatedRoute.parent.snapshot.params['owner']) || 0;
    this.companyId = this._activatedRoute.snapshot.params['company'] || (this._activatedRoute.parent && this._activatedRoute.parent.snapshot.params['company']) || 0;

    // this._homeService.getResource(resource.companies, [this.ownerId, this.companyId])
    //   .subscribe((c: CompanyIdentity) => {
    //     this.tieneDepartamentos = c.hasDepartments;
    //     if (c.hasDepartments) this.loadDepartments();
    //     if (!c.hasDepartments) this.camposAdd = ['horario'];
    //   });

    this.empleadosTotales = [];
    // this.loadSchedules();
    // this._api.employee.Identity.list(this.ownerId, this.companyId, this.camposAdd).subscribe(
    //   (resultado: IEmpleados[]) => {
    //     resultado.forEach(x => {
    //       x.alta = getFechaT(x.altaActualTxt);
    //       if (x.bajaTxt) x.baja = getFechaT(x.bajaTxt);
    //       this.empleadosTotales.push(x);
    //     });
    //     this.mostrarEmpleadosFiltrados();
    //   }, (err: Error) => {
    //     console.error(err);
    //   }, () => {
    // this.terminoCarga = true;
    //   }
    // );
  }
}

type CampoEmpleado = 'nombre' | 'rfc';
