<div *ngIf="cargando">
  <div class="text-center py-5">
      <mat-spinner class="img-center"></mat-spinner>
  </div>
  <div class="text-center py-5">
    <span>Cargando datos...</span>
  </div>
</div>

<div class="m-3" *ngIf="!cargando">
  <!-- =============================== botones sig. ant. =============================== -->
  <div class="d-flex justify-content-between mx-auto mb-3">
    <div>
      <button class="button btn-perso" (click)="periodoAnterior()">{{ textoFiltro }} anterior</button>
    </div>
    <div>
      <h2 class="fw-bold" id="current-week">{{ semanaActualTitulo }}</h2>
    </div>
    <div>
      <button class="button btn-perso" (click)="periodoSiguiente()">{{ textoFiltro }} siguiente</button>
    </div>
  </div>
  <!-- =============================== filtros =============================== -->
  <div class="d-flex justify-content-between mx-auto">
    <div class="radio-inputs d-flex">
      <label class="radio">
        <input type="radio" name="radioGrupo1" checked="">
        <span class="name" (click)="filtrarPorSemana()">Semana</span>
      </label>
      <label class="radio">
        <input type="radio" name="radioGrupo1">
        <span class="name" (click)="filtrarPorMes()">Mes</span>
      </label>
    </div>
    <div class="d-flex">
      <button class="button" (click)="periodoActualBtn()">{{ textoFiltro }} actual</button>
    </div>
    <div class="radio-inputs d-flex">
      <label class="radio">
        <input type="radio" name="radioGrupo2" checked="">
        <span class="name" (click)="cambiarFormatoDatosEmpleados()">Empleados</span>
      </label>
      <label class="radio">
        <input type="radio" name="radioGrupo2">
        <span class="name" (click)="cambiarFormatoDatosGrupos()">Grupos</span>
      </label>
    </div>
    <div class="d-flex">
      <button class="button mr-2" *ngIf="aplicarOtrosDias" (click)="deshacer()">Deshacer</button> <!-- (click)="aplicarCambiosOtroDia()" -->
      <button class="button" (click)="habilitarEdicion()">{{ textoBoton }}</button>
    </div>
  </div>
  <hr />
  <!-- =============================== tabla =============================== -->
  <div class="m-3">
    <table class="table table-striped table-hover text-center">
      <thead class="thead-light">
        <!-- =============================== thead filtro por semana =============================== -->
        <ng-container *ngIf="filtro === 'semana'">
          <tr>
            <th scope="col" colspan="3">{{ tituloFiltroEmpleadoGrupo }}</th>
            <th scope="col" *ngIf="campoDeEntrada !== '' && habilitar" class="border-left"></th>
            <th scope="col" class="border-right border-left" *ngFor="let dia of diasSemana">{{ dia.numero }}</th>
          </tr>
          <tr>
            <th scope="col" colspan="3"></th>
            <th scope="col" *ngIf="campoDeEntrada !== '' && habilitar" class="border-left"></th>
            <th scope="col" class="border-right border-left" *ngFor="let dia of diasSemana">{{ dia.nombre }}</th>
          </tr>
        </ng-container>
        <!-- =============================== thead filtro por mes =============================== -->
        <ng-container -container *ngIf="filtro === 'mes'">
          <tr>
            <th scope="col" colspan="3">{{ tituloFiltroEmpleadoGrupo }}</th>
            <th scope="col" *ngIf="campoDeEntrada !== '' && habilitar" class="border-left"></th>
            <th scope="col" class="border-right border-left" *ngFor="let semana of semanasDelMes">{{ semana }}</th>
          </tr>
          <tr>
            <th scope="col" colspan="3"></th>
            <th scope="col" *ngIf="campoDeEntrada !== '' && habilitar" class="border-left"></th>
            <th scope="col" class="border-right border-left" *ngFor="let dia of diasSemana">{{dia.info }} {{ dia.nombre }}</th>
          </tr>
        </ng-container>
      </thead>
      <tbody>
        <!-- =============================== tbody filtro por empleado =============================== -->
        <!-- {{horarioSeleccionado}} -->
        <ng-container *ngIf="cambiarDatos === 'empleados'">
          <tr *ngFor="let empleado of empleados">
            <td scope="row" colspan="3" class="min-vw-20 border-right">
              <small>{{ empleado.nombre }}</small>
              <!-- {{filaDatos[empleado.id] | json}} -->
            </td>
            <td class="border-right border-left" *ngFor="let dato of filaDatos[empleado.id]; let i = index">
              <!-- {{dato}} -> {{i}} -->
              <div *ngIf="aplicarOtrosDias" class="d-flex justify-content-end">
                <button class="btn btn-default p-0" (click)="copiarValor(empleado.id, i)">
                  <i class="material-icons" style="font-size: 20px;">add</i>
                </button>
                <button class="btn btn-default p-0" (click)="copiarValorEnAdelante(empleado.id, i)">
                  <i class="material-icons" style="font-size: 20px;">navigate_next</i>
                </button>
              </div>
              <select class="form-select form-select-sm" [ngClass]="{ 'bg-edit': !habilitar }" (change)="abrirModal(modal, empleado.id, i)" [(ngModel)]="filaDatos[empleado.id][i]">
                <option [disabled]="true" [value]="">Seleccionar</option>
                <option [disabled]="!habilitar" [value]="'-'" *ngIf="filtro === 'semana'">Descanso</option>
                <option [disabled]="!habilitar" [value]="opcion.id" *ngFor="let opcion of horarios">{{ opcion.nombreHorario }}</option>
              </select>
            </td>
          </tr>
        </ng-container>
        <!-- =============================== tbody filtro por grupo =============================== -->
        <ng-container *ngIf="cambiarDatos === 'grupos'">
          <tr *ngFor="let grupo of grupoEmpleados">
            <td scope="row" colspan="3" class="min-vw-20 border-right">
              <small>{{ grupo }}</small>
              <!-- filaDatosJson: {{filaDatos[grupo] | json}} -->
            </td>
            <td class="border-right border-left" *ngFor="let dato of filaDatos[grupo]; let i = index">
               <!-- datos y i:  {{dato}} -> {{i}} -->
              <div *ngIf="aplicarOtrosDias" class="d-flex justify-content-end">
                <button class="btn btn-default p-0" (click)="copiarValor(grupo, i)">
                  <i class="material-icons" style="font-size: 20px;">add</i>
                </button>
                <button class="btn btn-default p-0" (click)="copiarValorEnAdelante(grupo, i)">
                  <i class="material-icons" style="font-size: 20px;">navigate_next</i>
                </button>
              </div>
              <select class="form-select form-select-sm" name="opcion" id="opcionHorarios" [ngClass]="{ 'bg-edit': !habilitar }" (change)="actualizarValorTemporal($event)" [(ngModel)]="filaDatos[grupo][i]">
                <option [disabled]="!habilitar" [value]="" disabled>Seleccionar</option>
                <option [disabled]="!habilitar" [value]="'-'" *ngIf="filtro === 'semana'">Descanso</option>
                <option [disabled]="!habilitar" [value]="opcion.id" *ngFor="let opcion of horarios">{{ opcion.nombreHorario }}</option>
              </select>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>

<!-- ===================== modal ===================== -->
<ng-template #modal let-modal>
  <div class="d-flex justify-content-between align-items-center px-3 border-bottom border-light shadow-sm">
    <h2 class="modal-title mb-0 ms-2 fw-bold">Detalles de Incidencia</h2>
    <button mat-icon-button (click)="modalRef?.hide()">
      <mat-icon fontIcon="close"></mat-icon>
    </button>
  </div>
  <div class="modal-body">
    <h4>¿Deseas aplicar este horario a otros días?</h4>
  </div>
  <div class="d-flex justify-content-end p-3">
    <button type="button" class="btn btn-success text-white mr-2" (click)="aceptarModal()">Aceptar</button>
    <button type="button" class="btn btn-primary text-white" (click)="modalRef?.hide()">Cancelar</button>
  </div>
</ng-template>
