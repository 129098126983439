<div class="d-flex justify-content-between">
  <div class="ms-5 mt-2">
    <h1>Configuración de reconocimiento facial</h1>
  </div>
  <button mat-dialog-close mat-icon-button><mat-icon>close</mat-icon></button>
</div>

<div class="row g-3 mx-2 mb-3">
  <div class="col-3">
    <label class="form-label mb-0" for="inputGroupSelect01">Cámara</label>
    <select [(ngModel)]="facingMode" class="form-select" aria-label="Default select example" id="inputGroupSelect01">
      <option value="User">Frontal</option>
      <option value="environment">Trasera</option>
    </select>
  </div>
  <div class="col-3" *ngIf="tipoAccesoSelect !== 'CF'">
    <label class="form-label mb-0" for="inputGroupSelect02">Modelo FaceApi</label>
    <select [(ngModel)]="faceApiModel" class="form-select" aria-label="Default select example" id="inputGroupSelect02">
      <option value="tinyFace">tinyFace</option>
      <option value="Mobilenetv">Mobilenetv</option>
      <option value="ssdMobileNetV1">ssdMobileNetV1</option>
    </select>
  </div>
  <div class="col-3">
    <label class="form-label mb-0" for="inputGroupSelect03">Servidor</label>
    <select [(ngModel)]="servidorSelect" class="form-select" aria-label="Default select example" id="inputGroupSelect03">
      <option *ngFor="let servidor of opcionesServidor | keyvalue" [value]="servidor.key">
        {{ servidor.value }}
      </option>
    </select>
  </div>
  <div [ngClass]="{'col-6': tipoAccesoSelect == 'CF', 'col-3': tipoAccesoSelect !== 'CF'}">
    <label class="form-label mb-0" for="inputGroupSelect04">Manejo de acceso</label>
    <select [(ngModel)]="tipoAccesoSelect" class="form-select" aria-label="Default select example" id="inputGroupSelect04">
      <option *ngFor="let servidor of opcionesAcceso | keyvalue" [value]="servidor.key">
        {{ servidor.value }}
      </option>
    </select>
  </div>
  <div class="col-4" *ngIf="tipoAccesoSelect !== 'CF'">
    <label for="nombre" class="form-label mb-0">FaceBox X</label>
    <input type="text" id="faceBoxX" class="form-control bg-light slide-in-left" id="nombre" onClick="this.select();" [(ngModel)]="faceBoxX">
  </div>
  <div class="col-4" *ngIf="tipoAccesoSelect !== 'CF'">
    <label for="nombre" class="form-label mb-0">FaceBox Y</label>
    <input type="text" id="faceBoxY" class="form-control bg-light slide-in-left" id="nombre" onClick="this.select();" [(ngModel)]="faceBoxY">
  </div>
  <div class="col-4" *ngIf="tipoAccesoSelect !== 'CF'">
    <label for="nombre" class="form-label mb-0">FaceBox H</label>
    <input type="text" id="faceBoxH" class="form-control bg-light slide-in-left" id="nombre" onClick="this.select();" [(ngModel)]="faceBoxH">
  </div>
  <div class="col-4" *ngIf="tipoAccesoSelect !== 'CF'">
    <label for="nombre" class="form-label mb-0">FaceBox W</label>
    <input type="text" id="faceBoxW" class="form-control bg-light slide-in-left" id="nombre" onClick="this.select();" [(ngModel)]="faceBoxW">
  </div>
  <div class="col-4" *ngIf="tipoAccesoSelect !== 'CF'">
    <label for="nombre" class="form-label mb-0">FaceBox T</label>
    <input type="text" id="faceBoxT" class="form-control bg-light slide-in-left" id="nombre" onClick="this.select();" [(ngModel)]="faceBoxTolerance">
  </div>
  <div class="col-4" *ngIf="tipoAccesoSelect !== 'CF'">
    <label for="nombre" class="form-label mb-0">Area de tolerancia</label>
    <input type="text" id="faceAndCamAreaTolerance" class="form-control bg-light slide-in-left" id="nombre" onClick="this.select();" [(ngModel)]="faceAndCamAreaTolerance">
  </div>
  <div class="col-6" *ngIf="tipoAccesoSelect !== 'CF'">
    <label for="nombre" class="form-label mb-0">Escala de la cámara</label>
    <input type="text" id="webCamScale" class="form-control bg-light slide-in-left" id="nombre" onClick="this.select();" [(ngModel)]="webCamScale">
  </div>
  <div class="col-6" *ngIf="tipoAccesoSelect !== 'CF'">
    <label for="nombre" class="form-label mb-0">Toma de cámara</label>
    <input type="text" id="drawingLoopInicial" class="form-control bg-light slide-in-left" id="nombre" onClick="this.select();" [(ngModel)]="drawingLoopInicial">
  </div>
</div>

<div>
  <mat-dialog-actions align="end">
    <button mat-raised-button class="text-secondary" (click)="restablecerDatos()">Restablecer a los valores</button>
    <button mat-raised-button class="text-success" (click)="guardarDatos()">Guardar Datos</button>
    <button mat-raised-button class="text-primary" (click)="log()">Log</button>
  </mat-dialog-actions>
</div>
