import { HttpHeaders } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import * as CryptoJS from 'crypto-js';
import { environment } from "src/environments/environment";
import Swal, { SweetAlertIcon } from "sweetalert2";

export function getUuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function getToken(code: string, id: number, secret: number) {
    const codeComponents = getTokenCodeTimeComponents(code);
    var x = (codeComponents.h + (codeComponents.m / 60) + (codeComponents.s / 3600)) / 24;
    var y = (Math.trunc(Math.sin(x * Math.PI) * secret + secret)).toString();
    var vc = y.substr(y.length - 3, 3);
    return `${id.toString(16)}${vc}`;
}

export function getTokenCodeTimeComponents(code: string) {
    var h = +reversedBase[code[2]];
    var m = +reversedBase[code[1]] + +reversedBase[code[3]];
    var s = +reversedBase[code[4]] + +reversedBase[code[0]];

    return {
        h: h,
        m: m,
        s: s,
        isValid: code.length == 5 && h >= 0 && h <= 24 && m >= 0 && m <= 60 && s >= 0 && s <= 60
    };
}

export const base = [
    'i', '7', '4', 'k', 't', 'a', 'f', 'u', '5', 'm', 'j', 'g', 'o', 'n', 'x', '8',
    'd', 's', 'e', '9', '6', '2', '1', '3', 'c', 'q', 'p', 'h', 'l', 'b', 'r'
];

export const reversedBase = (function () {
    let indexed: { [id: string]: number } = {};
    for (let index = 0; index < base.length; index++) {
        const element: string = base[index];
        indexed[element] = index;
    }
    return indexed;
})();

export function getHeaders(deviceId: string, clientId: string, deviceKey: string, requestKey?: string, debug?: boolean) {
    let token = getTokenHeader(deviceId, deviceKey);
    if (!!requestKey) {
        token.requestKey = requestKey;
    }
    // if (debug && !environment.production) {
    //   let txt: string = `ClientId: ${clientId}, RequestKey: ${token.requestKey}, Secret: ${token.secret}, DeviceId: ${deviceId}, clientSecret: ${token.clientSecret}, deviceKey: ${deviceKey}`;
    //   console.log(txt);
    // }
    return new HttpHeaders({
        'ClientId': clientId,
        'RequestKey': token.requestKey,
        'Secret': token.secret,
        'DeviceId': deviceId
    });
}

export function getHeadersFetch(deviceId: string, clientId: string, deviceKey: string, requestKey?: string, debug?: boolean) {
  let token = getTokenHeader(deviceId, deviceKey);
  if (!!requestKey) {
      token.requestKey = requestKey;
  }
  return {
      'ClientId': clientId,
      'RequestKey': token.requestKey,
      'Secret': token.secret,
      'DeviceId': deviceId
  };
}

export function getHeaders2(deviceId: string, clientId: string, ) {
    return new HttpHeaders({
        'ClientId': clientId,
        'RequestKey': 'verifPuntoDeAcceso',
        'Secret': 'token',
        'DeviceId': deviceId
    });
  }

export function getHeadersCF(apiKey: string) {
    return new HttpHeaders({
        'Accept': '*/*',
        // 'ContentType': 'application/json',
        'x-api-key': apiKey
    });
}

export function getHeadersFetchCF(apiKey: string) {
  return {
      'Accept': '*/*',
      // 'ContentType': 'application/json',
      'x-api-key': apiKey
  };
}

export function getHeadersFetch2CF(apiKey: string) {
  return {
      "Accept": '*/*',
      // 'ContentType': 'application/json',
      'x-api-key': apiKey
  };
  // 'ContentType': 'application/x-www-form-urlencoded',
}

  export function getTokenHeader(deviceId: string, deviceKey: string) {
    if (!deviceKey) {
      return { requestKey: '', secret: '' };
    }

    let proceso: string = '';
    // let procesoVar: string = '';
    try {
        proceso = 'clientSecret';
        // let deviceIdCrypto: string = JSON.stringify({ deviceId })
        // procesoVar = `(${deviceIdCrypto}, ${deviceId}, ${deviceKey})`;
        let clientSecret: string = decrypt(deviceId, deviceKey);

        proceso = 'getUuid';
        let Uuid: string = getUuid();

        proceso = 'requestKey';
        // procesoVar = `(${Uuid})`;
        var requestKey = CryptoJS.SHA256(Uuid).toString();

        proceso = 'token';
        // procesoVar = `(${requestKey}, ${clientSecret})`;
        let token = CryptoJS.HmacSHA256(requestKey, clientSecret).toString();
        return { requestKey: requestKey, secret: token, clientSecret: clientSecret };
    } catch (error) {
        console.log(`ERROR (${proceso})  ====================================================================== getTokenHeader`); // ${procesoVar}`);
        console.error(error);
        return { requestKey: '', secret: '' };
    }

  };

  export function encrypt(txt: string, deviceId: string): string {
    if (!txt) return txt;
    return CryptoJS.AES.encrypt(txt, deviceId).toString();
  }

  export function decrypt(txtToDecrypt: string, deviceId: string) {
    var decryptTxt = CryptoJS.AES.decrypt(txtToDecrypt, deviceId);
    try {
        return decryptTxt.toString(CryptoJS.enc.Utf8);
    } catch (error) {
        // console.log(`############################## Error decrypting ${decryptTxt}`);
        return decryptTxt.toString();
    }

  }

  export function mostrarMensajeSnack(snackBar: MatSnackBar, mensaje: string, duracion: number) {
    snackBar.open(mensaje, '', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: duracion,
    });
  }

  export function mostrarSwalToast(text: string, icon: SweetAlertIcon) {
    Swal.fire({
      html: text,
      icon: icon,
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      position: 'top-end',
      toast: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });
  }

  export function mostrarSwalError(title: string, text: string) {
    Swal.fire({
      title: title,
      html: text,
      icon: 'error',
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });
  }

  export function mostrarSwalConfirm(text: string, icon: SweetAlertIcon, title?: string) {
    Swal.fire({
      title: title,
      html: text,
      icon: icon,
      showConfirmButton: true,
      confirmButtonText: 'Aceptar'
    });
  }

  export function encrypt64(txt: string) {
    if (!txt) return txt;
    let key = CryptoJS.enc.Base64.parse(txt);
    let has = CryptoJS.HmacSHA256(key, '');
    return txt;
  }
