import { Component } from '@angular/core';
import { NomiExpressApiService } from '../../services/NomiExpress.api.service';
import { AccesoExpress } from 'src/app/models/accesoExpress';
import { Router } from '@angular/router';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})
export class LogComponent {

  public logs: string[];

  constructor(
    private nomiExpressApi: NomiExpressApiService
  ) {
    this.logs = this.nomiExpressApi.log();
  }

  public estilos = {
    'font-size': '18px',
    'line-height': '15px'
  };
}
