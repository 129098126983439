<div *ngIf="cargando">
  <div class="text-center py-5">
    <mat-spinner class="img-center"></mat-spinner>
  </div>
  <div class="text-center py-5">
    <span>Cargando datos...</span>
  </div>
</div>


<div *ngIf="!cargando">
  <!-- ====================== select cuando se modifica un empleado ====================== -->
  <ng-container *ngIf="asignandoHorario">
    <div class="m-3 opacity-50 text-center">
      <h4 class="mb-0">Asignando horario a {{ nombreCompleto() }}</h4>
    </div>


    <div class="mb-1 mx-2 d-flex justify-content-end">
      <button class="text-primary mx-2" mat-raised-button (click)="horarioSeleccionado = undefined; asignandoHorario = false">Cancelar asignación</button>
      <button class="text-success" mat-raised-button (click)="guardarAsignacionHorario()">Guardar asignación</button>
    </div>

    <div class="row my-4 mx-3">
      <div class="col-6">
        <mat-form-field appearance="outline" class="col-12">
          <mat-label>Selecciona el horario</mat-label>
          <mat-select [(ngModel)]="horarioSeleccionado">
            <mat-option *ngFor="let horario of horarios" [value]="horario">{{ horario.nombreHorario }}</mat-option>
          </mat-select>
          <mat-error *ngIf="!horarioSeleccionado">Debes seleccionar un horario</mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="example-full-width" appearance="outline" class="col-12">
          <mat-label>Fecha de asignación</mat-label>
          <input matInput [min]="minDate.toISOString().split('T')[0]" [matDatepicker]="picker" [(ngModel)]="fechaAsignacion">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div class="col-12" *ngIf="!!horarioSeleccionado" >
      <app-horario-detalle [horario]="horarioSeleccionado" [soloConsulta]="true" [horarioEmpleado]="true"></app-horario-detalle>
    </div>
  </ng-container>

  <!-- ====================== búsqueda de horarios ====================== -->
  <div class="px-3 mt-4" *ngIf="!asignandoHorario">

    <div class="m-3 opacity-50 col-12">
      <h4 class="mb-0">{{ nombreCompleto() }} tiene {{ horariosEmpleado.length }} horarios signados</h4>
    </div>

    <div class="d-flex justify-content-end px-3 mt-2">
      <!-- ====================== input búsqueda ====================== -->
      <div class="col-7">
        <mat-form-field appearance="fill" class="col-12" *ngIf="false">
          <mat-label>Buscar horario</mat-label>
          <input [(ngModel)]="busqueda" matInput type="text" Búsqueda="Búsqueda por nombre"/>
          <button *ngIf="busqueda" matSuffix mat-icon-button aria-label="Clear" (click)="limpiarBusqueda()">
            <mat-icon>close</mat-icon>
          </button>
          <button matSuffix mat-icon-button>
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <!-- ====================== botón asignar horario y agregar horario ====================== -->
      <div class="col-5 d-flex justify-content-end">
        <button mat-raised-button matTooltip="Nuevo horario" class="ms-3 text-info" (click)="asignandoHorario = true">
          <mat-icon class="me-0">more_time</mat-icon>
          <p class="ms-2 mb-0">Asignar horario</p>
        </button>
        <button mat-raised-button matTooltip="Nuevo horario" class="ms-3 text-success" (click)="agregarHorarioDialog()">
          <mat-icon class="me-0">more_time</mat-icon>
          <p class="ms-2 mb-0">Nuevo horario</p>
        </button>
      </div>
    </div>



    <div class="col-12">
      <div *ngFor="let horario of horariosEmpleado">
        <app-horario-detalle [horario]="horario" [soloConsulta]="false" [horarioEmpleado]="true"></app-horario-detalle>
      </div>
    </div>


  </div>
</div>
