import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-transferir-dialog',
  templateUrl: './transferir-dialog.component.html',
  styleUrls: ['./transferir-dialog.component.scss']
})
export class TransferirDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<TransferirDialogComponent>,
  ) {}

  public cerrarForm() {
    this.dialogRef.close();
  }

  public aceptarCambios() {
    this.dialogRef.close('Aceptar');
  }

}
