import { Component } from '@angular/core';
import { AccesoExpress } from 'src/app/models/accesoExpress';
import { NomiExpressApiService } from '../../services/NomiExpress.api.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { mostrarMensajeSnack } from '../../Funciones/funciones';
import { ManejoAcceso, ServidoresCF } from 'src/app/models/reconocimientoFacial';
import { PasswordComponent } from '../password/password.component';
import { sha256 } from '../../Funciones/fTexto';
import { fFecha } from '../../Funciones/fFecha';

@Component({
  selector: 'app-configura-reconocimiento',
  templateUrl: './configura-reconocimiento.component.html',
  styleUrls: ['./configura-reconocimiento.component.scss']
})

export class ConfiguraReconocimientoComponent {
  private accesoExpress: AccesoExpress;
  public faceBoxX: number;
  public faceBoxY: number;
  public faceBoxW: number;
  public faceBoxH: number;
  public faceBoxTolerance: number;
  public faceAndCamAreaTolerance: number;
  public webCamScale: number;
  public drawingLoopInicial: number;
  public facingMode: string = 'User';
  public faceApiModel: string;

  public servidorSelect: string = '';
  public opcionesServidor = ServidoresCF;
  public tipoAccesoSelect: string = '';
  public opcionesAcceso = ManejoAcceso;

  constructor (
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ConfiguraReconocimientoComponent>,
    public nomiExpress: NomiExpressApiService,
    private dialog: MatDialog
  ) {
    this.accesoExpress = this.nomiExpress.accesoExpress();
    this.faceBoxX = this.accesoExpress.faceBoxX;
    this.faceBoxY = this.accesoExpress.faceBoxY;
    this.faceBoxW = this.accesoExpress.faceBoxW;
    this.faceBoxH = this.accesoExpress.faceBoxH;
    this.faceBoxTolerance = this.accesoExpress.faceBoxTolerance;
    this.faceAndCamAreaTolerance = this.accesoExpress.faceAndCamAreaTolerance;
    this.webCamScale = this.accesoExpress.webCamScale;
    this.drawingLoopInicial = this.accesoExpress.drawingLoopInicial;
    this.facingMode = this.accesoExpress.facingMode;
    this.faceApiModel = this.accesoExpress.faceApiModel;
    this.servidorSelect = this.accesoExpress.servidorCF;
    this.tipoAccesoSelect = this.accesoExpress.tipoAcceso;
  }

  public cerrarForm() {
    this.dialogRef.close();
  }

  public log() {
    this.dialogRef.close('log');
  }

  public restablecerDatos() {
    this.nomiExpress.restablecerDatosAccesoExpress();
    this.dialogRef.close('Guardado');
    mostrarMensajeSnack(this._snackBar, 'Valores de la cámara restablecidos', 2000);
  }

  public guardarDatos() {
    this.accesoExpress.faceBoxX = +this.faceBoxX;
    this.accesoExpress.faceBoxY = +this.faceBoxY;
    this.accesoExpress.faceBoxW = +this.faceBoxW;
    this.accesoExpress.faceBoxH = +this.faceBoxH;
    this.accesoExpress.faceBoxTolerance = +this.faceBoxTolerance;
    this.accesoExpress.faceAndCamAreaTolerance = +this.faceAndCamAreaTolerance;
    this.accesoExpress.webCamScale = +this.webCamScale;
    this.accesoExpress.facingMode = this.facingMode;
    this.accesoExpress.drawingLoopInicial = this.drawingLoopInicial;
    this.accesoExpress.faceApiModel = this.faceApiModel;
    if ((this.accesoExpress.servidorCF != this.servidorSelect && this.servidorSelect.startsWith('NM')) || (this.accesoExpress.tipoAcceso != this.tipoAccesoSelect && this.tipoAccesoSelect == 'BI')){
      const password = this.dialog.open(PasswordComponent, {
        data: 'Acceso a configuración',
        width: '33rem',
        height: 'auto'
      });
  
      password.afterClosed().subscribe(async (resultado) => {
        // if (!resultado) return;
        // resultado = await this.verificaPwd(resultado);
        // if (resultado != 'verificadaPwd') {
        //   // this.ponerError('Contraseña incorrecta');
        //   return
        // };
      });
    }
    this.accesoExpress.servidorCF = this.servidorSelect;
    this.accesoExpress.tipoAcceso = this.tipoAccesoSelect;
    console.log (`facingMode: ${this.accesoExpress.facingMode}, facingMode: ${this.facingMode}`);

    this.nomiExpress.guardarAccesoExpress(this.accesoExpress);
    this.dialogRef.close('Guardado');

    mostrarMensajeSnack(this._snackBar, 'Datos de la cámara guardados', 2000);
  }

  private async verificaPwd(password: string): Promise<string | undefined> {
    var hash = await sha256(password);
    // var hashTotal = await sha256(hash + this.accesoExpress.deviceId.toLocaleLowerCase());    
    if ((!hash || hash.toLocaleLowerCase() != this.accesoExpress.localSecret.toLocaleLowerCase()) ) {
      const hoy: string = fFecha(new Date(), "amd");
      let contraseña: string = `asesor0${hoy.substring(3, 4)}${hoy.substring(4, 5) }${hoy.substring(6, 7) }${hoy.substring(7, 8) }${hoy.substring(5, 6)}`;
      if (password != contraseña) {
        // console.log(`${password} ==> ${await sha256(password)} ==> hash1: ${hashTotal} hash: ${hash} ==> ${this.accesoExpress.localSecret}`);        
        // this.ponerError('Contraseña incorrecta');
        return undefined;
      }      
    }
    return 'verificadaPwd';
  }

}
