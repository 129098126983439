<div *ngIf="+empleado.id <= 0">
  <div class="text-center py-5">
    <mat-spinner class="img-center"></mat-spinner>
  </div>
  <div class="text-center py-5">
    <span>Cargando datos...</span>
  </div>
</div>
<div class="mt-4" *ngIf="+empleado.id > 0">
  <ng-container *ngIf="tieneCodigo">
    <div class="text-center">
      <h4 class="opacity-50 mx-3 lh-1">{{ nombreCompleto() }} debe de escanear el código siguiente desde su teléfono para registrar su dispositivo como una credencial a esta terminal.</h4>
      <mat-card class="bg-light rounded">
        <mat-card-header class="d-flex justify-content-center">
          <mat-card-title>{{ nombreCompleto() }}</mat-card-title>
          <mat-card-subtitle>Punto de acceso: {{ nombrePuntoAcceso }}</mat-card-subtitle>
        </mat-card-header>
        <qrcode class="pb-3" [qrdata]="qrCodeTxt" [width]="256" [errorCorrectionLevel]="'M'" [colorDark]="'#000000ff'" [colorLight]="'#ffffffff'"></qrcode>
      </mat-card>
      <div class="form-check d-flex justify-content-end align-item-center align-self-center mt-2">
        <input class="form-check-input me-1" type="checkbox" [checked]="mostrarDatosAppMovil" (change)="toggleMostrarDatosAppMovil($event)" id="appMovil">
        <label class="form-check-label" for="appMovil">
          Mostrar aplicaciones móviles
        </label>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!tieneCodigo">
    <div class="d-flex justify-content-center overflow-hidden pb-3">
      <div class="text-center">
        <div class="pt-4">
          <h2 class="mb-0 lh-1">Aún no hay un código asignado para</h2>
          <h2 class="mb-0">{{ nombreCompleto() }}</h2>
          <h3>Crear un código permitirá que el empleado lo escanee para registrar su dispositivo móvil como una credencial de acceso.</h3>
          <div class="col-12 mb-3">
            <button mat-raised-button color="primary" (click)="asignarCodigo()"><mat-icon>qr_code</mat-icon> Asignar código nuevo</button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!tieneCodigo || mostrarDatosAppMovil">
    <div class="mt-5 text-center overflow-hidden">
      <p>Por favor, descargue nuestra aplicación "Acceso Express" en su dispositivo móvil para registrar su acceso. La aplicación está disponible tanto para iOS como para Android.</p>
      <div class="row justify-content-center">
        <mat-card class="bg-light col-5 col-lg-4 m-2">
          <mat-card-header>
            <mat-card-title-group>
              <mat-card-title>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="55px" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" xml:space="preserve"><g id="_x31_03-GooglePlay_x2C__play_x2C__google_play_x2C__apps"><g><g><g><path d="M280.572,246.052L49.721,477.526c-11.034-10.465-16.997-24.819-16.997-40.374V74.096      c0-15.849,6.263-30.207,17.597-40.968L280.572,246.052z M280.572,246.052" style="fill:#2299F8;"/><g><path d="M480.104,255.62c0,20.938-11.34,39.477-30.131,49.95l-65.605,36.482l-81.428-75.367       l-22.367-20.634l86.193-86.429l83.207,46.056C468.764,216.152,480.104,234.69,480.104,255.62L480.104,255.62z M480.104,255.62" style="fill:#FFC107;"/><path d="M280.572,246.052L50.321,33.127c2.981-2.996,6.862-5.685,10.739-8.077       c18.787-11.363,41.456-11.667,60.839-0.895l244.867,135.467L280.572,246.052z M280.572,246.052" style="fill:#5ACF5F;"/></g><path d="M384.367,342.053L121.899,487.095c-9.243,5.386-19.688,7.774-29.826,7.774      c-10.734,0-21.469-2.69-31.013-8.668c-4.182-2.394-8.058-5.384-11.339-8.675l230.851-231.475l22.367,20.634L384.367,342.053z       M384.367,342.053" style="fill:#F84437;"/></g></g></g></g><g id="Layer_1"/></svg>
              </mat-card-title>
              <mat-card-subtitle>Descargar en Play Store</mat-card-subtitle>
              <img class="pb-3" mat-card-md-image src="../../../../../assets/images/qrPlayStore.png" alt="QR Play Store">
            </mat-card-title-group>
          </mat-card-header>
        </mat-card>

        <mat-card class="bg-light col-5 col-lg-4 m-2">
          <mat-card-header>
            <mat-card-title-group>
              <mat-card-title>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="55px" enable-background="new 0 0 512 512" id="Layer_1" version="1.1" viewBox="0 0 512 512" xml:space="preserve"><g><path d="M184.6,127.8c20.5-0.6,37.5,6.4,54.6,12.8c7.6,2.9,15.2,5.8,23.1,7.9c6.2,1.7,12.2,0.9,18.1-1.3   c10.2-3.7,20.3-7.4,30.5-11.2c17.8-6.6,36.1-11.1,55.1-9.9c35.5,2.2,65.6,16,88.5,43.9c4,4.8,3.7,5.3-1.3,8.8   c-37.1,26.1-53.3,62-48.3,106.9c4.4,39.9,25.5,68.5,61.1,86.6c4.6,2.3,5.5,4.4,3.8,9.1c-11.9,32.5-28.7,62.2-50.7,88.9   c-6.6,8-13.3,15.8-21.1,22.5c-17.5,14.9-37.3,18.2-59.4,12.4c-11.3-3-21.8-8.1-32.9-11.8c-21.4-7-42.5-5.7-63.7,1.1   c-11.1,3.6-21.6,8.5-32.9,11.6c-22,6.1-40.6,0.7-57-14.7c-27.8-26-48.4-57.1-63.8-91.6C77,374.8,68.9,348.4,64.5,321   c-5.5-33.9-5.9-67.6,4.5-100.7c8.4-27.1,22.9-50.3,45.4-67.8C135.4,136.2,159.3,127.4,184.6,127.8z" fill="#A7A7A7"/><path d="M265,109.8c1-31.7,14.8-57.1,38.3-77.7c17-14.9,36.9-23.5,58.9-27.6c3.4-0.6,4.8-0.1,4.9,3.7   c0.3,37.6-13.6,68.6-42.3,93c-15.5,13.2-33.2,19.7-53.5,19.9c-5,0.1-7.1-1.3-6.4-6.4C265.2,113.2,265,111.5,265,109.8z" fill="#A7A7A7"/></g></svg>
              </mat-card-title>
              <mat-card-subtitle>Descargar en App Store</mat-card-subtitle>
              <img class="pb-3" mat-card-md-image src="../../../../../assets/images/qrAppStore.png" alt="QR App Store">
            </mat-card-title-group>
          </mat-card-header>
        </mat-card>

        <div class="form-check d-flex justify-content-end align-item-center align-self-center mt-2">
          <input class="form-check-input me-1" type="checkbox" [checked]="mostrarDescargarApk" (change)="toggleMostrarApk($event)" id="apk">
          <label class="form-check-label" for="apk">
            Mostrar descarga APK
          </label>
        </div>

        <mat-card class="bg-light col-5 col-lg-4 m-2" *ngIf="mostrarDescargarApk">
          <mat-card-header>
            <mat-card-title-group>
              <mat-card-title>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" class="icon" fill="currentColor" width="55px">
                  <path fill="green" d="M40-239q8-106 65-196.5T256-579l-75-129q-3-9-.5-18t10.5-14q9-5 19.5-2t15.5 12l74 127q86-37 180-37t180 37l75-127q5-9 15.5-12t19.5 2q8 5 11.5 14.5T780-708l-76 129q94 53 151 143.5T920-239H40Zm240-110q20 0 35-15t15-35q0-20-15-35t-35-15q-20 0-35 15t-15 35q0 20 15 35t35 15Zm400 0q20 0 35-15t15-35q0-20-15-35t-35-15q-20 0-35 15t-15 35q0 20 15 35t35 15Z"></path>
                </svg>
              </mat-card-title>
              <mat-card-subtitle>Descargar APK</mat-card-subtitle>
              <img class="pb-3" mat-card-md-image src="../../../../../assets/images/qrApk.png" alt="QR APK">
            </mat-card-title-group>
          </mat-card-header>
        </mat-card>
      </div>
    </div>
  </ng-container>
</div>
