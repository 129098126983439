import { Injectable, TemplateRef, EventEmitter } from '@angular/core';
import { Subject, interval } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { AppEntity } from '../Funciones/appEntity';
import { ModelState } from '../Funciones/modelState';
import { AccesoExpress } from 'src/app/models/accesoExpress';
import { NomiExpressApiService } from './NomiExpress.api.service';


@Injectable({
  providedIn: 'root',
})
export class AppService {
  public activeSectionSubject: Subject<string> = new Subject<string>();
  public beforeScrollBottomSubject: Subject<any>;
  public themeSubject: Subject<string> = new Subject<string>();
  public sideBarSubject: Subject<boolean> = new Subject<boolean>();
  public printTemplateSubject: Subject<IReport> = new Subject<IReport>();
  public printLayoutSubject: Subject<IReportConfig> = new Subject<IReportConfig>();
  public accesoExpress: AccesoExpress

  constructor(private nomiExpressApi: NomiExpressApiService) {
    this.beforeScrollBottomSubject = new Subject<any>();
    this.beforeScrollBottomSubject.pipe(debounce(() => interval(500)));
    this.accesoExpress = this.nomiExpressApi.accesoExpress();
  }

  public isLocked = true;

  public activeEntity: AppEntity | undefined = undefined;
  public onEntityMaxed = new EventEmitter<AppEntity>();
  public onEntityClosed = new EventEmitter<AppEntity>();

  private previousMaxElement: any | undefined;
  private defaultCloneStrategy = (x: AppEntity) => {x._backup = {}; AppEntity.clone(x, x._backup);};
  private defaultRestoreStrategy = (x: AppEntity) => {
    if (!x._backup) return;
    AppEntity.clone(x._backup, x);
  };

  openEntityDialog(entity: AppEntity, id?: string, cloneStrategy?: (x: AppEntity) => void) {
    let entityId = id || entity.id;

    if (this.previousMaxElement === entityId) return;

    entity._state = 'data';
    let container = document.getElementById(entityId);
    if (!container) {
      console.error('Container element not found');
      return;
    }
    let content = container.getElementsByClassName('item-content')[0] as HTMLElement;
    let bounds = container.getBoundingClientRect();

    content.style.cssText = `position: fixed; max-width: ${bounds.width}px; left: ${bounds.left}px; top: ${bounds.top}px`;
    container.style.position = 'fixed';

    content.setAttribute('data-width', bounds.width.toString() + 'px');
    content.setAttribute('data-left', bounds.left.toString() + 'px');
    content.setAttribute('data-top', bounds.top.toString() + 'px');

    setTimeout(() => {
      if (container) {
          container.classList.add('max');
          content.style.cssText = `position: static; max-width: 950px;`;
          content.style.position = 'fixed';
      } else {
          console.error("Container element is null.");
      }
  }, 1);


    entity._ready = false;
    this.activeEntity = entity;
    this.previousMaxElement = entityId;
    this.onEntityMaxed.next(entity);

    setTimeout(() => {
      entity._ready = true;
    }, 500);

    (cloneStrategy || this.defaultCloneStrategy)(entity);

    return entity;
  }

  closeEntityDialog(id?: string, isNew?: boolean, restoreStrategy?: (x: AppEntity) => void) {
    let entityId = id || (this.activeEntity ? this.activeEntity.id : undefined);

    let container = document.getElementById(entityId);
    if (!container) {
      console.error('Container element not found');
      return;
    }
    let content = container.getElementsByClassName('item-content')[0] as HTMLElement;

    if (isNew) {
      content.classList.add('no-animate');
    }

    content.style.cssText = `position: fixed; max-width: ${content.dataset.width}; left: ${content.dataset.left}; top: ${content.dataset.top};`;
    container.classList.remove('max');

    setTimeout(() => {
      if (container) {
          container.style.position = 'static';
          content.style.cssText = `position: static; max-width: unset; margin-left: 0`;
          // content.removeAttribute('style');
          this.previousMaxElement = '';
      } else {
          console.error("Container element is null.");
      }
  }, 350);


    (restoreStrategy || this.defaultRestoreStrategy)
    // (this.activeEntity);

    this.onEntityClosed.emit(this.activeEntity);
    if (this.activeEntity) this.activeEntity._state = undefined;
    if (this.activeEntity) this.activeEntity._backup = undefined;
    if (this.activeEntity) this.activeEntity._modelState = undefined;
    if (this.activeEntity) this.activeEntity = undefined;

    return id;
  }

  addErrorsToCurrentEntity = (dbError: any) => {
    if (this.activeEntity) {
        this.addErrorsToEntity(this.activeEntity, dbError);
    } else {
        console.error("No active entity found to add errors to.");
    }
};

  addErrorsToEntity = (entity: AppEntity, dbError: any) => {
    if (dbError?.response?.errors) {
      entity._modelState = new ModelState(dbError.response.errors);
      return;
    }
    entity._modelState = new ModelState({'': 'Ha ocurrido un erro desconocido',});
  };

  startNewAnimation(entity: AppEntity) {
    entity._isNew = true;
    setTimeout(() => {
      entity._isNew = false;
    }, 50);
  }
  startRemoveAnimation() {
    let entityId = this.activeEntity?.id;
    if (!entityId) {
      console.error('Active entity ID is not defined');
      return;
    }

    let container = document.getElementById(entityId);
    if (!container) {
      console.error('Container element not found');
      return;
    }

    let content = container.getElementsByClassName('item-content')[0] as HTMLElement;
    if (!content) {
      console.error('Content element not found');
      return;
    }

    content.classList.add('removing');
  }

  // loadAppSettings() {
  //   let jsonTxt: string = this.nomiExpressApi.ipcRenderer.sendSync('get-app-settings');
  //   if (jsonTxt.substring(0, 5) == 'Error') {
  //     mostrarSwalError('Acceso Express', jsonTxt);
  //     return;
  //   }
  //   let localConfig = JSON.parse(jsonTxt);
  //   for (const propertyName in localConfig) {
  //     if (Object.prototype.hasOwnProperty.call(this.accesoExpress, propertyName)) {
  //       AccesoExpress[propertyName] = localConfig[propertyName];
  //     }
  //   }
  //   this.migrateToV1Settings();
  // }

  // saveAppSettings() {
  //   this._electronService.ipcRenderer.sendSync('save-app-settings',JSON.stringify(this.accesoExpress));
  // }

  // setClientSecret(secret: string) {
  //   this._electronService.ipcRenderer.sendSync('set-client-secret', secret);
  // }

  private migrateToV1Settings() {
    if (this.accesoExpress.version) return;

    this.accesoExpress.version = '1.0';
    this.accesoExpress.scanCountToCheck = 3;
    this.accesoExpress.canvasFps = 60;
    this.accesoExpress.faceRecognitionDebugMode = false;
    this.accesoExpress.showCamIndicators = false;
    this.accesoExpress.faceAndCamAreaTolerance = 0.07;
    this.accesoExpress.camHighlight1 = '#fafafa';
    this.accesoExpress.camHighlight2 = '#004c8c';
    this.accesoExpress.showActualCheckTime = false;

    this.accesoExpress.distanceThreshold = 0.9;
    this.accesoExpress.ssdMinConfidence = 0.9;
    this.accesoExpress.tinyFaceScoreThreshold = 0.9;
  }
}

export interface IAppTitle {
  title: TemplateRef<any>;
}

export interface IReport {
  body: TemplateRef<any>;
  tools: TemplateRef<any> | undefined;
}

export interface IReportConfig {
  horizontal: boolean;
}
