import { SelectionModel } from '@angular/cdk/collections';
import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { addDays, fFecha } from 'src/app/core/Funciones/fFecha';
import { mostrarSwalError, mostrarSwalToast } from 'src/app/core/Funciones/funciones';
import { AccesoDatosService } from 'src/app/core/services/acceso-datos.service';
import { IEmpleados, nombreEmpleado } from 'src/app/models/empleados';
import { IHorarios } from 'src/app/models/horarios';
import { IRespuestaChecker } from 'src/app/models/resultadoActualiza';

@Component({
  selector: 'app-asignar-horarios',
  templateUrl: './asignar-horarios.component.html',
  styleUrls: ['./asignar-horarios.component.scss'],
})
export class AsignarHorariosComponent {
  public empleadosOrigen: IEmpleados[] = [];
  public empleados: IEmpleados[] = [];

  public cargando: boolean = true;
  public mostrarBajas: boolean = false;
  public filtroGrupo: string | undefined = undefined;
  public filtroArea: string | undefined = undefined;

  public fechaAsignacion: Date = new Date();

  public mensajeError: string | undefined = undefined;
  public mensajeRespuesta: string | undefined = undefined;


  public displayedColumns: string[] = ['select', 'clave', 'nombre', 'rfc', 'area', 'alta', 'baja',];  // horario, area
  public dataSource: MatTableDataSource<IEmpleados> = new MatTableDataSource<IEmpleados>(this.empleados);;
  public selection = new SelectionModel<IEmpleados>(true, []);
  public empleadoSeleccionado: IEmpleados | undefined = undefined;

  public horarioSeleccionado: IHorarios | undefined = undefined;
  public horarios: IHorarios[] = [];

  public myControlHorarios = new FormControl('');
  public opcionesHorarios!: Observable<IHorarios[]>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private accesoDatosService: AccesoDatosService,
    private paginadoLabel: MatPaginatorIntl
  ) {
    this.cargando = true;
    this.paginadoLabel.itemsPerPageLabel = 'Empleados por página';
    this.cargarDatos();
   }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  public cargarDatos() {
    this.accesoDatosService.empleadosListado().subscribe(
      (empleados: IEmpleados[]) => {
        this.empleadosOrigen = empleados;
        this.aplicarFiltros();
        this.cargando = false;
      }, (error) => {
        console.error(error);
        let err: string = error.error;
        this.accesoDatosService.logAgrega2("Error al cargar los horarios. " + err);
        mostrarSwalError('Error', 'Error al cargar los horarios');
      }
    );

    this.accesoDatosService.horarios().subscribe(
      (horarios: IHorarios[]) => {
        this.horarios = horarios;
      }, (error) => {
        console.error(error);
        let err: string = error.error;
        this.accesoDatosService.logAgrega2("Error al cargar los horarios. " + err);
        mostrarSwalError('Error', 'Error al cargar los horarios');
      }
    );
  }

  public formatoFecha(fecha: Date | undefined) {
    if (!fecha) {
      return '-';
    }
    return fFecha(fecha, "FSL")
  }

  public nombreCompleto(empleado: IEmpleados){
    return nombreEmpleado(empleado);
  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  public masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  public checkboxLabel(row?: IEmpleados): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  public buscarEmpleado(event: Event) {
    if (event.target instanceof HTMLInputElement) {
      const filterValue = event.target.value.trim().toLowerCase();
      if (filterValue) {
        this.empleados = this.dataSource.data.filter((data: IEmpleados) => {
          return data.nombre.toLowerCase().includes(filterValue);
        });

        this.dataSource.filterPredicate = (data: IEmpleados, filter: string) => {
          return data.nombre.toLowerCase().includes(filter);
        };

        this.dataSource.filter = filterValue;
      } else {
        this.restablecerFiltro();
      }
    }
  }

  private restablecerFiltro() {
    this.dataSource.filter = '';
    this.aplicarFiltros();
  }

  public aplicarFiltros() {
    // console.log(`aplicarFiltros. ${this.empleadosOrigen.length}`);

    this.empleados = this.empleadosOrigen.filter(x => !x.baja || x.baja.getTime() <= new Date(1900, 1, 1).getTime()).sort(
      (a, b) => +a.numero - +b.numero || a.nombre?.toLowerCase().localeCompare(b.nombre?.toLowerCase())
    );

    // if (!this.filtroGrupo) {
    //   this.empleados = this.empleados.sort((a, b) => a.grupo.toLowerCase().localeCompare(this.filtroGrupo?.toLowerCase()));
    // }

    if (!!this.filtroArea) {
      let filtroArea: string = this.filtroArea.toLowerCase() || '-';
      this.empleados = this.empleados.sort((a, b) => a.area.toLowerCase().localeCompare(filtroArea));
    }

    // console.log(`aplicarFiltros. ${this.empleados.length}`);
    this.dataSource = new MatTableDataSource<IEmpleados>(this.empleados);
    this.dataSource.paginator = this.paginator;
  }

  public aplicarCambios() {
    const fecha = new Date(2024, 0, 1);

    if (this.fechaAsignacion < fecha) {
      mostrarSwalToast(`No puedes asignar el horario con fecha anterior a ${this.formatoFecha(fecha)}`, 'warning');
        return;
    }

    if (!this.horarioSeleccionado || !this.horarioSeleccionado.idHorario) {
      mostrarSwalToast('Selecciona un horario para asignar', 'warning');
      return;
    }

    let idHorario: string = this.horarioSeleccionado.idHorario;

    this.cargando = true;
    let iEmpleados: number = 0;
    let empleadosCargados: string = '';
    // console.log(`aplicarCambios.`);
    this.empleados.forEach(empleado => {
      if (this.selection.isSelected(empleado)) {
        this.accesoDatosService.horarioEmpleadoAsignar(+empleado.id, idHorario, this.fechaAsignacion).subscribe(
          (respuesta: IRespuestaChecker) => {
            let txt: string = ``;
            if (respuesta.code == 100) {
              txt = `Horario ${this.horarioSeleccionado?.nombreHorario} asignado al empleado: ${nombreEmpleado(empleado)}`;
              mostrarSwalToast(txt, 'success');
            } else {
              txt = `Empleado: ${nombreEmpleado(empleado)}, Respuesta: ${respuesta.code}, ${respuesta.mensaje}`;
              mostrarSwalToast(txt, 'warning');
              empleadosCargados += `, ${txt}`;
            }
          }, (error) => {
            console.error(error);
            let err: string = error.error;
            this.accesoDatosService.logAgrega2("Error al cargar los horarios. " + err);
            mostrarSwalError('Error', 'Error al cargar los horarios');
          }
        );
      }
      iEmpleados++;
    });

    setTimeout(() => {
      mostrarSwalToast(`Procesados: ${iEmpleados}${empleadosCargados}`, 'info');
      this.cargando = false;
      this.selection.clear();
      this.horarioSeleccionado = undefined;

    }, 3000);
  }
  private _filter(value: string): IHorarios[] {
    const filterValue = this._normalizeValue(value);
    return this.horarios.filter(street => this._normalizeValue(street.nombreHorario).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  public displayFn(horario: IHorarios | null): string {
    return horario ? horario.nombreHorario : '';
  }

  ngOnInit(): void {
    this.opcionesHorarios = this.myControlHorarios.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

}
