import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IEmpleados, nombreEmpleado } from 'src/app/models/empleados';
import { AccesoDatosService } from 'src/app/core/services/acceso-datos.service';
import { IEmpresa, empresaDesdeAcceso } from 'src/app/models/empresa';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { addDays, fechaCV, fFecha, inicioMesActual } from 'src/app/core/Funciones/fFecha';
import { mostrarSwalError, mostrarSwalToast } from 'src/app/core/Funciones/funciones';
import { $tipoIncapacidad, $tipoManejoIncidenciaUsuario, IAccesoIncidenciasUsuario, TipoManejoIncidencia } from 'src/app/models/incidencias';
import { IRespuestaChecker } from 'src/app/models/resultadoActualiza';
import { KeyValue } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-empleados-incidencias-masivo',
  templateUrl: './empleados-incidencias-masivo.component.html',
  styleUrls: ['./empleados-incidencias-masivo.component.scss']
})
export class EmpleadosIncidenciasMasivoComponent {
  public empleados: IEmpleados[] = [];
  public empleadosTotales: IEmpleados[] = [];
  public nuevaIncidencia: IAccesoIncidenciasUsuario = new IAccesoIncidenciasUsuario();
  public empresa!: IEmpresa;

  public esDebugger: boolean = false;
  public estaCargando: boolean = true;
  public mostrarBajas: boolean = false;
  public diasPeriodo: number = 1;

  public myControl = new FormControl('');
  public filteredOptions!: Observable<IEmpleados[]>;

  public tipoManejoIncidencia = $tipoManejoIncidenciaUsuario;
  public tipoIncapacidad = $tipoIncapacidad;

  public selectedAcomodo: string = 'clave';
  public opcionesFiltrosAcomodo = {
    'nombre': 'Ordenar por nombre',
    'fechaAlta': 'Ordenar por fecha de alta',
    'clave': 'Ordenar por clave',
    'puesto': 'Ordenar por puesto',
    'area': 'Ordenar por area',
    'sinAcomodo': 'Mostrar todos',
  }

  public inicioPersonalizado: string = '';
  public finPersonalizado: string = '';
  public inicioPeriodo: Date;
  public finPeriodo: Date;
  public rangoPersonalizado = new FormGroup({
    desde: new FormControl<Date | null>(null),
    hasta: new FormControl<Date | null>(null),
  });

  public displayedColumns: string[] = [];
  public dataSource: MatTableDataSource<IEmpleados>;
  public selection = new SelectionModel<IEmpleados>(true, []);

  @ViewChild(MatSort) sort!: MatSort;

  @Input() dialogRef!: MatDialogRef<any>;

  @Output()
  public cerrarIncidenciasMasivas = new EventEmitter<void>();

  constructor(
    private accesoDatosService: AccesoDatosService,
    public dialog: MatDialog
  ) {
    this.inicioPeriodo = inicioMesActual();
    this.finPeriodo = addDays(new Date(this.inicioPeriodo.getFullYear(), this.inicioPeriodo.getMonth() + 1, 1), -1);
    this.cargarEmpleados();
    this.dataSource = new MatTableDataSource(this.empleados);
  }

  public cargarEmpleados() {
    this.estaCargando = true;
    this.esDebugger = this.accesoDatosService.getModoDebug();
    this.accesoDatosService.empresaObtener().subscribe(
      (empresa: IEmpresa) => {
        this.empresa = empresa;
        this.accesoDatosService.empleadosListado().subscribe(
          (empleados: IEmpleados[]) => {
            this.empleadosTotales = empleados;
            this.empleados = empleados;
            // this.empleados = [];
            // empleados.forEach(
            //   x => {
            //     x.selected = true;
            //     this.empleados.push(x);
            //   }
            // );

            this.aplicarFiltros();
            this.dataSource = new MatTableDataSource(this.empleados);
            this.dataSource.sort = this.sort;
            this.estaCargando = false;
          },
          (error) => {
            this.accesoDatosService.logAgrega2("Error al cargar los datos de los empleados");
            console.error(error);
            this.empleados = [];
            this.empleadosTotales = [];
          }
        );
      },
      (error) => {
        this.accesoDatosService.logAgrega2("Error al cargar los datos de la empresa");
        console.error(error);
        this.empleados = [];
        this.empleadosTotales = [];
      }
    );
  }

  public aplicarFiltros() {
    if (this.mostrarBajas) {
      this.empleados = this.empleadosTotales;
      this.displayedColumns = ['select', 'id', 'clave', 'nombre', 'rfc', 'alta', 'baja'];
    }  else {
      this.empleados = this.empleadosTotales.filter(x => !x.baja || x.baja.getTime() <= new Date(1900, 1, 1).getTime());
      this.displayedColumns = ['select', 'id', 'clave', 'nombre', 'rfc', 'alta'];
    }
    // this.empleados = this.empleadosTotales.filter(x => !x.baja || x.baja.getTime() <= new Date(1900, 1, 1).getTime()).sort(
    //   (a, b) => +a.numero - +b.numero || a.nombre?.toLowerCase().localeCompare(b.nombre?.toLowerCase())
    // );

    if (this.selectedAcomodo === 'nombre') {
      this.empleados = this.empleados.sort((a, b) => a.nombre.toLowerCase().localeCompare(b.nombre.toLowerCase()));
    } else if (this.selectedAcomodo === 'fechaAlta') {
      this.empleados = this.empleados.sort((a, b) => a.alta.getTime() - b.alta.getTime());
    } else if (this.selectedAcomodo === 'clave') {
      this.empleados = this.empleados.sort((a, b) => +a.numero - +b.numero);
    } else if (this.selectedAcomodo === 'area') {
      this.empleados = this.empleados.sort((a, b) => {
        const areaA = a.area ? a.area.toLowerCase() : '';
        const areaB = b.area ? b.area.toLowerCase() : '';
        if (areaA && !areaB) return -1;
        if (!areaA && areaB) return 1;
        return areaA.localeCompare(areaB);
      });
    } else if (this.selectedAcomodo === 'puesto') {
      this.empleados = this.empleados.sort((a, b) => {
        const puestoA = a.puesto ? a.puesto.toLowerCase() : '';
        const puestoB = b.puesto ? b.puesto.toLowerCase() : '';
        if (puestoA && !puestoB) return -1;
        if (!puestoA && puestoB) return 1;
        return puestoA.localeCompare(puestoB);
      });
    }

    this.dataSource = new MatTableDataSource(this.empleados);
    this.dataSource.sort = this.sort;
  }

  public nombreCompleto(empleado: IEmpleados){
    return nombreEmpleado(empleado);
  }

  public formatoFecha(fecha: any) {
    return fFecha(fecha, "FSL");
  }

  public calcularDias() {
    if (this.inicioPersonalizado && this.finPersonalizado) {
      const fechaInicio = new Date(this.inicioPersonalizado);
      const fechaFin = new Date(this.finPersonalizado);
      const diferencia = fechaFin.getTime() - fechaInicio.getTime();
      this.diasPeriodo = diferencia / (1000 * 3600 * 24);
      this.diasPeriodo++;
    } else {
      this.diasPeriodo = 0;
    }
  }

  public empleadosBaja(index: any): boolean {
    const empleado = this.empleados[index];
    const baja = empleado.baja ? new Date(empleado.baja) : empleado.bajaActualTxt;
    const fechaPorDefecto = new Date('1900-01-01');
    const hoy = new Date();

    if (!baja || baja > fechaPorDefecto) return true;

    if (baja < hoy) return false;

    return true;
  }


  private _filter(value: string): IEmpleados[] {
    const filterValue = this._normalizeValue(value);
    return this.empleadosTotales.filter(empleado => this._normalizeValue(this.nombreCompleto(empleado)).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  public onOptionSelected() {
    this.myControl.setValue('');
  }

  public valueTipoIncidenciaAscOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return +this.tipoManejoIncidencia[+a.key] - +this.tipoManejoIncidencia[+b.key];
  };

  public guardarIncidenciasMasivas() {
    if (!this.inicioPersonalizado) {
      mostrarSwalToast('No se ha especificado la fecha de inicio de la nueva incidencia', 'error');
      return;
    }
    if (!this.finPersonalizado) {
      mostrarSwalToast('No se ha especificado la fecha de terminación de la nueva incidencia', 'error');
      return;
    }

    this.nuevaIncidencia.inicio = fechaCV(this.inicioPersonalizado);
    this.nuevaIncidencia.fin = fechaCV(this.finPersonalizado);

    if (this.nuevaIncidencia.inicio < new Date(2000, 0, 1)) {
      mostrarSwalToast(`La fecha de inicio de la nueva incidencia (${fFecha(this.nuevaIncidencia.inicio, 'fsl')}) no es válida`, 'error');
      return;
    }

    if (this.nuevaIncidencia.fin > new Date(new Date().getFullYear() + 10, 0, 1)) {
      mostrarSwalToast(`La fecha de terminación de la nueva incidencia (${fFecha(this.nuevaIncidencia.inicio, 'fsl')}) no es válida`, 'error');
      return;
    }

    if (this.nuevaIncidencia.inicio > this.nuevaIncidencia.fin) {
      mostrarSwalToast(`La fecha de terminación de la nueva incidencia (${fFecha(this.nuevaIncidencia.inicio, 'fsl')}) no es válida, es menor a la fecha de inicio`, 'error');
      return;
    }

    if (!this.nuevaIncidencia.tipoManejoIncidencia) {
      mostrarSwalToast(`Debe seleccionar un Tipo de Movimiento para la nueva incidencia`, 'error');
      return;
    }

    if (this.nuevaIncidencia.tipoManejoIncidencia == TipoManejoIncidencia.Incapacidad) {
      if (!this.nuevaIncidencia.tipoIncapacidad) {
        mostrarSwalToast(`Debe seleccionar un Tipo de Incapacidad para la nueva incidencia`, 'error');
        return;
      }


      if (!this.nuevaIncidencia.observaciones) {
        mostrarSwalToast(`Debe especificar un folio para la Incapacidad de la nueva incidencia`, 'error');
        return;
      }
    }

    this.nuevaIncidencia.idEmpresa = this.empresa.id;
    this.nuevaIncidencia.idEmpleado = 0;

    this.estaCargando = true;
    this.accesoDatosService.incidenciasUsuarioMasivoGuardar(this.empleados, this.nuevaIncidencia).subscribe(
      (respuesta: IRespuestaChecker) => {
        if (respuesta.code == 100) {
          mostrarSwalToast(`Datos guardados correctamente. ${respuesta.mensaje}`, 'success');
          this.cerrarModulo();
          return;
        }
        mostrarSwalError('Validación de Incidencias', `Error al guardar las incidencias, respuesta del servidor: ${respuesta.code}, ${respuesta.mensaje}`)
      },
      (error) => {
        let err: string = JSON.stringify(error);
        this.accesoDatosService.logAgrega2(err);
        mostrarSwalError('Validación de Incidencias', `Error al guardar las incidencias, ${err}`)
      }
    );

  }

  public cerrarModulo() {
    this.dialogRef.close();
  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  public toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  public checkboxLabel(row?: IEmpleados): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.numero + 1}`;
  }

  public formatoFechaCorto(fechaTxt: Date | undefined): string {
    if (!fechaTxt) {
      return '-';
    }
    const fecha = new Date(fechaTxt);
    return fFecha(fecha, "fc");
  }

  public cerrarIncidencias() {
    this.cerrarIncidenciasMasivas.emit();
  }

  ngOnInit() {
    this.aplicarFiltros();
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => value ? (typeof value === 'string' ? value : this.nombreCompleto(value)) : ''),
      map(name => name ? this._filter(name) : this.empleadosTotales.slice())
    );

  }
}
