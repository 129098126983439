<div *ngIf="cargando">
  <div class="text-center py-5">
      <mat-spinner class="img-center"></mat-spinner>
  </div>
  <div class="text-center py-5">
    <span>Cargando datos...</span>
  </div>
</div>

<div *ngIf="!cargando" class="overflow-hidden pb-3">
  <div class="m-3 opacity-50">
    <h4 class="mb-0">{{ nombreCompleto() }} datos del <b>registro de incidencias</b></h4>
  </div>
  <div class="d-flex justify-content-between align-items-center mb-3 mx-3 text-start">
    <div class="col-md-6">
      <label class="form-label mb-0">Mostrar vista de tarjetas</label>
      <select class="form-select" aria-label="Default select example" [(ngModel)]="seleccionEmpleados" (change)="onChangeVistaEmpleado()">
        <option *ngFor="let opcion of opcionesVistaEmpleado | keyvalue" [value]="opcion.key">
          {{ opcion.value }}
        </option>
      </select>
    </div>
    <button mat-raised-button class="text-danger" (click)="toggleMostrarRegistroIncidencias()">
      <p class="mb-0">Cerrar registros</p>
    </button>
  </div>

  <div class="text-start d-flex mb-3 mx-3">
    <div class="col-4">
      <label for="fecha" class="form-label mb-0">Periodo</label>
      <select class="form-select form-select-sm" aria-label="Default select example" [(ngModel)]="periodo" (change)="cambiarPeriodo()">
        <option *ngFor="let tipoPeriodo of tipoPeriodoReporte | keyvalue: valuePeriodoAscOrder" [value]="tipoPeriodo.key">
          {{ tipoPeriodo.value }}
        </option>
      </select>
    </div>
    <!-- ================================== periodo personalizado ================================== -->
    <div class="col-4 mx-2" *ngIf="periodo == 'personalizado'">
      <div class="example-form">
        <label for="filtroEmpleado" class="form-label mb-0">Periodo personalizado</label>
        <div class="d-flex align-items-center form-control form-control-sm" style="height: 31px;" (click)="picker.open()">
          <mat-date-range-input [formGroup]="rangoPersonalizado" [rangePicker]="picker">
            <input matStartDate formControlName="desde" placeholder="Desde" readonly>
            <input matEndDate formControlName="hasta" placeholder="Hasta" readonly>
          </mat-date-range-input>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="empleado.tieneDatosRF" class="row mx-auto">
    <div *ngFor="let imagen of datosRF.datos | keyvalue: valueDescOrder; let i = index" [ngClass]="{ 'col-6': vista2Cards, 'col-4': vista3Cards, 'col-3': vista4Cards, 'col-2': vista6Cards, 'col-1': vista12Cards }">
      <mat-card class="mb-3">
        <mat-card-header>
          <img src="{{'data:image/jpg;base64,' + imagen.value.datos}}" class="img-fluid" height="250" />
        </mat-card-header>
        <mat-card-content class="text-start">
          <small class="mt-2">{{formatoFecha(imagen.value.fechaTxt)}}</small>
        </mat-card-content>
        <mat-card-actions class="d-flex justify-content-end">
          <button mat-raised-button class="text-primary bg-secondary-subtle mx-1" (click)="verDetalles(imagen.value)"><mat-icon class="m-0">info</mat-icon></button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
  <div class="d-flex justify-content-center my-5" *ngIf="!empleado.tieneDatosRF">
    <img class="opacity-50" src="assets/images/face.png" alt="" height="250"/>
  </div>
</div>

<div class="bg-danger rounded d-sm-inline-block d-none justify-content-center mt-4 mt-md-1 centro col-12" *ngIf="error">
  <div class="justify-content-center">
    <p class="text-white fs-6 mt-2">{{error}}</p>
  </div>
</div>
<div id="uno" class="bg-primary rounded d-sm-inline-block d-none p-2 justify-content-center mt-4 mt-md-1 centro col-12 col-md-7" *ngIf="mensaje">
  <div class="align-items-start justify-content-center">
    <span class="text-white fs-6">{{mensaje}}</span>
  </div>
</div>

