import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NomiExpressApiService } from 'src/app/core/services/NomiExpress.api.service';
import { AccesoDatosService } from 'src/app/core/services/acceso-datos.service';
import { IEmpleados, nombreEmpleado } from 'src/app/models/empleados';
import { IEmpresa } from 'src/app/models/empresa';
import { IHorarios } from 'src/app/models/horarios';

@Component({
  selector: 'app-checador-tiempo-real',
  templateUrl: './checador-tiempo-real.component.html',
  styleUrls: ['./checador-tiempo-real.component.scss']
})
export class ChecadorTiempoRealComponent implements OnInit {
  showFiller = true;
  public empresa: IEmpresa | undefined = undefined;
  public empleados: IEmpleados[] = [];
  public horarioSeleccionado: IHorarios | undefined = undefined;

  constructor(
    private accesoDatosService: AccesoDatosService,
    private nomiExpressApi: NomiExpressApiService,
  ) {
    this.cargarDatos();
  }


  private cargarDatos() {
    this.accesoDatosService.empresaObtener().subscribe(
      (empresa: IEmpresa) => {
        // if (this.accesoExpress.debug) {
        //   this.ponerTxtEnConsola(`Empresa obtener. ${JSON.stringify(empresa)}`);
        // }
        this.empresa = empresa;
        // console.log(`vigenteHasta: ${this.empresa.vigenteHastaFecha} ${this.vigenteHastaTxt}, Logo: ${empresa.imageUrl}`);
      },
      (error: HttpErrorResponse) => {
        this.ponerTxtEnConsola(`Conectando con el servidor. Empresa obtener. Error.`);
        let err: string = JSON.stringify(error);
        this.ponerTxtEnConsola(err);
      }
    );

    this.accesoDatosService.empleadosListado().subscribe(
      (empleados: IEmpleados[]) => {
        this.empleados = empleados;
      },
      (error) => {
        this.accesoDatosService.logAgrega2("Error al cargar los datos de los empleados");
        console.error(error);
      }
    );
  }

  private ponerTxtEnConsola(txt: string) {
    console.log(txt);
    this.nomiExpressApi.logAgrega(txt);
  }

  public nombreCompleto(empleado: IEmpleados){
    return nombreEmpleado(empleado);
  }

  ngOnInit(): void {}
}
