import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfigAvanzadaComponent } from 'src/app/core/components/config-avanzada/config-avanzada.component';
import { PasswordComponent } from 'src/app/core/components/password/password.component';
import { addSeconds, fFecha } from 'src/app/core/Funciones/fFecha';
import { NomiExpressApiService } from 'src/app/core/services/NomiExpress.api.service';
import { AccesoExpress } from 'src/app/models/accesoExpress';

@Component({
  selector: 'app-fecha-no-sincronizada',
  templateUrl: './fecha-no-sincronizada.component.html',
  styleUrls: ['./fecha-no-sincronizada.component.scss']
})
export class FechaNoSincronizadaComponent implements OnInit {
  public fechaHoraActual: Date = new Date();
  public fechaHoraServidor: Date = new Date();
  public accesoExpress: AccesoExpress;
  private clicConfigAvanzada: boolean = false;
  public verConfigAvanzada: boolean = false;

  constructor(
    private nomiExpressApi: NomiExpressApiService, 
    private readonly _router: Router,
    private dialog: MatDialog) {
    this.accesoExpress = this.nomiExpressApi.accesoExpress();
    this.fechaHoraActual = new Date();
    this.fechaHoraServidor = addSeconds(this.fechaHoraActual, this.accesoExpress.offSetFromServer * -1);
  }

  public formatoFecha(fecha: Date | undefined) {
    if (!fecha) {
      return '-';
    }
    return fFecha(fecha, "fmhs")
  }

  public ConfigAvanzada() {
    if (this.clicConfigAvanzada) {
      this.verConfigAvanzada = true;
      return;
    }
    this.clicConfigAvanzada = true;
  }

  public confirmarPass() {
    const password = this.dialog.open(PasswordComponent, {
      data: 'Confirmar cerrar sesión',
      width: '33rem',
      height: 'auto'
    });

    password.componentInstance.verificarPasswordEvent.subscribe((resultado: string) => {
      if (resultado !== 'verificadaPwd') return;      
      password.close();

      const config = this.dialog.open(ConfigAvanzadaComponent, {
        data: undefined,
        width: '50rem',
        height: '22rem',
      });
  
      config.afterClosed().subscribe((resultado) => {        
        if (resultado && resultado == 'log') {
          this._router.navigate(['log']);
          return;
        }
        this._router.navigate([``]);
      });
    });
  }

  ngOnInit(): void {
    setInterval(() => {
      this.fechaHoraActual = new Date();
      this.fechaHoraServidor = addSeconds(this.fechaHoraActual, this.accesoExpress.offSetFromServer * -1);
    }, 1000);
  }
}
