import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { LogLevel } from '@microsoft/signalr';
import { HubConnection } from '@microsoft/signalr/dist/esm/HubConnection';
import { HubConnectionBuilder } from '@microsoft/signalr/dist/esm/HubConnectionBuilder';
import { environment } from 'src/environments/environment';
import { NomiExpressApiService } from './NomiExpress.api.service';
import { AccesoExpress } from 'src/app/models/accesoExpress';

@Injectable({
    providedIn: 'root'
})
export class AccessHubService {

    public accesoExpress: AccesoExpress;
    private debug: boolean = false;

    private _hubConnection: HubConnection = new HubConnectionBuilder()
        .withUrl(`${environment.hubUrl}/accessHub`)
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build();

    constructor(
        private readonly _httpClient: HttpClient,
        private nomiExpressApi: NomiExpressApiService
      ) {
        this.accesoExpress = this.nomiExpressApi.accesoExpress();
       }

    connectionState: IConnectionState = { id: ':)', state: 'disconnected' };
    connectionStateSubject = new EventEmitter<IConnectionState>();
    onUserAccess = new EventEmitter<TimeToken>();
    onTokenValidationRequested = new EventEmitter<SecretValidation>();
    isListeningToUserAccess = false;

    connect() {
        if (this.debug) console.log('accessHub, Inicializando');
        this.createConnection();
        this.registerEvents();
        this.startConnection();
    }

    private createConnection() {
        if (this.debug) console.log('accessHub, Creando conexión');
        this._hubConnection = new HubConnectionBuilder()
            .withUrl(`${environment.hubUrl}/accessHub`)
            .configureLogging(LogLevel.Information)
            .withAutomaticReconnect()
            .build();
    }

    private registerEvents() {
        this._hubConnection.on('access', (model: TimeToken) => {
            if (this.debug) console.log('accessHub, acceso');
            if (this.accesoExpress.debug) {
                console.log('_hubConnection.on ==> access', model);
            }
            this.onUserAccess.emit(model);
        });

        this._hubConnection.on('token', (model: SecretValidation) => {
            if (this.debug) console.log('accessHub, validar token');
            this.onTokenValidationRequested.emit(model);
        })

        this._hubConnection.onreconnecting((error) => {
            if (this.debug) console.log('accessHub, reconectando');
            this.connectionState = { id: ':)', state: 'connecting' }
            this.connectionStateSubject.emit(this.connectionState);
        });

        this._hubConnection.onreconnected((error) => {
            if (this.debug) console.log('accessHub, reconectado');
            this.onConnectionSucceeded();
        });

        this._hubConnection.onclose(() => {
            if (this.debug) console.log('accessHub, desconectado');
            this.connectionState = { id: ':)', state: 'disconnected' };
            this.connectionStateSubject.emit(this.connectionState);
            this.keepTryingStartConnection();
        });
    }

    private startConnection(): void {
        if (this.debug) console.log('accessHub, iniciando conexión');
        this._hubConnection
            .start()
            .then(() => {
                this.onConnectionSucceeded();
            })
            .catch(err => {
                this.connectionState = { id: ':)', state: 'disconnected' };
                this.connectionStateSubject.emit(this.connectionState);
                this.keepTryingStartConnection();
            });
    }

    private keepTryingStartConnection() {
        if (this.connectionState.state == 'connected') return;        
        setTimeout(() => {
            this._hubConnection
                .start()
                .then(() => {
                    this.onConnectionSucceeded();
                }).catch(error => {
                    this.keepTryingStartConnection();
                })
        }, 10000);
    }

    private onConnectionSucceeded() {     
        if (this.debug) console.log('accessHub, conectado');   
        this.connectionState = { id: this._hubConnection.connectionId || '', state: 'connected' };
        this.connectionStateSubject.emit(this.connectionState);        
        this._httpClient.post(
            `${environment.hubUrl}/access/subscribeToGroup/${this._hubConnection.connectionId}/` + 
            `${this.accesoExpress.ownerId}.${this.accesoExpress.companyId}?terminalId=${this.accesoExpress.clientId}`, {})
            .subscribe();
    }
}

export interface IConnectionState {
    id: string;
    state: 'connected' | 'connecting' | 'disconnected';
}

export class TimeToken {
    serverTime: string = '';
    code: string = '';
    time: string = '';
    token: string = '';
    connectionId: string = '';
    replyTo: string = '';
    replyToken: string = '';
}

export class SecretValidation {
    terminalId: string = '';
    secret: string = '';
    replyTo: string = '';
    state: string = '';
}