import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { mostrarSwalConfirm, mostrarSwalError } from 'src/app/core/Funciones/funciones';
import { AccesoDatosService } from 'src/app/core/services/acceso-datos.service';
import { IEmpleadoImagenRF } from 'src/app/models/accesoCF';
import { IRespuestaEmpresaEmpleados, IRespuestaImagenesRF } from 'src/app/models/documentos';
import { $tipoFoto, IEmpleados, nombreEmpleado } from 'src/app/models/empleados';
import { IEmpresa } from 'src/app/models/empresa';
import { IRespuestaChecker } from 'src/app/models/resultadoActualiza';

@Component({
  selector: 'app-empleados-imagenes-registro-rf',
  templateUrl: './empleados-imagenes-registro-rf.component.html',
  styleUrls: ['./empleados-imagenes-registro-rf.component.scss']
})
export class EmpleadosImagenesRegistroRFComponent implements OnInit {
  @Output()
  public cerrarRegistroRF = new EventEmitter<void>();

  public empresa!: IEmpresa;
  public empleados: IEmpleados[] = [];
  public empleadosTotales: IEmpleados[] = [];
  public empleadoSeleccionado: IEmpleados | undefined = undefined;
  public imagenesRegistradas: IEmpleadoImagenRF[] = [];

  public estaCargando = true;
  public tipoRegistro = $tipoFoto;
  public mostrarBajas: boolean = false;

  constructor(
    private accesoDatosService: AccesoDatosService,
  ) {
    this.cargaInicial();
  }

  public cargaInicial() {
    this.estaCargando = true;
    this.accesoDatosService.empresaEmpleados().subscribe(
      (respuesta: IRespuestaEmpresaEmpleados) => {
        if (respuesta.code != 100) {
          mostrarSwalError('Empresa', `Respuesta del servidor. Código: ${respuesta.code}, ${respuesta.mensaje}`);
          return;
        }

        if (!respuesta.empresa) {
          mostrarSwalError('Empresa', `No se pueden obtener los datos de la empresa`);
          return;
        }

        this.cargarEmpleados();
        this.empresa = respuesta.empresa;
        this.cargarDatos();
      },
      (err) => {
        this.accesoDatosService.logAgrega2("Error al cargar los datos de los empleados");
        console.error(err);
        this.empleados = [];
        mostrarSwalError('Documentos', `Error al cargar los datos de los documentos desde el servidor. Código: ${err}`);
      }
    );
  }

  public cargarDatos() {
    let idEmpleado: number = !this.empleadoSeleccionado || !this.empleadoSeleccionado.id ? 0 : +this.empleadoSeleccionado.id;
    this.estaCargando = true;
    this.accesoDatosService.imagenesRegistroRF(idEmpleado).subscribe(
      (respuesta: IRespuestaImagenesRF) => {
        if (respuesta.code != 100) {
          mostrarSwalError('Imágenes RF', `Respuesta del servidor. Código: ${respuesta.code}, ${respuesta.mensaje}`);
          return;
        }

        this.imagenesRegistradas = respuesta.empleadoFotos;
        console.log(`imagenesRegistradas servidor`, respuesta.empleadoFotos);
        this.estaCargando = false;
      },
      (err) => {
        this.accesoDatosService.logAgrega2("Error al cargar los datos de los empleados");
        console.error(err);
        this.empleados = [];
        mostrarSwalError('Documentos', `Error al cargar los datos de los documentos desde el servidor. Código: ${err}`);
      }
    );
  }

  public cargarEmpleados() {
    this.accesoDatosService.empleadosListado().subscribe(
      (empleados: IEmpleados[]) => {
        this.empleados = empleados;
        this.empleadosTotales = empleados;
        this.aplicarFiltros();
      },
      (error) => {
        this.accesoDatosService.logAgrega2("Error al cargar los datos de los empleados");
        console.error(error);
        this.empleados = [];
        this.empleadosTotales = [];
      }
    );
  }

  public aplicarFiltros() {
    this.empleados = this.empleadosTotales;

    if (this.mostrarBajas) {
      this.empleados = this.empleadosTotales;
      return;
    }
    this.empleados = this.empleadosTotales.filter(x => !x.baja || x.baja.getTime() <= new Date(1900, 1, 1).getTime()).sort(
      (a, b) => +a.numero - +b.numero || a.nombre?.toLowerCase().localeCompare(b.nombre?.toLowerCase())
    );
  }

  public cerrarRegistroImagenesRF() {
    this.cerrarRegistroRF.emit();
  }

  public nombreCompleto(empleado: IEmpleados): string {
    return nombreEmpleado(empleado);
  }

  public limpiarFiltro(): void {
    const cargarIncidencias = true;
    this.empleadoSeleccionado = undefined;
    this.cargarDatos();
  }

  public abrirImagen(imagen: IEmpleadoImagenRF) {
    if (!imagen.datos) {
      mostrarSwalError('Imágenes de RF', 'No se tienen datos de la imagen, no es posible abrirla');
      return;
    }

      let w = window.open("");
      var image = new Image();
      image.src = 'data:image/jpg;base64,' + imagen.datos;
      if (!w) return;
      w.document.write(image.outerHTML);
  }

  public eliminarImagen(imagen: IEmpleadoImagenRF) {
    this.estaCargando = true;
    this.accesoDatosService.depurarImagenesRegistroRF(10).subscribe(
      (respuesta: IRespuestaChecker) => {
        mostrarSwalConfirm(`Respuesta del servidor: ${respuesta.code}, ${respuesta.mensaje}`, 'info');
        this.cargarDatos();
      },
      (err) => {
        this.accesoDatosService.logAgrega2("Error al cargar los datos de los empleados");
        console.error(err);
        this.empleados = [];
        mostrarSwalError('Documentos', `Error al cargar los datos de los documentos desde el servidor. Código: ${err}`);
        this.estaCargando = false;
      }
    );
  }

  ngOnInit(): void {
  }

}
