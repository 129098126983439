import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './core/components/login/login.component';
import { InicioComponent } from './core/components/inicio/inicio.component';
import { AccesoComponent } from './core/components/acceso/acceso.component';
import { ErrorEnDatosComponent } from './core/components/error-en-datos/error-en-datos.component';
import { LogComponent } from './core/components/log/log.component';
import { RecargarComponent } from './core/components/recargar/recargar.component';
import { DatosEmpresaComponent } from './core/components/empresa/datos-empresa/datos-empresa.component';
import { InicioEmpresaComponent } from './core/components/empresa/inicio-empresa/inicio-empresa.component';
import { EmpleadosComponent } from './core/components/empresa/empleados/empleados.component';
import { IncidenciasComponent } from './core/components/empresa/incidencias/incidencias.component';
import { HorariosComponent } from './core/components/empresa/horario/horarios/horarios.component';
import { PaginaNoEncontradaComponent } from './core/components/pagina-no-encontrada/pagina-no-encontrada.component';
import { DatosEmpleadoComponent } from './core/components/empresa/datos-empleado/datos-empleado.component';
import { ConfigEmpresaComponent } from './core/components/empresa/config-empresa/config-empresa.component';
import { CodigoAccesoEmpleadoComponent } from './core/components/empresa/codigo-acceso-empleado/codigo-acceso-empleado.component';
import { AccesoBiComponent } from './acceso/components/acceso-bi/acceso-bi.component';
import { RegistroIncidenciasComponent } from './empleados/registro-incidencias/registro-incidencias.component';
import { NoVigenteComponent } from './empresa/no-vigente/no-vigente.component';
import { FechaNoSincronizadaComponent } from './empresa/fecha-no-sincronizada/fecha-no-sincronizada.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'login/:id', component: LoginComponent },
  { path: 'login/:id/llave/:llave', component: LoginComponent },
  { path: 'inicio', component: InicioComponent },
  { path: 'acceso-bi', component: AccesoBiComponent },
  { path: 'error', component: ErrorEnDatosComponent },
  { path: 'log', component: LogComponent },
  { path: 'recargar', component: RecargarComponent },
  { path: 'datos-empresa', component: DatosEmpresaComponent },
  { path: 'inicio-empresa', component: InicioEmpresaComponent },
  { path: 'empleados', component: EmpleadosComponent },
  { path: 'incidencias', component: IncidenciasComponent },
  { path: 'horarios', component: HorariosComponent },
  { path: 'no-vigente', component: NoVigenteComponent },
  { path: 'fecha-no-sincronizada', component: FechaNoSincronizadaComponent },
  { path: '', component: AccesoComponent, pathMatch: 'full' },
  { path: '**', component: PaginaNoEncontradaComponent },
  { path: 'datos-empleado', component: DatosEmpleadoComponent },
  { path: 'config-empresa', component: ConfigEmpresaComponent },
  { path: 'codigo-acceso-empleado', component: CodigoAccesoEmpleadoComponent },
  { path: 'registro-incidencias', component: RegistroIncidenciasComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
