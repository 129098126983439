<div [ngClass]="{'fullscreen': esPantallaCompleta}">
    <div class="text-center mx-8">
      <div class="px-3 shadow bg-white row">
        <div class="col-3">
          <a href="">
            <img
            routerLink="{{urlBase()}}"
            src="assets/images/ACE_Acceso-Express.png"
            width="120"
            height="120"
            class="pt-2 img-fluid"/>
          </a>
        </div>
        <div class="col-6">
          <div class="col-sm-12" (click)="mandarPagina()">
            <h2 class="mt-4 mb-0 text-primary f-sm-9 fw-medium lh-10">ASESOR CONTABLE EXPRESS</h2>
          </div>
          <div class="d-md-flex d-inline-block justify-content-center mb-0">
            <div>
              <h4 class="pt-0 pb-1 mb-0 text-center">
                <a class="text-center text-danger text-decoration-none fs-6 fw-medium ms-8"
                  href="https://asesorcontable.mx/" target="_blank">www.asesorcontable.mx
                </a>
              </h4>
            </div>
            <div class="me-9 d-none d-md-flex">
              <mat-icon class="pt-1 ms-4 ms-md-3 text-danger">support_agent </mat-icon>
              <p class="ms-1 pt-2">
                <a class="text-decoration-none text-danger me-5 me-md-0" href="tel:3331231565">33-3123-1565</a>
              </p>
            </div>
          </div>
        </div>
        <div class="col-3" (click)="mandarPagina()">
          <img
            src="assets/images/logo.png"
            style="width: 80px; height: 55px"
            class="pt-3 img-fluid"  *ngIf="accesoExpress.imagenUrl"/>
        </div>
      </div>
    </div>

    <!-- ============================================ parte azul ============================================== -->
    <div class="connection-state rounded-bottom shadow bg-primary mx-3 h-3">
      <div class="px-3 row">
        <div class="col-md-3 col-4 d-flex">
          <ng-container *ngIf="!accesoExpress.isActive">
            <div class="d-flex flex-row justify-content-center py-3">
              <span class="material-icons text-denger"> warning </span>
              <span class="text-denger">Acceso desactivado</span>
            </div>
          </ng-container>

          <!-- ============================================ botón volumen ============================================== -->
          <div class="d-flex flex-row justify-content-center py-3 px-1 px-lg-2 text-white" (click)="cambiarSonido()" *ngIf="this.accesoExpress.habilitarSonido">
            <mat-icon class="mx-0">{{ iconoVolumen }}</mat-icon>
          </div>


          <!-- ============================================ botón cámara ============================================== -->
          <div class="d-flex flex-row justify-content-center py-3 px-1 px-lg-2 text-white" (click)="mostrarFaceIdClick()" *ngIf="this.accesoExpress.faceDetectionEnabled">
            <mat-icon class="mx-0">{{ iconoCamara }}</mat-icon>
          </div>

          <!-- ============================================ botón pantalla completa ============================================== -->
          <ng-container *ngIf="!esPantallaCompleta">
            <div class="d-flex flex-row justify-content-center py-3 px-1 px-lg-2 text-white" (click)="pantallaCompleta()">
              <mat-icon class="mx-0">fullscreen</mat-icon>
            </div>
          </ng-container>
          <ng-container *ngIf="esPantallaCompleta">
            <div class="d-flex flex-row justify-content-center py-3 px-1 px-lg-2 text-white" (click)="salirPantallaCompleta()" [ngClass]="{'me-2': !esPantallaCompleta}">
              <mat-icon class="mx-0">fullscreen_exit</mat-icon>
            </div>
          </ng-container>

          <!-- ============================================ botón configuración en wifi ============================================== -->
          <ng-container *ngIf="connectionState.state == 'connected'; else connecting">
            <div class="d-flex flex-row justify-content-center py-3 px-1 px-lg-2 text-white" (click)="abrirConfig()" title="Conectado y escuchando al servidor">
              <mat-icon class="text-white">wifi</mat-icon>
            </div>
          </ng-container>

          <ng-template #connecting>
            <ng-container *ngIf="connectionState.state == 'connecting'; else disconnected">
              <div class="d-flex flex-row justify-content-center py-3 px-1 px-lg-2" title="Conectando...">
                <mat-icon class="text-white rotate">motion_photos_on</mat-icon>
              </div>
            </ng-container>
            <ng-template #disconnected>
              <div class="d-flex flex-row justify-content-center py-3 px-1 px-lg-2" title="Desconectado" (click)="abrirConfig()">
                <mat-icon class="text-danger">wifi_off</mat-icon>
              </div>
            </ng-template>
          </ng-template>
        </div>

        <!-- ============================================ nombre del punto de acceso ============================================== -->
        <div class="text-center text-white mt-9 col-5 col-md-6">
          <button class="d-none d-sm-inline-block bg-primary text-white border-0" (click)="idEmpleadoActivo = undefined; modoAdmin = 1;">
            <span class="fs-4 fw-medium"> {{ accesoExpress.companyName }}</span>
          </button>
          <button class="d-inline-block d-sm-none bg-primary text-white border-0">
            <small>{{ accesoExpress.companyName }}</small> <small *ngIf="modoFullDebug">({{ accesoExpress.companyId }})</small>
          </button>
          <p>{{ "Acceso: " + accesoExpress.accesoNombre }} {{ modoAdmin > 0 ? 'Admin: Activado' : '' }}</p>
        </div>

        <!-- ============================================ reloj ============================================== -->
        <div class="col-3">
          <h2 class="text-center pt-2 pe-4 pe-md-0 text-white fw-normal"  (click)="idEmpleadoActivo = undefined; modoAdmin = 2;" style="width: 135px">{{ terminalTime }}</h2>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-center renovacion mt-2" *ngIf="renovar" (click)="quitarRenovacion()">
    <span class="text-danger fs-4 fw-medium">{{ renovar }}</span>
  </div>
  <div class="mt-1" id="qr-access" [ngClass]="{ small: true }">
    <div class="text-center">
      <!-- ============================================ cámara ============================================== -->
      <div class="pt-3 col-6 centro d-md-inline-block px-4" id="camara" *ngIf="mostrarFaceID" [ngClass]="{'col-7': !mostrarFaceID}">
        <div id="padre" class="d-inline-block">
          <!-- <img src="assets/images/SinImagen.jpg" alt="" height="400px" [ngClass]="{ camara: mostrarFaceID }"> -->
          <canvas id="face-marks" style="position: absolute; z-index: 1"></canvas>
          <div class="cam-wrapper ms-4 ms-md-0" (window:resize)="onResize($event)">
            <webcam [width]="camaraAncho" [height]="camaraAlto" [allowCameraSwitch]="true" [videoOptions]="videoOptions" [trigger]="invokeObservable" (imageCapture)="captureImg($event)"
            [switchCamera]="nextWebcamObservable"></webcam>
            <!-- [mirrorImage]="'always'"  -->
          </div>
          <div id="uno" class="bg-primary rounded d-sm-inline-block p-2 centro col-12 col-md-7" *ngIf="mensaje">
            <div class="align-items-start">
              <span class="text-white fs-6">{{mensaje}}</span>
            </div>
          </div>
          <div id="uno" class="bg-danger rounded d-sm-inline-block d-none p-2 centro col-12 col-md-7" *ngIf="error">
            <div class="align-items-start">
              <span class="text-white fs-6">{{error}}</span>
            </div>
          </div>
          <div *ngIf="infoCamara && this.accesoExpress.infoReconocimientoFacial">
            <span class="text-muted">{{infoCamara}}</span>
          </div>
        </div>
      </div>

      <!-- ============================================ sin cámara ============================================== -->
      <div class="col-5 centro d-none d-md-inline-block mt-3" *ngIf="!mostrarFaceID">
        <img src="{{accesoExpress.imagenUrl}}" alt="" height="300px" *ngIf="accesoExpress.imagenUrl">
        <img src="../../../../assets/images/logo.png" alt="" [ngClass]="{ camara: mostrarFaceID }" *ngIf="!accesoExpress.imagenUrl">
        <div class="py-3">
          <div class="bg-primary rounded d-sm-inline-block d-none justify-content-center mt-4 mt-md-1 centro col-12" *ngIf="mensaje">
            <div class="justify-content-center">
              <p class="text-white fs-6 mt-2">{{mensaje}}</p>
            </div>
          </div>
          <div class="bg-danger rounded d-sm-inline-block d-none justify-content-center mt-4 mt-md-1 centro col-12" *ngIf="error">
            <div class="justify-content-center">
              <p class="text-white fs-6 mt-2">{{error}}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="float-md-end float-none col-md-4" id="codigoQrDiv" *ngIf="accesoExpress.isActive" [ngClass]="{ 'col-md-5': true }">
        <!-- ============================================ codigo Qr / keypad ============================================== -->
        <div class="rounded bg-white shadow centro mt-3 me-0 me-md-3" *ngIf="!codigoQR || codigoQR == '-'">
          <div class="text-center py-5">
              <mat-spinner class="img-center"></mat-spinner>
          </div>
          <div class="text-center py-5">
              <span>{{proceso}}</span>
          </div>
          <div *ngIf="accesoExpress.debug">
            <span>{{codigoQR}}</span>
          </div>
        </div>

        <!-- ============================================ codigo Qr ============================================== -->
        <div class="rounded bg-white shadow centro mt-3 mx-3" [ngClass]="{ 'd-inline-block': mostrarFaceID }" *ngIf="codigoQR && codigoQR != '-'">
          <qrcode
            [qrdata]="codigoQR"
            [errorCorrectionLevel]="'M'"
            [colorDark]="'#01579bff'"
            [colorLight]="'#fafafa00'"
            [width]="qrAncho">
          </qrcode>

          <div class="text-center shadow rounded bg-danger text-white errores p-2 fs-6" *ngIf="!estaEscuchando">
            <b class="fw-medium" *ngIf="!buscandoConexion">Sin conexión con el servidor</b>
            <b class="fw-medium" *ngIf="buscandoConexion">Validando datos del servidor</b>
          </div>
        </div>

        <!-- ============================================ logo con cámara ============================================== -->
        <div class="col-10 mx-auto py-3 centro d-md-block d-none" *ngIf="mostrarFaceID">
          <div *ngIf="!!accesoExpress.imagenUrl">
            <img src="{{accesoExpress.imagenUrl}}" alt="Logo de la empresa" width="200px">
          </div>
          <div *ngIf="!accesoExpress.imagenUrl">
            <img src="../../../../assets/images/logo.png" alt="Logo ACE" width="200px">
          </div>
        </div>
        <div class="pt-1">

          <!-- ============================================ al estar en vertical ============================================== -->
          <div id="padre" class="col-9 ms-4 py-3 centro d-inline-block d-md-none">
            <div class=" d-inline-block">
              <img src="{{accesoExpress.imagenUrl}}" alt="" width="200px" *ngIf="accesoExpress.imagenUrl">
              <img src="../../../../assets/images/logo.png" alt="" width="200px" *ngIf="!accesoExpress.imagenUrl">
            </div>
            <div class="py-3">
              <div id="uno" class="bg-primary rounded d-md-none d-inline-block p-2 justify-content-center mt-4 mt-md-1 centro col-12 col-md-7" *ngIf="mensaje">
                <div class="align-items-start justify-content-center">
                  <span class="text-white fs-6">{{mensaje}}</span>
                </div>
              </div>
              <div id="uno" class="bg-danger rounded d-md-none d-inline-block p-2 justify-content-center mt-4 mt-md-1 centro col-12 col-md-7" *ngIf="error">
                <div class="align-items-start justify-content-center">
                  <span class="text-white fs-6">{{error}}</span>
                </div>
              </div>>
            </div>
          </div>

          <!-- ============================================ botones ============================================== -->
          <div class="my-3 rounded d-inline-flex fixed-sm-bottom d-none d-lg-block" *ngIf="accesoExpress.isActive">
            <!-- *ngIf="accesoExpress.faceDetectionEnabled" -->
            <button mat-raised-button class="text-primary bg-secondary-subtle me-2" (click)="cambiarSonido()" *ngIf="this.accesoExpress.habilitarSonido">
              <mat-icon class="mx-0">{{ iconoVolumen }}</mat-icon>
            </button>
            <button mat-raised-button class="text-primary bg-secondary-subtle me-2" (click)="mostrarFaceIdClick()" *ngIf="this.accesoExpress.faceDetectionEnabled">
              <mat-icon class="mx-0">{{ iconoCamara }}</mat-icon>
            </button>
            <button mat-raised-button class="text-primary bg-secondary-subtle me-2" (click)="pantallaCompleta()" *ngIf="!esPantallaCompleta">
              <mat-icon class="mx-0">fullscreen</mat-icon>
            </button>
            <button mat-raised-button class="text-primary bg-secondary-subtle me-0" (click)="salirPantallaCompleta()" *ngIf="esPantallaCompleta" [ngClass]="{'me-2': !esPantallaCompleta}">
              <mat-icon class="mx-0">fullscreen_exit</mat-icon>
            </button>
            <button mat-raised-button class="text-primary bg-secondary-subtle" (click)="abrirConfig()" *ngIf="!esPantallaCompleta">
              <mat-icon class="mx-0">settings</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- ============================================ datos de version y otras cosas ============================================== -->
    <div>
      <div class="fixed-bottom text-center fw-light text-secondary">
        <div *ngIf="accesoExpress.mostrarInfoAdd && infoAdd">
          <span class="pe-2">{{infoAdd}}</span>
          <br/>
        </div>
        <span class="pe-2">v {{accesoExpress.version}}</span>
        <span class="pe-2"><mat-icon style="font-size: 15px; padding-top: 10px;">sync</mat-icon>{{fFecha(inicioApp)}}<small class="ml-5">{{mensajeAddInicioApp}}</small></span>
      </div>
    </div>
  </div>
