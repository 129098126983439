<div *ngIf="estaCargando">
  <div class="text-center py-5">
    <mat-spinner class="img-center"></mat-spinner>
  </div>
  <div class="text-center py-5">
    <span>Cargando datos...</span>
  </div>
</div>

<div *ngIf="!estaCargando">
  <div class="d-flex justify-content-end m-3 text-start" [ngClass]="{'justify-content-between': !subirDocumentos && !mostrarDocumentosPorEmpleado }">
    <div class="col-6 px-3" *ngIf="!subirDocumentos && !mostrarDocumentosPorEmpleado">
      <label class="mb-0 text-start">Búsqueda del empleado</label>
      <ng-select [items]="empleados" [(ngModel)]="empleadoSeleccionado" bindLabel="nombreCompleto" (ngModelChange)="buscarEmpleado($event)" (clear)="limpiarFiltro()" notFoundText="Empleado no encontrado"  placeholder="Selecciona un empleado">
        <ng-template ng-option-tmp let-item="item">
          {{ nombreCompleto(item) }}
        </ng-template>
      </ng-select>
    </div>

    <div class="d-flex align-items-end">
      <button mat-raised-button class="text-primary mx-2" (click)="openContrato()">
        <p class="mb-0">Contrato biométrico</p>
      </button>
      <button mat-raised-button class="text-success" (click)="subirDocumentos = !subirDocumentos" *ngIf="!subirDocumentos">
        <p class="mb-0">Subir documentos</p>
      </button>
      <button mat-raised-button class="text-success" (click)="cancelarSubida()" *ngIf="subirDocumentos">
        <p class="mb-0">Cancelar subida</p>
      </button>
      <button mat-raised-button class="text-danger mx-2" (click)="toggleMostrarDocumentos()">
        <p class="mb-0">Volver a empleados</p>
      </button>
      <button mat-raised-button class="text-danger mx-2" (click)="mostrarDocumentosPorEmpleado = !mostrarDocumentosPorEmpleado" *ngIf="mostrarDocumentosPorEmpleado">
        <p class="mb-0">Volver a documentos</p>
      </button>
    </div>
  </div>

  <ng-container class="container mt-5" *ngIf="subirDocumentos">
    <div class="row mx-auto mb-2">

      <div class="col-md-6">
        <mat-card class="bg-light">
          <mat-card-header class="py-1 bg-primary-subtle d-flex justify-content-center border-bottom">
            <mat-card-title>Subir Documentos</mat-card-title>
          </mat-card-header>
          <mat-card-content class="text-start">
            <div class="form-group my-3">
              <label for="employeeName">Nombre del Empleado</label>
              <div class="input-group">
                <input type="text" class="form-control form-select-sm" id="filtroEmpleado" placeholder="Selecciona un empleado" [(ngModel)]="empleadoSeleccionado" [matAutocomplete]="auto" multiple>
              </div>
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let empleado of empleados" [value]="empleado">
                  {{nombreCompleto(empleado)}}
                </mat-option>
              </mat-autocomplete>
            </div>
            <div class="form-group mb-3">
              <label for="employeeDocument">Seleccionar Documento</label>
              <input type="file" class="form-control" id="employeeDocument" (change)="seleccionarArchivo($event)" multiple>
            </div>
          </mat-card-content>
          <mat-card-actions align="end">
            <button mat-raised-button color="primary" (click)="subirArchivos()">Subir Documentos</button>
          </mat-card-actions>
        </mat-card>
      </div>

      <div class="col-md-6">
        <div class="row row-cols-1 row-cols-md-3 g-3 mx-auto">
          <div *ngFor="let file of archivosSubidos; let i = index">
            <mat-card class="text-start bg-body-tertiary">
              <mat-card-header class="pt-2">
                <div mat-card-avatar>
                  <mat-icon [fontIcon]="file.icon"></mat-icon>
                </div>
                <div class="w-100">
                  <mat-card-title class="px-0 h6 text-truncate" [matTooltip]="file.name" style="max-width: 130px;">{{ file.name }}</mat-card-title>
                  <mat-card-subtitle class="px-0 text-truncate" style="max-width: 150px;">{{ formatoFecha(file.fecha) }}</mat-card-subtitle>
                  <mat-card-subtitle class="px-0 text-truncate" style="max-width: 150px;">{{ file.size }}</mat-card-subtitle>
                </div>
              </mat-card-header>
              <mat-card-actions align="end">
                <button mat-button class="text-danger" (click)="eliminarImagen(i)">Eliminar</button>
              </mat-card-actions>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!subirDocumentos && !mostrarDocumentosPorEmpleado">
    <div class="fw-medium fs-4 alert alert-primary mt-3" *ngIf="empleadoNoEncontrado">
      Empleado no encontrado
    </div>

    <div class="row row-cols-1 row-cols-md-3 row-cols-lg-4 g-3 mx-auto">
      <div *ngFor="let empleado of empleados">
        <mat-card class="text-start bg-body-tertiary folder" style="cursor: pointer;" (click)="abrirDocumentosEmpleado(empleado)">
          <mat-card-header class="pt-2 d-flex align-items-center">
            <div mat-card-avatar class="example-header-image">
              <mat-icon fontIcon="folder"></mat-icon>
            </div>
            <mat-card-title class="px-0 h6 col-10 text-truncate" [matTooltip]="nombreCompleto(empleado)">{{ nombreCompleto(empleado) }}</mat-card-title>
            <mat-card-subtitle class="px-0">Documentos: {{ docInfoArray[empleado.id]?.[0]?.cantidad }}</mat-card-subtitle>
            <mat-card-subtitle class="px-0">Ult. mod.: {{ formatoFecha(docInfoArray[empleado.id]?.[0]?.ultimaMod) }}</mat-card-subtitle>
          </mat-card-header>
        </mat-card>
      </div>
    </div>

    <div class="d-flex justify-content-end mt-3">
      <div class="form-check mx-2">
        <input class="form-check-input" type="checkbox" id="mostrarBajas" [(ngModel)]="mostrarBajas" (change)="aplicarFiltros()">
        <label class="form-check-label fw-bold" for="mostrarBajas">Mostrar empleados dados de baja</label>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!subirDocumentos && mostrarDocumentosPorEmpleado">
    <div class="d-flex justify-content-center">
      <h2 class="fw-medium">Documento del empleado {{ nombreCompleto(empleadoSeleccionado) }}</h2>
    </div>
    <div class="row row-cols-1 row-cols-md-3 row-cols-lg-4 g-3 mx-auto mb-2">
      <div *ngFor="let documento of documentosPorEmpleado; let i = index">
        <mat-card class="bg-body-tertiary">
          <mat-card-content>
            <div *ngIf="!!documento.datos && !documento.extension.endsWith('pdf')">
              <img src="{{documento.tipoDocumento + documento.datos}}" width="250" />
            </div>
            <div *ngIf="!!documento.datos && documento.extension.endsWith('pdf')">
              <iframe [src]="documento.urlSegura" width="250" ></iframe>
            </div>
            <div *ngIf="!documento.datos">
              <mat-icon [fontIcon]="tipoIcono(documento.extension)" style="font-size: 200px; width: 200px; height: 11.6rem;"></mat-icon>
            </div>
            <div class="col-11 text-truncate"><small>{{documento.nombreArchivo}}</small></div>
            <div>{{formatoFecha(documento.fecha)}}</div>
          </mat-card-content>
          <mat-card-actions align="end">
            <button mat-raised-button class="mx-1 text-primary" (click)="abrirDoc(documento)">Abrir</button>
            <button mat-raised-button class="mx-1 text-info" (click)="descargarDoc(documento)">Descargar</button>
            <button mat-raised-button class="mx-1 text-danger" (click)="eliminar(documento)">Eliminar</button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </ng-container>
</div>
