<div class="row mx-3">
  <div class="col-12 centro">
    <div class="text-center my-3">
      <img class="img-fluid" src="assets/images/accesoexpress-logo.jpg" style="width: 200px; height: 60px"/>
    </div>
    <h3 class="text-center text-body-tertiary fw-medium fs-5 mb-3">Bienvenido</h3>
  </div>
  <div class="col-xl-2"></div>
  <div class="col-xl-8">
    <div class="bg-white rounded shadow bg-white p-3">
      <p class="text-body-tertiary tracking-in-expand">
        Por favor indica las claves de este punto de acceso. Si aún no tienes
        una clave de acceso, puedes generar una en
        <span class="action text-primary" (click)="navigateTo('https://nomiexpress.com/')">NomiExpress</span>,
        entrando a la empresa que deseas configurar
        <b class="text-primary mx-2">></b> Configuración
        <b class="text-primary mx-2">></b> Reloj checador
        <b class="text-primary mx-2">></b> Habilitar reloj checador (si aún no estaba)
        <b class="text-primary mx-2">></b> Crear nuevo punto de acceso
      </p>

      <!-- ========================================= input punto de acceso ========================================= -->
      <div>
        <mat-form-field appearance="outline" class="col-md-12 col-12 slide-in-left bg-white">
          <mat-label>Identificación del punto de acceso</mat-label>
          <input onClick="this.select();" (input)="ponerNombrePuntoAcceso()" [(ngModel)]="accesoExpress.clientId" (keyup.enter)="focusLLave()" id="inputField1" matInput type="text"autocomplete="off" placeholder="ID"/>
          <button *ngIf="accesoExpress.clientId" (click)="accesoExpress.clientId = ''" matSuffix mat-icon-button aria-label="Clear">
            <mat-icon>close</mat-icon>
          </button>
          <button (click)="pegar(1)" matSuffix mat-icon-button aria-label="Clear">
            <mat-icon>content_paste</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div *ngIf="false">
        <button
          *ngIf="accesoExpress.clientId"
          (click)="focusLLave()"
          enabled="!enProceso"
          class="btn btn-primary bg-perso text-center text-white col-md-12 col-12">
          <div class="d-flex flex-row justify-content-center align-items-center">
            <b class="fw-normal">Ingresar punto de acceso</b>
          </div>
        </button>
      </div>

      <!-- ========================================= input nombre punto de acceso ========================================= -->
      <div class="pt-1" *ngIf="accesoExpress.clientId">
        <mat-form-field appearance="outline" class="col-md-12 col-12 slide-in-left bg-white">
          <mat-label>Nombre del Punto de Acceso</mat-label>
          <input
            readonly="true"
            [(ngModel)]="nombrePuntoAcceso"
            #llave
            onClick="this.select();"
            id="inputField3"
            matInput
            type="text"
            autocomplete="off"
            placeholder="Nombre del Punto de Acceso"
            id="llave"/>
        </mat-form-field>
      </div>

      <!-- ========================================= input llave secreta ========================================= -->
      <div class="pt-1" *ngIf="accesoExpress.clientId">
        <mat-form-field appearance="outline" class="col-md-12 col-12 slide-in-left bg-white">
          <mat-label>Coloca el secreto</mat-label>
          <input #llave onClick="this.select();" [(ngModel)]="llaveSecreta" (keyup.enter)="guardarDatos()" id="inputField2" matInput type="text" autocomplete="off"placeholder="ID"id="llave"/>
          <button *ngIf="llaveSecreta" (click)="llaveSecreta = ''" matSuffix mat-icon-button aria-label="Clear">
            <mat-icon>close</mat-icon>
          </button>
          <button (click)="pegar(2)" matSuffix mat-icon-button aria-label="Clear">
            <mat-icon>content_paste</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <!-- ========================================= botón establecer secreto ========================================= -->
      <button
        *ngIf="llaveSecreta"
        (click)="guardarDatos()"
        enabled="!enProceso"
        class="btn btn-primary navy col-4 text-center h-3 centro">
        <div class="d-flex flex-row justify-content-center align-items-center">
          <mat-icon class="fw-light mx-2">vpn_key</mat-icon>
          <b class="fw-normal">Activar punto de acceso</b>
        </div>
      </button>

      <div class="text-right mt-2 flicker" *ngIf="error">
        <b class="text-danger">{{ error }}</b>
      </div>

      <div class="text-right mt-5" *ngIf="connected == 'si' || connected == 'no' || connected == 'probando'">
        <b class="text-muted" *ngIf="connected == 'probando'">Un momento...</b>
        <b class="text-primary" *ngIf="connected == 'si'">Conectado correctamente</b>
        <b class="text-danger" *ngIf="connected == 'no'">
          Error de conexión, asegúrate que la identificación y secreto sean
          correctos y que tu equipo cuente con una conexión a internet estable,
          por favor intenta de nuevo.
        </b>
      </div>
    </div>

    <!-- ====================================== checkbox mostrar botón QR ====================================== -->
    <div class="form-check float-end mt-3 centro" *ngIf="llaveSecreta">
      <input
        (change)="mostrarBoton = checkboxSeleccionado"
        [(ngModel)]="checkboxSeleccionado"
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"/>
      <label class="form-check-label" for="flexCheckDefault">Mostrar botón código QR</label>
    </div>
  </div>
  <!-- ========================================= botón QR ========================================= -->
  <div class="mt-3 mb-5">
    <button
      *ngIf="!llaveSecreta || mostrarBoton"
      (click)="abrirModalCamara()"
      class="btn btn-primary navy col-4 text-center h-23 centro">
      <div class="d-flex flex-row justify-content-center align-items-center">
        <mat-icon class="fw-light mx-3">qr_code_scanner</mat-icon>
        <b class="fw-normal">Leer código QR</b>
      </div>
    </button>
  </div>

  <div class="" *ngIf="enProceso">
    <div class="text-center py-5">
      <mat-spinner class="img-center"></mat-spinner>
    </div>
    <div class="text-center py-5">
      <span>Validando datos...</span>
    </div>
  </div>

  <div class="fixed-bottom text-right px-2 col-5 col-md-6">
    <span class="fw-light text-secondary">v {{ accesoExpress.version }}</span>
  </div>
</div>
