import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { fFecha } from 'src/app/core/Funciones/fFecha';
import { fNumero } from 'src/app/core/Funciones/fTexto';
import { AccesoDatosService } from 'src/app/core/services/acceso-datos.service';
import { IAccesoEmpleadoRF, IEmpleadoRF, nuevoEmpleadoRF } from 'src/app/models/accesoCF';
import { IAccesoEmpleadoRFData, IEmpleados, nombreEmpleado, nuevoEmpleado } from 'src/app/models/empleados';
import { $tipoPeriodoReporte, $tipoPeriodoReporteOrden } from 'src/app/models/periodos';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-registro-incidencias',
  templateUrl: './registro-incidencias.component.html',
  styleUrls: ['./registro-incidencias.component.scss']
})
export class RegistroIncidenciasComponent implements OnInit {
  public empleado: IEmpleados = nuevoEmpleado();
  public datosRF: IAccesoEmpleadoRF = nuevoEmpleadoRF();
  public tieneDatosRF: boolean = false;
  public cargando: boolean = true;
  public seleccionEmpleados: string = '4';

  public vista2Cards: boolean = false;
  public vista3Cards: boolean = false;
  public vista4Cards: boolean = true;
  public vista6Cards: boolean = false;
  public vista12Cards: boolean = false;

  public opcionesVistaEmpleado = {
    '2': '2 Tarjetas',
    '3': '3 Tarjetas',
    '4': '4 Tarjetas',
    '6': '6 Tarjetas',
    '12': '12 Tarjetas',
  }

  @Output()
  public cerrarRegistroIncidencias = new EventEmitter<void>();

  public error: string = '';
  public mensaje: string = '';

  public periodo: string = 'semana0';
  public tipoPeriodoReporte = $tipoPeriodoReporte;
  public tipoPeriodoReporteOrden = $tipoPeriodoReporteOrden;
  public rangoPersonalizado = new FormGroup({
    desde: new FormControl<Date | null>(null),
    hasta: new FormControl<Date | null>(null),
  });

  constructor (
    private accesoService: AccesoDatosService
  ) {
    this.cargarEmpleado();
  }

  private cargarEmpleado() {
    this.error = '';
    this.mensaje = '';
    this.accesoService.empleadoObtenerRegistroIncidencias().subscribe(
      (empleadoQr: IEmpleadoRF) => {
        this.empleado = empleadoQr.empleado;
        this.datosRF  = empleadoQr.empleadoRF;
        this.tieneDatosRF = !!this.datosRF.rfCode;
        this.cargando = false;
      },
      (error) => {
        console.error(error);
        let err: string = error.error;
        this.accesoService.logAgrega2("Error al cargar el registro de incidencias (1). " + err);
        Swal.fire({
          html: `Error al cargar el registro de incidencias (1)`,
          icon: 'error',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });
      }
    );
  }

  public formatoFecha(fechaTxt: string | undefined): string {
    if (!fechaTxt) {
      return '-';
    }
    const fecha = new Date(fechaTxt);
    return fFecha(fecha, "fsl");
  }

  public formatoFechaHora(fechaTxt: string | undefined): string {
    if (!fechaTxt) {
      return '-';
    }
    const fecha = new Date(fechaTxt);
    return fFecha(fecha, "fmh");
  }

  public verDetalles(datosRF: IAccesoEmpleadoRFData) {
    let isDebugging: boolean = this.accesoService.getModoDebug();
    let txt: string = '';
    let title: string = '';
    if (datosRF.similarity > 0) {
      title = 'Semejanza';
      txt = `${fNumero(datosRF.similarity, '%')}`;
    }
    if (isDebugging && !datosRF.box) {
      title += 'Box'
      txt += `${datosRF.box}`;
    }

    Swal.fire({
      html: `
        <p style="margin: 5px 0; font-weight: bold;">${this.nombreCompleto()}</p>
        <div class="d-flex justify-content-center">
          <p class="mb-0">
            <b class="fw-semibold">Tomada el: </b>${this.formatoFechaHora(datosRF.fechaTxt)}
          </p>
        </div>
        <div class="d-flex justify-content-center">
          <p class="mb-0">
            <b class="fw-semibold">Punto de acceso: </b>${datosRF.deviceId}
          </p>
        </div>
        <div class="d-flex justify-content-center">
          <p class="mb-0">
            <b class="fw-semibold">${title}: </b>${txt}
          </p>
        </div>
      `,
      imageUrl: `data:image/jpg;base64,${datosRF.datos}`,
      imageWidth: 350,
      imageHeight: 'auto',
      showCancelButton: true,
      cancelButtonText: 'Cerrar',
      cancelButtonColor: 'red',
      showConfirmButton: false,
    });
  }

  private ponerTxtEnConsola(txt: string) {
    console.log(txt);
    this.accesoService.logAgrega(txt);
  }

  public nombreCompleto(){
    return nombreEmpleado(this.empleado);
  }

  public ordenarDatos() {
    this.datosRF.datos.sort((a, b) => new Date(a.fechaTxt).getTime() - new Date(b.fechaTxt).getTime());
  }

  public valueDescOrder = (a: KeyValue<string, IAccesoEmpleadoRFData>, b: KeyValue<string, IAccesoEmpleadoRFData>): number => {
    const fechaA = new Date(a.value.fechaTxt);
    const fechaB = new Date(b.value.fechaTxt);
    return fechaB.getTime() - fechaA.getTime();
  };

  public toggleMostrarRegistroIncidencias() {
    this.cerrarRegistroIncidencias.emit();
  }

  public onChangeVistaEmpleado() {
    this.vista2Cards = false;
    this.vista3Cards = false;
    this.vista4Cards = false;
    this.vista6Cards = false;
    this.vista12Cards = false;

    switch (this.seleccionEmpleados) {
      case '2':
        this.vista2Cards = true;
        break;
      case '3':
        this.vista3Cards = true;
        break;
      case '4':
        this.vista4Cards = true;
        break;
      case '6':
        this.vista6Cards = true;
      break;
      case '12':
        this.vista12Cards = true;
      break;
      default:
        console.log('Opción no válida');
    }
  }

  public cambiarPeriodo() {
    // if (this.periodo != 'personalizado') console.log(`cambiarPeriodo. periodo: ${this.periodo}`);

    // let inicio: Date = this.inicioPeriodo;
    // let fin: Date = soloHoy();

    // switch (this.periodo) {
    //   case 'hoy':
    //     inicio = soloHoy();
    //     break;
    //   case 'semana0':
    //     inicio = inicioSemanaActual();
    //     break;
    //   case 'semana1a':
    //     inicio = addDays(inicioSemanaActual(), -7);
    //     fin = addDays(inicio, 6);
    //     break;
    //   case 'quincena0':
    //     inicio = inicioQuincenaActual();
    //     break;
    //   case 'quincena1a':
    //     fin = addDays(inicioQuincenaActual(), -1);
    //     inicio = inicioQuincena(fin);
    //     break;
    //   case 'mes0':
    //     inicio = inicioMesActual();
    //     break;
    //   case 'mes1a':
    //     fin = addDays(inicioMesActual(), -1);
    //     inicio = inicioMes(fin);
    //     break;
    //   case 'personalizado':
    //     inicio = this.rangoPersonalizado.value.desde ? this.rangoPersonalizado.value.desde : this.inicioPeriodo;
    //     fin = this.rangoPersonalizado.value.hasta ? this.rangoPersonalizado.value.hasta : this.finPeriodo;
    //     console.log(`cambiarPeriodo. periodo: ${this.periodo}, ${inicio}, ${fin}`);
    //     break;
    //   default:

    //     return;
    // }

    // if (inicio.getTime() > fin.getTime()) {
    //   fin = inicio;
    // }

    // if (this.inicioPeriodo.getTime() != inicio.getTime() || this.finPeriodo.getTime() != fin.getTime()) {
    //   console.log(`===========>>> Nuevo periodo: ${fFecha(inicio, 'fm')}, ${fFecha(fin, 'fm')} <<<<===========`);
    //   this.inicioPeriodo = inicio;
    //   this.finPeriodo = fin;
    //   this.accesoDatosService.guardarDatosRegistro(this.inicioPeriodo, this.finPeriodo, this.empleadoSeleccionado);
    //   if (this.inicioPeriodo.getTime() < this.fechaBajaMax.getTime()) {
    //     this.cargarDatosInicial();
    //   } else {
    //     this.cargarDatos();
    //   }
    //   return;
    // }
  }

  public valuePeriodoAscOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return this.tipoPeriodoReporteOrden[a.key] - this.tipoPeriodoReporteOrden[b.key];
  };

  ngOnInit(): void {
    this.ordenarDatos();
  }

}
