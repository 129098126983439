import { IEmpleados } from "./empleados";
import { AccesoCF_RespuestaRecognize_Respuesta } from "./accesoCF";

export interface IReconocimientoFacial {
    id: string;
    cara: string;
}

export interface IMinifiedReconocimientoFacial {
    a: string;
    b: string;
}

export function reconocimientoFacialExpandir(reconocimientoFacial: IMinifiedReconocimientoFacial): IReconocimientoFacial {
    return {
        id: reconocimientoFacial.a,
        cara: reconocimientoFacial.b
    }
}

export class FaceScan {
    constructor(
        public label: string,
        public time: Date
    ) {
    }

    public employee: IEmpleados | undefined;
    public alreadyRegisteredWarningTime: Date | undefined;
}

export interface IReconocimientoFacialRespuesta {
    employeeId: string;
    face: string;
    lastUpdate: string;
}

export interface CheckerInfoEmpleados {
    idEmpresa: number,
    idUsuario: string,
    idEmpleado: number,
    info: string,
    fecha: string,
    tipoChecada: string,
    similitud: number,
    resultados: AccesoCF_RespuestaRecognize_Respuesta | undefined
  }

  export const ServidoresCF = {
    'CF1': 'Servidor CF1',
    'NM1': 'Servidor NomiExpress',
  };

  export const ManejoAcceso = {
    'CF': 'Reconocimiento Facial por foto',
    'BI': 'Reconocimiento Facial con biométricos ',
  };
