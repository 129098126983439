import { Injectable } from '@angular/core';
import * as faceApi from 'face-api.js';
import { NomiExpressApiService } from './NomiExpress.api.service';
import { AccesoExpress } from 'src/app/models/accesoExpress';
import { IReconocimientoFacial, IReconocimientoFacialRespuesta } from 'src/app/models/reconocimientoFacial';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FaceApiService {
    private _accesoExpress: AccesoExpress;
    public isFaceDetectionLoading = false;
    public isFaceDetectionLoaded = false;
    private debug: boolean = false;

    constructor(
        private readonly _nomiExpressApi: NomiExpressApiService
    ) {
        this._accesoExpress = this._nomiExpressApi.accesoExpress();
        this.debug = this._accesoExpress.debug;
    }

    public recargar() {
        this._accesoExpress = this._nomiExpressApi.accesoExpress();
        this.debug = this._accesoExpress.debug;
    }

    private _faceMatcher: faceApi.FaceMatcher | undefined;

    public async detectSingleFace(input: faceApi.TNetInput) {
        return faceApi.detectSingleFace(input, this.getOptions())
            .withFaceLandmarks()
            .withFaceExpressions()
            .withFaceDescriptor();
    }

    public async detectAllFaces(input: faceApi.TNetInput) {
        return faceApi.detectAllFaces(input, this.getOptions())
            .withFaceLandmarks()
            .withFaceExpressions()
            .withFaceDescriptors();
    }

    public async findBestMatch(descriptor: Float32Array | undefined) {
        if (!this._faceMatcher || !descriptor) return undefined;
        return this._faceMatcher.findBestMatch(descriptor);
    }

    public addAndSaveLabeledDescriptor(labeledDescriptors: faceApi.LabeledFaceDescriptors): Observable<IReconocimientoFacialRespuesta> {
        if (!this.agregarLabeledDescriptor(labeledDescriptors)) return of({ employeeId: '0', face: '', lastUpdate: '' });

        let faceObject = JSON.stringify(labeledDescriptors.toJSON());
        if (this.debug) this.ponerTxtEnConsola(`addAndSaveLabeledDescriptor ==> registerFace`);
        return this._nomiExpressApi.registerFace(+labeledDescriptors.label, { employeeId: labeledDescriptors.label, face: faceObject });
    }

    public agregarLabeledDescriptor(labeledDescriptors: faceApi.LabeledFaceDescriptors): boolean {
        if (!labeledDescriptors) {
            if (this.debug) this.ponerTxtEnConsola(`addAndSaveLabeledDescriptor --> sin datos`);
            return false;
        }

        if (this.debug) this.ponerTxtEnConsola(`++ addAndSaveLabeledDescriptor`);
        if (!this._faceMatcher) {
            this._faceMatcher = new faceApi.FaceMatcher([labeledDescriptors], this._accesoExpress.distanceThreshold);
        } else {
            for (let index = 0; index < this._faceMatcher.labeledDescriptors.length; index++) {
                const ld = this._faceMatcher.labeledDescriptors[index];
                if (labeledDescriptors.label != ld.label) continue;
                this._faceMatcher.labeledDescriptors.splice(index, 1);
            }
            this._faceMatcher.labeledDescriptors.push(labeledDescriptors);
        }
        return true;
    }

    private loadFaceDescriptors(): 'ok' | 'error' {        
        let reconocimientoFacial: IReconocimientoFacial[] = this._nomiExpressApi.reconocimientoFacial();
        if (!reconocimientoFacial || reconocimientoFacial.length < 1) {
            if (this.debug) this.ponerTxtEnConsola(`loadFaceDescriptors --> sin reconocimientos faciales cargados`);
            return 'error';
        }

        // if (this.debug) this.ponerTxtEnConsola(`loadFaceDescriptors`);
        let reconocimientoFacialJson = reconocimientoFacial.map(x => {
            return faceApi.LabeledFaceDescriptors.fromJSON(JSON.parse(x.cara));
        });

        this._faceMatcher = new faceApi.FaceMatcher(reconocimientoFacialJson, this._accesoExpress.distanceThreshold);
        return 'ok';
    }

    async loadFaceDetection() {
        this.debug = true;
        if (this.isFaceDetectionLoaded) {
            if (this.debug) this.ponerTxtEnConsola(`loadFaceDetection --> isFaceDetectionLoaded`);
            return;
        }
        if (this.isFaceDetectionLoading) {
            if (this.debug) this.ponerTxtEnConsola(`loadFaceDetection --> isFaceDetectionLoading`);
            return;
        }
        
        if (this.debug) this.ponerTxtEnConsola(`isFaceDetectionLoading`);
        this.isFaceDetectionLoading = true;

        // evita un bug en la versión actual...
        faceApi.env.monkeyPatch({
            Canvas: HTMLCanvasElement,
            Image: HTMLImageElement,
            ImageData: ImageData,
            Video: HTMLVideoElement,
            createCanvasElement: () => document.createElement('canvas'),
            createImageElement: () => document.createElement('img')
        });

        const url = `./assets/faceJsModels`;        
        if (!this._accesoExpress.faceApiModel || this._accesoExpress.faceApiModel == 'tinyFace') 
        {
            if (this.debug) this.ponerTxtEnConsola(`isFaceDetectionLoading (1a)--> faceApiModel (tinyFace)`);
            await faceApi.loadTinyFaceDetectorModel(url)
            .then( () => this.ponerTxtEnConsola(`isFaceDetectionLoading (1a)--> faceApiModel (tinyFace) cargado`) )
            .catch( (reason) => {
                let txt: string = JSON.stringify(reason);
                if (!txt || txt == null) {
                    txt = reason.toJSON();
                    this.ponerTxtEnConsola(`isFaceDetectionLoading (1a)--> ${txt} error (2)`);
                } else {
                    this.ponerTxtEnConsola(`isFaceDetectionLoading (1a)--> ${txt} error`);
                }
                this._accesoExpress.faceApiModel = 'Mobilenetv';
                this._nomiExpressApi.guardarAccesoExpress(this._accesoExpress);              
            } );
        }
        else 
        {
            if (this.debug) this.ponerTxtEnConsola(`isFaceDetectionLoading (1b)--> faceApiModel (Mobilenetv)`);
            await faceApi.loadSsdMobilenetv1Model(url)
                .then( () => this.ponerTxtEnConsola(`isFaceDetectionLoading (1b)--> faceApiModel (Mobilenetv) cargado`) )
                .catch( (reason) => {
                    let txt: string = JSON.stringify(reason);
                    if (!txt || txt == null) {
                        txt = reason.toJSON();
                        this.ponerTxtEnConsola(`isFaceDetectionLoading (1b)--> ${txt} error (2)`);
                    } else {
                        this.ponerTxtEnConsola(`isFaceDetectionLoading (1b)--> ${txt} error`);
                    }
                    this._accesoExpress.faceApiModel = 'tinyFace';
                    this._nomiExpressApi.guardarAccesoExpress(this._accesoExpress);
                } );                
        }

        if (this.debug) this.ponerTxtEnConsola(`isFaceDetectionLoading (2)--> loadFaceLandmarkModel`);
        await faceApi.loadFaceLandmarkModel(url);
        await faceApi.loadFaceRecognitionModel(url);
        await faceApi.loadFaceExpressionModel(url);

        if (this.debug) this.ponerTxtEnConsola(`isFaceDetectionLoading (3)--> loadFaceDescriptors`);
        this.loadFaceDescriptors();

        if (this.debug || true) {
            let txt: string = JSON.stringify(faceApi.nets);
            if (txt.length > 100) {
                txt = txt.substring(0, 100) + `... (${txt.length})`;
            }
            if (this.debug) this.ponerTxtEnConsola(`faceApi.nets: ${txt}`);
        }

        this.isFaceDetectionLoaded = true;
    }

    private getOptions() {
        return this._accesoExpress.faceApiModel == 'tinyFace'
            ? new faceApi.TinyFaceDetectorOptions({ 
                scoreThreshold: this._accesoExpress.tinyFaceScoreThreshold 
            })
            : new faceApi.SsdMobilenetv1Options({
                minConfidence: this._accesoExpress.ssdMinConfidence,
                maxResults: 1 
            });
    }

    private ponerTxtEnConsola(txt: string) {
        console.log(txt);
        this._nomiExpressApi.logAgrega(txt);
      }
}
