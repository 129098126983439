import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { fFecha } from '../Funciones/fFecha';
import { IEmpresa } from 'src/app/models/empresa';

@Injectable({
  providedIn: 'root'
})
export class PDFService {
  constructor(private http: HttpClient) {}

  getBase64ImageFromAssets(path: string): Promise<string> {
    return this.http.get(path, { responseType: 'blob' }).toPromise().then(blob => {
      return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result as string);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
  }

  getBase64ImageFromUrl(url: string): Promise<string> {
    return this.http.get(url, { responseType: 'blob' }).toPromise().then(blob => {
      return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result as string);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
  }

  async obtenerImg(empresa: IEmpresa): Promise<{ empresaImageBase64: string, logoAcceso: string }> {
    const imgDefault = 'assets/images/ACE_Acceso-Express.png';
    const imgDefaultBase64 = await this.getBase64ImageFromAssets(imgDefault);

    let empresaImageBase64: string;
    let logoAcceso: string;
    try {
      empresaImageBase64 = await this.getBase64ImageFromUrl(empresa.imageUrl);
      logoAcceso = await this.getBase64ImageFromAssets('./../../assets/images/ACE_Acceso-Express.png');
    } catch (error) {
      console.error('Error al cargar la imagen de la empresa', error);
      empresaImageBase64 = imgDefaultBase64;
      logoAcceso = '';
    }
    return { empresaImageBase64, logoAcceso };
  }

  public formatoFecha(fechaTxt: Date | undefined): string {
    if (!fechaTxt) {
      return '-';
    }
    const fecha = new Date(fechaTxt);
    return fFecha(fecha, "fsl");
  }
}
