
<div class="d-flex justify-content-between align-items-center px-3 border-bottom border-light shadow-sm modal-header-fixed">
  <h2 class="mb-0 ms-2 fw-bold">Leyenda de impresión en el contrato biométrico</h2>
  <button mat-icon-button mat-dialog-close>
    <mat-icon fontIcon="close"></mat-icon>
  </button>
</div>

<div *ngIf="estaCargando">
  <div class="text-center py-5">
    <mat-spinner class="img-center"></mat-spinner>
  </div>
  <div class="text-center py-5">
    <span>Cargando datos...</span>
  </div>
</div>
<div *ngIf="!estaCargando">
  <mat-dialog-content class="overflow-hidden pb-0 pt-2">
    <div class="col-12 py-2">
      <label class="mb-0 text-start">Seleccionar empleado</label>
      <ng-select [items]="empleados" [(ngModel)]="empleadoSeleccionado" bindLabel="nombreCompleto" notFoundText="Empleado no encontrado" placeholder="Selecciona un empleado">
        <ng-template ng-option-tmp let-item="item">
          {{ nombreCompleto(item) }}
        </ng-template>
      </ng-select>
    </div>

    <div class="col-12">
      <div class="d-flex flex-column mb-4">
        <textarea [readOnly]="enEdicion" [(ngModel)]="cuerpoContrato.leyenda" class="form-control" style="min-height: 9lh;"></textarea>
      </div>
    </div>

    <small class="text-black">
      <b class="text-danger">Nota:</b> Puedes editar el texto libremente, pero los elementos entre llaves, como {{ '{empresa}' }}, {{ '{domicilioCompleto}' }}, {{ '{departamento}' }}, y  {{ '{correo}' }}, son campos predefinidos que no deben modificarse, ya que se completarán automáticamente con la información correspondiente.
    </small>
  </mat-dialog-content>

  <mat-dialog-actions class="d-flex justify-content-between">
    <div class="form-check mx-2">
      <input class="form-check-input" type="checkbox" id="nombresUpper" [(ngModel)]="nombresUpper">
      <label class="form-check-label fw-bold" for="nombresUpper">Mostrar nombres en mayúsculas</label>
    </div>

    <div>
      <button mat-raised-button class="text-primary mx-2" (click)="guardar(true)">Descargar documento</button>
      <button mat-raised-button class="text-success" (click)="guardar(false)">Guardar</button>
    </div>
  </mat-dialog-actions>
</div>
