import { SafeResourceUrl } from "@angular/platform-browser";
import { IEmpleados } from "./empleados";
import { IEmpresa } from "./empresa";
import { IEmpleadoImagenRF } from "./accesoCF";

export class EmpleadosDocumentos
{
    public id: number = 0;
    public idEmpresa: number = 0;
    public idDepartamento: number = 0;
    public idEmpleado: number = 0;
    public agrupador: string = '';
    public fecha: Date = new Date(1900, 0, 1);
    public archivo: string = '';
    public tipoDocumento: string = '';
}

export class EmpleadosContratos
{
    public id: number = 0;
    public idEmpresa: number = 0;
    public idDepartamento: number = 0;
    public idEmpleado: number = 0;
    public agrupador: string = '';
    public tipoContrato: TipoContratos = TipoContratos.ContratoDatosBiometricos;
    public fecha: Date = new Date(1900, 0, 1);
    public archivo: string = '';
}

export class EmpleadosContratosDatos
{
    public id: number = 0;
    public idEmpresa: number = 0;
    public idDepartamento: number = 0;
    public idEmpleado: number = 0;
    public agrupador: string | undefined = undefined;
    public tipoContrato: number = TipoContratos.ContratoDatosBiometricos;
    public fecha: string | undefined = undefined;
    public datos: string = '';
}

export class EmpleadosDocumentoDatos
{
    public id: number = 0;
    public idEmpresa: number = 0;
    public idDepartamento: number = 0;
    public idEmpleado: number = 0;
    public agrupador: string | undefined = undefined;
    public fecha: string | undefined = undefined;
    public datos: string = '';
    public nombreArchivo: string = '';
    public tipoDocumento: string = '';
    public extension: string = '';
    public urlSegura: SafeResourceUrl = '';
}

export class EmpleadosDocumentosDatos
{
    public id: number = 0;
    public idEmpresa: number = 0;
    public idDepartamento: number = 0;
    public idEmpleado: number = 0;
    public agrupador: string | undefined = undefined;
    public fecha: string | undefined = undefined;
    public documentos: Array<{nombreArchivo: string, datos: string, tipoDocumento: string}> = [];
}

export enum TipoContratos
{
    ContratoTrabajo,
    ContratoPrivacidad,
    ContratoDatosBiometricos
}

export class IRespuestaContratos{
    code: number = 99;
    mensaje: string = '';
    empresa: IEmpresa | undefined = undefined;
    contrato: EmpleadosContratos | undefined;
    data: string = ''
}

export class IRespuestaDocumento{
    code: number = 99;
    mensaje: string = '';
    empresa: IEmpresa | undefined = undefined;
    documento: EmpleadosDocumentos | undefined = undefined;
    data: string = ''
}

export class IRespuestaDocumentos{
    code: number = 99;
    mensaje: string = '';
    empresa: IEmpresa | undefined = undefined;
    documentos: EmpleadosDocumentoDatos[] = [];
    empleados: IEmpleados[] = [];
}

export const TipoArchivoEjecutables = ["exe", "bat", "com", "cmd", "inf", "ipa", "osx", "pif", "run", "wsh", "dll"];

export class DocumentosInfo {
  cantidad: number = 0;
  ultimaMod: string | undefined = undefined;
}

export class IRespuestaImagenesRF{
    code: number = 99;
    mensaje: string = '';
    empleadoFotos: IEmpleadoImagenRF[] = [];
}

export class IRespuestaEmpresaEmpleados{
    code: number = 99;
    mensaje: string = '';
    empresa: IEmpresa | undefined = undefined;    
    empleados: IEmpleados[] = [];    
}