export const $tipoPeriodo: { [key: string]: string } = {
  "ENE": "Enero",
  "FEB": "Febrero",
  "MAR": "Marzo",
  "ABR": "Abril",
  "MAY": "Mayo",
  "JUN": "Junio",
  "JUL": "Julio",
  "AGO": "Agosto",
  "SEP": "Septiembre",
  "OCT": "Octubre",
  "NOV": "Noviembre",
  "DIC": "Diciembre",
  "AN": "Anual",
  "U3": "Últimos 3 meses",
  "U2": "Últimos 2 meses"
}

export const $tipoPeriodoOrden: { [key: string]: number } = {
  "AN": 0,
  "U3": 30,
  "U2": 20,
  "ENE": 1,
  "FEB": 2,
  "MAR": 3,
  "ABR": 4,
  "MAY": 5,
  "JUN": 6,
  "JUL": 7,
  "AGO": 8,
  "SEP": 9,
  "OCT": 10,
  "NOV": 11,
  "DIC": 12
}

export const $tipoPeriodoReporte: { [key: string]: string } = {  
    'hoy': 'Hoy',
    'semana0': 'Semana actual',
    'semana1a': 'Semana anterior',
    'quincena0': 'Quincena actual',
    'quincena1a': 'Quincena anterior',
    'mes0': 'Mes actual',
    'mes1a': 'Mes anterior',
    'personalizado': 'Personalizado'
}

export const $tipoPeriodoReporteOrden: { [key: string]: number } = {  
  'hoy': 0,
  'semana0': 10,
  'semana1a': 11,
  'quincena0': 20,
  'quincena1a': 21,
  'mes0': 30,
  'mes1a': 31,
  'personalizado': 99
}