export interface DatosEmpleadosAcceso {
  fotos: string[], 
  ultimaVerificacion: Date,  
  esDesconocido: number, // cuantas veces se ha verificado
  idEmpleado: string | undefined,
  origen: TipoOrigen
};

export enum TipoOrigen {
  NoEspecificado,
  codigoQr,
  reconocimientoFacialLocal,
  reconocimientoFacialGoogle,
  reconocimientoFacialAmazon,
  reconocimientoFacialLocalHeredado,
  reconocimientoFacialServidorACE,
  reconocimientoFacialCF
}

export enum TipoRevisarImagenContraRegistro {
  NoRevisado,
  SinRegistro,
  SinImágenesSuficientes,
  Validado,
  NoValidado
}