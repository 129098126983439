import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { fFecha } from 'src/app/core/Funciones/fFecha';
import { NomiExpressApiService } from 'src/app/core/services/NomiExpress.api.service';
import { AccesoExpress } from 'src/app/models/accesoExpress';
import { IRespuestaChecker } from 'src/app/models/resultadoActualiza';

@Component({
  selector: 'app-puntos-de-acceso-estado',
  templateUrl: './puntos-de-acceso-estado.component.html',
  styleUrls: ['./puntos-de-acceso-estado.component.scss']
})
export class PuntosDeAccesoEstadoComponent implements OnInit {
  public displayedColumns: string[] = ['nombrePuntoAcceso', 'ultimoAcceso'];
  public nombrePuntoAcceso: string = '';
  public accesoExpress: AccesoExpress;
  public ELEMENT_DATA: IRespuestaChecker [] = [];
  public dataSource = ELEMENT_DATA;

  constructor(
    private nomiExpressApi: NomiExpressApiService,
    public dialogRef: MatDialogRef<PuntosDeAccesoEstadoComponent>,
  ) {
    this.accesoExpress = this.nomiExpressApi.accesoExpress();
  }

  public ponerNombrePuntoAcceso() {
    this.nombrePuntoAcceso = '';
    this.nomiExpressApi.obtenerNombrePuntoAcceso(this.accesoExpress.clientId).subscribe(
      (respuesta: any) => {
        if (respuesta.code == 100) this.nombrePuntoAcceso = respuesta.mensaje;
      }
    );
  }

  public formatoFecha(fecha: Date | undefined){
    if (!fecha) {
      return '-';
    }
    // console.log(`fecha: ${fecha}`);
    return fFecha(fecha, "FSL")
  }

  ngOnInit(): void {}
}

const ELEMENT_DATA: PuntosAcceso[] = [
  {nombre: 'Oficina', ultimoAcceso: new Date()},
  {nombre: 'Bodega', ultimoAcceso: new Date()},
  {nombre: 'Entrada', ultimoAcceso: new Date()},
];

interface PuntosAcceso {
  nombre: string;
  ultimoAcceso: Date;
}
