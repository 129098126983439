<div *ngIf="cargando">
  <div class="text-center py-5">
    <mat-spinner class="img-center"></mat-spinner>
  </div>
  <div class="text-center py-5">
    <span>Cargando datos...</span>
  </div>
</div>

<div *ngIf="!cargando">
  <div class="row g-3 mt-4 mx-3 text-start">
    <div class="col-6">
      <label class="form-label m-0">Fecha de asignación</label>
      <div class="input-group">
        <input type="text" class="form-control form-select-sm" [value]="formatoFecha(fechaAsignacion)" readonly (click)="picker.open()">
        <input class="d-none form-control form-select-sm" [(ngModel)]="fechaAsignacion" [matDatepicker]="picker">
        <button mat-raised-button type="button" class="text-primary" (click)="picker.open()">
          <mat-icon fontIcon="today" class="m-0"></mat-icon>
        </button>
        <mat-datepicker #picker></mat-datepicker>
      </div>
    </div>

    <div class="col-6">
      <label class="form-label mb-0">Selecciona el horario</label>
      <div class="input-group">
        <input type="text" placeholder="Selecciona el horario" class="form-control form-select-sm" [(ngModel)]="horarioSeleccionado" [formControl]="myControlHorarios" [matAutocomplete]="autoHorarios">
      </div>
      <mat-autocomplete #autoHorarios="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let horario of opcionesHorarios | async" [value]="horario">
          {{ horario.nombreHorario }}
        </mat-option>
      </mat-autocomplete>
    </div>

    <div class="col-6">
      <mat-label class="form-label mb-0">Buscar empleado</mat-label>
      <input class="form-control form-control-sm" placeholder="Buscar empleado" [(ngModel)]="empleadoSeleccionado" (keyup)="buscarEmpleado($event)">
    </div>

    <div class="col-6 d-flex justify-content-end align-items-center">
      <button mat-raised-button class="text-success d-flex" (click)="aplicarCambios()">
        <mat-icon fontIcon="save" class="me-0"></mat-icon>
        <h4 class="mb-0 ms-2">Asignar horarios</h4>
      </button>
    </div>

  </div>

  <div class="row px-5 py-2 col-12 text-white bg-primary" *ngIf="mensajeError">
    <mat-icon>warning</mat-icon>
    <h5>
      <b>{{ mensajeError }}</b>
    </h5>
  </div>

  <div class="row px-5 py-2 col-12 text-white bg-success" *ngIf="mensajeRespuesta">
    <mat-icon>done</mat-icon>
    <h5>
      <b>{{ mensajeRespuesta }}</b>
    </h5>
  </div>

  <div class="px-3 mt-3">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            color="primary"
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            color="primary"
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
            [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="clave">
        <th mat-header-cell *matHeaderCellDef>ID</th>
        <td mat-cell *matCellDef="let empleado">{{ empleado.numero }}</td>
      </ng-container>

      <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef>Nombre</th>
        <td mat-cell *matCellDef="let empleado">{{ nombreCompleto(empleado) }}</td>
      </ng-container>

      <ng-container matColumnDef="rfc">
        <th mat-header-cell *matHeaderCellDef>R.F.C.</th>
        <td mat-cell *matCellDef="let empleado">{{ empleado.rfc }}</td>
      </ng-container>

      <ng-container matColumnDef="area">
        <th mat-header-cell *matHeaderCellDef>Area</th>
        <td mat-cell *matCellDef="let empleado">{{ empleado.area }}</td>
      </ng-container>

      <!-- <ng-container matColumnDef="horario">
        <th mat-header-cell *matHeaderCellDef>Horario</th>
        <td mat-cell *matCellDef="let empleado">{{ empleado.horario }}</td>
      </ng-container>
  -->
      <ng-container matColumnDef="alta">
        <th mat-header-cell *matHeaderCellDef>Alta</th>
        <td mat-cell *matCellDef="let empleado">{{ formatoFecha(empleado.alta) }}</td>
      </ng-container>

      <ng-container matColumnDef="baja">
        <th mat-header-cell *matHeaderCellDef>Baja</th>
        <td mat-cell *matCellDef="let baja">{{ formatoFecha(baja.baja) }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center h5 text-danger p-3" colspan="8">
          No existe información a mostrar
        </td>
      </tr>
    </table>

    <mat-paginator [length]="dataSource.data.length" (page)="paginator.pageIndex = $event.pageIndex" [pageSizeOptions]="[20, 40, 60, 100, dataSource.data.length]" showFirstLastButtons aria-label="Select page of periodic elements"></mat-paginator>
  </div>

  <div class="d-flex justify-content-end">
    <div class="form-check mx-2">
      <input class="form-check-input" type="checkbox" id="mostrarBajas" [(ngModel)]="mostrarBajas" (change)="aplicarFiltros()">
      <label class="form-check-label fw-bold" for="mostrarBajas">Mostrar empleados dados de baja</label>
    </div>
  </div>
</div>

<div class="row px-5 py-2 col-12 text-white bg-primary" *ngIf="mensajeError">
  <span class="material-icons mr-2"> warning </span>
  <h5>
    <b>{{ mensajeError }}</b>
  </h5>
</div>

<div class="row px-5 py-2 col-12 text-white bg-success" *ngIf="mensajeRespuesta">
  <span class="material-icons mr-2"> done </span>
  <h5>
    <b>{{ mensajeRespuesta }}</b>
  </h5>
</div>
<!-- <div class="mt-3" *ngIf="!schedules || schedules.length <= 0">
  <h3>No existen horarios creados</h3>
</div> -->

