export interface ICodigoQr {
    id: string;
    idCorto: number;
    llave: number;
}

export interface EmployeeQRCredential {
    employeeId: string;
    shortId: number;
    checkKey: number;
}

export interface IMinifiedCodigoQr {
    a: string;
    b: number;
    c: number;
}

export function codigoQrExpandir(codigoQr: IMinifiedCodigoQr): ICodigoQr {
    return  {
        id: codigoQr.a,
        idCorto: codigoQr.b,
        llave: codigoQr.c
    }
}

export function nuevoCodigoQr() {
    return  {
        id: '0',
        idCorto: 0,
        llave: 0
    }
}