export class Transition<T> {
    constructor(
      public currentValue: T,
      public targetValue: T,
      public start: number,
      public speed: number
    ) {
    }
    public progress: number = 0;
  
    move(target: T, currentTime: number) {
      this.targetValue = target;
      this.start = currentTime;
    }
  }