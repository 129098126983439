import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NomiExpressApiService } from '../../services/NomiExpress.api.service';
import { AccesoExpress } from 'src/app/models/accesoExpress';
import { PasswordComponent } from '../password/password.component';
import { Router } from '@angular/router';
import { Ahora, fFecha } from '../../Funciones/fFecha';
import { mostrarSwalToast } from '../../Funciones/funciones';
import { sha256 } from '../../Funciones/fTexto';
import { AccesoDatosService } from '../../services/acceso-datos.service';
import { environment } from 'src/environments/environment';
import { IEmpleados, nuevoEmpleado, TipoEmpleado } from 'src/app/models/empleados';

@Component({
  selector: 'app-configura-basica',
  templateUrl: './configura-basica.component.html',
  styleUrls: ['./configura-basica.component.scss']
})
export class ConfiguraBasicaComponent implements OnInit {
  public infoReconocimiento: boolean;
  public reconocimientoFacial: boolean;
  public habilitarSonido: boolean;
  public habilitarCodigoManual: boolean;
  public accesoExpress: AccesoExpress;
  public opcionesFecha: number;
  public mostrarConfigReconocimiento: boolean;
  public isDebugg: boolean = !environment.production;
  public esDebugger: boolean = false;

  public error: string | undefined = '';

  constructor(
    public dialogRef: MatDialogRef<ConfiguraBasicaComponent>,
    public nomiExpress: NomiExpressApiService,
    private accesoDatosServicio: AccesoDatosService,
    private readonly _router: Router,
    private dialog: MatDialog,
  ) {
    this.accesoExpress = this.nomiExpress.accesoExpress();
    this.reconocimientoFacial = this.accesoExpress.faceDetectionEnabled;
    this.habilitarSonido = this.accesoExpress.habilitarSonido;
    this.habilitarCodigoManual = this.accesoExpress.habilitarCodigoManual;
    this.mostrarConfigReconocimiento = this.reconocimientoFacial;
    this.infoReconocimiento = this.accesoExpress.infoReconocimientoFacial;
    this.opcionesFecha = this.accesoExpress.opcionesFecha;
    console.log(`Datos de fecha: ${this.opcionesFecha} => ${this.accesoExpress.opcionesFecha}`);
    this.esDebugger = this.accesoDatosServicio.getModoDebug();
  }

  public seleccionar(id: number) {
    switch (id) {
      case 1:
        this.reconocimientoFacial = !this.reconocimientoFacial;
        break;
      case 2:
        this.habilitarCodigoManual = !this.habilitarCodigoManual;
        break;
      case 3:
        this.habilitarSonido = !this.habilitarSonido;
        break;
      case 4:
        this.infoReconocimiento = !this.infoReconocimiento;
        break;
    }
  }

  public reconocimientoChange() {
    this.mostrarConfigReconocimiento = this.reconocimientoFacial;
  }

  public cerrarForm() {
    this.dialogRef.close();
  }

  public abrirDatos() {
    console.log(`abrirDatos datos de la empresa`);
    let empleado: IEmpleados = nuevoEmpleado();
    empleado.id = '999';
    empleado.tipoEmpleado = TipoEmpleado.Sistemas;
    this.accesoDatosServicio.setEmpleadoActual(empleado);
    this._router.navigate(['inicio-empresa']);
    this.dialogRef.close('inicio-empresa');
  }

  public datosEmpresa() {
    const password = this.dialog.open(PasswordComponent, {
      data: 'Acceso a panel de control',
      width: '33rem',
      height: 'auto'
    });

    password.componentInstance.verificarPasswordEvent.subscribe((resultado: string) => {
      if (resultado !== 'verificadaPwd') return;
      this.abrirDatos();
    });
  }

  public confirmarPass(cambioHorario: boolean) {
    const password = this.dialog.open(PasswordComponent, {
      data: 'ace',
      width: '33rem',
      height: 'auto'
    });

    password.componentInstance.verificarPasswordEvent.subscribe((resultado: string) => {
      if (resultado !== 'verificadaPwd') return;

      if (cambioHorario) {
        const hoy: string = fFecha(Ahora(this.accesoExpress.opcionesFecha), "amd");
        const cg: string = `asesor0${hoy.substring(3, 4)}${hoy.substring(4, 5)}${hoy.substring(6, 7)}${hoy.substring(7, 8)}${hoy.substring(5, 6)}`;
        if (resultado.toLocaleLowerCase() !== cg) {
          console.log(`${resultado} ==> ${cg} ==> ${this.accesoExpress.localSecret}`);
          return;
        }
        this.accesoExpress.opcionesFecha = this.opcionesFecha;
        this.nomiExpress.guardarAccesoExpress(this.accesoExpress);
        password.close();
        return;
      }
      this.cerrarSesion(resultado);
    });
  }

  private cerrarSesion(password: string) {
    const hoy: string = fFecha(Ahora(this.accesoExpress.opcionesFecha), "amd");
    const cg: string =  `asesor0${hoy.substring(3, 4)}${hoy.substring(4, 5) }${hoy.substring(6, 7) }${hoy.substring(7, 8) }${hoy.substring(5, 6)}`;
    if (password.toLocaleLowerCase() !== cg) {
      console.log(`${password} ==> ${cg} ==> ${this.accesoExpress.localSecret}`);
      return;
    }
    this.nomiExpress.cerrarSesion();
    window.location.reload();
  }

  public aplicarCambios() {
    this.accesoExpress.infoReconocimientoFacial = this.infoReconocimiento;
    this.accesoExpress.faceDetectionEnabled = this.reconocimientoFacial;
    this.accesoExpress.faceDetectionActive = this.reconocimientoFacial;
    this.accesoExpress.habilitarSonido = this.habilitarSonido;
    this.accesoExpress.habilitarCodigoManual = this.habilitarCodigoManual;
    this.nomiExpress.guardarAccesoExpress(this.accesoExpress);
    mostrarSwalToast('Cambios aplicados', 'success');
    this.dialogRef.close();
    window.location.reload();
  }

  private async verificaPwd(password: string): Promise<string | undefined> {
    var hash = await sha256(password);
    // var hashTotal = await sha256(hash + this.accesoExpress.deviceId.toLocaleLowerCase());
    if ((!hash || hash.toLocaleLowerCase() != this.accesoExpress.localSecret.toLocaleLowerCase()) ) {
      const hoy: string = fFecha(Ahora(this.accesoExpress.opcionesFecha), "amd");
      let contraseña: string = `asesor0${hoy.substring(3, 4)}${hoy.substring(4, 5) }${hoy.substring(6, 7) }${hoy.substring(7, 8) }${hoy.substring(5, 6)}`;
      if (password != contraseña) {
        // console.log(`${password} ==> ${await sha256(password)} ==> hash1: ${hashTotal} hash: ${hash} ==> ${this.accesoExpress.localSecret}`);
        this.ponerError('Contraseña incorrecta');
        return undefined;
      }
    }
    return 'verificadaPwd';
  }

  private ponerError(error: string) {
    this.error = error;
    console.log(`error: ${this.error}`);
    setTimeout(() => {
      this.error = '';
    }, 10000);
  }

  public abrirConfiguraReconocimientoFacial() {
   this.dialogRef.close('ConfiguraReconocimientoFacial');
  }

  public abrirConfigAvanzada() {
    this.dialogRef.close('ConfigAvanzada');
  }

  public refrescarPagina() {
    window.location.reload();
  }

  public modoDebug() {
    this.accesoDatosServicio.setModoDebug(!this.esDebugger);
    this.esDebugger = this.accesoDatosServicio.getModoDebug();
    mostrarSwalToast(`Modo debug ${this.esDebugger ? 'activado' : 'desactivado'}`, 'success');
  }


  ngOnInit(): void {}
}
