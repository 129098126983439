<div class="d-flex justify-content-between">
  <div class="ms-5 mt-2">
    <h1>Configuración Avanzada</h1>
  </div>
  <button mat-dialog-close mat-icon-button><mat-icon>close</mat-icon></button>
</div>

<div class="row g-3 mx-3 mb-4">
  <div class="col-6">
    <label class="form-label mb-0" for="inputGroupSelect03">Servidor</label>
    <select [(ngModel)]="servidorSelect" class="form-select" aria-label="Default select example" id="inputGroupSelect03">
      <option *ngFor="let servidor of opcionesServidor | keyvalue" [value]="servidor.key">
        {{ servidor.value }}
      </option>
    </select>
  </div>
  <div class="col-6">
    <label class="form-label mb-0" for="inputGroupSelect04">Manejo de acceso</label>
    <select [(ngModel)]="tipoAccesoSelect" class="form-select" aria-label="Default select example" id="inputGroupSelect04">
      <option *ngFor="let servidor of opcionesAcceso | keyvalue" [value]="servidor.key">
        {{ servidor.value }}
      </option>
    </select>
  </div>
</div>

<div class="d-flex">
  <div class="col-6 pb-3">
    <ul class="list-group list-group-flush ms-4">
      <li class="list-group-item">
        <mat-slide-toggle color="primary" [checked]="debug" (click)="seleccionar(1)">Debug</mat-slide-toggle>
      </li>
      <li class="list-group-item">
        <mat-slide-toggle color="primary" [checked]="infoAdicional" (click)="seleccionar(2)">Información adicional</mat-slide-toggle>
      </li>
      <li class="list-group-item">
        <mat-slide-toggle color="primary" [checked]="validarDirectoOnline" (click)="seleccionar(3)">Online check</mat-slide-toggle>
      </li>
      <li class="list-group-item">
        <mat-slide-toggle color="primary" [checked]="usarReconocimientoCompleto" (click)="seleccionar(4)">Usar reconocimiento local completo</mat-slide-toggle>
      </li>
      <li class="list-group-item">
        <mat-slide-toggle color="primary" [checked]="bajarFrecuenciaRF" (click)="seleccionar(5)">Bajar frecuencia RF</mat-slide-toggle>
      </li>
      <div class="mt-3" *ngIf="bajarFrecuenciaRF">
        <label class="form-label mb-0 opacity-75">Rango para bajara la frecuencia del reconocimiento facial</label>
        <select  [(ngModel)]="rangoFrecuenciaRF" class="form-select form-select-sm" aria-label="Default select example">
          <option value="0">No bajar frecuencia</option>
          <option value="5">Bajar 5 segundos</option>
          <option value="10">Bajar 10 segundos</option>
          <option value="15">Bajar 15 segundos</option>
          <option value="20">Bajar 20 segundos</option>
          <option value="25">Bajar 25 segundos</option>
          <option value="30">Bajar 30 segundos</option>
          <option value="40">Bajar 40 segundos</option>
          <option value="50">Bajar 50 segundos</option>
          <option value="60">Bajar 60 segundos</option>
          <option value="100">Desactivarlo</option>
        </select>
      </div>
      <div class="mt-3">
        <label class="form-label mb-0 opacity-75">Manejo Horario</label>
        <select  [(ngModel)]="opcionesFecha" class="form-select form-select-sm" aria-label="Default select example">
          <option value="0">Normal</option>
          <option value="1">Horario de verano</option>
          <option value="2">Hora del dispositivo</option>
          <option value="3">Hora del dispositivo más una hora</option>
          <option value="4">Hora del dispositivo más dos horas</option>
          <option value="5">Hora del dispositivo menos una hora</option>
          <option value="6">Hora del dispositivo menos dos horas</option>
        </select>
      </div>
      <div class="mt-3">
        <label for="validar" class="form-label mb-0">Validez de Reconocimiento Facial</label>
        <input type="number" class="form-control bg-light slide-in-left" id="validar" onClick="this.select();" [(ngModel)]="validarReconocimiento">
      </div>
      <div class="mt-3">
        <label for="validar" class="form-label mb-0">Porcentaje de la imagen para validar ingreso</label>
        <input type="number" class="form-control bg-light slide-in-left" id="validarImagen" onClick="this.select();" [(ngModel)]="validarPorcentajeImagen">
      </div>
      <div class="mt-3">
        <label for="validacion" class="form-label mb-0">Veces por validación</label>
        <input type="number" class="form-control bg-light slide-in-left" id="validacion" onClick="this.select();" [(ngModel)]="vecesPorVerificar">
      </div>
      <div class="mt-3">
        <label for="validacion" class="form-label mb-0">Minutos para recargar aplicación</label>
        <input type="number" class="form-control bg-light slide-in-left" id="idMinutosParaRecargar" onClick="this.select();" [(ngModel)]="minutosParaRecargar">
      </div>
    </ul>
  </div>
  <div class="col-5 text-start mx-4">
    <button mat-raised-button class="ms-3 w-100 text-primary" (click)="restablecerDatos()">Restablecer a los valores predeterminados</button>
    <button mat-raised-button class="mt-3 ms-3 w-100 text-primary" (click)="cargarDatos2DelServidor()"><small>Recuperar configuración desde el servidor</small></button>
    <button mat-raised-button class="mt-3 ms-3 w-100 text-primary" (click)="cargarDatos2DelServidor()"><small>Recuperar anterior configuración desde el servidor</small></button>
    <button mat-raised-button class="mt-3 ms-3 w-100 text-primary bg-secondary-subtle text-start" (click)="sincronizarDatos()" disabled><small>Sincronizar Datos</small></button>
    <button mat-raised-button class="mt-3 ms-3 w-100 text-danger" disabled><small>Borrar todos los datos del sistema en el punto de acceso</small></button>
    <button mat-raised-button class="mt-3 ms-3 w-100 text-primary" (click)="log()">Log</button>
    <button color="primary" mat-raised-button class="ms-3 w-100 my-5" (click)="aplicarCambios()"><small>Aplicar cambios</small></button>
    <button mat-raised-button class="my-5 ms-3 w-100 text-danger" (click)="confirmarPass(false)"><mat-icon>logout</mat-icon> <small>Desactivar punto de acceso</small></button>
  </div>
</div>
