import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxScannerQrcodeComponent, NgxScannerQrcodeService, ScannerQRCodeConfig, ScannerQRCodeResult, ScannerQRCodeSelectedFiles } from 'ngx-scanner-qrcode';

@Component({
  selector: 'app-camara-modal',
  templateUrl: './camara-modal.component.html',
  styleUrls: ['./camara-modal.component.scss']
})
export class CamaraModalComponent implements AfterViewInit {  
  @Output() modal: EventEmitter<any> = new EventEmitter();  //Salida de evento para cerrar modal
  @ViewChild('action') action!: NgxScannerQrcodeComponent;
  
  constructor(
    public dialogRef: MatDialogRef<CamaraModalComponent>,
    private qrcode: NgxScannerQrcodeService
  ){ }

  public onEvent(e: ScannerQRCodeResult[], action?: any): void {
    console.log(e);
    if (!e || e.length < 1) return;
    if (!e[0].value) return;
    let valor: string = e[0].value;
    if (!valor.includes(`https://acceso.nomiexpress.com/login/`) && !valor.includes(`https://pruebas.nomiexpress.com/login/`)) return;

    let datos: string[] = valor.split(`/login/`);
    if (!datos || datos.length < 1) {
      console.log(`sin valores a cargar: ${valor}`);
      return;
    }
    console.log(`valores a cargar: ${datos[1]}`);

    valor = datos[1];
    datos = valor.split(`/`);
    if (!datos) {
      this.dialogRef.close(`id/${valor}`);
      console.log(`valores a cargar: ${valor}`);
      return;
    }
    
    if (datos.length >= 3 && datos[1] == `llave`) {
      this.dialogRef.close(`id/${valor}`);
      console.log(`id y llave: id/${valor}`);
      return;
    }
    console.log(`id : id/${datos[0]}`);
    this.dialogRef.close(`id/${datos[0]}`);

    // e && action && action.pause();
    
  }

  public config: ScannerQRCodeConfig = {
    constraints: {
      video: {
        width: window.innerWidth
      },
    }
  };

  public handle(action: any, fn: string): void {
    // Fix issue #27, #29
    const playDeviceFacingBack = (devices: any[]) => {
      // front camera or back camera check here!
      const device = devices.find(f => (/back|rear|environment/gi.test(f.label))); // Default Back Facing Camera
      action.playDevice(device ? device.deviceId : devices[0].deviceId);
    }

    if (fn === 'start') {
      action[fn](playDeviceFacingBack).subscribe((r: any) => console.log(fn, r), alert);
    } else {
      action[fn]().subscribe((r: any) => console.log(fn, r), alert);
    }
  }

  
  public cerrarDialog(): void {
    this.dialogRef.close();
  }

  ngAfterViewInit(): void {
    this.action.isReady.subscribe((res: any) => {
      this.handle(this.action, 'start');
    });
  }
}
