<div class="row g-2 mx-2 pb-3">
  <!-- Datos de la incidencia -->
  <div class="col-4">
    <div class="example-form">
      <label class="form-label mb-0">Inicio del periodo</label>
      <input type="date" [(ngModel)]="inicioPersonalizado" (change)="calcularDias()" class="form-control form-select-sm">
    </div>
  </div>

  <div class="col-4">
    <div class="example-form">
      <label class="form-label mb-0">Terminación del periodo</label>
      <input type="date" [(ngModel)]="finPersonalizado" (change)="calcularDias()" class="form-control form-select-sm">
    </div>
  </div>

  <div class="col-4">
    <div class="example-form">
      <label class="form-label mb-0">Días</label>
      <input type="number" [(ngModel)]="diasPeriodo" class="form-control form-select-sm bg-light" readonly>
    </div>
  </div>

  <div class="col-4">
    <label class="form-label mb-0">Tipo de movimiento</label>
    <select class="form-select form-select-sm" aria-label="Default select example" [(ngModel)]="nuevaIncidencia.tipoManejoIncidencia">
      <option *ngFor="let movimiento of tipoManejoIncidencia | keyvalue: valueTipoIncidenciaAscOrder" [value]="movimiento.key">
        {{ movimiento.value }}
      </option>
    </select>
  </div>

  <div class="col-4" *ngIf="nuevaIncidencia.tipoManejoIncidencia == 3">
    <label class="form-label mb-0">Tipo de incapacidad</label>
    <select class="form-select form-select-sm" aria-label="Default select example" [(ngModel)]="nuevaIncidencia.tipoIncapacidad">
      <option *ngFor="let incapacidad of tipoIncapacidad | keyvalue" [value]="incapacidad.key">
        {{ incapacidad.value }}
      </option>
    </select>
  </div>

  <div class="col-4" *ngIf="nuevaIncidencia.tipoManejoIncidencia == 3">
    <label class="form-label mb-0">Folio incapacidad</label>
    <input type="text" placeholder="Folio incapacidad" [(ngModel)]="nuevaIncidencia.observaciones" class="form-control form-select-sm">
  </div>

  <div class="col-8" *ngIf="nuevaIncidencia.tipoManejoIncidencia != 3">
    <label class="form-label mb-0">Observaciones</label>
    <input type="text" placeholder="Observaciones" [(ngModel)]="nuevaIncidencia.observaciones" class="form-control form-select-sm">
  </div>


  <!-- ==================================================== ordenar por ====================================================-->
  <div class="col-12 d-flex justify-content-end align-items-end">
    <div class="mx-2">
      <label class="form-label mb-0">Ordenar por</label>
      <select class="form-select form-select-sm" aria-label="Default select example" [(ngModel)]="selectedAcomodo" (ngModelChange)="aplicarFiltros()">
        <option *ngFor="let opcion of opcionesFiltrosAcomodo | keyvalue" [value]="opcion.key">
          {{ opcion.value }}
        </option>
      </select>
    </div>
    <div>
      <button mat-raised-button class="mx-2 text-primary" (click)="guardarIncidenciasMasivas()">
        <mat-icon>save</mat-icon>Guardar incidencias
      </button>
    </div>
    <div>
      <button mat-raised-button class="mx-2 text-danger" (click)="cerrarIncidencias()">
        Cancelar
      </button>
    </div>
  </div>
</div>

<div class="mat-elevation-z8 col-11 mx-auto">
  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? toggleAllRows() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      [aria-label]="checkboxLabel()"
                      color="primary">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)"
                      [aria-label]="checkboxLabel(row)"
                      color="primary">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="id"  *ngIf="esDebugger">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
      <td mat-cell *matCellDef="let empleado"> {{empleado.id}} </td>
    </ng-container>

    <ng-container matColumnDef="clave">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Clave</th>
      <td mat-cell *matCellDef="let empleado"> {{empleado.numero}} </td>
    </ng-container>

    <ng-container matColumnDef="nombre">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
      <td mat-cell *matCellDef="let empleado"> {{nombreCompleto(empleado)}} </td>
    </ng-container>

    <ng-container matColumnDef="rfc">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>R.F.C.</th>
      <td mat-cell *matCellDef="let empleado"> {{empleado.rfc}} </td>
    </ng-container>

    <ng-container matColumnDef="alta">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Alta</th>
      <td mat-cell *matCellDef="let empleado"> {{formatoFechaCorto(empleado.altaActualTxt)}} </td>
    </ng-container>

    <ng-container matColumnDef="baja" *ngIf="mostrarBajas">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Baja</th>
      <td mat-cell *matCellDef="let empleado"> {{formatoFechaCorto(empleado.bajaActualTxt)}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        (click)="selection.toggle(row)">
    </tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No hay empleados</td>
    </tr>
  </table>
</div>

<div class="d-flex justify-content-end mt-3">
  <div class="form-check mx-2">
    <input class="form-check-input" type="checkbox" id="mostrarBajasValidar" [(ngModel)]="mostrarBajas" (change)="aplicarFiltros()">
    <label class="form-check-label fw-bold" for="mostrarBajasValidar">Mostrar empleados dados de baja</label>
  </div>
</div>
