<div class="container">
  <div class="modal-header mx-4 mt-1">
    <h4 class="modal-title text-center" id="modal-basic-title">Leer código QR</h4>
    <button class="btn" (click)="cerrarDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <!-- devices -->
  <div class="row">
    <div class="col-xs-12 col-md-6 col-lg-4 col-xl-3 my-1">
      <select #select1 (change)="action.playDevice(select1.value)" class="form-select form-select-sm">
        <option [value]="null" selected>Select device</option>
        <option *ngFor="let c of action.devices.value; let i = index" [value]="c.deviceId" [selected]="i == action.deviceIndexActive">{{c.label}}</option>
      </select>
    </div>
  </div>
  
  <!-- ngx-scanner-qrcode -->
  <ngx-scanner-qrcode #action="scanner" [config]="config" (event)="onEvent($event, action)"></ngx-scanner-qrcode>
  <br>

  <!-- loading -->
  <p *ngIf="action.isLoading">⌛ Cargando lector de código de barras...</p>  
</div>