import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Ahora, CVFechaT, EsHorarioVeranoAnterior, TipoDateDiff, ValidarHora, addDays, addHours, dateDiff, fFecha, getFechaT } from '../Funciones/fFecha';
import { encrypt, getHeaders, getUuid } from '../Funciones/funciones';
import { Incidence } from 'src/app/models/incidence';
import { IRespuestaChecker, IResultadoActualiza, IResultadoActualizaApi } from 'src/app/models/resultadoActualiza';
import { IEmpresa, empresaExpandir } from 'src/app/models/empresa';
import { EmployeeQRCredential, ICodigoQr, codigoQrExpandir } from 'src/app/models/codigoQr';
import { IEmpleados, empleadosExpandir } from 'src/app/models/empleados';
import { IAcceso } from 'src/app/models/checks';
import { CheckerInfoEmpleados, IReconocimientoFacial, IReconocimientoFacialRespuesta, reconocimientoFacialExpandir } from 'src/app/models/reconocimientoFacial';
import { IPuntoDeAcceso, puntosDeAccesoExpandir } from 'src/app/models/puntosDeAcceso';
import { IDepartamentos, departamentoExpandir } from 'src/app/models/departamento';
import { ITokenAccess } from 'src/app/models/tokenAccess';
import { LocalService } from './local.service';
import { AccesoExpress, AccesoExpressConfig, AccesoExpressToConfig, ConfigToAccesoExpress, OpcionesFecha, nuevoAccesoExpress, restaurarAccesoExpress, validarAccesoExpress, versionAccesoExpress } from 'src/app/models/accesoExpress';
import { CS_ConDatos, fechaGetTime, fechaT } from '../Funciones/fTexto';
import * as CryptoJS from 'crypto-js';
import { map } from 'rxjs/operators';
import { AccesoCF_RespuestaRecognize_Respuesta } from 'src/app/models/accesoCF';

@Injectable({
  providedIn: 'root'
})
export class NomiExpressApiService {

  private _accesoExpress: AccesoExpress;
  // private _empresa: IEmpresa = newEmpresa();
  // private _empleados: IEmpleados[] = [];
  // private _puntoDeAcceso: IPuntoDeAcceso = newPuntoDeAcceso();
  private _reconocimientoFacial: IReconocimientoFacial[] = [];
  private _empleados: IEmpleados[] = [];

  private _log: string[] = [];
  public inicioApp: Date = new Date();

  constructor(
    private readonly _httpClient: HttpClient,
    private datosLocales: LocalService
  ) {
    let datosAcceso: string = this.datosLocales.obtenerDatos('acceso');
    if (CS_ConDatos(datosAcceso)) {
      this._accesoExpress = JSON.parse(datosAcceso);
      if (!this._accesoExpress.vigenteHastaTxt) {
        this._accesoExpress.vigenteHasta = new Date(2025, 3, 3);
        this._accesoExpress.vigenteHastaTxt = fechaT(this._accesoExpress.vigenteHasta);
      } else {
        this._accesoExpress.vigenteHasta = CVFechaT(this._accesoExpress.vigenteHastaTxt);
      }
      if (this._accesoExpress.imagenUrl && this._accesoExpress.imagenUrl.substring(0, 1) == '$') {
        this._accesoExpress.imagenUrl = '';
      }
      this._accesoExpress.faceRecognitionDebugMode = false;
    } else {
      this._accesoExpress = nuevoAccesoExpress();
    }
    validarAccesoExpress(this._accesoExpress);
  }

  public testConnection() {
    this.logAgrega2("====================================================================== tc");
    // this.logAgrega2(`${environment.api}/checker/testConnection`);
    let url = `${environment.api}/checker/testConnection`;
    return this._httpClient.get<{ name: string }>(url, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }

  public getAccesoACExpress(): AccesoExpress {
    return this._accesoExpress;
  }

  public getUpdate(manejoDescarga: ManejoDescarga): Observable<IResultadoActualiza | undefined> {
    // this.logAgrega2("====================================================================== getUpdate");
    let datosApi: IResultadoActualiza | undefined;
    return this.obtenerDatosApi(manejoDescarga).pipe(
      map(
        (resultado: IResultadoActualizaApi) => {
          // this.logAgrega2("====================================================================== actualización");
          // let fechaServidorTxt: string = resultado.e;
          let horaServidor: Date = new Date(resultado.e);
          if (EsHorarioVeranoAnterior(horaServidor)) {
            let verificaHoraServidor: Date = addHours(horaServidor, -1)
            this.logAgrega2(`Servidor: ${fFecha(horaServidor, 'fmh')}, Corrección: ${fFecha(verificaHoraServidor, 'fmh')}`);
            horaServidor = verificaHoraServidor;
          } 
          
          let now: Date = Ahora(this._accesoExpress.opcionesFecha);
          this.logAgrega2(`Cliente: ${fFecha(now, 'fmh')} opc hora: ${this._accesoExpress.opcionesFecha}, Servidor: ${fFecha(horaServidor, 'fmh')}`);

          this._accesoExpress.offSetFromServer = (now.getTime() - horaServidor.getTime()) / 1000;
          let dif: number = Math.abs(this._accesoExpress.offSetFromServer);
          // this.logAgrega2(`dif: ${dif}`);
          if (dif >= 3600 && this._accesoExpress.opcionesFecha >= OpcionesFecha.HorarioVerano && this._accesoExpress.opcionesFecha <= OpcionesFecha.Menos2) {
            let difHoras = dif / 3600;
            let horasDiffOpc = 1;
            if (this._accesoExpress.opcionesFecha == OpcionesFecha.Mas2 || this._accesoExpress.opcionesFecha == OpcionesFecha.Menos2) horasDiffOpc = 2;
            dif -= horasDiffOpc * 3600
            this.logAgrega2(`dif horas: ${difHoras}, Horas Dif por opciones: ${horasDiffOpc}, dif: ${dif}`);
          }
          
          let guardarDatos: boolean = false;          
          this._accesoExpress.checkServerTime = dif <= 30;
          if (dif > 30 && dif < 200) {
              this._accesoExpress.checkServerTime = true;
              this.logAgrega2(`Corrección de hora contra los datos del servidor: ${dif}`);              
              dif = 0; // Math.abs(horaServidor.getTime() - now.getTime()) / 1000;
          }
          if (dif >= 90) {
              let err = `Hay una diferencia de mas de 120 segundos entre la hora del servidor y la hora de esta computadora...`;
              this.logAgrega2(`${err} Ahora: ${now}, serverTime: ${horaServidor}`);
              // this.hasTimeError = true;
              guardarDatos = true;
          }
          // this.logAgrega2(`dif2: ${dif}`);

          let empresa: IEmpresa = empresaExpandir(resultado.l);
          this._accesoExpress.companyName = empresa.nombre;
          this._accesoExpress.imagenUrl = empresa.imageUrl;
          if (this._accesoExpress.imagenUrl && this._accesoExpress.imagenUrl.substring(0, 1) == '$') {
            this._accesoExpress.imagenUrl = '';
          }

          this._accesoExpress.isConnected = true;
          this._accesoExpress.lastUpdate = resultado.e;
          this._accesoExpress.accesoNombre = resultado.na;
          this._accesoExpress.ultimaActualizacion = fechaT(Ahora(this._accesoExpress.opcionesFecha));
          guardarDatos = guardarDatos || this._accesoExpress.apiKeyCF != empresa.apiKeyCF;
          this._accesoExpress.apiKeyCF = empresa.apiKeyCF;
          if (!!empresa.vigenteHasta) {
            empresa.vigenteHastaFecha = getFechaT(empresa.vigenteHasta);
            if (empresa.vigenteHastaFecha < new Date(2024, 8, 31)) {
              empresa.vigenteHastaFecha = new Date(2025, 3, 3);
            }
          } else {
            empresa.vigenteHastaFecha = new Date(2025, 3, 3);
          }
          if (empresa.vigenteHastaFecha.getTime() != this._accesoExpress.vigenteHasta.getTime()) {
            this._accesoExpress.vigenteHasta = empresa.vigenteHastaFecha;
            this._accesoExpress.vigenteHastaTxt = fechaT(this._accesoExpress.vigenteHasta);
            this._accesoExpress.díasVigenciaAdicional = 0;
            guardarDatos = true;
          }
          
          if (!!resultado.k) {
            guardarDatos = true;
            console.log(`Cambio de contraseña: ${resultado.k} ==> ${this._accesoExpress.localSecret}`);
            this._accesoExpress.localSecret = CryptoJS.SHA256(resultado.k.toLowerCase() + this._accesoExpress.deviceId.toLowerCase()).toString();
          }
          // this._accesoExpress.localSecret = resultado.k;

          this._empleados = [];
          resultado.a.forEach(x => this._empleados.push(empleadosExpandir(x)));

          this._reconocimientoFacial = [];
          resultado.g.forEach(x => this._reconocimientoFacial.push(reconocimientoFacialExpandir(x)));

          let codigosQr: ICodigoQr[] = [];
          resultado.h.forEach(x => codigosQr.push(codigoQrExpandir(x)));

          let puntosDeAcceso: IPuntoDeAcceso[] = [];
          resultado.i.forEach(x => puntosDeAcceso.push(puntosDeAccesoExpandir(x)));

          let departamentos: IDepartamentos[] = [];
          resultado.m.forEach(x => departamentos.push(departamentoExpandir(x)));

          datosApi = {
            empleados: this._empleados,
            empresa: empresa,
            codigosQr: codigosQr,
            reconocimientoFacial: this._reconocimientoFacial,
            horarios: [],
            historialHorarios: [],
            horariosEmpleados: [],
            checks: [],
            departamentos: departamentos,
            puntosDeAcceso: puntosDeAcceso,
            checkServerTime: this._accesoExpress.checkServerTime,
            offSetFromServer: this._accesoExpress.offSetFromServer
          }

          if (guardarDatos) {
            this.datosLocales.guardarDatos('acceso', JSON.stringify(this._accesoExpress));
          }
          // this.logAgrega2(`Regresando datos al sistema`);
          return datosApi;

        },
        (err: HttpErrorResponse) => {
          this.logAgrega2(`Error al cargar los datos del sistema`);
          this._accesoExpress.isConnected = false;
          console.error(err);
          return undefined;
        }
    ));


    // return this._httpClient.post(`${environment.api}/checker/update`, reporte, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) }).pipe(
    //   catchError(err => {
    //       // mostrarSwalError('Acceso Express', 'Error al actualizar la base de datos, verifica tu conexión a Internet e intenta de nuevo.');
    //       this.logAgrega2(`Error: checker/update`, err);
    //       return throwError(err);
    //   }), update => {
    //       // this.logAgrega2(`pipe.flatMap`, update);

    //       return from(new Promise((resolve, reject) => {
    //           // this.logAgrega2(`getUuid()`);
    //           let requestId = getUuid();


    //           this._electronService.ipcRenderer.once(
    //               `reply-update-database-${requestId}`,
    //               async (event, result: { accessPoints: { id: string, name: string, updatedDate: Date }[], updateDate: string, lastKnownCheckerReport: string, newLocalKey: string | undefined, company: ICompany }) => {

    //                   // console.dir(`Resultado: checker/update`, result);

    //                   if (result.newLocalKey) this._accesoExpress.localSecret =
    //                       await sha256(result.newLocalKey.toLocaleLowerCase() + this._accesoExpress.deviceId.toLowerCase());
    //                   this._appService.saveAppSettings();

    //                   this.hasTimeError = false;



    //                   resolve(result);
    //               });
    //           this._electronService.ipcRenderer.send('update-database', { update: update, id: requestId });
    //       })) as Observable<{ accessPoints: { id: string, name: string, updatedDate: Date }[], updateDate: string, lastKnownCheckerReport: string, newLocalKey: string | undefined }>;
    //   });
  }

  public registrarTerminal(llaveSecreta: string): Observable<IRespuestaChecker> {
    this.logAgrega2(`registrarTerminal.`);
    let url: string = `${environment.api}/checker/registrarTerminal`;
    if (!this._accesoExpress.deviceId  || this._accesoExpress.deviceId.length < 36 || this._accesoExpress.deviceId.length > 50) {
      this._accesoExpress.deviceId = getUuid();
      this.datosLocales.guardarDatos('acceso', JSON.stringify(this._accesoExpress));
    }    
    this._accesoExpress.deviceKey = encrypt(llaveSecreta, this._accesoExpress.deviceId);
    if (!environment.production) {
      console.log(`deviceId: ${this._accesoExpress.deviceId}, llaveSecreta: ${llaveSecreta}, deviceKey: ${this._accesoExpress.deviceKey}`);
    }
    if (this._accesoExpress.debug) {
      this.logAgrega2(`registrarTerminal. clientId: ${this._accesoExpress.clientId}, llaveSecreta: ${llaveSecreta}, deviceId: ${this._accesoExpress.deviceId}`);
    }
    let registro: IRespuestaChecker = {code: 99, mensaje: 'RegistrarTerminales' };

    return this._httpClient.post<ITokenAccess>(url, registro, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) }).pipe(
      map(
        (resultado: ITokenAccess) => {
          if (!resultado) {
            registro.mensaje = 'Error al registrar la terminal';
            if (this._accesoExpress.debug) {
              this.logAgrega2(`registrarTerminal. Error al registrar la terminal`);
            }
            return registro;
          }
          if (resultado.respuesta.code != 100) {
            if (this._accesoExpress.debug) this.logAgrega2(`registrarTerminal. Error. ${resultado.respuesta}`);
            return resultado.respuesta;
          }
          // this._empresa = empresaDesdeIdentidad(resultado.company);
          // this._puntoDeAcceso = resultado.accessPoint;

          if (this._accesoExpress.debug) {
            let txt: string = JSON.stringify(resultado.respuesta);
            this.logAgrega2(`registrarTerminal. ${txt}`);
          }

          this._accesoExpress.isConnected = true;
          this._accesoExpress.ownerId = resultado.company.ownerUserId;
          this._accesoExpress.companyId = resultado.company.id;
          this._accesoExpress.companyName = resultado.company.name;
          this._accesoExpress.imagenUrl = resultado.company.imageUrl;
          let contraseña: string = CryptoJS.SHA256(resultado.company.checkersPassword.toLowerCase() + this._accesoExpress.deviceId.toLowerCase()).toString();
          // console.log(`(1) contraseña: ${resultado.company.checkersPassword}, localSecret ${this._accesoExpress.localSecret}, c: ${contraseña}`);
          this._accesoExpress.localSecret = resultado.company.checkersPassword; // contraseña;
          // this._accesoExpress.localSecret = CryptoJS.SHA256(resultado.company.checkersPassword.toLocaleLowerCase();
          this._accesoExpress.apiKeyCF = '';

          this._accesoExpress.accesoNombre = resultado.accessPoint.name;
          this._accesoExpress.isActive = resultado.accessPoint.isActive;
          // this._accesoExpress.debug = resultado.accessPoint.isDebugging;
          this._accesoExpress.checkServerTime = false;
          this._accesoExpress.offSetFromServer = 0;

          if (this._accesoExpress.debug) this.logAgrega2(`registrarTerminal. Guardando datos.`);
          this.datosLocales.guardarDatos('acceso', JSON.stringify(this._accesoExpress));

          return resultado.respuesta;
        },
        (err: HttpErrorResponse) => {
          this._accesoExpress.isConnected = false;
          console.error(err);
          registro.mensaje = 'err.message';
          return registro;
        }
      ));
  }

  public guardarDatosTerminalDelServidor(): Observable<IRespuestaChecker>  {
    let url: string = `${environment.api}/acceso/empresa/config`;
    let httpHeaders: HttpHeaders = getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey);
    this.logAgrega2(`guardarDatosTerminalDelServidor.`);    
    let config: AccesoExpressConfig = AccesoExpressToConfig(this._accesoExpress);
    
    return this._httpClient.post<IRespuestaChecker>(url, config, { headers: httpHeaders });
  }

  public cargarDatosTerminalDelServidor(): Observable<IRespuestaChecker> {
    this.logAgrega2(`cargarDatosTerminalDelServidor.`);
    let url: string = `${environment.api}/acceso/empresa/config`;
    
    return this._httpClient.get<IRespuestaChecker>(url, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) }).pipe(
      map(
        (resultado: IRespuestaChecker) => {
          if (!resultado) {
            resultado = {
              code: 99,
              mensaje: 'Sin datos'
            };            
            if (this._accesoExpress.debug) {
              this.logAgrega2(`cargarDatosTerminalDelServidor. Sin datos`);
            }
            return resultado;
          }
          if (resultado.code != 100) {
            this.logAgrega2(`cargarDatosTerminalDelServidor. Respuesta. ${resultado.code} - ${resultado.mensaje}`);
            return resultado;
          }
          
          this.logAgrega2(`cargarDatosTerminalDelServidor. Cargando configuración. ${resultado.mensaje.substring(0, resultado.mensaje.length > 100 ? 100 : resultado.mensaje.length )}`);
          let accesoConfig: AccesoExpressConfig = JSON.parse(resultado.mensaje);

          this.logAgrega2(`cargarDatosTerminalDelServidor. Convirtiendo Configuración.`);
          this._accesoExpress = ConfigToAccesoExpress(accesoConfig, this._accesoExpress);

          this.logAgrega2(`cargarDatosTerminalDelServidor. Guardando nueva Configuración.`);
          this.datosLocales.guardarDatos('acceso', JSON.stringify(this._accesoExpress));
        
          return resultado;
        },
        (err: HttpErrorResponse) => {          
          console.error(err);
          let resultado: IRespuestaChecker = {
            code: 99,
            mensaje: err.message
          }
          this.logAgrega2(`cargarDatosTerminalDelServidor. Error: ${resultado.mensaje}`);          
          return resultado;
        }
      ));
  }

  public cargarDatos2TerminalDelServidor(): Observable<IRespuestaChecker> {
    this.logAgrega2(`cargarDatos2TerminalDelServidor.`);
    let url: string = `${environment.api}/acceso/empresa/config2`;
    
    return this._httpClient.get<IRespuestaChecker>(url, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) }).pipe(
      map(
        (resultado: IRespuestaChecker) => {
          if (!resultado) {
            resultado = {
              code: 99,
              mensaje: 'Sin datos'
            };            
            if (this._accesoExpress.debug) {
              this.logAgrega2(`cargarDatos2TerminalDelServidor. Sin datos`);
            }
            return resultado;
          }
          if (resultado.code != 100) {
            this.logAgrega2(`cargarDatos2TerminalDelServidor. Respuesta. ${resultado.code} - ${resultado.mensaje}`);
            return resultado;
          }
          
          this.logAgrega2(`cargarDatos2TerminalDelServidor. Cargando configuración. ${resultado.mensaje.substring(0, resultado.mensaje.length > 100 ? 100 : resultado.mensaje.length )}`);
          let accesoConfig: AccesoExpressConfig = JSON.parse(resultado.mensaje);

          this.logAgrega2(`cargarDatos2TerminalDelServidor. Convirtiendo Configuración.`);
          this._accesoExpress = ConfigToAccesoExpress(accesoConfig, this._accesoExpress);

          this.logAgrega2(`cargarDatos2TerminalDelServidor. Guardando nueva Configuración.`);
          this.datosLocales.guardarDatos('acceso', JSON.stringify(this._accesoExpress));
        
          return resultado;
        },
        (err: HttpErrorResponse) => {          
          console.error(err);
          let resultado: IRespuestaChecker = {
            code: 99,
            mensaje: err.message
          }
          this.logAgrega2(`cargarDatos2TerminalDelServidor. Error: ${resultado.mensaje}`);          
          return resultado;
        }
      ));
  }

  public validarPuntoDeAcceso(): Observable<ITokenAccess> {
    let url: string = `${environment.api}/checker/validateScreen`;
    return this._httpClient.post<ITokenAccess>(url, {  }, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }

  public accessHub_EscucharPorMovimiento(id: string) {
    let url: string = `${environment.hubUrl}/access/startListeningForScreen?connectionId=${id}`;
    return this._httpClient.post(url, { version: this._accesoExpress.version }, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }

  public updateCheck(acceso: IAcceso): Observable<IRespuestaChecker> {
    let url: string = `${environment.api}/checker/updateCheck`;
    return this._httpClient.post<IRespuestaChecker>(url, acceso, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }

  public accesoVerificar(tiempoDesdeUltimoCheck: number): Observable<IRespuestaChecker> {
    let url: string = `${environment.api}/checker/verificar`;
    let registro: IRespuestaChecker = {code: 99, mensaje: 'VerificarChecks' };

    return this._httpClient.post<ITokenAccess>(url, registro, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) }).pipe(
      map(
        (resultado: ITokenAccess) => {
          if (!resultado) {
            registro.mensaje = 'Error al verificar el punto de acceso';
            if (this._accesoExpress.debug) {
              this.logAgrega2(`accesoVerificar. Error al verificar el punto de acceso`);
            }
            return registro;
          }
          if (resultado.respuesta.code != 100) {
            if (this._accesoExpress.debug) this.logAgrega2(`accesoVerificar. Error. ${resultado.respuesta}`);
            return resultado.respuesta;
          }

          if (this._accesoExpress.debug) {
            let txt: string = JSON.stringify(resultado.respuesta);
            this.logAgrega2(`accesoVerificar. ${txt}`);
          }

          this._accesoExpress.isConnected = true;
          this._accesoExpress.companyName = resultado.company.name;
          // this.logAgrega2(`accesoVerificar. company.imageUrl: ${resultado.company.imageUrl}`);
          this._accesoExpress.imagenUrl = resultado.company.imageUrl;
          let contraseña: string = CryptoJS.SHA256(resultado.company.checkersPassword.toLowerCase() + this._accesoExpress.deviceId.toLowerCase()).toString();
          console.log(`contraseña: ${resultado.company.checkersPassword}, localSecret ${this._accesoExpress.localSecret}, c: ${contraseña}`);
          // this._accesoExpress.localSecret = contraseña;
          this._accesoExpress.localSecret = resultado.company.checkersPassword;

          this._accesoExpress.accesoNombre = resultado.accessPoint.name;
          this._accesoExpress.isActive = resultado.accessPoint.isActive;

          // this.logAgrega2(`accesoVerificar. accessPoint.updatedDate: ${resultado.accessPoint.updatedDate}`);
          // let fLastUpdate: Date = CVFechaT(this._accesoExpress.lastUpdate);
          let updatedDateMS: number = fechaGetTime(resultado.accessPoint.updatedDate);
          let lastUpdateMs: number = fechaGetTime(CVFechaT(this._accesoExpress.lastUpdate));
          this.logAgrega2(`accesoVerificar. updatedDateMS: ${updatedDateMS}, lastUpdateMs: ${lastUpdateMs}`);
          if (updatedDateMS > lastUpdateMs) {
            // this.logAgrega2(`accesoVerificar. fLastUpdate: ${fLastUpdate}`);
            // let lastUpdate: string =
            // this.logAgrega2(`accesoVerificar. accessPoint.updatedDate: ${resultado.accessPoint.updatedDate}, lastUpdate: ${lastUpdate}, accesoExpress.lastUpdate: ${this._accesoExpress.lastUpdate}`);
            this._accesoExpress.lastUpdate = fechaT(resultado.accessPoint.updatedDate);;

            if (this._accesoExpress.debug || true) this.logAgrega2(`accesoVerificar. Guardando datos. tiempoDesdeUltimoCheck: ${tiempoDesdeUltimoCheck}`);
            this.datosLocales.guardarDatos('acceso', JSON.stringify(this._accesoExpress));
          }

          return resultado.respuesta;
        },
        (err: HttpErrorResponse) => {
          this._accesoExpress.isConnected = false;
          console.error(err);
          registro.mensaje = 'err.message';
          return registro;
        }
      ));
  }


  private obtenerDatosApi(manejoDescarga: number): Observable<IResultadoActualizaApi> {
    let lastUpdate = new Date(this._accesoExpress.lastUpdate);
    if (isNaN(lastUpdate.getTime())) lastUpdate = addDays(new Date(), -1);
    if (dateDiff(lastUpdate, new Date(), TipoDateDiff.días ) > 2) lastUpdate = addDays(new Date(), -1);
    lastUpdate = new Date(lastUpdate.getUTCDate());

    if (!this._accesoExpress) this.logAgrega2(" _accesoExpress --> indefinida");
    let url: string = `${environment.api}/checker/update`;

    let httpHeaders: HttpHeaders = new HttpHeaders();
    try {
      httpHeaders = getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey, '', true);
      // if (!environment.production) {
      //   console.log('httpHeaders', httpHeaders);        
      // }
    } catch (error) {
      console.log(`ERROR  ====================================================================== _api.getUpdate`);
      console.error(error);
    }    
    this.logAgrega2(`====================================================================== _api.getUpdate`);
    
    let reporte = new ChecksReport();
    reporte.clientLastUpdate = this._accesoExpress.lastUpdate;
    // this.logAgrega2("====================================================================== obtenerDatosApi - JSON.stringify");
    // try {
    //   reporte.config = this.JsonConfig();
    // } catch (error) {
    //   console.log('obtenerDatosApi - JSON.stringify (e)');
    //   console.error('Error obtenerDatosApi - JSON.stringify:', error);
    // }

    // if (!environment.production) {
    //   console.log('obtenerDatosApi - JSON.stringify (c)');
    // }


    // poner los chequeos fuera de linea para pasar al servidor
    // report.checks = checks.map(x => {
    //     let c = new MinifiedCheck();
    //     c.a = x.id;
    //     c.b = x.date;
    //     c.c = x.employeeId;
    //     return c;
    // });

    // this.logAgrega2("======================================================================");
    // this.logAgrega2(`${environment.api}/checker/update`, reporte);

    return this._httpClient.post<IResultadoActualizaApi>(url, reporte, { headers: httpHeaders });
  }


  public enviarDatosEmpleado(employeeId: number, tipoChecada: string, datos: string, similitud: number, respuesta: AccesoCF_RespuestaRecognize_Respuesta | undefined): Observable<IRespuestaChecker> {
    let url = `${environment.api}/checker/infoEmpleado/${employeeId}`;
    let info: CheckerInfoEmpleados = {
      idEmpleado: employeeId,
      idEmpresa: this._accesoExpress.companyId,
      idUsuario: this._accesoExpress.ownerId,
      info: datos,
      fecha: fechaT(new Date()),
      tipoChecada: tipoChecada,
      similitud: similitud,
      resultados: respuesta
    };
    return this._httpClient.post<IRespuestaChecker>(url, info, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }

// public postIncidences(from: Date, to: Date, reportItems: EmployeeDepartmentDayCheck[]) {
//     let extras: MinifiedExtraHour[] = [];
//     let absences: MinifiedAbsence[] = [];

//     for (let i = 0; i < reportItems.length; i++) {
//         const reportItem = reportItems[i];

//         if (reportItem.incidences.extraTime) {
//             let extra = new MinifiedExtraHour();
//             extra.b = +reportItem.employee.id;
//             extra.c = reportItem.departmentId;
//             extra.d = reportItem.incidences.extraTime.date;
//             extra.g = reportItem.incidences.extraTime.value;
//             extras.push(extra);
//             continue;
//         }
//         if (reportItem.incidences.delay) {
//             let delay = new MinifiedAbsence();
//             delay.b = 0;
//             delay.c = +reportItem.employee.id;
//             delay.d = reportItem.departmentId;
//             delay.e = reportItem.incidences.delay.value / reportItem.incidences.delay.scheduledWorkDays;
//             delay.f = reportItem.incidences.delay.date;
//             delay.g = null;
//             absences.push(delay);
//             continue;
//         }
//         if (reportItem.incidences.absence) {
//             let absence = new MinifiedAbsence();
//             absence.b = 0;
//             absence.c = +reportItem.employee.id;
//             absence.d = reportItem.departmentId;;
//             absence.e = 1;
//             absence.f = reportItem.incidences.absence.date;
//             absence.g = null;
//             absences.push(absence);
//             continue;
//         }
//         if (reportItem.incidences.workedRestDay) {
//             let worked = new MinifiedAbsence();
//             worked.b = 2;
//             worked.c = +reportItem.employee.id;
//             worked.d = reportItem.departmentId;;
//             worked.e = 1;
//             worked.f = reportItem.incidences.workedRestDay.date;
//             worked.g = null;
//             absences.push(worked);
//             continue;
//         }
//     }

//     var report = new IncidencesReport();
//     report.extras = extras;
//     report.absences = absences;
//     report.from = fromClientToServerStaticDate(from);
//     report.to = fromClientToServerStaticDate(to);

//     let url = `${environment.api}/checker/incidences`;
//     return this._httpClient.post<{ to: string }>(url, report, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
// }

  public registerQrCredential(employeeId: number): Observable<EmployeeQRCredential> {
    let url = `${environment.api}/checker/qrCode/${employeeId}`;
    return this._httpClient.post<EmployeeQRCredential>(url, {}, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }

  public registerFace(employeeId: number, face: { employeeId: string, face: string }): Observable<IReconocimientoFacialRespuesta> {
      let url = `${environment.api}/checker/face/${employeeId}`;
    
    return this._httpClient.post<IReconocimientoFacialRespuesta>(url, face, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }



  public accesoExpress(): AccesoExpress {
    return this._accesoExpress;
  }

  public reconocimientoFacial(): IReconocimientoFacial [] {
    return this._reconocimientoFacial;
  }

  public obtenerEmpleado(empleadoId: string): IEmpleados | undefined {
    return this._empleados.find(x => x.id == empleadoId);
  }

  public obtenerEmpleados(): IEmpleados[] {
    return this._empleados;
  }

  public obtenerNombrePuntoAcceso(id: string) {
    let url = `${environment.api}/checker/puntoDeAcceso/${id}`;
    let registro: IRespuestaChecker = {code: 99, mensaje: 'VerificarChecks' };

    if (!this._accesoExpress.deviceId  || this._accesoExpress.deviceId.length < 36 || this._accesoExpress.deviceId.length > 50) {
      this._accesoExpress.deviceId = getUuid();
      this.datosLocales.guardarDatos('acceso', JSON.stringify(this._accesoExpress));
    }

    return this._httpClient.post(url, registro, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey, `verifPuntoDeAcceso`) })
  }

  public guardarAccesoExpress(accesoExpress: AccesoExpress) {
    // this.logAgrega2(`accesoExpress`, accesoExpress);
    this._accesoExpress = accesoExpress;
    validarAccesoExpress(this._accesoExpress);
    // this.logAgrega2(`accesoExpress`, this._accesoExpress);
    let accesoExpressTxt: string = JSON.stringify(this._accesoExpress);
    this.datosLocales.guardarDatos('acceso', accesoExpressTxt);
  }

  public restablecerDatosAccesoExpress() {
    this._accesoExpress = restaurarAccesoExpress(this._accesoExpress);
    let accesoExpressTxt: string = JSON.stringify(this._accesoExpress);
    this.datosLocales.guardarDatos('acceso', accesoExpressTxt);
  }

  public cerrarSesion() {
    this._accesoExpress.clientId = '';
    this._accesoExpress.deviceKey = '';
    let accesoExpressTxt: string = JSON.stringify(this._accesoExpress);
    this.datosLocales.guardarDatos('acceso', accesoExpressTxt);
  }

  public guardarConfig() {
    let url = `${environment.api}/checker/guardarConfig`;

    this.logAgrega2("====================================================================== guardarConfig");
    let config: AccesoExpressConfig | undefined = undefined;
    try {
      let config: AccesoExpressConfig | undefined = this.JsonConfig();
    } catch (error) {
      console.log('guardarConfig (e)');
      console.error('Error guardarConfig:', error);
      return;
    }

    if (!environment.production) {
      console.log('guardarConfig (c)');
    }

    if (!config) {
      this.logAgrega2(" guardarConfig -- nulo");
      return;
    }

    // return this._httpClient.post<AccesoExpressConfig>(url, config, { headers: getHeaders(id, this._accesoExpress.clientId ) });

  }




  public logAgrega(log: string) {
    this._log.push(log);
  }

  public logAgrega2(log: string) {
    this._log.push(log);
    console.log(log);
  }

  public log(): string[] {
    return this._log;
  }

  // public empresa(): IEmpresa {
  //   return this._empresa;
  // }

  // public empleados(): IEmpleados[] {
  //   return this._empleados;
  // }

  // public puntosDeAcceso(): IPuntoDeAcceso {
  //   return this._puntoDeAcceso;
  // }

  private JsonConfig(): AccesoExpressConfig | undefined {
    // let configJson: string = '';
    try {
      let accesoExpressConfig: AccesoExpressConfig = AccesoExpressToConfig(this._accesoExpress);
      return accesoExpressConfig;
      // configJson = JSON.stringify(accesoExpressConfig);
    } catch (error) {
      console.log('Error obtenerDatosApi - JSON.stringify');
      console.error('Error obtenerDatosApi - JSON.stringify:', error);
    }
    return undefined;
  }


}

export class IncidencesReport {
  reportId: string = '';
  absences: MinifiedAbsence[] = [];
  extras: MinifiedExtraHour[] = [];
  from: string = '';
  to: string = '';
}

export class ChecksReport {
  clientLastUpdate: string = '';
  checks: MinifiedCheck[] = [];
  manejoDescarga: number = 10;
}

export enum ManejoDescarga {
  LoMasReciente = 0,
  TodoDesde = 9,
  TodoSinAccesos = 10
}

export interface IIncidencesSum {
  absences: number;
  extras: number;
  incidences: Incidence[];
}

export class MinifiedAbsence {
  /** kind */
  b: number = 0;
  /** employeeId */
  c: number = 0;
  /** departmentID */
  d: number = 0;
  /** miltiplier */
  e: number = 0;
  /** date */
  f: Date = new Date();
  /** folio */
  g: string = '';
}

export class MinifiedExtraHour {
  /** employeeId */
  b: number = 0;
  /** departmentId */
  c: number = 0;
  /** date */
  d: Date = new Date();
  /** value */
  g: number = 0;
}

export class MinifiedCheck {
  /** id */
  a: string = '';
  /** date */
  b: Date = new Date();
  /** employeeId */
  c: string = '';
}

export interface ICompany {
  name: string;
  address: string;
  phone: string;
  mail: string;
  taxId: string;
  ownerUserId: string;
}