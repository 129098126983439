import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NomiExpressApiService } from 'src/app/core/services/NomiExpress.api.service';
import { AccesoExpress, } from 'src/app/models/accesoExpress';
import { WebcamImage } from 'ngx-webcam';
import { IRespuestaChecker } from 'src/app/models/resultadoActualiza';
import { IAccesoEmpleadoRFData, IEmpleados, nombreEmpleado, nuevoEmpleado } from 'src/app/models/empleados';
import { AccesoCF_RespuestaRecognize_Respuesta, AccesoCF_RespuestaRecognize_Subjects, IAccesoEmpleadoRF, IEmpleadoRF, nuevoEmpleadoRF } from 'src/app/models/accesoCF';
import { AccesoDatosService } from 'src/app/core/services/acceso-datos.service';
import { environment } from 'src/environments/environment';
import { TipoDateDiff, dateDiff, fFecha } from 'src/app/core/Funciones/fFecha';
import { Router } from '@angular/router';
import { IEmpresa } from 'src/app/models/empresa';
import Swal from 'sweetalert2';
import { ReconocimientoCFService } from 'src/app/core/services/reconocimiento-cf.service';
import { KeyValue } from '@angular/common';
import { HttpError } from '@microsoft/signalr';
import { Dimensiones, getImageDimensions } from 'src/app/core/Funciones/imagen';
import { mostrarSwalError } from 'src/app/core/Funciones/funciones';

@Component({
  selector: 'app-empleado-reconocimiento-facial-cf',
  templateUrl: './empleado-reconocimiento-facial-cf.component.html',
  styleUrls: ['./empleado-reconocimiento-facial-cf.component.scss']
})
export class EmpleadoReconocimientoFacialCfComponent implements OnInit, OnDestroy {
  public accesoExpress: AccesoExpress;

  // RECONOCIMIENTO FACIAL
  public mostrarFaceID: boolean = false;
  public infoCamara: string = '';

  private trigger: Subject<any> = new Subject();
  public webcamImage!: WebcamImage;
  public facingMode: string = '-'; // 'user'; // 'environment'
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();
  private _buscarRostro: any = undefined;

  public camaraAncho: number = 100;
  public camaraAlto: number = 100;

  public empleado: IEmpleados = nuevoEmpleado();
  public datosRF: IAccesoEmpleadoRF = nuevoEmpleadoRF();
  public tieneDatosRF: boolean = false;
  public datosCFTxt: string = '';
  public cargando: boolean = true;
  public guardando: boolean = false;
  public esAdmin: boolean = false;
  public error: string = '';
  public mensaje: string = '';
  public mostrarLog: boolean = true;
  private _mensaje: any = undefined;
  private _tomarFotoClick: boolean = false
  private _inicioReconocimientoFacial: Date = new Date();
  private _primeraFoto: Date = new Date();
  private cantFotos: number = 0;
  public desactivarToma: boolean = false;
  public esDebugger: boolean = false;

  public selectedFile: File | undefined = undefined;

  @Input()
  public mostrarRegistroIncidencias: boolean = false;

  constructor(
    private reconocimientoFacialService: ReconocimientoCFService,
    private nomiExpressApi: NomiExpressApiService,
    private accesoDatosService: AccesoDatosService,
    private readonly _router: Router,
  ) {
      this.cargarEmpleado();
      this.accesoExpress = this.nomiExpressApi.accesoExpress();
      this.camaraAlto = this.accesoExpress.camaraEmpleados;
      this.camaraAncho = this.accesoExpress.camaraEmpleados;
      if (isNaN(this.camaraAncho)) this.camaraAncho = 400;
      if (isNaN(this.camaraAlto)) this.camaraAlto = 400;
      this.esDebugger = !environment.production || this.accesoExpress.debug;
  }

  private cargarEmpleado() {    
    this._tomarFotoClick = false;
    this.error = '';
    this.mensaje = '';
    this.mostrarRegistroIncidencias = false;
    if (this.empleado.id == '999') {
      this.cargando = true;
      return;
    }
    this.accesoDatosService.empleadoObtenerRF().subscribe(
      (empleadoQr: IEmpleadoRF) => {
        this.empleado = empleadoQr.empleado;
        this.datosRF  = empleadoQr.empleadoRF;
        this.tieneDatosRF = !!this.datosRF.rfCode;
        this.datosCFTxt = 'no tiene';
        this.cantFotos = 0;
        if (this.empleado.tieneDatosRF) this.datosCFTxt = `tiene ${this.datosRF.datos.length}`;
        this.cargando = false;
        this.guardando = false;
        if (this.mostrarFaceID) {
          console.log(`cargarEmpleado => desactivarReconocimientoFacial`);
          this.desactivarReconocimientoFacial();
        }
        this.accesoDatosService.empresaObtener().subscribe(
          (empresa: IEmpresa) => {
            if (!empresa.apiKeyCF) {
              Swal.fire({
                html: `La empresa no esta registrada en los servidores de Reconocimiento Facial, favor de comunicarse a soporte para que sea solucionado este problema.`,
                icon: 'error',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer);
                  toast.addEventListener('mouseleave', Swal.resumeTimer);
                },
              });
            }
          }
        );
      },
      (error) => {
        console.error(error);
        let err: string = error.error;
        this.accesoDatosService.logAgrega2("Error al cargar los datos de los empleados RF. " + err);
        Swal.fire({
          html: `Error al cargar los datos de los empleados RF`,
          icon: 'error',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });
      }
    );
  }

  private ponerTxtEnConsola(txt: string) {
    console.log(txt);
    this.nomiExpressApi.logAgrega(txt);
  }

  public nombreCompleto(){
    return nombreEmpleado(this.empleado);
  }

  // public ponerMensaje(mensaje: string) {
  //   this.mensaje = mensaje;
  //   this.error = '';
  //   this._mensaje = setTimeout(() => { this.mensaje = ''; }, 5000);
  // }

  public abrirLog() {
    this.ponerTxtEnConsola('Abriendo Log');
    this._router.navigate(['log']);
  }

  public cambiarCamara(cambio: number) {
    let maxAncho: number = this.camaraAncho * cambio;
    if (maxAncho < 400) maxAncho = 400;

    this.camaraAncho = maxAncho;
    this.camaraAlto = maxAncho;

    this.ponerTxtEnConsola(`camaraAncho: ${this.camaraAncho}, camaraAlto: ${this.camaraAlto}`);
  }



  // ============================================================================================================ TOMA DE FOTO
  public inicializarReconocimientoFacial() {
    this._tomarFotoClick = false;
    this._inicioReconocimientoFacial = new Date();
    this._primeraFoto = new Date(1900, 1, 1);
    this.error = '';
    this.mensaje = '';
    this.cantFotos = 0;
    this.ponerTxtEnConsola(`>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> inicializarReconocimientoFacial. ${fFecha(this._inicioReconocimientoFacial, 'fmhs')}`);
    this.mostrarFaceID = true;
    this.mostrarRegistroIncidencias = false;
    this.desactivarToma = true;
    // this._buscarRostro = setTimeout(() => { this.tomarFoto(false); }, 5000);
  }

  public onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    this.cargarImagen();
  }

  public cargarImagen() {
    if (!this.selectedFile) return;
    let file: File = this.selectedFile;

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const img = new Image();
      let data: string = reader.result as string;
      img.src = data;
      img.onload = () => {
        const imageHeight = img.naturalHeight;
        const imageWidth = img.naturalWidth;
        if (!environment.production) console.log('Width and Height', imageWidth, imageHeight, file.size) ;
        if (imageHeight < 10 || imageHeight > 1200 || imageWidth < 10 || imageWidth > 1200) {
          console.log('Width and Height', imageWidth, imageHeight);
          mostrarSwalError('Reconocimiento facial', `El tamaño de la imagen es incorrecto, debe ser máximo de 1200x1200 pixeles (${imageHeight}x${imageWidth})`);
          return;
        }
        let txt64: string = ';base64,';
        if (file.size > 10000000) {
          txt64 = `No se puede cargar el archivo seleccionado: ${file.name}, el archivo es demasiado grande`;
          console.log(txt64, `Tamaño: ${file.size}`);
          mostrarSwalError('Reconocimiento facial', txt64);
          return;
        }
        let i: number = data.indexOf(txt64, 0);
        if (i < 0) {
          txt64 = `No se puede cargar el archivo seleccionado: ${file.name}`;
          console.log(txt64, data.substring(0, 150));
          mostrarSwalError('Reconocimiento facial', txt64);
          return;
        }

        let tipoDocumento: string = data.substring(0, i + txt64.length);
        let data64: string = data.substring(tipoDocumento.length);
        if (this.esDebugger) console.log(`Cargando archivo: ${file.name}`, data.substring(0, 100), data64.substring(0, 50));
        
        this.empleadoReconocerRostro(data64, imageWidth, imageHeight);
      };
    };
  }
  
  public desactivarReconocimientoFacial() {
    this.mostrarFaceID = false;
    this.ponerTxtEnConsola('Desactivando reconocimiento facial');
    for (let index = 0; index < +this._buscarRostro; index++) {
      if (index != this._mensaje) clearInterval(index);
    }
  }

  public desactivarTomaAutomatica() {
    this.desactivarToma = !this.desactivarToma;
    if (!this.desactivarToma) {
      console.log(`Reactivando toma automática de imagen`);
      this.cantFotos = 0;
      this._buscarRostro = setTimeout(() => { this.tomarFoto(false); }, 1000);
    } else {
      console.log(`Desactivando toma automática de imagen`);
      for (let index = 0; index < +this._buscarRostro; index++) {
        if (index != this._mensaje) clearInterval(index);
      }
    }
  }

  public tomarFoto(tomarFotoClick: boolean) {
    if (!tomarFotoClick && this.desactivarToma) {
      console.log(`Desactivando toma automática de imagen (tomarFoto)`);
      for (let index = 0; index < +this._buscarRostro; index++) {
        if (index != this._mensaje) clearInterval(index);
      }
      return;
    }
    if (!tomarFotoClick && (this.cantFotos > 25 || !this.mostrarFaceID)) {
      if (this.cantFotos > 25) {
        Swal.fire({
          html: `Se ha excedido el tiempo para la toma de la foto del empleado.`,
          icon: 'warning',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          }
        });
      }
      this.desactivarReconocimientoFacial();
      return;
    }
    if (tomarFotoClick || this._tomarFotoClick) {
      console.log(`=========================================>   tomarFoto --> click, desactivarToma: ${this.desactivarToma}`);
    } else {
      console.log(`tomarFoto, desactivarToma: ${this.desactivarToma}`);
    }
    // this.ponerTxtEnConsola(`tomarFoto. trigger. IdEmpleado: ${this.empleado.id}`);
    this.trigger.next(void 0);
    if (tomarFotoClick || this._tomarFotoClick) {
      this._tomarFotoClick = true;
      Swal.fire({
        text: 'Tomando foto al empleado',
        icon: 'info',
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
        position: 'top-end',
        toast: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
      });
      return;
    }
    this._buscarRostro = setTimeout(() => { this.tomarFoto(false); }, 2500);
  }

  public get invokeObservable(): Observable<any> {
    return this.trigger.asObservable();
  }

  public get videoOptions(): MediaTrackConstraints {
    const result: MediaTrackConstraints = {};
    if (this.accesoExpress.facingMode && this.accesoExpress.facingMode !== '') {
        if (this.facingMode != this.accesoExpress.facingMode) {
          this.ponerTxtEnConsola(`>>>>>>>>>>>>>>>>>>>>>>>>> facingMode: ${this.accesoExpress.facingMode}`)
          this.facingMode = this.accesoExpress.facingMode;
        }
        result.facingMode = { ideal: this.accesoExpress.facingMode };
    }
    return result;
  }

  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }

  public async captureImg(webcamImage: WebcamImage): Promise<void> {
    this.webcamImage = webcamImage;
    let sysImage: string = webcamImage!.imageAsDataUrl;
    let foto64: string = webcamImage!.imageAsBase64;

    if (!foto64) {
      this.ponerTxtEnConsola(`captureImg. foto: null... sysImage: ${sysImage.substring(0, 50)}..., IdEmpleadoFoto: ${this.empleado.id}`);
      return;
    }

    let dimensiones: Dimensiones = new Dimensiones();
    try {
      dimensiones = await getImageDimensions('data:image/jpg;base64,' + foto64);
    } catch {
      console.log(`No se pudieron obtener datos de la imagen seleccionada`);      
    }     
    let imageWidth: number =  dimensiones.ancho == 0 ? this.camaraAncho : dimensiones.ancho;
    let imageHeight: number = dimensiones.alto == 0 ? this.camaraAlto : dimensiones.alto;

    if (this._primeraFoto < this._inicioReconocimientoFacial) {
      this._primeraFoto = new Date();
      console.log(`Primera foto: ${fFecha(this._primeraFoto, 'fmhs')}, dif: ${dateDiff(this._inicioReconocimientoFacial, this._primeraFoto, TipoDateDiff.segundos)} segundos`);

    }
    // this.ponerTxtEnConsola(`captureImg - empleado. Toma de foto al empleado: ${this.empleado.id}, imagen: ${foto.substring(0, 30)}...`);

    if (this._tomarFotoClick) {
      console.log(`Tomando foto click`);
    }

    this.cantFotos++;
    this.empleadoReconocerRostro(foto64, imageWidth, imageHeight);
  }

  private empleadoReconocerRostro(foto64: string, imageWidth: number, imageHeight: number) {
    this.reconocimientoFacialService.empleadoReconocerRostro(foto64).subscribe( (respuesta: AccesoCF_RespuestaRecognize_Respuesta) => {
      let similitud: number = respuesta.subjects.similarity;
      let txt: string = `Semejanza: ${similitud}, Mensaje: ${respuesta.subjects.subject}, IdEmpleadoFoto ${this.empleado.id}`;
      this.ponerTxtEnConsola(`=========================================  captureImg ${this.cantFotos}. empleadoReconocerRostro. ${txt}`);      
      if (similitud > this.accesoExpress.validarReconocimiento) {
        let box_x: number = 0;
        let box_y: number = 0;
        if (!!respuesta.box) {
          box_x = respuesta.box.x_max - respuesta.box.x_min;
          box_y = respuesta.box.y_max - respuesta.box.y_min;
        }
        if (this.esDebugger) console.log(`empleadoReconocer --> similarity`, respuesta.subjects, respuesta.box);

        let validarPorcentajeImagen: number = this.accesoExpress.validarPorcentajeImagen * 100;
        if (validarPorcentajeImagen < 25) {
          if (this.esDebugger) {
            console.log(`Se cambió el valor mínimo del porcentaje de la imagen de ${(validarPorcentajeImagen).toFixed(2)}% a 25%`);
          }
          validarPorcentajeImagen = 25;          
        }

        let propX: number = box_x * 100 / imageWidth;
        let propY: number = box_y * 100 / imageHeight;
        if (propX > 0) {
          if (propX < validarPorcentajeImagen) {
            txt = `Imagen demasiado pequeña. ${propX.toFixed(2)}% x`;
            if (this.esDebugger) console.log(txt);
            this.infoCamara = txt;
            return;
          }
        }
        if (propY > 0) {          
          if (propY < validarPorcentajeImagen) {
             txt = `Imagen demasiado pequeña. ${propY.toFixed(2)}% y`;
            if (this.esDebugger) console.log(txt);
            this.infoCamara = txt;
            return;
          }
        }

        if (respuesta.box.x_max > 0) {          
          let x1: number = respuesta.box.x_min;
          let x2: number = imageWidth - respuesta.box.x_max;
          let diferenciaActualX: number = Math.abs(x1 - x2);
          let diferenciaMaxX: number = (imageWidth - box_x)  * 0.5;          
          
          let y1: number = respuesta.box.y_min;
          let y2: number = imageHeight - respuesta.box.y_max;
          let diferenciaActualY: number = Math.abs(y1 - y2);
          let diferenciaMaxY: number = (imageHeight - box_y) * 0.6;

          if ((diferenciaActualX > diferenciaMaxX) || (diferenciaActualY > diferenciaMaxY)) {
            console.log(`diferenciaActualX: ${diferenciaActualX}, diferenciaMaxX: ${diferenciaMaxX}, x1: ${x1}, x2 ${x2}, imageWidth: ${imageWidth}, box.x_max: ${respuesta.box.x_max}`);
            console.log(`diferenciaActualY: ${diferenciaActualY}, diferenciaMaxY: ${diferenciaMaxY}, y1: ${y1}, y2 ${y2}, imageHeight: ${imageHeight}, box.y_max: ${respuesta.box.y_max}`);
            txt = `La imagen no esta centrada.`;            
            if (this.esDebugger) {
              txt += `        x1: ${x1}, x2: ${x2}, w: ${box_x}, dm: ${diferenciaMaxX}, d: ${diferenciaActualX} ---`;
              txt += `        y1: ${y1}, y2: ${y2}, a: ${box_y}, dm: ${diferenciaMaxY}, d: ${diferenciaActualY}`;              
              console.log(txt);
            }
            this.infoCamara = txt;
            return;
          }
        }
        
        let now = new Date();
        if (this.esDebugger || (now.getFullYear() == 2024 && now.getMonth() == 8 && now.getDay() == 17)) {
          txt = `Prop X: ${propX.toFixed(2)}, Prop Y: ${propY.toFixed(2)}`;
          this.infoCamara = txt;
          console.log(txt);
        }
        
        this.infoCamara = 'Guardando datos en el servidor';
        this.desactivarReconocimientoFacial();
        this.nomiExpressApi.enviarDatosEmpleado(+this.empleado.id, 'ep', foto64, similitud, respuesta).subscribe( (x: IRespuestaChecker) => {
          this.ponerTxtEnConsola(`=========================================  captureImg ${this.cantFotos}. enviarDatosEmpleado. Código: ${x.code}, Mensaje: ${x.mensaje}, IdEmpleadoFoto ${this.empleado.id}`);
          Swal.fire({
            text: 'Imagen guardada en el servidor',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            position: 'top-end',
            toast: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
          });
          this.cargarEmpleado();
        }, (err: Error) => {
          let txt: string = err.message;
          this.ponerTxtEnConsola(`=========================================  captureImg ${this.cantFotos}. enviarDatosEmpleado. Error: ${txt}`);
          Swal.fire({
            html: txt,
            icon: 'error',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
          });
        });
      } else if (respuesta.subjects.similarity < 0.5) {
        this.infoCamara = 'Sin rostro';
        if (this._tomarFotoClick) {
          mostrarSwalError('Toma de foto', 'No se encontró rostros en la imagen');
          this.mostrarFaceID = false;
        }
      } else {
        this.infoCamara = 'No se detecta rostro';
        mostrarSwalError('Toma de foto', 'El rostro no tiene similitud con el empelado seleccionado');        
        this.mostrarFaceID = false;        
      }

    }, (err: HttpError) => {
      let txt: string = err.message;

      this.ponerTxtEnConsola(`=========================================  captureImg. enviarDatosEmpleado. Error: ${txt}`);
      Swal.fire({
        html: txt,
        icon: 'error',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
      });
    });
  }
  

  public formatoFecha(fechaTxt: string | undefined): string {
    if (!fechaTxt) {
      return '-';
    }
    const fecha = new Date(fechaTxt);
    return fFecha(fecha, "fsl");
  }

  public formatoFechaHora(fechaTxt: string | undefined): string {
    if (!fechaTxt) {
      return '-';
    }
    const fecha = new Date(fechaTxt);
    return fFecha(fecha, "fmh");
  }

  public verDetalles(datosRF: IAccesoEmpleadoRFData) {
    let isDebugging: boolean = this.accesoDatosService.getModoDebug();
    let txt: string = '';
    let title: string = '';
    if (datosRF.similarity > 0) {
      title = 'Semejanza';
      txt = `${datosRF.similarity}`;
    }
    if (isDebugging && !datosRF.box) {
      title += 'Box'
      txt += `${datosRF.box}`;
    }

      Swal.fire({
        html: `
          <p style="margin: 5px 0; font-weight: bold;">${this.nombreCompleto()}</p>
          <div class="d-flex justify-content-center">
            <p class="mb-0">
              <b class="fw-semibold">Tomada el: </b>${this.formatoFechaHora(datosRF.fechaTxt)}
            </p>
          </div>
          <div class="d-flex justify-content-center">
            <p class="mb-0">
              <b class="fw-semibold">Punto de acceso: </b>${datosRF.deviceId}
            </p>
          </div>
          <div class="d-flex justify-content-center">
            <p class="mb-0">
              <b class="fw-semibold">${title}: </b>${txt}
            </p>
          </div>
        `,
        imageUrl: `data:image/jpg;base64,${datosRF.datos}`,
        imageWidth: 350,
        imageHeight: 'auto',
        showCancelButton: true,
        cancelButtonText: 'Cerrar',
        confirmButtonColor: 'red',
        cancelButtonColor: 'green',
        confirmButtonText: 'Eliminar',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.eliminarImagen(datosRF.idCf);
        }
      });
    }

  public eliminarImagen(idImagen: string) {
    this.mensaje = 'Eliminando imagen';
    this.reconocimientoFacialService.empleadoEliminarFoto(this.empleado.id, idImagen).subscribe(
      (respuesta: IRespuestaChecker) => {
        this.mensaje = '';
        if (respuesta.code == 100) {
          this.cargando = true;
          Swal.fire({
            text: 'La imagen ha sido eliminada',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            position: 'top-end',
            toast: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
          }).then( () => {
            this.cargarEmpleado();
          }
          );
        } else {
          Swal.fire({
            title: 'Eliminado!',
            html: `No ha sido posible eliminar la imagen. Código: ${respuesta.code}, Mensaje: ${respuesta.mensaje}`,
            icon: 'error',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
          });
        }
      }, (error) => {
        console.error(error);
        let err: string = error.error;
        this.accesoDatosService.logAgrega2("Error al eliminar la imagen del empleados RF. " + err);
        Swal.fire({
          title: 'Eliminado!',
          text: 'Error al eliminar la imagen del empleado RF',
          icon: 'error',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });
      }
    );
  }

  public ordenarDatos() {
    this.datosRF.datos.sort((a, b) => new Date(a.fechaTxt).getTime() - new Date(b.fechaTxt).getTime());
  }

  public valueDescOrder = (a: KeyValue<string, IAccesoEmpleadoRFData>, b: KeyValue<string, IAccesoEmpleadoRFData>): number => {
    const fechaA = new Date(a.value.fechaTxt);
    const fechaB = new Date(b.value.fechaTxt);
    return fechaB.getTime() - fechaA.getTime();
  };

  public handleCerrarRegistroIncidencias() {
    this.mostrarRegistroIncidencias = false;
  }

  ngOnInit(): void {
    this.esAdmin = !environment.production;
    this.ordenarDatos();
  }

  ngOnDestroy(): void {
    console.log('empleado-reconocimiento-facial-cf, ngOnDestroy');
    this.desactivarReconocimientoFacial();
  }
}

