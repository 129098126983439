export class ModelState {
  constructor(errors?: { [key: string]: string }) {
      this.errors = errors || {};
  }

  public get isValid(): boolean {

      const isValid = !this.errors || !this.hasAnyOwnProperty(this.errors);

      if (!isValid) {
          console.error('one or more validation errors occurred.' + JSON.stringify(this.errors));
      }

      return isValid;
  }

  public errors: { [key: string]: string } = {};

  private hasAnyOwnProperty(o: any) {
      for (const key in o) {
          if (o.hasOwnProperty(key) && o[key]) {
              return true;
          }
      }
      return false;
  }
}
