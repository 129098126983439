import { formatNumber, registerLocaleData } from "@angular/common";
import localeMx from "@angular/common/locales/eS-MX";

export function newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function fechaT(fecha: Date): string {
  // console.log(fechaT);
  if (!fecha) return "1900-01-01T00:00:00";
  let fechaMs: number = fechaGetTime(fecha);
  if (fechaMs < 1) return "1900-01-01T00:00:00";
  let año: string = fecha.getFullYear().toString().padStart(4, "0");
  let mes: string = (1 + fecha.getMonth()).toString().padStart(2, "0");
  // console.log(año + "-" + mes);
  let fechaTxt: string = año + "-" + mes  + "-" +
    fecha.getDate().toString().padStart(2, "0") + "T" +
    fecha.getHours().toString().padStart(2, "0") + ":" +
    fecha.getMinutes().toString().padStart(2, "0") + ":" +
    fecha.getSeconds().toString().padStart(2, "0");
  return fechaTxt;
}

export function fechaGetTime(fecha: Date): number {
  let timeMS: number = 0;
  try {
    timeMS = fecha.getTime();
    // console.log(`FechaMS: ${timeMS}`);
  } catch {
    return 0;
  }
  return timeMS;
}

export function fechaUTC(fecha: Date): string {
  if (!fecha || fecha.getTime() < 100) return "1900-01-01T00:00:00";
  let fechaTxt: string = fecha.getUTCFullYear().toString().padStart(4, "0") + "-" +
    (1 + fecha.getUTCMonth()).toString().padStart(2, "0") + "-" +
    fecha.getUTCDate().toString().padStart(2, "0") + "T" +
    fecha.getUTCHours().toString().padStart(2, "0") + ":" +
    fecha.getUTCMinutes().toString().padStart(2, "0") + ":" +
    fecha.getUTCSeconds().toString().padStart(2, "0");
  return fechaTxt;
}

  export function getDateT(fecha: Date | undefined): string | undefined {
    if (!fecha || fecha.getTime() < 100) return undefined;
    let fechaTxt: string = fecha.getFullYear().toString().padStart(4, "0") + "-" +
      (1 + fecha.getMonth()).toString().padStart(2, "0") + "-" +
      fecha.getDate().toString().padStart(2, "0") + "T" +
      fecha.getHours().toString().padStart(2, "0") + ":" +
      fecha.getMinutes().toString().padStart(2, "0") + ":" +
      fecha.getSeconds().toString().padStart(2, "0");
    return fechaTxt;
  }

  export function getDateText(fecha: Date | undefined): string | undefined {
    if (!fecha) return undefined;
    let fechaTxt: string = fecha.getFullYear().toString().padStart(4, "0") + "-" +
      (1 + fecha.getMonth()).toString().padStart(2, "0") + "-" +
      fecha.getDate().toString().padStart(2, "0");
    return fechaTxt;
  }

  export function parseJwt(token: string) {
    if (!token) return undefined;
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  export function getFechaF2(fecha: Date) {
    return `${fecha.getDate().toString().padStart(2, '0')}/${(fecha.getMonth() + 1).toString().padStart(2, '0')}/${fecha.getFullYear().toString().substring(2, 4)}`;
  }

  export function getFechaFH2(fecha: Date) {
    return `${getFechaF2(fecha)} ${(fecha.getHours()).toString().padStart(2, '0')}:${(fecha.getMinutes()).toString().padStart(2, '0')}`;
  }

  export function cvNumero(numeroTxt: string): number {
    let numero: number = +numeroTxt;
    if (!isNaN(numero)) return numero;
    numero = Number(numeroTxt.replace(/[$@%]/g, ''));
    if (!isNaN(numero)) return numero;
    return 0;
  }

  export function CS_QuitarEspaciosGuiones(texto: string) {
    let txt = texto.replace(/ /g, ""); // quitarEspacios
    txt = txt.replace(/-/g, ""); // quitarGuiones
    return txt.replace(/_/g, ""); // quitarSeparadores
  }

export function CS_ConDatos(texto: string): boolean {
  if (!texto) return false;
  if (texto.trim().length < 1) return false;
  return true;
}

export async function sha256(message: string) {
  const msgBuffer = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => ('00' + b.toString(16)).slice(-2)).join('');
  return hashHex;
}

export function CS_QuitarPunto(texto: string) {
  return  texto.replace(/\./g, "");
}

export function eliminarDiacriticosEs(texto: string) {
  return texto
  .normalize('NFD')
  .replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1")
  .normalize();
}

export function fNumero(numero:number, formato: string): string {
  if (+numero == 0 && formato.endsWith('-')) return '-';
  if (!formato) formato = 'MilesF2';

  registerLocaleData(localeMx, 'eS-MX');

  let formatoTxt = formato.replace('-', '');
  switch (formatoTxt) {
    case 'MilesF2':
    case 'MF2':
    case 'F2':
      return formatNumber(numero, 'eS-MX', '1.2-2');

    case 'MilesD2':
    case 'MD2':
    case 'D2':
    case 'd2':
      return formatNumber(numero, 'eS-MX', '1.0-2');

    case 'Miles6':
    case 'M6':
      return formatNumber(numero, 'eS-MX', '1.2-6');

    case 'hm':
      if (numero == 0) return '-';
      var hora: number = Math.floor(numero);
      var minutos: number = Math.floor((numero - hora) * 60);
      // var formatoHora: string = `${hora > 0 ? `${hora} hora${hora != 1 ? 's' : ''}` : ''}`;
      if (hora == 0 && minutos == 0) return '-';
      // return `${formatoHora} ${minutos} minutos` ;
      return `${hora} hora${hora != 1 ? 's' : ''} ${minutos} minutos`;

    case 'hms':
      if (numero == 0) return '-';
      var hora: number = Math.floor(numero);
      var minutos: number = Math.floor((numero - hora) * 60);
      var segundos: number = Math.floor((numero - hora - (minutos / 60)) * 60 * 60);
      if (hora == 0 && minutos == 0 && segundos == 0) return '-';
      return `${hora} hora${hora != 1 ? 's' : ''} ${minutos} minutos ${segundos} segundos`;

    case '%':
      if (numero == 0) return '-';
      let porcentaje: number = numero * 100;
      return `${formatNumber(porcentaje, 'eS-MX', '1.2-2')} %`;

    case 'cf':
      return numero.toString().padStart(10, '0');  //0000000000

    default:
      return numero.toString();
  }
}
