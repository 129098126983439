import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IEmpleados } from 'src/app/models/empleados';
import { IHorarios } from 'src/app/models/horarios';

@Component({
  selector: 'app-horario-dialog',
  templateUrl: './horario-dialog.component.html',
  styleUrls: ['./horario-dialog.component.scss']
})
export class HorarioDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<HorarioDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { horario: IHorarios, nombreEmpleado: IEmpleados}
  ) {}

  ngOnInit(): void {}
}
