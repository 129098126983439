<p>recargar works!</p>

<div class="fixed-top">
  <div class="text-center p-5">
      <img src="../../../../assets/images/ACE_Acceso-Express.png" class="pt-3 img-fluid" width="150" height="150"/>
  </div>
  <div class="text-center">
      <mat-spinner class="img-center"></mat-spinner>
  </div>
  <div class="text-center py-5">
      <span>Cargando datos...</span>
  </div>
</div>
