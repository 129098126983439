import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccesoExpress, nuevoAccesoExpress, validarAccesoExpress, validarReconocimiento, verificarReconocimientoFacial } from 'src/app/models/accesoExpress';
import { environment } from 'src/environments/environment';
import { LocalService } from './local.service';
import { getHeaders, getHeadersCF, getHeadersFetch, getHeadersFetch2CF, getHeadersFetchCF } from '../Funciones/funciones';
import { CS_ConDatos, fechaT, fNumero } from '../Funciones/fTexto';
import { AccesoCF_EmpleadoFoto, AccesoCF_EmpleadosListado, AccesoCF_File, AccesoCF_RespuestaEmpleadosListado, AccesoCF_RespuestaListoImagenes, AccesoCF_RespuestaRecognize, AccesoCF_RespuestaRecognize_Respuesta, AccesoCF_RespuestaRecognize_ResultVerify, AccesoCF_RespuestaRecognize_Subjects, AccesoCF_RespuestaVerify, nuevaAge, nuevaBox, nuevaMask, nuevoGender } from 'src/app/models/accesoCF';
import { Observable, of, throwError } from 'rxjs';
import { TipoDateDiff, dateDiffAhora } from '../Funciones/fFecha';
import { catchError, map } from 'rxjs/operators';
import { IResponseChecker, IRespuestaChecker, IRespuestaEmpleadoListadoImagenesCF, IRespuestaEmpleadosListadoImagenesCF } from 'src/app/models/resultadoActualiza';
import { identifierName } from '@angular/compiler';
import { TipoRevisarImagenContraRegistro } from 'src/app/models/datosEmpleadosAcceso';
import { resolve } from 'dns';

@Injectable({
  providedIn: 'root'
})
export class ReconocimientoCFService {

  private urlBaseCF_http = `http://74.208.43.216:8000/api/v1`;
  private urlBaseCF = `https://ace.acexpress.com.mx/api/v1`;
  private urlBaseAcceso = `${environment.api}/acceso`;
  private apiKeyReconocerRostro = "d68e1711-d5fd-4609-915f-0ef801dc63bf";
  private soloUsarServidorACE: boolean = false;

  private _accesoExpress: AccesoExpress;
  private _log: string[] = [];

  private _listadoEmpleados: string [] = [];
  private _ultimaVerificaCF: Date = new Date(1900, 0, 1);

  private _det_prob_threshold: string = '0.9';
  private _face_plugins: string = 'gender,age,mask,pose';
  // private _face_plugins: string = 'landmarks,gender,age,calculator,mask,pose';

  constructor(
    private readonly _httpClient: HttpClient,
    private datosLocales: LocalService
  ) {
    let datosAcceso: string = this.datosLocales.obtenerDatos('acceso');
    if (CS_ConDatos(datosAcceso)) {
      this._accesoExpress = JSON.parse(datosAcceso);
      if (this._accesoExpress.imagenUrl && this._accesoExpress.imagenUrl.substring(0, 1) == '$') {
        this._accesoExpress.imagenUrl = '';
      }
      this._accesoExpress.faceRecognitionDebugMode = false;
    } else {
      this._accesoExpress = nuevoAccesoExpress();      
    }

    validarAccesoExpress(this._accesoExpress);
    if (!this._accesoExpress.apiKeyCF) this.logAgrega2('No existe la ApiKey de la empresa actual');

    this.empresaCFActiva().subscribe(
      (activa: boolean) => {
        // if (this._accesoExpress.debug) this.logAgrega2(`====================================================================== empresaCFActiva: ${activa}, empleados: ${this._listadoEmpleados.length}`);
        if (!activa && !!this._accesoExpress.apiKeyCF) {          
          this.empresaVerificarEmpleadosCF();
          if (this._accesoExpress.debug) this.logAgrega2(`====================================================================== empresaCFActiva (2): ${activa}, empleados: ${this._listadoEmpleados.length}`);
        }
      }
    );
  }
  
  public empresaCFActiva(): Observable<boolean> {
    if (dateDiffAhora(this._ultimaVerificaCF, TipoDateDiff.horas ) < 1) {
      if (this._accesoExpress.debug) this.logAgrega2(`_ultimaVerificaCF: ${this._ultimaVerificaCF}`);
      return of(this._listadoEmpleados.length > 0);
    }

    if (!this._accesoExpress.apiKeyCF) {
      if (this._accesoExpress.debug) this.logAgrega2('No existe la ApiKey de la empresa actual');
      return of(false);
    }

    if (this._accesoExpress.servidorCF.startsWith('NM') || this.soloUsarServidorACE) {
      if (this._accesoExpress.debug) this.logAgrega2(`empresaCFActiva. servidor NM`);
      let url = `${this.urlBaseAcceso}/empresa/verificarEmpleadosCF`;
      return this._httpClient.get<AccesoCF_EmpleadosListado>(url, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) }).pipe(
        map(
        (empleados: AccesoCF_EmpleadosListado) => {
          if (this._accesoExpress.debug) this.logAgrega2(`Verificando empleados CFD desde el servidor. Empleados: ${empleados.subjects.length}`);
          this._listadoEmpleados = empleados.subjects;
          this._ultimaVerificaCF = new Date();
          return this._listadoEmpleados.length > 0;
        }, (error: Error) => {
            this.logAgrega2('Error al verificar desconocido en CF');
            this.logAgrega2(error.message);
            console.error(error);
            return false;
        }
      ));      
    }
    
    this.logAgrega2(`empresaCFActiva. servidor CF`);
    let url = `${this.urlBaseCF}/recognition/subjects`;
    return this._httpClient.get<AccesoCF_EmpleadosListado>(url, { headers: getHeadersCF(this._accesoExpress.apiKeyCF) }).pipe(
      map(
        (listado: AccesoCF_EmpleadosListado) => {
          this._listadoEmpleados = listado.subjects;
          this._ultimaVerificaCF = new Date();
          return this._listadoEmpleados.length > 0;
        }, (error: Error) => {
          this.logAgrega2('Error al obtener los datos del listado CF de los empleados');
          this.logAgrega2(error.message);
          console.error(error);
          return false;
        }

      )
    );
  }

  public empresaVerificarEmpleadosCF() {
    if (this._accesoExpress.servidorCF.startsWith('NM') || this.soloUsarServidorACE) {
      if (this._accesoExpress.debug) this.logAgrega2(`empresaVerificarEmpleadosCF. servidor NM`);
      let url = `${this.urlBaseAcceso}/empresa/verificarEmpleadosCF`;
      this._httpClient.get<AccesoCF_EmpleadosListado>(url, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) }).subscribe(
        (empleados: AccesoCF_EmpleadosListado) => {
          if (this._accesoExpress.debug) this.logAgrega2(`Verificando empleados CF desde el servidor. Empleados: ${empleados.subjects.length}`);
          this._listadoEmpleados = empleados.subjects;
          this._ultimaVerificaCF = new Date();
        }, (error: Error) => {
            this.logAgrega2('Error al verificar desconocido en CF');
            this.logAgrega2(error.message);
            console.error(error);
        }
      );
    } else {
      if (this._accesoExpress.debug) this.logAgrega2(`empresaVerificarEmpleadosCF. servidor CF`);
      let url = `${this.urlBaseCF}/recognition/subjects`;
      this._httpClient.get<AccesoCF_EmpleadosListado>(url, { headers: getHeadersCF(this._accesoExpress.apiKeyCF) }).subscribe(      
        (empleados: AccesoCF_EmpleadosListado) => {
          if (this._accesoExpress.debug) this.logAgrega2(`Verificando empleados CF desde el servidor CF. Empleados: ${empleados.subjects.length}`);
          this._listadoEmpleados = empleados.subjects;
          this._ultimaVerificaCF = new Date();
          return this._listadoEmpleados.length > 0;
        }, (error: Error) => {
          this.logAgrega2('Error al verificar desconocido en CF');
          this.logAgrega2(error.message);
          console.error(error);
        }     
      );
    }
  }

  public empleadoReconocer(img64: string): Observable<AccesoCF_RespuestaRecognize_Respuesta> {
    if (this._accesoExpress.servidorCF.startsWith('NM') || this.soloUsarServidorACE) {  
      if (this._accesoExpress.debug) this.logAgrega2(`empleadoReconocer. servidor NM`);
      return this.empleadoReconocerViaServidor(img64);
    }

    if (this._accesoExpress.debug) this.logAgrega2(`empleadoReconocer. servidor CF`);

    let url = `${this.urlBaseCF}/recognition/recognize`;
    let params: HttpParams = new HttpParams();
    params.append('limit', 1);
    params.append('det_prob_threshold', this._det_prob_threshold);
    params.append('prediction_count', 1);
    params.append('face_plugins', this._face_plugins);
    params.append('status', true);
    params.append('detect_faces', true);

    let httpHeaders: HttpHeaders = getHeadersCF(this._accesoExpress.apiKeyCF);
    let respuesta: AccesoCF_RespuestaRecognize_Respuesta = {
      age: nuevaAge(),
      gender: nuevoGender(),
      box: nuevaBox(),
      mask: nuevaMask(),
      subjects: {
        similarity: 0,
        subject: ''
      }
    };

    return this._httpClient.post<AccesoCF_RespuestaRecognize>(url, { file: img64 }, { headers: httpHeaders, params: params }).pipe(
      map(
        (resultado: AccesoCF_RespuestaRecognize) => {
          if (this._accesoExpress.debug) this.logAgrega2(`Mapeo CF online.`);          

          for (let index = 0; index < resultado.result.length; index++) {
            const result = resultado.result[index];
            for (let i = 0; i < result.subjects.length; i++) {
              const subject = result.subjects[i];
              if (subject.similarity > respuesta.subjects.similarity) {
                respuesta.subjects.similarity = subject.similarity;
                respuesta.subjects.subject = subject.subject;
                respuesta.age = result.age;
                respuesta.gender = result.gender;
                respuesta.box = result.box;
                respuesta.mask = result.mask;
              }
            }            
          }    
          return respuesta;
        }
      ), catchError (err => {        
        throwError(err);
        return of(respuesta);
      })
    );
  }

  public empleadoReconocerRostro(img64: string): Observable<AccesoCF_RespuestaRecognize_Respuesta> {    
    if (this._accesoExpress.servidorCF.startsWith('NM') || this.soloUsarServidorACE) {
      return this.empleadoReconocerRostroViaServidor(img64);
    }

    // if (this._accesoExpress.debug) this.logAgrega2(`empleadoReconocerRostro. servidor CF`);

    let url = `${this.urlBaseCF}/detection/detect`;
    let params: HttpParams = new HttpParams();
    params.append('limit', 1);
    params.append('det_prob_threshold', this._det_prob_threshold);
    params.append('face_plugins', this._face_plugins);
    params.append('status', true);

    let respuesta: AccesoCF_RespuestaRecognize_Respuesta = {
      age: nuevaAge(),
      gender: nuevoGender(),
      box: nuevaBox(),
      mask: nuevaMask(),
      subjects: {
        similarity: 0,
        subject: ''
      }
    };

    let httpHeaders: HttpHeaders = getHeadersCF(this.apiKeyReconocerRostro);

    return this._httpClient.post<AccesoCF_RespuestaRecognize>(url, { file: img64 }, { headers: httpHeaders, params: params }).pipe(
      map(
        (resultado: AccesoCF_RespuestaRecognize) => {
          // if (this._accesoExpress.debug) this.logAgrega2(`empleadoReconocerRostro. servidor CF - Map`);

          for (let index = 0; index < resultado.result.length; index++) {
            const result = resultado.result[index];            
            const subject = result.box;
            if (subject.probability > respuesta.subjects.similarity) {
              respuesta.subjects.similarity = subject.probability;
              respuesta.age = result.age;
              respuesta.gender = result.gender;
              respuesta.mask = result.mask;
              respuesta.box = result.box;
            }
            respuesta.subjects.subject = subject.probability > 0.9 ? "Con rostro" : "Sin rostro";
          }
          return respuesta;
        }, (resultadoNoReconocido: IResponseChecker) => {
          // if (this._accesoExpress.debug) this.logAgrega2(`empleadoReconocerRostro. servidor CF - Map (2) ==> Código: ${resultadoNoReconocido.code}, Mensaje: ${resultadoNoReconocido.message}`);
          respuesta.subjects.subject = `Código: ${resultadoNoReconocido.code}, Mensaje: ${resultadoNoReconocido.message}`;
          return respuesta;
        }
      ), catchError (err => {
        let mensaje: string = err.message;
        let code: number = err.code || 0;
        respuesta.subjects.subject = mensaje;
        if (mensaje == 'No face is found in the given image' || code == 28) {
          if (code > 0) mensaje = `Código: ${code}, Mensaje: ${mensaje}`;
          respuesta.subjects.subject = mensaje;          
        }
        this.logAgrega2(`empleadoReconocerRostro. servidor CF - Map (2) ==> ${mensaje}`);
        // console.log(err);        
        if (mensaje == 'Http failure response for https://ace.acexpress.com.mx/api/v1/detection/detect: 0 Unknown Error') {
          this.soloUsarServidorACE = true;
          this.logAgrega2(`empleadoReconocerRostro. activando soloUsarServidorACE`);
        }
        return of(respuesta);
      })
    );
  }

  public empleadoEliminarFoto(idEmpleado: string, idImagen: string): Observable<IRespuestaChecker> {        
    if (this._accesoExpress.debug) this.logAgrega2(`CFR online. ${!this._accesoExpress.apiKeyCF}, ${this._listadoEmpleados.length}`);      
    return this.empleadoEliminarFotoViaServidor(idEmpleado, idImagen);    
  }

  public empleadoListadoImagenesCF(idEmpleado: number): Observable<IRespuestaEmpleadoListadoImagenesCF> {
    if (this._accesoExpress.servidorCF.startsWith('NM') || this.soloUsarServidorACE) {
      let url = `${this.urlBaseAcceso}/empleados/empleadoListadoImágenesCF`;
      return this._httpClient.post<IRespuestaEmpleadoListadoImagenesCF>(url, idEmpleado, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
    } else {
      let url = `${this.urlBaseCF}/recognition/faces`;
      let params: HttpParams = new HttpParams();
      params.append('subject', fNumero(idEmpleado, ''));

      let httpHeaders: HttpHeaders = getHeadersCF(this._accesoExpress.apiKeyCF);

      return this._httpClient.get<AccesoCF_RespuestaListoImagenes>(url,{ headers: httpHeaders, params: params }).pipe(
        map(
          (resultado: AccesoCF_RespuestaListoImagenes) => {
            let imágenes: string[] = [];
            resultado.faces.forEach(
              (x) => {
                imágenes.push(x.image_id);
              }
            )
            return {
              code: 100,
              mensaje: 'datos cargados',
              imágenes: imágenes
            };
          }, (error: Error) => {
            this.logAgrega2('Error al verificar desconocido en CF');
            this.logAgrega2(error.message);
            console.error(error);
          }
        )
      );
    }
  }

  public empleadosListadoImagenesCF(): Observable<IRespuestaEmpleadosListadoImagenesCF> {
    if (this._accesoExpress.servidorCF.startsWith('NM') || this.soloUsarServidorACE) {
      let url = `${this.urlBaseAcceso}/empleados/empleadosListadoImágenesCF`;
      return this._httpClient.get<IRespuestaEmpleadosListadoImagenesCF>(url, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
    } else {
      let url = `${this.urlBaseCF}/recognition/faces`;
      
      let httpHeaders: HttpHeaders = getHeadersCF(this._accesoExpress.apiKeyCF);

      return this._httpClient.get<AccesoCF_RespuestaListoImagenes>(url,{ headers: httpHeaders }).pipe(
        map(
          (resultado: AccesoCF_RespuestaListoImagenes) => {
            let imágenesEmpleado: { [empleado: number]: string[] } = {};
            resultado.faces.forEach(
              imagen => {
                let key: number = +imagen.subject;
                if (!(key in imágenesEmpleado)) {
                  imágenesEmpleado[key] = [];          
                }
                imágenesEmpleado[key].push(imagen.image_id);
              }
            );
            
            return {
              code: 100,
              mensaje: 'datos cargados',
              imágenes: imágenesEmpleado
            };
          }, (error: Error) => {
            this.logAgrega2('Error al verificar desconocido en CF');
            this.logAgrega2(error.message);
            console.error(error);
          }
        )
      );
    }
  }
  
  public async empleadosImagenesCF(): Promise<IRespuestaEmpleadosListadoImagenesCF> {    
    let respuesta: IRespuestaEmpleadosListadoImagenesCF = { code: 99, mensaje: '', imágenes: {} };
    if (this._accesoExpress.servidorCF.startsWith('NM') || this.soloUsarServidorACE) {
      let url = `${this.urlBaseAcceso}/empleados/empleadosListadoImágenesCF`;
      const opciones = {
        method: 'GET',
        headers: getHeadersFetch(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey)
      };

      const respuestaFetch = await fetch(url, opciones);
      if (!respuestaFetch.ok) {        
        respuesta.mensaje = 'No ha sido posible conectarse con el servidor';
        return respuesta;
      }

      const data = await respuestaFetch.json();
      return data;
    }

    let url = `${this.urlBaseCF}/recognition/faces`;
    const opciones = {
      method: 'GET',
      headers: getHeadersFetchCF(this._accesoExpress.apiKeyCF)
    };


    const respuestaFetch = await fetch(url, opciones);
    if (!respuestaFetch.ok) {        
      respuesta.mensaje = 'No ha sido posible conectarse con el servidor';
      return respuesta;
    }

    const data = await respuestaFetch.json();
    const dataListadoImagenes: AccesoCF_RespuestaListoImagenes = data;

    let imágenesEmpleado: { [empleado: number]: string[] } = {};
    dataListadoImagenes.faces.forEach(
      imagen => {
        if (respuesta.code == 99) {
          respuesta.code = 100;
          respuesta.mensaje = `Datos cargados correctamente. (${dataListadoImagenes.faces.length})`;
        }
        let key: number = +imagen.subject;
        if (!(key in imágenesEmpleado)) {
          imágenesEmpleado[key] = [];          
        }
        imágenesEmpleado[key].push(imagen.image_id);
      }
    );
    respuesta.imágenes = imágenesEmpleado;
    return respuesta;    
  }

  public async empleadoValidarReconocimiento(idEmpleado: number, imágenesRegistradas: string[], imagen64: string, ): Promise<{ code: number, mensaje: string, respuestaReconocimiento: TipoRevisarImagenContraRegistro }> {
    let respuesta: { code: number, mensaje: string, respuestaReconocimiento: TipoRevisarImagenContraRegistro } = { code: 99, mensaje: '', respuestaReconocimiento: TipoRevisarImagenContraRegistro.NoRevisado };
    if (this._accesoExpress.servidorCF.startsWith('NM') || this.soloUsarServidorACE) {
      let url = `${this.urlBaseAcceso}/empleados/verificarReconocimiento`;
      const opciones = {
        method: 'POST',
        headers: getHeadersFetch(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey),
        body: JSON.stringify({idEmpleado, imagenesRegistradas: imágenesRegistradas})
      };

      const respuestaFetch = await fetch(url, opciones);
      if (!respuestaFetch.ok) {
        respuesta.mensaje = 'No ha sido posible conectarse con el servidor';
        return respuesta;
      }

      const data = await respuestaFetch.json();
      return data;
    }

    let params: HttpParams = new HttpParams();
    params.append('limit', 1);
    params.append('det_prob_threshold', this._det_prob_threshold);
    params.append('face_plugins', this._face_plugins);
    params.append('status', true);

    let opciones = {
      method: 'POST',
      headers: getHeadersFetch2CF(this._accesoExpress.apiKeyCF),
      body: `file: ${imagen64}`,
      params: params
    };
    

    let procesados: number = 0;
    let sumaProcesados: number = 0;
    let respuestaImagenes: { [imagen: string]: number } = { };
    
    for (let index = 0; index < imágenesRegistradas.length; index++) {      
      const imagen = imágenesRegistradas[index];

      // let url = `${this.urlBaseCF}/recognition/faces/${imagen}/verify`; // ?limit=1&det_prob_threshold=${this._det_prob_threshold}&face_plugins=${this._face_plugins}&status=true`;      
      // let body = {
      //   limit: 1,
      //   det_prob_threshold: this._det_prob_threshold,
      //   face_plugins: this._face_plugins,
      //   status: true,
      //   file: imagen64
      // }
      // opciones.body = JSON.stringify(body);
      // opciones.body = queryString.string(`limit=1&det_prob_threshold=${this._det_prob_threshold}&face_plugins=${this._face_plugins}&status=true&file=${imagen64}`);

      const params = {
        limit: '1',
        det_prob_threshold: this._det_prob_threshold,
        face_plugins: this._face_plugins,
        status: 'true'
      };
      
      let urlString = `${this.urlBaseCF}/recognition/faces/${imagen}/verify`;
      let url = new URL(urlString);
      Object.entries(params).forEach(([k, v]) => url.searchParams.append(k, v));
      console.log(url);

      const respuestaFetch = await fetch(url, opciones);
      if (!respuestaFetch.ok) {        
        respuesta.mensaje = 'No ha sido posible conectarse con el servidor';
        respuestaImagenes[imagen] = 0;
        continue;
      }

      const data = await respuestaFetch.json();
      const dataListadoImagenes: AccesoCF_RespuestaVerify = data;
      const resultadoVerificar = dataListadoImagenes.result[0];
      
      respuestaImagenes[imagen] = resultadoVerificar.similarity;
      if (resultadoVerificar.similarity == 0) {
        respuesta.mensaje = `Imagen no verificada ${imagen}`;
        respuesta.respuestaReconocimiento = TipoRevisarImagenContraRegistro.NoRevisado;
        continue;
      }

      if (resultadoVerificar.similarity < validarReconocimiento) {
        respuesta.mensaje = `Imagen no validada ${imagen}`;
        respuesta.respuestaReconocimiento = TipoRevisarImagenContraRegistro.NoValidado;
        break;
      }
      
      procesados++;
      sumaProcesados += resultadoVerificar.similarity;
    }

    let guardarValidación: boolean = new Date() <= new Date(2024, 9, 1) || respuesta.respuestaReconocimiento == TipoRevisarImagenContraRegistro.NoValidado;

    if (respuesta.respuestaReconocimiento != TipoRevisarImagenContraRegistro.NoValidado) {
      if (procesados > 0 && sumaProcesados > 0) {
        let promedio: number = sumaProcesados / procesados;
        if (promedio >= verificarReconocimientoFacial) {
          respuesta.respuestaReconocimiento = TipoRevisarImagenContraRegistro.Validado;
          respuesta.mensaje = `Imagen validada`;
        } else {
          respuesta.respuestaReconocimiento = TipoRevisarImagenContraRegistro.NoValidado;
          respuesta.mensaje = `Imagen no validada`;
          guardarValidación = true;
        }
      } else {
        respuesta.respuestaReconocimiento = TipoRevisarImagenContraRegistro.NoRevisado;
        respuesta.mensaje = `Imagen no revisada`;
      }
    }
    respuesta.code = 100;

    if (guardarValidación) {
      let url = `${this.urlBaseAcceso}/empleados/reconocimientoVerificar`;
      const opciones = {
        method: 'POST',
        headers: getHeadersFetch(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey),
        body: JSON.stringify({idEmpleado, imagenesRegistradas: imágenesRegistradas, archivo: imagen64 })
      };

      const respuestaFetch = await fetch(url, opciones);
      if (!respuestaFetch.ok) {
        respuesta.mensaje = 'No ha sido posible conectarse con el servidor';
        return respuesta;
      }

      const data = await respuestaFetch.json();      
    }

    return respuesta;
  }

  public async empleadoValidarReconocimientoFacial(idEmpleado: number, imágenesRegistradas: string[], imagen64: string ): Promise<{ code: number, mensaje: string, respuestaReconocimiento: TipoRevisarImagenContraRegistro }> { 
    let respuesta: { code: number, mensaje: string, respuestaReconocimiento: TipoRevisarImagenContraRegistro } = { code: 99, mensaje: '', respuestaReconocimiento: TipoRevisarImagenContraRegistro.NoRevisado };
    if (this._accesoExpress.servidorCF.startsWith('NM') || this.soloUsarServidorACE) {
      let url = `${this.urlBaseAcceso}/empleados/verificarReconocimiento`;
      const opciones = {
        method: 'POST',
        headers: getHeadersFetch(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey),
        body: JSON.stringify({idEmpleado, imagenesRegistradas: imágenesRegistradas})
      };

      const respuestaFetch = await fetch(url, opciones);
      if (!respuestaFetch.ok) {
        respuesta.mensaje = 'No ha sido posible conectarse con el servidor';
        return respuesta;
      }

      const data = await respuestaFetch.json();
      return data;
    }

    let procesados: number = 0;
    let sumaProcesados: number = 0;
    let respuestaImagenes: { [imagen: string]: number } = { };

    for (let index = 0; index < imágenesRegistradas.length; index++) {
      const imagen = imágenesRegistradas[index];
      let resultadoVerificar = await this.empleadoValidarImagenReconocimientoFacial(imagen, imagen64);  

      respuestaImagenes[imagen] = resultadoVerificar.subjects.similarity;
      if (resultadoVerificar.subjects.similarity == 0) {
        respuesta.mensaje = `Imagen no verificada ${imagen}`;
        respuesta.respuestaReconocimiento = TipoRevisarImagenContraRegistro.NoRevisado;
        continue;
      }

      if (resultadoVerificar.subjects.similarity < validarReconocimiento) {
        respuesta.mensaje = `Imagen no validada ${imagen}`;
        respuesta.respuestaReconocimiento = TipoRevisarImagenContraRegistro.NoValidado;
        break;
      }
      
      procesados++;
      sumaProcesados += resultadoVerificar.subjects.similarity;
    }

    let guardarValidación: boolean = new Date() <= new Date(2024, 9, 1) || respuesta.respuestaReconocimiento == TipoRevisarImagenContraRegistro.NoValidado;

    if (respuesta.respuestaReconocimiento != TipoRevisarImagenContraRegistro.NoValidado) {
      if (procesados > 0 && sumaProcesados > 0) {
        let promedio: number = sumaProcesados / procesados;
        if (promedio >= verificarReconocimientoFacial) {
          respuesta.respuestaReconocimiento = TipoRevisarImagenContraRegistro.Validado;
          respuesta.mensaje = `Imagen validada`;
        } else {
          respuesta.respuestaReconocimiento = TipoRevisarImagenContraRegistro.NoValidado;
          respuesta.mensaje = `Imagen no validada`;
          guardarValidación = true;
        }
      } else {
        respuesta.respuestaReconocimiento = TipoRevisarImagenContraRegistro.NoRevisado;
        respuesta.mensaje = `Imagen no revisada`;
      }
    }
    respuesta.code = 100;

    if (guardarValidación) {
      let url = `${this.urlBaseAcceso}/empleados/reconocimientoVerificar`;
      const opciones = {
        method: 'POST',
        headers: getHeadersFetch(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey),
        body: JSON.stringify({idEmpleado, imagenesRegistradas: imágenesRegistradas, archivo: imagen64, respuestaImagenes: respuestaImagenes })
      };

      const respuestaFetch = await fetch(url, opciones);
      if (!respuestaFetch.ok) {
        respuesta.mensaje = 'No ha sido posible conectarse con el servidor';
        return respuesta;
      }

      const data = await respuestaFetch.json();
    }

    return respuesta;
    
  }

  public async empleadosReconocimientoVerificarGuardar(idEmpleado: number, imágenesRegistradas: string[], imagen64: string, respuestaImagenes: { [imagen: string]: number } ) {
    let url = `${this.urlBaseAcceso}/empleados/reconocimientoVerificar`;
      const opciones = {
        method: 'POST',
        headers: getHeadersFetch(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey),
        body: JSON.stringify({idEmpleado, imagenesRegistradas: imágenesRegistradas, archivo: imagen64, respuestaImagenes: respuestaImagenes })
      };

      const respuestaFetch = await fetch(url, opciones);
      if (!respuestaFetch.ok) {
        return { code: 99, mensaje: 'No ha sido posible conectarse con el servidor' };
      }

      const data = await respuestaFetch.json();

      return data;
  }

  

  

  // public empleadosVerificarImagenCF(idEmpleado: number, img64: string, image_id: string): Observable<AccesoCF_RespuestaRecognize_Respuesta> {
  //   if (this._accesoExpress.servidorCF.startsWith('NM') || this.soloUsarServidorACE) {
  //     let url = `${this.urlBaseAcceso}/empleados/verificarEmpleadosCF`;
  //     return this._httpClient.get<AccesoCF_RespuestaRecognize_Respuesta>(url, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  //   }
      
    
    
    
  // }



  // function empleadoValidarRF(idImagen: string, img64: string, _httpClient: HttpClient, apiKeyReconocerRostro: string, urlBaseCF: string, _det_prob_threshold: string, _face_plugins: string): Promise<AccesoCF_RespuestaRecognize_Respuesta> {




  //   return new Promise(
  //     function(resolve, reject) => {
  //       _httpClient.post<AccesoCF_RespuestaVerify>(url, { file: img64 }, { headers: httpHeaders, params: params }).subscribe(
  //         (resultado: AccesoCF_RespuestaVerify) => {
  //           // if (this._accesoExpress.debug) this.logAgrega2(`empleadoReconocerRostro. servidor CF - Map`);  
  //           for (let index = 0; index < resultado.result.length; index++) {
  //             const result = resultado.result[index];            
  //             const subject = result.box;
  //             if (subject.probability > respuesta.subjects.similarity) {
  //               respuesta.subjects.similarity = subject.probability;
  //               respuesta.age = result.age;
  //               respuesta.gender = result.gender;
  //               respuesta.mask = result.mask;
  //               respuesta.box = result.box;
  //             }
  //             respuesta.subjects.subject = subject.probability > 0.9 ? "Con rostro" : "Sin rostro";
  //           }
  //           resolve respuesta;
  //         }, (err: Error) => {
  //           let mensaje: string = err.message;        
  //           respuesta.subjects.subject = mensaje;
  //           if (mensaje == 'No face is found in the given image') {          
  //             respuesta.subjects.subject = mensaje;          
  //           }          
  //           resolve respuesta;
  //         });
  //       )
  //    }
  //   )
  // }

  
  

  // terminado, no parece que se este usando
  public empleadoValidarRostro(idEmpleado: number, img64: string): Observable<AccesoCF_RespuestaRecognize_Respuesta> {    
    if (this._accesoExpress.servidorCF.startsWith('NM') || this.soloUsarServidorACE) {
      return this.empleadoReconocerRostroViaServidor(img64);
    }

    let url = `${this.urlBaseCF}/detection/detect`;
    let params: HttpParams = new HttpParams();
    params.append('limit', 1);
    params.append('det_prob_threshold', this._det_prob_threshold);
    params.append('face_plugins', this._face_plugins);
    params.append('status', true);

    let respuesta: AccesoCF_RespuestaRecognize_Respuesta = {
      age: nuevaAge(),
      gender: nuevoGender(),
      box: nuevaBox(),
      mask: nuevaMask(),
      subjects: {
        similarity: 0,
        subject: ''
      }
    };

    let httpHeaders: HttpHeaders = getHeadersCF(this.apiKeyReconocerRostro);

    return this._httpClient.post<AccesoCF_RespuestaRecognize>(url, { file: img64 }, { headers: httpHeaders, params: params }).pipe(
      map(
        (resultado: AccesoCF_RespuestaRecognize) => {
          // if (this._accesoExpress.debug) this.logAgrega2(`empleadoReconocerRostro. servidor CF - Map`);

          for (let index = 0; index < resultado.result.length; index++) {
            const result = resultado.result[index];            
            const subject = result.box;
            if (subject.probability > respuesta.subjects.similarity) {
              respuesta.subjects.similarity = subject.probability;
              respuesta.age = result.age;
              respuesta.gender = result.gender;
              respuesta.mask = result.mask;
              respuesta.box = result.box;
            }
            respuesta.subjects.subject = subject.probability > 0.9 ? "Con rostro" : "Sin rostro";
          }
          return respuesta;
        }, (resultadoNoReconocido: IResponseChecker) => {
          // if (this._accesoExpress.debug) this.logAgrega2(`empleadoReconocerRostro. servidor CF - Map (2) ==> Código: ${resultadoNoReconocido.code}, Mensaje: ${resultadoNoReconocido.message}`);
          respuesta.subjects.subject = `Código: ${resultadoNoReconocido.code}, Mensaje: ${resultadoNoReconocido.message}`;
          return respuesta;
        }
      ), catchError (err => {
        let mensaje: string = err.message;
        let code: number = err.code || 0;
        respuesta.subjects.subject = mensaje;
        if (mensaje == 'No face is found in the given image' || code == 28) {
          if (code > 0) mensaje = `Código: ${code}, Mensaje: ${mensaje}`;
          respuesta.subjects.subject = mensaje;          
        }
        this.logAgrega2(`empleadoReconocerRostro. servidor CF - Map (2) ==> ${mensaje}`);
        // console.log(err);        
        if (mensaje == 'Http failure response for https://ace.acexpress.com.mx/api/v1/detection/detect: 0 Unknown Error') {
          this.soloUsarServidorACE = true;
          this.logAgrega2(`empleadoReconocerRostro. activando soloUsarServidorACE`);
        }
        return of(respuesta);
      })
    );
  }

  
// ===================================================================================================================================================================
  private empleadoReconocerViaServidor(img64: string): Observable<AccesoCF_RespuestaRecognize_Respuesta> {    
    
    let url = `${this.urlBaseAcceso}/empleados/reconocimiento`;
    let httpHeaders: HttpHeaders = getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey);
    let file: AccesoCF_File = {
      file: img64
    }

    if (this._accesoExpress.debug) this.logAgrega2(`empleadoReconocerViaServidor. (1)`);
    //let fileTxt: string = JSON.stringify(file);

    // if (this._accesoExpress.debug) this.logAgrega2(`empleadoReconocerViaServidor. (2)`);
    return this._httpClient.post<AccesoCF_RespuestaRecognize_Respuesta>(url, file, { headers: httpHeaders });
  }

  private empleadoReconocerRostroViaServidor(img64: string): Observable<AccesoCF_RespuestaRecognize_Respuesta> {    
    
    let url = `${this.urlBaseAcceso}/empleados/reconocerRostro`;
    let httpHeaders: HttpHeaders = getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey);
    let file: AccesoCF_File = {
      file: img64
    }
    
    // if (this._accesoExpress.debug) this.logAgrega2(`ReconocerRostro. (1)`);
    //let fileTxt: string = JSON.stringify(file);

    // if (this._accesoExpress.debug) this.logAgrega2(`empleadoReconocerRostro. (2)`);
    return this._httpClient.post<AccesoCF_RespuestaRecognize_Respuesta>(url, file, { headers: httpHeaders });
  }

  private empleadoEliminarFotoViaServidor(idEmpleado: string, idImagen: string): Observable<IRespuestaChecker> {    
    
    let url = `${this.urlBaseAcceso}/empleados/eliminarImagen`;
    let httpHeaders: HttpHeaders = getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey);
    let fotoEmpleado: AccesoCF_EmpleadoFoto = {
      subject: idEmpleado,
      image_id: idImagen
    }
    
    if (this._accesoExpress.debug) this.logAgrega2(`eliminarImagen. (1)`);
    //let fotoEmpleadoTxt: string = JSON.stringify(fotoEmpleado);

    // if (this._accesoExpress.debug) this.logAgrega2(`eliminarImagen. (2)`);
    return this._httpClient.post<IRespuestaChecker>(url, fotoEmpleado, { headers: httpHeaders });
  }
  // ===================================================================================================================================================================

  // nuevas funciones verificando
  // ===================================================================================================================================================================

  public async empleadoValidarImagenReconocimientoFacial(imagenRegistrada: string, imagen64: string, ): Promise<AccesoCF_RespuestaRecognize_Respuesta> {     
    let respuesta: AccesoCF_RespuestaRecognize_Respuesta = {
      age: nuevaAge(),
      gender: nuevoGender(),
      box: nuevaBox(),
      mask: nuevaMask(),
      subjects: {
        similarity: 0,
        subject: identifierName.toString()
      }
    };

    let url = `${this.urlBaseCF}/recognition/faces/${imagenRegistrada}/verify`;
    let params: HttpParams = new HttpParams();
    params.append('limit', 1);
    params.append('det_prob_threshold', this._det_prob_threshold);
    params.append('face_plugins', this._face_plugins);
    params.append('status', true);

    let httpHeaders: HttpHeaders = getHeadersCF(this._accesoExpress.apiKeyCF);
    
    return new Promise((resolve) => { 
      this._httpClient.post<AccesoCF_RespuestaVerify>(url, { file: imagen64 }, { headers: httpHeaders, params: params }).pipe(
        map(
          (resultado: AccesoCF_RespuestaVerify) => {            
            const result = resultado.result[0];
            respuesta.subjects.subject = imagenRegistrada;
            respuesta.subjects.similarity = result.similarity;
            respuesta.age = result.age;
            respuesta.gender = result.gender;
            respuesta.mask = result.mask;
            respuesta.box = result.box;            
            resolve(respuesta);
          }, (error: Error) => {
            respuesta.subjects.similarity = 0;
            respuesta.subjects.subject = error.message;
            resolve(respuesta);
          }
        )
      );
    })
  }

  // ===================================================================================================================================================================

  

  public logAgrega(log: string) {
    this._log.push(log);
  }

  public logAgrega2(log: string) {
    this._log.push(log);
    console.log(log);
  }

  public log(): string[] {
    return this._log;
  }
  
}


