import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NomiExpressApiService } from 'src/app/core/services/NomiExpress.api.service';
import { AccesoDatosService } from 'src/app/core/services/acceso-datos.service';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { AccesoExpress } from 'src/app/models/accesoExpress';
import { TipoEmpleado } from 'src/app/models/empleados';
import { IEmpresa } from 'src/app/models/empresa';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar-empresa',
  templateUrl: './navbar-empresa.component.html',
  styleUrls: ['./navbar-empresa.component.scss'],
})
export class NavbarEmpresaComponent implements OnInit{
  public accesoExpress: AccesoExpress;
  public empresa: IEmpresa | undefined = undefined;
  public breadcrumb: string[] = [];
  public esDebugger: boolean = false;
  public tipoEmpleado: number = 0;

  constructor(
    public breadcrumbService: BreadcrumbService,
    private readonly _router: Router,
    private nomiExpressApi: NomiExpressApiService,
    private accesoDatosService: AccesoDatosService
  ) {
    // console.log(`navbar-empresa --> nomiExpressApi.accesoExpress`);
    this.accesoExpress = this.nomiExpressApi.accesoExpress();
    this.esDebugger = this.accesoDatosService.getModoDebug();

    this.accesoDatosService.empresaObtener().subscribe(
      (empresa: IEmpresa) => {
        this.empresa = empresa;
      },
      (error) => {
        this.accesoDatosService.logAgrega2("Error al cargar los datos de los empleados");
        console.error(error);
       }
    );
    this.tipoEmpleado = this.accesoDatosService.getUsuario().tipoEmpleado;
  }

  public ayudaWhats() {
    const url = 'https://wa.me/+5213325945957?text=¡Hola!%20Necesito%20soporte%20para%20el%20programa%20AccesoExpress';
    window.open(url, '_blank');
  }

  public enviarPerfil() {
    const url = 'https://acexpress.com.mx/account/login?returnUrl=%2FManage%2FIndex';
    window.open(url);
  }

  public cerrarSesion() {
    this._router.navigate(['']);
  }

  public obtenerNombreTipoEmpleado(): string {
    return TipoEmpleado[this.tipoEmpleado];
  }

  ngOnInit(): void {
    this.breadcrumbService.breadcrumb$.subscribe(breadcrumb => {
      this.breadcrumb = breadcrumb;
    });
  }
}
