import { Component } from '@angular/core';
import { IHorarios, nuevoHorario } from 'src/app/models/horarios';
import { mostrarSwalError } from 'src/app/core/Funciones/funciones';
import { AccesoDatosService } from 'src/app/core/services/acceso-datos.service';

@Component({
  selector: 'app-horarios',
  templateUrl: './horarios.component.html',
  styleUrls: ['./horarios.component.scss'],
})
export class HorariosComponent {

  public horariosTotales: IHorarios[] = [];
  public horariosFiltrados: IHorarios[] = [];
  public inputBuscarHorario: IHorarios | null = null;
  public cargando: boolean = false;
  public isModificando: boolean = false;

  constructor(
    private accesoDatosService: AccesoDatosService
  ) {
    this.cargando = true;
    this.cargarDatos();
  }

  public cargarDatos() {
    this.cargando = true;
    this.isModificando = false;
    this.accesoDatosService.horarios().subscribe(
      (horarios: IHorarios[]) => {
        this.horariosTotales = horarios;
        this.horariosFiltrados = horarios;
        this.cargando = false;
        this.isModificando = false;
      }, (error) => {
        console.error(error);
        let err: string = error.error;
        this.accesoDatosService.logAgrega2("Error al cargar los datos de los horarios. " + err);
        mostrarSwalError('Error', 'Error al cargar los datos de los horarios');
      }
    );
  }

  public limpiarBusqueda() {
    this.inputBuscarHorario = null;
    this.horariosFiltrados = this.horariosTotales;
  }

  public agregarHorario() {
    this.isModificando = true;
    this.horariosFiltrados = [ nuevoHorario() ];
  }

  public regresarHorarios() {
    this.cargando = true;
    this.cargarDatos();
  }

  public cargarDatosEvent(dummy: any) {
    this.cargando = true;
    this.cargarDatos();
  }

  public isModificandoEvent(idHorario: string) {
    if (idHorario == '-') {
      this.isModificando = false;
      return;
    }
    this.isModificando = true;
    this.horariosFiltrados = this.horariosFiltrados.filter(x => x.idHorario == idHorario);
  }

  public buscarHorario(value: IHorarios | null) {
    if (value && value.nombreHorario) {
      const filterValue = value.nombreHorario.toLowerCase().trim();

      this.horariosFiltrados = this.horariosTotales.filter(horario =>
        horario.nombreHorario.toLowerCase().includes(filterValue)
      );
    } else {
      this.horariosFiltrados = this.horariosTotales;
    }
  }


  public displayFn(horario: IHorarios | null): string {
    return horario ? horario.nombreHorario : '';
  }

  ngOnInit() {}
}
