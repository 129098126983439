import { CVFechaT, fFecha } from "../core/Funciones/fFecha";
import { AccesoCF_RespuestaRecognize_Respuesta } from "./accesoCF";
import { TipoOrigen as TipoOrigen } from "./datosEmpleadosAcceso";

export interface ICheck {
    id: string;
    fecha: Date;
    idEmpleado: number;
    idTerminal: string;
    tipoIngreso: TipoIngreso;
    similitud: number;
}

export interface ICheckReporte extends ICheck {
    nombre: string;
    dia: string;
    hora: string;
    RFnoLocal: boolean;
    idIncidencia: number;
    idDepartamento: number;
}

export interface IMinifiedCheck {
    a: string;
    b: string;
    c: number;
    d: string;
    e: number;
    f: number;
}

export interface ITokenValidation {
    message: string,
    type: string
}

export interface IAcceso {
    localId: string,
    fecha: string,
    companyId: number,
    employeeId: string,
    checkerAccessId: string,
    origen: TipoOrigen,
    similitud: number
}

export enum TipoIngreso {
    desconocido,
    codigoQr,
    codigoNumerico,
    reconocimientoFacial,
    reconocimientoFacialGoogle,
    reconocimientoFacialAws,
    reconocimientoFacialHeredado,
    reconocimientoFacialServidorACE
}

export const $tipoIngreso: { [key: number]: string } = {
    0: '-',
    1: 'Código QR',
    2: 'Código numérico',
    3: 'Reconocimiento Facial',
    4: 'Reconocimiento Facial (G)',
    5: 'Reconocimiento Facial (A)',
    6: 'Reconocimiento Facial (H)',
    7: 'Reconocimiento Facial (S)',
}

export function checksExpandir(checks: IMinifiedCheck): ICheck {
    return  {
        id: checks.a,
        fecha: CVFechaT(checks.b),
        idEmpleado: checks.c,
        idTerminal: checks.d,
        tipoIngreso: checks.e,
        similitud: checks.f
    }
}

export function checksReporteExpandir(checks: IMinifiedCheck, nombre: string, idDepartamento: number): ICheckReporte {
    let fecha: Date = CVFechaT(checks.b);
    return  {
        id: checks.a,
        fecha: fecha,
        idEmpleado: checks.c,
        idTerminal: checks.d,
        tipoIngreso: checks.e,
        similitud: checks.f,
        nombre: nombre,
        dia: fFecha(fecha, 'fm'), 
        hora: fFecha(fecha, 'hs'),
        RFnoLocal: checks.e > 3,
        idIncidencia: 0,
        idDepartamento: idDepartamento
    }
}