import { KeyValue } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ArchivoExcel, ColumnaExcel, creaArchivoExcel, RenglonesExcel, TítulosExcel } from 'src/app/core/Funciones/excel';
import { addDays, fechaCV, fFecha, inicioMes, inicioMesActual, inicioQuincena, inicioQuincenaActual, inicioSemanaActual, soloHoy } from 'src/app/core/Funciones/fFecha';
import { fechaT } from 'src/app/core/Funciones/fTexto';
import { mostrarSwalError, mostrarSwalToast } from 'src/app/core/Funciones/funciones';
import { AccesoDatosService } from 'src/app/core/services/acceso-datos.service';
import { PDFService } from 'src/app/core/services/pdfmake.service';
import { IEmpleados, nombreEmpleado } from 'src/app/models/empleados';
import { IEmpresa } from 'src/app/models/empresa';
import { $tipoIncapacidad, $tipoManejoIncidenciaUsuario, DatosIncidencias, IAccesoIncidenciasUsuario, IAccesoIncidenciasUsuarioCargar, TipoManejoIncidencia } from 'src/app/models/incidencias';
import { $tipoPeriodoReporte, $tipoPeriodoReporteOrden } from 'src/app/models/periodos';
import { IRespuestaChecker } from 'src/app/models/resultadoActualiza';
import Swal from 'sweetalert2';
const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-empleados-validar-incidencias',
  templateUrl: './empleados-validar-incidencias.component.html',
  styleUrls: ['./empleados-validar-incidencias.component.scss']
})
export class EmpleadosValidarIncidenciasComponent implements OnInit {
  public esDebugger: boolean = false;
  public estaCargando: boolean = true;
  public esListado: boolean = false;
  public empresa!: IEmpresa;
  public incidenciasUsuarioOriginal: IAccesoIncidenciasUsuario[] = [];
  public incidenciasUsuario: IAccesoIncidenciasUsuario[] = [];
  public nuevaIncidencia: IAccesoIncidenciasUsuario = new IAccesoIncidenciasUsuario();

  // agregar nueva incidencia
  public periodo: string = 'mes0';
  public tipoPeriodoReporte = $tipoPeriodoReporte;
  public tipoPeriodoReporteOrden = $tipoPeriodoReporteOrden;
  public inicioPersonalizado: string = '';
  public finPersonalizado: string = '';
  public diasPeriodo: number = 0;
  public agregarNuevaIncidencia: boolean = false;
  public agregarIncidenciaMasiva: boolean = false;
  public tipoManejoIncidencia = $tipoManejoIncidenciaUsuario;
  public tipoIncapacidad = $tipoIncapacidad;
  public titleIncidencia: string = 'Validación de incidencias';

  public inicioPeriodo: Date;
  public finPeriodo: Date;
  public rangoPersonalizado = new FormGroup({
    desde: new FormControl<Date | null>(null),
    hasta: new FormControl<Date | null>(null),
  });


  // empleado
  public empleadoSeleccionado: IEmpleados | undefined = undefined;
  public empleados: IEmpleados[] = [];
  public empleadosTotales: IEmpleados[] = [];
  public mostrarBajas: boolean = false;
  public soloConsulta: boolean = false;

  public displayedColumns: string[] = ['nombre', 'inicio', 'fin', 'tipoMovimiento', 'tipoIncapacidad', 'observaciones'];
  public incidenciasObs!: Observable<any>;
  public dataSource: MatTableDataSource<IAccesoIncidenciasUsuario> = new MatTableDataSource<IAccesoIncidenciasUsuario>(this.incidenciasUsuario);

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<EmpleadosValidarIncidenciasComponent>,
    private accesoDatosService: AccesoDatosService,
    private pdfService: PDFService,
    private paginadoLabel: MatPaginatorIntl,
    @Inject(MAT_DIALOG_DATA) public data: { inicio: Date, fin: Date, periodo: string, empleado: IEmpleados, soloConsulta: boolean },
  ) {
    this.inicioPeriodo = inicioMesActual();
    this.finPeriodo = addDays(new Date(this.inicioPeriodo.getFullYear(), this.inicioPeriodo.getMonth() + 1, 1), -1);
    this.cargarDatos();
    this.paginadoLabel.itemsPerPageLabel = 'Incidencias por página';
  }

  public cargarDatos() {
    this.estaCargando = true;
    this.agregarNuevaIncidencia = false;
    this.esDebugger = this.accesoDatosService.getModoDebug();
    this.accesoDatosService.empleadosListado().subscribe(
      (empleados: IEmpleados[]) => {
        this.empleados = empleados;
        this.empleadosTotales = empleados;
        this.aplicarFiltrosEmpleados();
      },
      (error) => {
        this.accesoDatosService.logAgrega2("Error al cargar los datos de los empleados");
        console.error(error);
        this.empleados = [];
        this.empleadosTotales = [];
      }
    );

    this.accesoDatosService.incidenciasUsuarioCargar({inicio: this.inicioPeriodo, inicioTxt: fechaT(this.inicioPeriodo), fin: this.finPeriodo, finTxt: fechaT(this.finPeriodo)}).subscribe(
      (respuesta: IAccesoIncidenciasUsuarioCargar) => {
        if (respuesta.code == 100) {
          this.empresa = respuesta.empresa;
          this.incidenciasUsuarioOriginal = respuesta.incidencias;
          this.dataSource.data = respuesta.incidencias;
          this.aplicarFiltrosIncidencias();
          this.estaCargando = false;
          return;
        }
        mostrarSwalError('Validación de Incidencias', `Error al cargar las incidencias, respuesta del servidor: ${respuesta.code}, ${respuesta.mensaje}`)
      },
      (error) => {
        let err: string = JSON.stringify(error);
        this.accesoDatosService.logAgrega2(err);
        mostrarSwalError('Validación de Incidencias', `Error al cargar las incidencias, ${err}`);
      }
    );
  }

  public guardarIncidencia() {
    if (!this.inicioPersonalizado) {
      mostrarSwalToast('No se ha especificado la fecha de inicio de la nueva incidencia', 'error');
      return;
    }
    if (!this.finPersonalizado) {
      mostrarSwalToast('No se ha especificado la fecha de terminación de la nueva incidencia', 'error');
      return;
    }

    this.nuevaIncidencia.inicio = fechaCV(this.inicioPersonalizado);
    this.nuevaIncidencia.fin = fechaCV(this.finPersonalizado);

    if (this.nuevaIncidencia.inicio < new Date(2000, 0, 1)) {
      mostrarSwalToast(`La fecha de inicio de la nueva incidencia (${fFecha(this.nuevaIncidencia.inicio, 'fsl')}) no es válida`, 'error');
      return;
    }

    if (this.nuevaIncidencia.fin > new Date(new Date().getFullYear() + 10, 0, 1)) {
      mostrarSwalToast(`La fecha de terminación de la nueva incidencia (${fFecha(this.nuevaIncidencia.inicio, 'fsl')}) no es válida`, 'error');
      return;
    }

    if (this.nuevaIncidencia.inicio > this.nuevaIncidencia.fin) {
      mostrarSwalToast(`La fecha de terminación de la nueva incidencia (${fFecha(this.nuevaIncidencia.inicio, 'fsl')}) no es válida, es menor a la fecha de inicio`, 'error');
      return;
    }

    if (!this.empleadoSeleccionado) {
      mostrarSwalToast(`Debe seleccionar un empleado para la nueva incidencia`, 'error');
      return;
    }

    if (!this.nuevaIncidencia.tipoManejoIncidencia) {
      mostrarSwalToast(`Debe seleccionar un Tipo de Movimiento para la nueva incidencia`, 'error');
      return;
    }

    if (this.nuevaIncidencia.tipoManejoIncidencia == TipoManejoIncidencia.Incapacidad) {
      if (!this.nuevaIncidencia.tipoIncapacidad) {
        mostrarSwalToast(`Debe seleccionar un Tipo de Incapacidad para la nueva incidencia`, 'error');
        return;
      }


      if (!this.nuevaIncidencia.observaciones) {
        mostrarSwalToast(`Debe especificar un folio para la Incapacidad de la nueva incidencia`, 'error');
        return;
      }
    }

    this.nuevaIncidencia.idEmpresa = this.empresa.id;
    this.nuevaIncidencia.idEmpleado = +this.empleadoSeleccionado.id;

    this.accesoDatosService.incidenciasUsuarioGuardar(this.nuevaIncidencia).subscribe(
      (respuesta: IRespuestaChecker) => {
        if (respuesta.code == 100) {
          mostrarSwalToast(`${respuesta.mensaje}`, 'success');
          this.cargarDatos();
          if (this.data) {
            this.empleadoSeleccionado = this.data.empleado || undefined;
          } else {
            console.warn('this.data no está definido');
            this.empleadoSeleccionado = undefined;
          }
          this.agregarNuevaIncidencia = false;
          this.adjustDialogSize();
          return;
        }
        mostrarSwalError('Validación de Incidencias', `Error al guardar las incidencias, respuesta del servidor: ${respuesta.code}, ${respuesta.mensaje}`)
      },
      (error) => {
        this.accesoDatosService.logAgrega2(JSON.stringify(error));
        let errTxt: string = error.error;
        if (!errTxt) errTxt = error.message;
        mostrarSwalError('Error al guardar las incidencias', errTxt);
      }
    );

  }

  public eliminarIncidencia(incidencia: IAccesoIncidenciasUsuario) {
    Swal.fire({
      title: "Eliminar incidencia",
      html: `¿Estás seguro de que deseas eliminar la incidencia de ${incidencia.nombreEmpleado}?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar"
    }).then((resultado) => {
      if (resultado.isConfirmed) {
        this.accesoDatosService.incidenciasUsuarioEliminar(incidencia).subscribe(
          (respuesta: IRespuestaChecker) => {
            if (respuesta.code == 100) {
              mostrarSwalToast(`Incidencia eliminada correctamente`, 'success');
              this.agregarNuevaIncidencia = !this.agregarNuevaIncidencia;
              this.cargarDatos();
              return;
            }
            mostrarSwalError('Error', `Error al eliminar la incidencia, respuesta del servidor: ${respuesta.code}, ${respuesta.mensaje}`)
          },
          (error) => {
            this.accesoDatosService.logAgrega2(JSON.stringify(error));
            let errTxt: string = error.error;
            if (!errTxt) errTxt = error.message;
            mostrarSwalError('Error al eliminar la incidencia', errTxt);
          }
        );
      }
    })
  }

  private ponerTxtEnConsola(txt: string) {
    console.log(txt);
    this.accesoDatosService.logAgrega(txt);
  }

  public aplicarFiltrosEmpleados() {
    if (this.mostrarBajas) {
      this.empleados = this.empleadosTotales;
    }  else {
      this.empleados = this.empleadosTotales.filter(x => !x.baja || x.baja.getTime() <= new Date(1900, 1, 1).getTime()).sort(
        (a, b) => +a.numero - +b.numero || a.nombre?.toLowerCase().localeCompare(b.nombre?.toLowerCase())
      );
    }

    this.empleados.forEach(empleado => {
      (empleado as any)['nombreCompleto'] = `${empleado.nombre} ${empleado.paterno} ${empleado.materno}`;
    });

    this.empleados = this.empleados;
  }

  public aplicarFiltrosIncidencias() {
    if (!this.empleadoSeleccionado || !this.empleadoSeleccionado.id) {
      this.incidenciasUsuario = this.incidenciasUsuarioOriginal.sort(
        (a, b) => b.fin.getTime() - a.fin.getTime() || a.inicio.getTime() - b.inicio.getTime() || a.nombreEmpleado.toLowerCase().localeCompare(b.nombreEmpleado.toLowerCase())
      );
      return;
    }
    let idEmpleado: number = +this.empleadoSeleccionado.id;
    this.incidenciasUsuario = this.incidenciasUsuarioOriginal.filter(x => x.idEmpleado == idEmpleado).sort(
      (a, b) => b.fin.getTime() - a.fin.getTime() || a.inicio.getTime() - b.inicio.getTime() || a.nombreEmpleado.toLowerCase().localeCompare(b.nombreEmpleado.toLowerCase())
    );

    this.dataSource.data = this.incidenciasUsuario;
  }

  public formatoFecha(fechaTxt: Date | undefined): string {
    if (!fechaTxt) {
      return '-';
    }
    const fecha = new Date(fechaTxt);
    return fFecha(fecha, "fsl");
  }

  public formatoFechaCorto(fechaTxt: Date | undefined): string {
    if (!fechaTxt) {
      return '-';
    }
    const fecha = new Date(fechaTxt);
    return fFecha(fecha, "fc");
  }

  public formatoFechaInput(fechaTxt: string | undefined): string {
    if (!fechaTxt) {
      return '-';
    }
    const fecha = new Date(fechaTxt);
    return fFecha(fecha, "fsl");
  }

  public limpiarFiltro() {
    this.empleadoSeleccionado = undefined;

    if (!this.agregarNuevaIncidencia) {
      this.cargarDatos();
    }
  }

  public toggleAgregarNuevaIncidencia() {
    this.agregarNuevaIncidencia = !this.agregarNuevaIncidencia;
    this.agregarIncidenciaMasiva = false;
    if (this.agregarNuevaIncidencia) {
      this.titleIncidencia = 'Agregar incidencia';
    } else {
      this.titleIncidencia = 'Validación de incidencias';
    }
    this.adjustDialogSize();
  }

  public toggleIncidenciaMasiva() {
    this.agregarNuevaIncidencia = false;
    this.agregarIncidenciaMasiva = true;
    this.titleIncidencia = 'Agregar incidencia masiva';
    this.adjustDialogSize();
  }

  private adjustDialogSize() {
    if (this.agregarNuevaIncidencia) {
      this.dialogRef.updateSize('40rem', 'auto');
    } else {
      this.dialogRef.updateSize('70rem', '35rem');
    }
  }

  public calcularDias() {
    if (this.inicioPersonalizado && this.finPersonalizado) {
      const fechaInicio = new Date(this.inicioPersonalizado);
      const fechaFin = new Date(this.finPersonalizado);
      const diferencia = fechaFin.getTime() - fechaInicio.getTime();
      this.diasPeriodo = diferencia / (1000 * 3600 * 24);
      this.diasPeriodo++;
    } else {
      this.diasPeriodo = 0;
    }
  }

  public nombreCompleto(empleado: IEmpleados) {
    return nombreEmpleado(empleado);
  }

  public valuePeriodoAscOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return this.tipoPeriodoReporteOrden[a.key] - this.tipoPeriodoReporteOrden[b.key];
  };

  // public exportarExcel() {
  //   let títulos: TítulosExcel = {
  //     titulo1: `Validaciones de incidencias ${this.empresa.nombre}`,
  //     titulo2: `Fecha de expedición: ${this.formatoFecha(new Date())}`,
  //     titulo3: undefined,
  //     titulo4: undefined
  //   };

  //   let columnas: ColumnaExcel[] = [
  //     { header: 'Nombre', key: 'nombre', width: 30, alignment: { vertical: 'middle', horizontal: 'left', wrapText: false } },
  //     { header: 'Inicio', key: 'inicio', width: 20, alignment: { vertical: 'middle', horizontal: 'left', wrapText: false } },
  //     { header: 'Terminación', key: 'fin', width: 20, alignment: { vertical: 'middle', horizontal: 'left', wrapText: false } },
  //     { header: 'Tipo de movimiento', key: 'tipoMovimiento', width: 20, alignment: { vertical: 'middle', horizontal: 'left', wrapText: false } },
  //     { header: 'Tipo de incapacidad', key: 'tipoIncapacidad', width: 20, alignment: { vertical: 'middle', horizontal: 'left', wrapText: false } },
  //     { header: 'Observaciones', key: 'observaciones', width: 20, alignment: { vertical: 'middle', horizontal: 'left', wrapText: false } },
  //   ];

  //   let títuloColumnas: string[] = columnas.map(columna => columna.header);

  //   let renglones: RenglonesExcel[] = this.incidenciasUsuario.map(incidencia => {
  //     let datosRenglon: any[] = [
  //       incidencia.nombreEmpleado,
  //       this.formatoFecha(incidencia.inicio),
  //       this.formatoFecha(incidencia.fin),
  //       this.tipoManejoIncidencia[incidencia.tipoManejoIncidencia],
  //       incidencia.tipoIncapacidad == TipoIncapacidad.Ninguna ? '-' : this.tipoIncapacidad[incidencia.tipoIncapacidad],
  //       incidencia.observaciones
  //     ];

  //     return { datos: datosRenglon };
  //   });

  //   let totales: string[] = [];

  //   let datos: ArchivoExcel = {
  //     nombreArchivo: `${títulos.titulo1}.xlsx`,
  //     títulos: títulos,
  //     columnas: columnas,
  //     títuloColumnas: títuloColumnas,
  //     renglones: renglones,
  //     totales: totales,
  //     fuenteTítulo: undefined,
  //     fuenteRenglones: undefined,
  //     fuenteTotales: undefined,
  //   };

  //   creaArchivoExcel(datos, 'xxx');
  // }

  public async exportarPDF() {
    try {
      const { empresaImageBase64, logoAcceso } = await this.pdfService.obtenerImg(this.empresa);
      const rows = [];

      const headerRow = [
        { text: 'ID', style: 'tableHeader' },
        { text: 'Empleado', style: 'tableHeader' },
        { text: 'Inicio', style: 'tableHeader' },
        { text: 'Terminación', style: 'tableHeader' },
        { text: 'Tipo de movimiento', style: 'tableHeader' },
        { text: 'Tipo de incapacidad', style: 'tableHeader' },
        { text: 'Observaciones', style: 'tableHeader' },
      ];

      rows.push(headerRow);

      for (const incidencia of this.incidenciasUsuario) {
        const dataRow = [
          { text: `${incidencia.id}`, style: 'tableCell' },
          { text: `${incidencia.nombreEmpleado}`, style: 'tableCell' },
          { text: `${this.formatoFechaCorto(incidencia.inicio)}`, style: 'tableCell' },
          { text: `${this.formatoFechaCorto(incidencia.fin)}`, style: 'tableCell' },
          { text: `${this.tipoManejoIncidencia[incidencia.tipoManejoIncidencia]}`, style: 'tableCell' },
          { text: `${incidencia.tipoIncapacidad == 0 ? '-' : this.tipoIncapacidad[incidencia.tipoIncapacidad]}`, style: 'tableCell' },
          { text: `${incidencia.observaciones}`, style: 'tableCell' },
        ];

        rows.push(dataRow);
      }

      const docDefinition = {
        pageOrientation: 'landscape',
        pageSize: 'letter',
        footer: (currentPage: number, pageCount: number) => {
          return {
             image: logoAcceso, width: 50, alignment: 'right',
            // columns: [
            //   { text: `Página ${currentPage} de ${pageCount}`, alignment: 'left', style: 'footer' },
            //   { image: logoNomi, width: 50, alignment: 'right' }
            // ],
            margin: [0, 10, 10, 0],
          };
        },
        content: [
          {
            columns: [
              { image: empresaImageBase64, width: 100, margin: [0, 0, 20, 0] },
              {
                stack: [
                  { text: `Validación de incidencias`, style: 'headerTitle', alignment: 'right' },
                  { text: `Fecha de expedición: ${this.formatoFecha(new Date())}`, style: 'headerDate', alignment: 'right' },
                  { text: this.empresa.nombre, style: 'headerDate', alignment: 'right' },
                  { text: this.empresa.rfc, style: 'headerDate', alignment: 'right' },
                ],
                alignment: 'right'
              }
            ],
            margin: [0, 0, 0, 10]
          },
          {
            table: {
              headerRows: 1,
              widths: [20, 150, 'auto', 'auto', 'auto', 'auto', 200],
              body: rows,
            },
            layout: {
              fillColor: (rowIndex: number) => {
                return (rowIndex === 0) ? '#3498db' : (rowIndex % 2 === 0) ? '#f9f9f9' : '#ffffff';
              },
              hLineWidth: (i: number, node: any) => 0.5,
              vLineWidth: (i: number, node: any) => 0.5,
              hLineColor: (i: number, node: any) => '#bfbfbf',
              vLineColor: (i: number, node: any) => '#bfbfbf',
            },
            margin: [0, 0, 0, 20]
          },
        ],
        styles: {
          headerTitle: { fontSize: 16, bold: true, margin: [0, 0, 0, 10] },
          headerDate: { fontSize: 10, italics: true, margin: [0, 0, 0, 0] },
          footer: { fontSize: 10, italics: true, margin: [0, 0, 0, 10] },
          tableHeader: { bold: true, fontSize: 10, color: '#283593', alignment: 'center', fillColor: '#ffffff', margin: [0, 5, 0, 5] },
          tableCell: { fontSize: 8, alignment: 'center', margin: [0, 5, 0, 5] },
          faltasTabla: { fontSize: 8, alignment: 'center', margin: [0, 5, 0, 5], color: '#dc3545', fillColor: '#f8d7da' }
        }
      };

      const fileName = 'Validación_de_incidencias.pdf';
      pdfMake.createPdf(docDefinition).download(fileName);
    } catch (error) {
      console.error('Error al generar el PDF', error);
    }
  }

  public valueTipoIncidenciaAscOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return +this.tipoManejoIncidencia[+a.key] - +this.tipoManejoIncidencia[+b.key];
  };

  public cambiarPeriodo() {
    if (this.periodo != 'personalizado') console.log(`cambiarPeriodo. periodo: ${this.periodo}`);

    let inicio: Date = this.inicioPeriodo;
    let fin: Date = soloHoy();

    switch (this.periodo) {
      case 'hoy':
        inicio = soloHoy();
        break;
      case 'semana0':
        inicio = inicioSemanaActual();
        fin = addDays(inicio, 6);
        break;
      case 'semana1a':
        inicio = addDays(inicioSemanaActual(), -7);
        fin = addDays(inicio, 6);
        break;
      case 'quincena0':
        inicio = inicioQuincenaActual();
        if (inicio.getDay() == 1) {
          fin = addDays(inicio, 14);
        } else {
          fin = addDays(new Date(inicio.getFullYear(), inicio.getMonth() + 1, 1), -1);
        }
        break;
      case 'quincena1a':
        fin = addDays(inicioQuincenaActual(), -1);
        inicio = inicioQuincena(fin);
        break;
      case 'mes0':
        inicio = inicioMesActual();
        fin = addDays(new Date(inicio.getFullYear(), inicio.getMonth() + 1, 1), -1);
        break;
      case 'mes1a':
        fin = addDays(inicioMesActual(), -1);
        inicio = inicioMes(fin);
        break;
      case 'personalizado':
        inicio = this.rangoPersonalizado.value.desde ? this.rangoPersonalizado.value.desde : this.inicioPeriodo;
        fin = this.rangoPersonalizado.value.hasta ? this.rangoPersonalizado.value.hasta : this.finPeriodo;
        console.log(`cambiarPeriodo. periodo: ${this.periodo}, ${inicio}, ${fin}`);
        break;
      default:

        return;
    }

    if (inicio.getTime() > fin.getTime()) {
      fin = inicio;
    }

    if (this.inicioPeriodo.getTime() != inicio.getTime() || this.finPeriodo.getTime() != fin.getTime()) {
      console.log(`===========>>> Nuevo periodo: ${fFecha(inicio, 'fm')}, ${fFecha(fin, 'fm')} <<<<===========`);
      this.inicioPeriodo = inicio;
      this.finPeriodo = fin;
      this.accesoDatosService.guardarDatosRegistro(this.inicioPeriodo, this.finPeriodo, this.empleadoSeleccionado);
      this.cargarDatos();
      return;
    }
  }

  public compareFn(e1: IEmpleados, e2: IEmpleados): boolean {
    return e1 && e2 ? e1.id === e2.id : e1 === e2;
  }

  public handleCerrarIncidencias() {
    this.agregarIncidenciaMasiva = false;
    this.titleIncidencia = 'Validación de incidencias';
  }

  ngOnInit(): void {
    if (this.data) {
      this.empleadoSeleccionado = this.data.empleado;
      this.soloConsulta = this.data.soloConsulta;
      if (this.data.periodo) {
        this.periodo = this.data.periodo;
        if (this.periodo === 'personalizado' && this.data.inicio && this.data.fin) {
          this.inicioPeriodo = this.data.inicio;
          this.finPeriodo = this.data.fin;
        }
      }
      this.cambiarPeriodo();
      // this.changeDetectorRef.detectChanges();
      //this.aplicarFiltrosIncidencias();
    }

    this.changeDetectorRef.detectChanges();
    this.dataSource.paginator = this.paginator;
    this.incidenciasObs = this.dataSource.connect();
    this.aplicarFiltrosEmpleados();
    this.rangoPersonalizado.valueChanges.subscribe(values => {
      if (values.desde && values.hasta) {
        this.cambiarPeriodo();
      }
    });
  }
}
