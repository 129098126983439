import { Injectable } from '@angular/core';
import  *  as CryptoJS from  'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocalService {

  private llavePrivada = environment.auth;

  constructor() { }

  public guardarDatos(llave: string, valor: string) {
    localStorage.setItem(llave, this.encrypt(valor));
  }

  public obtenerDatos(llave: string) {
    let data = localStorage.getItem(llave) || "";
    // console.log(`llave ${llave}, data: ${data}, llavePrivada: ${this.llavePrivada}`);
    let decrypted: string = this.decrypt(data);
    // console.log(`decrypted: ${decrypted}`);
    return decrypted;
  }

  public eliminarDatos(llave: string) {
    localStorage.removeItem(llave);
  }

  private encrypt(txt: string): string {
    return CryptoJS.AES.encrypt(txt, this.llavePrivada).toString();
  }

  private decrypt(txtToDecrypt: string) {
    let datos = CryptoJS.AES.decrypt(txtToDecrypt, this.llavePrivada);
    // console.log(`decrypt: ${datos}`);
    // console.log(`decrypt.toString: ${datos.toString()}`);
    return datos.toString(CryptoJS.enc.Utf8);
  }
}
