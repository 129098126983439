import { IAccesoEmpleadoRFData, IEmpleados, TipoFoto } from "./empleados"
import { IRespuestaChecker } from "./resultadoActualiza"

export interface AccesoCF_RespuestaEmpleadosListado {
  listado: AccesoCF_EmpleadosListado,
  respuesta: IRespuestaChecker
}

export interface AccesoCF_EmpleadosListado {
  subjects: string[]
}

export interface AccesoCF_RespuestaEmpleadosReconocimiento {
  sujetoReconocimiento: AccesoCF_RespuestaRecognize_Subjects,
  respuesta: IRespuestaChecker
}

export interface AccesoCF_RespuestaRecognize {
  result: AccesoCF_RespuestaRecognize_Result[],
  plugins_versions: AccesoCF_RespuestaRecognize_PluginsVersions
}

export interface AccesoCF_RespuestaVerify {
  result: AccesoCF_RespuestaRecognize_ResultVerify[],
  plugins_versions: AccesoCF_RespuestaRecognize_PluginsVersions
}

export interface AccesoCF_RespuestaRecognize_Result {
  age: AccesoCF_RespuestaRecognize_Age;
  gender: AccesoCF_RespuestaRecognize_Gender;
  mask: AccesoCF_RespuestaRecognize_Mask;
  embedding: AccesoCF_RespuestaRecognize_Embedding;
  box: AccesoCF_RespuestaRecognize_Box;
  landmarks: any[];
  subjects: AccesoCF_RespuestaRecognize_Subjects[];
  execution_time: AccesoCF_RespuestaRecognize_Execution_Time;
}

export interface AccesoCF_RespuestaRecognize_ResultVerify {
  age: AccesoCF_RespuestaRecognize_Age;
  gender: AccesoCF_RespuestaRecognize_Gender;
  mask: AccesoCF_RespuestaRecognize_Mask;
  embedding: AccesoCF_RespuestaRecognize_Embedding;
  box: AccesoCF_RespuestaRecognize_Box;
  landmarks: any[];
  similarity: number;
  execution_time: AccesoCF_RespuestaRecognize_Execution_Time;
}

export interface AccesoCF_RespuestaRecognize_Respuesta {
  age: AccesoCF_RespuestaRecognize_Age;
  gender: AccesoCF_RespuestaRecognize_Gender;
  mask: AccesoCF_RespuestaRecognize_Mask;
  box: AccesoCF_RespuestaRecognize_Box;
  subjects: AccesoCF_RespuestaRecognize_Subjects;
}

export interface AccesoCF_RespuestaRecognize_Age {
  probability: number;
  high: number;
  low: number;
}

export interface AccesoCF_RespuestaRecognize_Gender {
  probability:number;
  value: string;
}

export interface AccesoCF_RespuestaRecognize_Mask {
  probability: number;
  value: string;
}

export interface AccesoCF_RespuestaRecognize_Embedding {
  embedding: number[];
}

export interface AccesoCF_RespuestaRecognize_Box {
  probability: number;
  x_max: number;
  y_max: number;
  x_min: number;
  y_min: number;
}

export interface AccesoCF_RespuestaRecognize_Landmarks {
  _1 : number;
  _2 : number;
}

export interface AccesoCF_RespuestaRecognize_Subjects {
  similarity: number;
  subject: string;
}

export interface AccesoCF_RespuestaRecognize_Execution_Time {
  age: number;
  gender: number;
  detector: number;
  calculator: number;
  mask: number;
}


export interface AccesoCF_RespuestaRecognize_PluginsVersions {
  age: string;
  gender: string;
  detector: string;
  calculator: string;
  mask: string;
}

export interface AccesoCF_File {
  file: string
}

export interface IAccesoEmpleadoRF
{
  id: number;
  rfCode: string | undefined;
  ultimaAct: string ;
  datos: IAccesoEmpleadoRFData[];
}

export interface IEmpleadoRF {
  empleado: IEmpleados,
  empleadoRF: IAccesoEmpleadoRF
}

export class IEmpleadoImagenRF {
  idEmpleado: number = 0;
  imagen: AccesoCF_EmpleadoFoto = new AccesoCF_EmpleadoFoto();
  idImagen: number = 0;
  tipoRegistroImagen: TipoFoto = TipoFoto.Identificacion;
  datos: string = '';
}

export class AccesoCF_EmpleadoFoto {
  subject: string = '';
  image_id: string = '';
}

export function nuevoEmpleadoRF(): IAccesoEmpleadoRF {
  return {
    id: 0,
    rfCode: '',
    ultimaAct: '',
    datos: []
  };
}

export interface IAccesoEmpleadoConRFData {
  empleado: IEmpleados,
  datosRF: IAccesoEmpleadoRFData
}

export function nuevaAge(): AccesoCF_RespuestaRecognize_Age {
  return {
    probability: 0,
    high: 0,
    low: 0  
  }
}

export function nuevoGender(): AccesoCF_RespuestaRecognize_Gender {
  return {
    probability: 0,
    value: ''
  }
}

export function nuevaMask(): AccesoCF_RespuestaRecognize_Mask {
  return {
    probability: 0,
    value: ''
  }
}

export function nuevaBox(): AccesoCF_RespuestaRecognize_Box {
  return {
    probability: 0,
    x_max: 0,
    y_max: 0,
    x_min: 0,
    y_min: 0
  }
}

export interface AccesoCF_RespuestaListoImagenes {
  faces: AccesoCF_EmpleadoFoto[],
  page_number: number,
  page_size: number,
  total_elements: number,
  total_pages: number
}